import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {Button, Grid, Typography} from "@material-ui/core";
import LoadingScreen from "../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {

    const classes = useStyles();

    const {
        packageNum,
        selectedSupplier,
        selectedCustomer,
        fromExitDate,
        toExitDate,
        fromEtaDate,
        toEtaDate,
    } = props;

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const ExcelButton = (props) => {
        const exportExcel = () => {
            setLoading(true);
            new Server().emailTransitProductsExcel(packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate, response => {
                setLoading(false);
                if (response.status === 200) {
                    enqueueSnackbar('Transit products report will be emailed once ready. May take a few minutes.', {variant: 'success', autoHideDuration: 3000});
                } else {
                    console.log(response.data);
                    enqueueSnackbar('Error (see console)', {variant: 'error'});
                }
            });
        };
        return (
            <Button
                variant="contained"
                onClick={exportExcel}
            >
                <CloudDownloadIcon className={classes.buttonIcon}/>
                Excel
            </Button>
        );
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Transit products
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>

                    <Grid item>
                        <ExcelButton/>
                    </Grid>

                </Grid>
            </Grid>


            <LoadingScreen loading={loading}/>
        </Grid>
    );
};

export default Header;