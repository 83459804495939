import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Topbar from './TopBar';
import Sidebar from './Sidebar';
import {useHistory} from "react-router-dom";
import AuthService from "./AuthService";
import Server from "./Server";


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 56,
        height: '100%',
        paddingLeft: 200,//was 240
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    },
    content: {
        height: '100%'
    }
}));

const Main = props => {
    const { children } = props;

    const classes = useStyles();
    const history = useHistory();

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    //if not logged in, show login page
    if (!AuthService.isAdminLoggedIn()) {
        history.push('/auth/login');
    }

    //refresh permissions
    const userId = AuthService.loggedInId();
    new Server().fetchUser(userId, (response) => {
        if(response.status !== 200) {
            alert("Error fetching permissions.");
            history.push('/auth/login')
            return;
        }
        AuthService.updatePermissions(response.data.permissions);
    });

    return (
        <div
            className={classes.root}
        >
            <Topbar onSidebarOpen={handleSidebarOpen} />
            <Sidebar
                onClose={handleSidebarClose}
                open={true}
                variant={'persistent'}
            />
            <main className={classes.content}>
                {children}
                {/*<Footer />*/}
            </main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
