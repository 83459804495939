import React, {useRef, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import LoadingScreen from "../LoadingScreen";
import Utils from "../Utils";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const BulkUploadDialog = props => {
    const {open, onConfirm, onClose} = props;

    const classes = useStyles();
    const csvUploadInputRef = useRef();

    const [loading, setLoading] = useState(false);

    const openTemplate = () => {
        window.open("https://docs.google.com/spreadsheets/d/1-dCI-nJwXmoYS7umeizG9qmm785NyK-zNQz0OxNXtvs/edit?usp=sharing")
    };

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            csvUploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.csv')) {
            alert('A csv file ending in .csv is required.')
            return;
        }

        setLoading(true);
        new Server().uploadProductsCsv(file, (response) => {
            setLoading(false);
            if (response.status === 200) {
                window.location.reload();//reload page
            } else {
                csvUploadInputRef.current.value = null;
                alert(Utils.formatList(response.data, '\n'));
            }
        });
    };

    return (

        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">Bulk upload products</Typography></DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Duplicate the template below<br/>
                            2 - Remove example products<br/>
                            3 - Add product data using the data tab as reference<br/>
                            4 - Export as csv and upload below.<br/>
                            - Existing products and variants are overwritten.<br/>
                            - Product code, sku and size columns are required<br/>
                            - All column headers are required<br/>
                            - Size must exist on portal<br/>
                            - Sizes for a given product must belong to the same size group. (e.g a product with sizes [S, M, 20, 28] is invalid)<br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row">

                            <Grid item>
                                <Button component="label" onClick={openTemplate}>
                                    <DescriptionIcon  className={classes.buttonIcon}/>
                                    Template
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload csv
                                    <input
                                        type="file"
                                        ref={csvUploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>

    );
};

export default BulkUploadDialog;