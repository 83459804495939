import React from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Server from "../Server";

const DebitNoteDocuments = props => {

    const useStyles = makeStyles(theme => ({
        content: {
            padding: 0
        },
        actions: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        },
        filename: {
          textDecoration: 'underline'
        }
    }));

    const classes = useStyles();

    const debitNote = props.debitNote;


    const deleteDocument = (document) => {
        props.onDeleteDocument(document.id)
    };

    const downloadDocument = (document) => {
      new Server().downloadDebitNoteDocument(debitNote.number, document.id, getFileName(document));
    };

    const getFileName = (document) => {
      return document.file.replace('debit_note_documents/','')
    };

    return (
        <Card>
            <CardHeader title="Documents"/>
            <Divider/>
            <CardContent className={classes.content}>
                <List>
                    {
                        debitNote.documents.map((document, index) => {
                            return  <ListItem key={index} onClick={() => downloadDocument(document)}>
                                <ListItemText primary={getFileName(document)} className={classes.filename}/>
                                <IconButton
                                    className={classes.signOutButton}
                                    color="inherit"
                                    onClick={() => deleteDocument(document)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        })
                    }
                </List>
            </CardContent>
            <CardActions className={classes.actions}>
                <div>
                    <Button component="label">
                        <EditIcon className={classes.buttonIcon}/>
                        Add document
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={(event) => {props.onAddDocumentFile(event.target.files[0])}}
                        />
                    </Button>

                </div>
            </CardActions>
        </Card>
    );
};

export default DebitNoteDocuments;