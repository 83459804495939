import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(3)
    }
}));

const Welcome = props => {

    const classes = useStyles();

    useEffect(() => {document.title = 'Welcome';}, []);

    return (
        <div className={classes.root}>

            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>

                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Welcome!
                    </Typography>

                    <Typography
                        component="h2"
                        gutterBottom
                        variant="subtitle1"
                    >
                        Select a page on the left to get started

                    </Typography>
                </Grid>
            </Grid>


        </div>
    );
};

export default Welcome;