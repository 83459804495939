
class ProductValidator {

    constructor(product) {
        this.product = product;
    }

    isValid() {
        const product = this.product;
        return this.isValidProductCode(product.code) &&
            this.isValidHscode(product.hscode) &&
            this.isDefined(product.name, 'Name undefined') &&
            this.isDefined(product.type, 'Type undefined') &&
            this.isDefined(product.body, 'Body undefined') &&
            this.isDefined(product.brand, 'Brand undefined') &&
            this.isDefined(product.style, 'Style undefined') &&     
            this.isDefined(product.category, 'Category undefined') &&        
            this.isDefined(product.season, 'Season undefined') &&
            this.isDefined(product.color, 'Color undefined') &&
            // this.isDefined(product.supplier_color, 'Supplier color undefined') &&
            this.isDefined(product.size_group, 'Size group undefined') &&
            this.isValidMaterials(product.materials) &&
            this.isValidPrice(product.cost, 'Cost') &&
            this.isValidPrice(product.rrp, 'RRP') &&
            this.hasEmptyOrValidEans(product.variants);
    }

    isValidHscode(hscode) {
        if(hscode === '') return true;
        const regex = /^[0-9]+$/;
        const isDigitsOnly = hscode.match(regex);
        const isCorrectLength = hscode.length === 6 || hscode.length === 8 || hscode.length === 10;
        const isValid = isDigitsOnly && isCorrectLength;
        if(!isValid) {
            alert('Invalid HS code. Optional HS code can be a 6, 8 or 10 digits code.');
        }
        return isValid;
    }

    isValidProductCode(code) {
        if (!this.isDefined(code, 'Product code undefined')) {
            return false;
        }
        let regex = /^[A-Z0-9-]+$/;
        if (!code.match(regex)) {
            alert('Invalid product code: ' + code+'\nAllowed characters: Capital letters, digits and dashes.');
            return false;
        }
        return true;
    }

    isValidMaterials(materials) {
        return this.isValidMaterial(materials.primary) && this.isValidMaterial(materials.secondary);
    };

    isValidMaterial(materials) {
        if(!materials){
            return true;
        }
        let totalPercentage = 0;
        for (let i = 0; i < materials.length; i++) {
            const percentage = parseFloat(materials[i].percentage)
            totalPercentage += percentage;
            if (percentage <= 0 || percentage > 100) {
                alert('Material has to be in range 0 - 100');
                return false
            }
        }
        if (materials.length > 0 && totalPercentage !== 100) {
            alert('Total materials percentage has to be 100');
            return false;
        }
        return true;
    };

    isValidPrice(value, name) {
        if (value === null || value === undefined || value === '') {
            alert(name + ' undefined');
            return false;
        }
        const amount = parseInt(value);
        if (isNaN(amount)) {
            alert(name + ' invalid');
            return false;
        }
        return true;
    };

    isValidSku(sku, size) {
        if (!this.isDefined(size, 'No size for sku ' + sku)) {
            return false;
        }

        if (!this.isDefined(sku, 'No SKU for size: ' + size)) {
            return false;
        }

        if (!this.containsString(sku, this.product.code)) {
            alert('SKU ' + sku + ' doesnt contain product code');
            return false;
        }

        if (!this.containsString(sku, size)) {
            alert('SKU ' + sku + ' doesnt contain size');
            return false;
        }
        return true;
    };

    containsString(text, stringToCheck) {
        return text.indexOf(stringToCheck) !== -1;
    };

    isValidEan(ean) {
        let regex = /^\d+$/;
        return ean.match(regex);
    };

    hasEmptyOrValidEans(variants) {
        for(let i=0; i<variants.length; i++) {
            const ean = variants[i].ean;
            if(ean.length > 0 && !this.isValidEan(ean)) {
                alert('Invalid EAN for SKU: ' + variants[i].sku);
                return false;
            }
        }
        return true;
    }

    isDefined(property, errorMessage) {
        if (property === undefined || property === null || property === '') {
            alert(errorMessage);
            return false;
        }
        return true;
    }
}

export default ProductValidator;
