import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Filters from "./Filters";
import Header from "./Header";
import ProductsTable from "./ProductsTable";
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";
import StockPager from "../../Stock/StockPager";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const StockReport = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const defaultWarehouse = 'ALL';
    const [selectedWarehouse, setSelectedWarehouse] = useState(defaultWarehouse);

    const [code, setCode] = useState('');

    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const [fits, setFits] = useState([]);
    const [selectedFit, setSelectedFit] = useState(null);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [selectedSeason, setSelectedSeason] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');
    const [selectedCoo, setSelectedCoo] = useState('');

    const [products, setProducts] = useState([]);

    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Stock';}, []);

    useEffect(() => {
        new Server().fetchProductAttributeValues('departments', (response) => {
            setDepartments(response.data);
        });

        new Server().fetchProductAttributeValues('fits', (response) => {
            setFits(response.data);
        });

        new Server().fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });

        fetchStockProducts(defaultWarehouse,'', null, null, null, '', '', '', currentPageNumber, pageSize);
    }, []);


    const fetchStockProducts = (warehouse, code, department, fit, category, season, collection, coo, pageNumber, pageSize) => {
        setLoading(true);
        new Server().fetchStockProducts(warehouse, code, department, fit, category, season, collection, coo, pageNumber, pageSize, (response) => {
            setLoading(false);
            setProducts(response.data.products);
            setPageSize(response.data.page_info.page_size);
            setPageCount(response.data.page_info.page_count);
            setCurrentPageNumber(response.data.page_info.page_number);
            setHasNextPage(response.data.page_info.has_next)
            setHasPreviousPage(response.data.page_info.has_previous)

            setSelectedWarehouse(warehouse);
            setCode(code);
            setSelectedDepartment(department);
            setSelectedFit(fit);
            setSelectedCategory(category);
            setSelectedSeason(season);
            setSelectedCollection(collection);
            setSelectedCoo(coo);
        });
    };

    const onPageSizeChange = (event) => {
        fetchStockProducts(selectedWarehouse, code, selectedDepartment, selectedFit, selectedCategory, selectedSeason, selectedCollection, selectedCoo, currentPageNumber, event.target.value);
    };

    const onNextPageClick = (event) => {
        fetchStockProducts(selectedWarehouse, code, selectedDepartment, selectedFit, selectedCategory, selectedSeason, selectedCollection, selectedCoo,currentPageNumber + 1, pageSize);
    };

    const onPreviousPageClick = (event) => {
        fetchStockProducts(selectedWarehouse, code, selectedDepartment, selectedFit, selectedCategory, selectedSeason, selectedCollection, selectedCoo, currentPageNumber - 1, pageSize);
    };

    const onApplyFilterClick = () => {
        fetchStockProducts(selectedWarehouse, code, selectedDepartment, selectedFit, selectedCategory, selectedSeason, selectedCollection, selectedCoo, 1, pageSize);
    };

    const onClearFiltersClick = () => {
        fetchStockProducts(defaultWarehouse, '',null, null, null, '', '', '', 1, pageSize);
    };

    const onExportClick = () => {
        setLoading(true);
        new Server().export_stock_products(selectedWarehouse, code, selectedDepartment, selectedFit, selectedCategory, selectedSeason, selectedCollection, selectedCoo, (response) => {
            setLoading(false);
        });
    }

    return (
        <div className={classes.root}>

            <Header/>

            <Filters
                selectedWarehouse={selectedWarehouse} setSelectedWarehouse={setSelectedWarehouse}
                code={code} setCode={setCode}
                departments={departments} setDepartments={setDepartments}
                selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment}

                fits={fits} setFits={setFits}
                selectedFit={selectedFit} setSelectedFit={setSelectedFit}

                categories={categories} setCategories={setCategories}
                selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}

                selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason}
                selectedCollection={selectedCollection} setSelectedCollection={setSelectedCollection}
                selectedCoo={selectedCoo} setSelectedCoo={setSelectedCoo}

                onApplyFilterClick={onApplyFilterClick}
                onClearFiltersClick={onClearFiltersClick}
                onExportClick={onExportClick}
            />

            <ProductsTable products={products} className={classes.conversationPlaceholder}/>

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    );
}

export default StockReport;