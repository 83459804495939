import Card from "@material-ui/core/Card/Card";
import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Pricing from "../../Pricing";
import Server from "../../Server";

const Totals = props => {

    const useStyles = makeStyles(theme => ({
        item: {
            padding: theme.spacing(1.75),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                '&:not(:last-of-type)': {
                    borderRight: `1px solid ${theme.palette.divider}`
                }
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-of-type)': {
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        valueText: {
            fontWeight: "bold",
        },
        valueLabel: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const shNumber = props.shNumber;

    const [totals, setTotals] = useState({
        total_value: '0.00',
        currency: 'USD',
        total_cartons: '0',
        total_shipped: '0',
    })

    useEffect(() => {
        new Server().fetchShipmentStats(shNumber, (response) => {
            setTotals(response.data);
        });
    }, []);

    return (
        <Card>
            <Grid container
                  alignItems="center"
                  justifyContent="flex-start"
                  direction="row"
            >
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{Pricing.formatPrice(totals?.total_value, totals.currency)}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}></Typography>
                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{totals?.total_cartons}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>cartons</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{totals?.total_shipped}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>units</Typography>
                    </div>
                </Grid>

            </Grid>
        </Card>
    );
};
export default Totals