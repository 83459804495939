import React, {useRef, useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import LoadingScreen from "../../LoadingScreen";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";
import Server from "../../Server";
import Utils from "../../Utils";
import SyncIcon from "@material-ui/icons/Sync";


const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));


const JLPDialog = props => {

    const {open, onConfirm, onClose} = props;

    const classes = useStyles();

    const inputRef = useRef();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const DeleteProductsButton = () => {
        const handleFileSelection = (files) => {
            if (!files) {
                alert('No file selected!');
                inputRef.current.value = null;
                return;
            }
            setLoading(true);
            new Server().deleteJlpProductsCsv(files[0], (response) => {
                setLoading(false);
                if (response.status === 200) {
                    alert("The following eans have been set as deleted on JLP and their respective JLP product codes have been removed.\n" + Utils.formatList(response.data, '\n'))
                    onConfirm();
                } else if (response.status === 400) {
                    alert(Utils.formatList(response.data, '\n'));
                } else {
                    enqueueSnackbar("Error (see console)", {variant: 'error'});
                    console.log(response.data);
                }
            });
        };
        return (
            <Button component="label" color="primary">
                <CloudUploadIcon className={classes.buttonIcon} color="primary"/>
                CSV
                <input
                    type="file"
                    ref={inputRef}
                    style={{display: "none"}}
                    hidden
                    onChange={(e) => {
                        handleFileSelection(e.target.files)
                    }}
                />
            </Button>
        )
    };

    const SyncJlpIdsButton = () => {
        const synJlpIds = () => {
            setLoading(true);
            new Server().downloadChannelVariantJlpCodes(response => {
                setLoading(false);
                if (response.status === 200) {
                    enqueueSnackbar('JLP codes successfully updated', {variant: 'success'});
                    onConfirm();
                } else {
                    console.log(response.data);
                    enqueueSnackbar('Error (see console)', {variant: 'error'});
                }
            });
        };

        return (
            <Button
                component="label" color="primary"
                onClick={synJlpIds}
            >
                <SyncIcon className={classes.buttonIcon}/>
                JLP codes
            </Button>
        );
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">JLP product operations</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            <b>Set JLP products as Deleted</b>
                            <br/>
                            - This feature enables you to bulk set JLP products as Deleted on the edge and remove respective JLP product codes from portal.
                            <br/>
                            - Requires CSV with a list of EANs.
                            <br/>
                            - Expected header: EAN
                            <br/>
                            - CSV cannot contain more than 100 eans.
                            <br/>
                            - Respective products will be set as Deleted on the edge.
                            <br/>
                            - JLP product codes will be removed for given EANs.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <DeleteProductsButton/>
                    </Grid>

                    <Grid item></Grid>

                    <Grid item>
                        <Typography variant="body1" component="p">
                            <b>Download JLP product codes</b>
                            <br/>
                            - Click below to download the latest JLP product codes for non deleted products.
                            <br/>
                            - JLP Product codes for "Deleted" products will be removed.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <SyncJlpIdsButton/>
                    </Grid>

                    <Grid item></Grid>
                </Grid>
            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
};

export default JLPDialog;