import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Backdrop, CircularProgress} from "@material-ui/core";

const LoadingScreen = props => {

    const useStyles = makeStyles(theme => ({
        backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', }
    }));

    const classes = useStyles();

    return (
        <Backdrop
            className={classes.backdrop}
            open={props.loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );

};

export default LoadingScreen;
