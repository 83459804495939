import React, {useState, useEffect} from "react";
import Server from "../../Server";
import {Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const AddProductDialog = props => {

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);

    const poProducts = props.poProducts;
    const po = props.po;

    useEffect(() => {
        new Server().fetchProductCodes((response) => {
            setProducts(response.data);
        })
    }, [props.open]);

    const selectProduct = (selectedProduct) => {
        setProduct(selectedProduct);
    };

    const addProductToPO = () => {
        if (!product) {
            return;
        }
        if (productExistsInPO(product)) {
            alert("Product already exists in PO.");
            return;
        }
        new Server().addProductToPO(po.number, product, (response) => {
            if (response.status !== 200) {
                alert('Could not add product. \n Error: ' + JSON.stringify(response.data));
            }
            props.setPo(response.data);
        });
        window.location.reload();
    };
    
    const productExistsInPO = () => {
        for(let i=0; i<poProducts.length; i++){
            let summary = poProducts[i];
            if(product === summary.product.code){
                return true;
            }
        }
        return false;
    };
    
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Add product</Typography></DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={products}
                    value={product}
                    onChange={(e,v,r) => selectProduct(v)}
                    autoHighlight
                    style={{width: 300}}
                    renderInput={params => <TextField {...params} label="Product" variant="outlined"/>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={addProductToPO} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AddProductDialog;
