import React, {useEffect, useState, useRef} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Typography,
    Button,
    DialogActions, IconButton, Tooltip,
} from '@material-ui/core';
import Server from "../../Server";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/styles";

const EditShipmentInvoiceDialog = props => {

    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const input1ref = useRef();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [amount, setAmount] = useState('');
    const [number, setNumber] = useState('');

    const shipment = props.shipment;
    const invoice = shipment.invoice;

    useEffect(() => {
        if(invoice){
            setFileName(invoice.file);
            setAmount(invoice.amount);
            setNumber(invoice.number);
        }
    },[props.open])

    const confirm = () =>{
        const newInvoiceDetails = {
            file:file,
            amount:amount,
            number:number,
        }
        const errors = validate(newInvoiceDetails);
        if(errors.length>0){
            let formatted_errors = '';
            errors.forEach(error => {
                formatted_errors += error;
            })
            return alert(formatted_errors);
        }
        new Server().updateShipmentInvoice(shipment.number, newInvoiceDetails, response => {
            if(response.status !== 200){
                alert('Error occured: \n' + JSON.stringify(response.data));
                return;
            }
            props.setShipment(response.data);
        })
        props.onClose();
    };

    const validate = (newInvoiceDetails) =>{
        let errors = [];

        if(newInvoiceDetails.file === null && fileName === ''){
            errors.push('Please upload an invoice file.\n');
        }
        if(newInvoiceDetails.amount === ''){
            errors.push('Amount cannot be null.\n');
        }
        if(newInvoiceDetails.number === ''){
            errors.push('Number cannot be null.\n');
        }
        if(isNaN(newInvoiceDetails.amount)){
            errors.push('Amount must be a valid number.\n');
        }
        return errors;
    };

    const UploadInvoiceButton = () => (
        <div>
            <input type="file"
                   ref={input1ref}
                   onChange={(e) => setInvoiceFile(e.target.files)}
                   hidden/>

            <Tooltip title="Select new file">
                <IconButton size={"small"} onClick={(e) => input1ref.current.click()}>
                    <EditIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
        </div>
    );
    
    const setInvoiceFile = (files) =>{
        if (files.length === 0) {
            return;
        }

        if (!files[0].name.endsWith('.pdf')) {
            alert('A PDF file ending in .pdf is required.')
            return;
        }

        setFile(files[0]);
        setFileName(files[0].name);
        input1ref.current.value = null;
    };

    const invoiceFileName = (fileName) => {
        if (!fileName) {
            return 'No file.';
        }
        return fileName.replace('/media/invoices/', '');
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle disableTypography><Typography variant="h4">Edit invoice</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>

                    <Grid item>
                        <Grid container direction={"row"}>
                            <Grid item>
                                <Typography>{invoiceFileName(fileName)}</Typography>
                            </Grid>
                            <Grid item>
                                <UploadInvoiceButton/>
                            </Grid>
                        </Grid>
                    </Grid>
         
                    <Grid item>
                        <TextField size="small"  variant="outlined" label="Invoice Number" value={number} onChange={e=>setNumber(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <TextField size="small"  variant="outlined" label="Amount" value={amount} onChange={e=>setAmount(e.target.value)}/>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditShipmentInvoiceDialog;