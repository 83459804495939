import React, {useEffect, useState} from "react";
import Server from "../../Server";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";
import DialogActions from "@material-ui/core/DialogActions";

const EditPoDialog = props => {

    const po = props.po;

    const [supplier, setSupplier] = useState(null);
    const [issueDate, setIssueDate] = useState(new Date());
    const [exitDate, setExitDate] = useState(new Date());
    const [currency, setCurrency] = useState('GBP');
    const [ref, setRef] = useState('');

    const [suppliers, setSuppliers] = useState([]);

    const currencies = ['GBP', 'EUR', 'USD'];

    useEffect(() => {
        new Server().fetchSuppliers((response) => {
            setSuppliers(response.data)
        });

        setSupplier(po.supplier);
        setIssueDate(po.issue_date);
        setExitDate(po.exit_date);
        setCurrency(po.currency);
        setRef(po.reference);

    }, [props.open]);

    const confirm = () => {
        new Server().updatePo(po.number, supplier.id, currency, issueDate, exitDate, ref, (response) => {
            if (response.status === 200) {
                props.onConfirm(response.data);
            } else {
                alert('Failed to update PO with error: \n' + JSON.stringify(response.data, null, 2));
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Edit PO</Typography></DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Autocomplete
                            options={suppliers}
                            getOptionLabel={sup => sup.name}
                            value={supplier}
                            onChange={(e,v,r) => setSupplier(v)}
                            autoHighlight
                            style={{width: 300}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={currencies}
                            getOptionLabel={crncy => crncy}
                            value={currency}
                            onChange={(e,v,r) => setCurrency(v)}
                            autoHighlight
                            style={{width: 300}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Issue date"
                            format="DD/MM/YYYY"
                            value={issueDate}
                            size="small"
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setIssueDate(date)}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Exit date"
                            format="DD/MM/YYYY"
                            value={exitDate}
                            size="small"
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setExitDate(date)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label="Reference"
                            fullWidth
                            value={ref}
                            size="small"
                            onChange={(e) => {
                                setRef(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditPoDialog;