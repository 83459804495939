import React, {useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Server from "../Server";
import AuthService from "../AuthService";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";
import Utils from "../Utils";

const NewPackageDialog = props => {

    const history = useHistory();
    const nav = new NavigationService(history)

    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);

    const seasons = ['NOS', 'AW24', 'SS24', 'SS25', 'AW25', 'SS26', 'AW26', 'SS27', 'AW27', 'SS28', 'AW28', 'SS29', 'AW29'];
    const [season, setSeason] = useState('');

    const [currency, setCurrency] = useState('');

    const [countries, setCountries] = useState([]);
    const [coo, setCoo] = useState(null);

    const modes = ['SEA', 'AIR', 'LND'];
    const [mode, setMode] = useState(null);


    useEffect(()=>{
        if(props.open) {
            new Server().fetchSuppliers(response => {
                setSuppliers(response.data);
            });

            new Server().fetchCountries(response => {
                setCountries(response.data);
            });
        }
    },[props.open]);

    const onConfirm = () => {
        if (!Utils.validatePackageInfoInput(supplier, currency, season, mode, coo)) {
            return;
        }

        new Server().newPackage(season, supplier.id, currency, mode, coo.id, (response) => {
            if (response.status !== 200) {
                alert(response.data);
                return;
            }
            props.onConfirm();
            nav.goToConsolidatedPackage(response.data.number);
        });
    };

    const onCancel = () => {
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} disableBackdropClick={true}>
            <DialogTitle disableTypography><Typography variant="h4">New package</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={suppliers}
                            getOptionLabel={s => s.name}
                            value={supplier}
                            onChange={(e, v, r) => setSupplier(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                            disabled={AuthService.isSupplierLoggedIn()}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={['GBP', 'EUR', 'USD']}
                            getOptionLabel={t => t}
                            value={currency}
                            onChange={(e, v, r) => setCurrency(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={seasons}
                            getOptionLabel={t => t}
                            value={season}
                            onChange={(e, v, r) => setSeason(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={countries}
                            getOptionLabel={t => t.name}
                            value={coo}
                            onChange={(e, v, r) => setCoo(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="COO" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={modes}
                            getOptionLabel={t => t}
                            value={mode}
                            onChange={(e, v, r) => setMode(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Mode" variant="outlined"/>}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPackageDialog;