import React, {useRef, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import LoadingScreen from "../../LoadingScreen";
import Server from "../../Server";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const ImageSetter = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const fileUploadInputRef = useRef();

    const openTemplate = () => {
        window.open("https://docs.google.com/spreadsheets/d/122dg9M8kFgDlz_Lf3qXdBuUGCqKc3CeCkS-Yh9zHI4k/edit?usp=sharing")
    };

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            fileUploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.xlsx')) {
            alert('An excel file ending in .xlsx is required.')
            return;
        }
        setLoading(true);
        new Server().uploadStockReportWithNoImages(file, (uploadResponse) => {
            fileUploadInputRef.current.value = null;

            if (uploadResponse.status !== 200) {
                setLoading(false);
                alert("An error occurred: " + JSON.stringify(uploadResponse.data, null, 4));
                return;
            } else {
                new Server().downloadStockReportWithImages(uploadResponse.data.reference, file.name, (downloadResponse) => {
                    setLoading(false);
                    if (downloadResponse.status !== 200) {
                        alert(downloadResponse.data);
                    } else {
                        if(uploadResponse.data.missing_image_skus.length > 0) {
                            alert("No images found for the following skus: " + uploadResponse.data.missing_image_skus);
                        }
                    }
                })
            }
        });
    };

    return (
        <Card>
            <CardHeader title="Export Product Images"/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            Use this tool to set product images based on skus on a given excel report.
                            <br/>
                             - First row is ignored
                            <br/>
                            - Last row is determined by text "Grand total" in the first column
                            <br/>
                            - The first column (A) must contain skus
                            <br/>
                            - The second column (B) will contain the product image
                            <br/>
                            - All images must be uploaded via the Bulk upload tool
                            <br/>
                            - The sku must match the uploaded image file name e.g sku CPNY-22-123 must have an image file CPNY-22-123_1.jpg uploaded.
                            <br/>
                            - If a shoot image is not available, the CAD image is used.
                            <br/>
                            - Excel file must be .xlsx. Other excel formats are not supported.
                            <br/>
                            - Depending on the number of products, it may take a few minutes.
                        </Typography>
                    </Grid>

                    <Grid item>

                        <Grid item>
                            <Grid container direction="row">

                                <Grid item>
                                    <Button component="label" onClick={openTemplate}>
                                        <DescriptionIcon  className={classes.buttonIcon}/>
                                        Template
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button component="label" color="primary">
                                        <CloudUploadIcon  className={classes.buttonIcon}/>
                                        Upload
                                        <input
                                            type="file"
                                            ref={fileUploadInputRef}
                                            style={{ display: "none" }}
                                            hidden
                                            onChange={(e) => {handleFileSelection(e.target.files)}}
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </CardContent>
            <LoadingScreen loading={loading}/>
        </Card>
    );

};

export default ImageSetter;