import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Header from "./Header";
import Filters from "./Filters";
import PRTable from "./PRTable";
import StockTabs from "../../Stock/StockTabs";
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";

const ProductionReport = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            height: '100%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            padding: theme.spacing(3),
        },
    }));
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [sizegroupProducts, setSizegroupProducts] = useState([]);

    const [tabIndex, setTabIndex] = useState(0);

    const getTabNames = () => {
        let tabNames =  [];
        sizegroupProducts.forEach((sg) => {tabNames.push(sg.size_group_name)});
        return tabNames;
    };

    const getCurrentSizeGroupProduct = () => {
        const sg = getTabNames()[tabIndex];
        for(let i=0; i<sizegroupProducts.length; i++) {
            if (sizegroupProducts[i].size_group_name === sg) {
                return sizegroupProducts[i];
            }
        }
        return null;
    };

    const getProductsForTab = () => {
        const currentSgProduct = getCurrentSizeGroupProduct();
        return currentSgProduct ? currentSgProduct.products : [];
    };

    const getSizesForTab = () => {
        const currentSgProduct = getCurrentSizeGroupProduct();
        return currentSgProduct ? currentSgProduct.sizes : [];
    };

    const onSizeGroupChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    return (
        <div className={classes.root}>

            <Header/>

            <Filters setSizegroupProducts={setSizegroupProducts}/>

            <StockTabs tabNames={getTabNames()} tabIndex={tabIndex} onTabChange={onSizeGroupChange}/>

            <PRTable products={getProductsForTab()} sizes={getSizesForTab()}/>

            <LoadingScreen loading={loading}/>
        </div>
    );
}

export default ProductionReport;