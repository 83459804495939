import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import Header from "./Header";
import Filters from "./Filters";

import LoadingScreen from "../LoadingScreen";
import RatiosTable from "./RatiosTable";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const SizeRatios = props => {

    const classes = useStyles();
    const server = new Server();

    const [sizeGroups, setSizeGroups] = useState([]);

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const [fits, setFits] = useState([]);
    const [selectedFit, setSelectedFit] = useState(null);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [sizeRange, setSizeRange] = useState('');

    const [ratioRanges, setRatioRanges] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {document.title = 'Size ratios';}, []);

    useEffect(() => {
        server.fetchProductAttributeValues('sizeGroups', (response) => {
            setSizeGroups(response.data);
        })

        server.fetchProductAttributeValues('fits', (response) => {
            setFits(response.data);
        });

        server.fetchProductAttributeValues('departments', (response) => {
            setDepartments(response.data);
        });

        server.fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });

        server.fetchCustomers((response) => {
            setCustomers(response.data);
        });

    }, []);

    const reloadRatios = (selectedCustomer, selectedDepartment, selectedFit, selectedCategory, sizeRange) => {
        setLoading(true);
        server.fetchRatios(selectedCustomer, selectedDepartment, selectedFit, selectedCategory, sizeRange, (response) => {
            setLoading(false);
            setRatioRanges(response.data);
        });
    };

    const onApplyFilters = () => {
        reloadRatios(selectedCustomer, selectedDepartment, selectedFit, selectedCategory, sizeRange);
    };

    const onClearFilters = () => {
        setSelectedCustomer(null);
        setSelectedDepartment(null);
        setSelectedFit(null);
        setSelectedCategory(null);
        setSizeRange('');
        reloadRatios(null, null, null, null, '')
    };

    const onRatiosUploaded = () => {
        onApplyFilters();
    }

    return (
        <div className={classes.root}>

            <Header onRatiosUploaded={onRatiosUploaded}/>

            <Filters
                customers={customers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer}
                departments={departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment}
                fits={fits} selectedFit={selectedFit} setSelectedFit={setSelectedFit}
                categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}
                sizeRange={sizeRange} setSizeRange={setSizeRange}
                onApplyFilters={onApplyFilters} onClearFilters={onClearFilters}
            />

            <RatiosTable
                className={classes.conversationPlaceholder}
                sizeGroups={sizeGroups}
                ratioRanges={ratioRanges} setRatioRanges={setRatioRanges}
            />

            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default SizeRatios;