import {
    Card,
    IconButton,
    TableHead,
    Table,
    TableRow,
    TableContainer,
    TableCell,
    TableBody, Link
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Utils from "../../Utils";
import EditQAReportButton from "./EditQAReportButton";
import AddQAReportButton from "./AddQAReportButton";


const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));


const QAReportsTabContent = props => {

    const product = props.product;

    const classes = useStyles();

    const [reports, setReports] = useState([]);

    useEffect(() => {
        if (product?.code === 'ABC-123') return;

        reloadReports();
    }, [product, props.hidden]);

    const reloadReports = () => {
        new Server().fetchQAReports(product.code, (response) => {
            setReports(response.data);
        });
    };

    const deleteReport = (reportId) => {
        new Server().deleteQAReport(product.code, reportId, (response) => {
            reloadReports();
        });
    };

    return (
        <Card hidden={props.hidden}>

            <TableContainer>

                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">Number</TableCell>
                            <TableCell size="small">Packages</TableCell>
                            <TableCell size="small">POs</TableCell>
                            <TableCell size="small">Expires</TableCell>
                            <TableCell size="small">Added</TableCell>
                            <TableCell size="small">Last modified</TableCell>
                            <TableCell size="small">
                                <AddQAReportButton onReportAdded={reloadReports}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            reports.map(
                                (report, index) => (
                                    <TableRow key={report.id}>
                                        <TableCell size="small">
                                            <Link href={report.url} rel="noopener noreferrer" target="_blank">
                                                <span>{report.number}</span>
                                            </Link>
                                        </TableCell>

                                        <TableCell size="small">
                                            {
                                                report.packages.map(
                                                    (pkg, index) => (
                                                        <div key={pkg.id}>
                                                            <span>{pkg.number}</span>
                                                            <br/>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </TableCell>

                                        <TableCell size="small">
                                            {
                                                report.pos.map(
                                                    (po, index) => (
                                                        <div key={po.id}>
                                                            <span>{po.number}</span>
                                                            <br/>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </TableCell>
                                        <TableCell size="small">{Utils.formatDate(report.expiry_date, 'DD/MM/YYYY')}</TableCell>
                                        <TableCell size="small">{Utils.formatUserAndDate(report.created_by, report.created_date)}</TableCell>
                                        <TableCell size="small">{Utils.formatUserAndDate(report.last_modified_by, report.last_modified)}</TableCell>
                                        <TableCell size="small">

                                            <EditQAReportButton reportId={report.id} onReportEdited={reloadReports}/>


                                            <IconButton size="small" color="default"
                                                        onClick={() => deleteReport(report.id)}>
                                                <RemoveCircleIcon size="small"/>
                                            </IconButton>


                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Card>
    );
};

export default QAReportsTabContent;