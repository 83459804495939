import {Card, CardMedia} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PoProductTable from "./PoProductTable";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    cover: {
        float: 'left',
        width:  110,
        height: 182,
        objectFit: 'cover',
    },
    mainImageContainer: {
        position: "relative",
    },
}));

const PoProduct = props => {

    const classes = useStyles();

    const po = props.po;
    const poProduct = props.poProduct;

    const getMainImageUrl = () => {
        const url = poProduct.image_url;
        return url || '/images/no-image.svg';
    };

    return (
        <Card className={classes.root}>
            <div className={classes.mainImageContainer}>
                <CardMedia
                    className={classes.cover}
                    component="img"
                    image={getMainImageUrl()}
                    title={poProduct.product.code}
                />
            </div>
            <div className={classes.details}>
                <PoProductTable po={po} poProduct={poProduct}/>
            </div>
        </Card>
    )
};
export default PoProduct;