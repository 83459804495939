import React, {useEffect, useState} from "react";
import Server from "../Server";
import {Button, Grid, TextField, Typography, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Utils from "../Utils";
import NavigationService from "../NavigationService";
import {useHistory} from "react-router-dom";

const NewPoDialog = props => {

    const [newPoNumber, setNewPoNumber] = useState('PO-XYZ');
    const [issueDate, setIssueDate] = useState(new Date());
    const [exitDate, setExitDate] = useState(new Date());
    const [supplier, setSupplier] = useState(null);
    const [currency, setCurrency] = useState('GBP');
    const [reference, setReference] = useState('');

    const [suppliers, setSuppliers] = useState([]);

    const history = useHistory();
    const nav = new NavigationService(history);
    const server = new Server();

    useEffect(() => {
        server.fetchNewPoNumber((response) => {
            setNewPoNumber(response.data)
        });
        server.fetchSuppliers((response) => {
            setSuppliers(response.data)
        });
    }, [props.open]);

    const confirm = () => {
        if(supplier === null) {
            alert('Select a supplier');
            return;
        }
        if(!Utils.isValidPoNumber(newPoNumber)) {
            alert('Invalid PO number format. Expected format is PO-DDD where D is a digit.');
            return;
        }
        if(reference === '') {
            alert('Enter a reference');
            return;
        }

        const po = {
            po_number: newPoNumber,
            supplier_id: supplier.id,
            currency: currency,
            exit_date: exitDate,
            issue_date: issueDate,
            reference: reference,
        };
        server.newDraftPo(po, (response) => {
           if(response.status === 200) {
               nav.goToPoDetails(newPoNumber);
           } else {
               alert('Error creating PO : \n ' + JSON.stringify(response.data, null, 2));
           }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">New PO</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>

                        <TextField fullWidth label="PO number" variant="outlined" size="small"
                                   value={newPoNumber}
                                   onChange={(e) => setNewPoNumber(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={suppliers}
                            size="small"
                            getOptionLabel={s => s.name}
                            value={supplier}
                            onChange={(e, v, r) => setSupplier(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            fullWidth
                            autoOk
                            variant="inline"
                            size="small"
                            inputVariant="outlined"
                            label="Issued"
                            format="DD/MM/YYYY"
                            value={issueDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setIssueDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            fullWidth
                            size="small"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Exit"
                            format="DD/MM/YYYY"
                            value={exitDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setExitDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <TextField fullWidth size="small" label="Reference" variant="outlined"
                                   value={reference}
                                   onChange={(e) => setReference(e.target.value)}/>

                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={['GBP', 'EUR', 'USD']}
                            size="small"
                            getOptionLabel={w => w}
                            value={currency}
                            onChange={(e, v, r) => setCurrency(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default NewPoDialog;