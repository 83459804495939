import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Server from "../../Server";
import Utils from "../../Utils";

const EditExitPriceDialog = props => {

    const {open, onConfirm, onClose, pkgNumber} = props;

    const defaultPos = [];

    const [pos, setPos] = useState(defaultPos);

    useEffect(() => {
        if(open) {
            new Server().fetchPackageExitsAndPrices(pkgNumber, response => {
                if (response.status !== 200) {
                    alert(response.data);
                    return;
                }
                setPos(response.data);
            });
        }
    },[open])

    const onInputChange = (index, name, value) => {
        let newPos = [...pos];
        newPos[index][name] = value;
        setPos(newPos);
    };

    const onConfirmClick = () => {
        new Server().updatePackageExitsAndPrices(pkgNumber, pos, response => {
            if (response.status !== 200) {
                alert(response.data);
                return;
            }
            onConfirm();
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Edit exit dates and prices</Typography></DialogTitle>
            <DialogContent>

                <Grid container direction={"column"} spacing={1}>
                    {pos.map((po, index) => (
                            <Grid item key={po.number}>
                                <Grid container direction="row" spacing={1} alignItems={"center"}>
                                    <Grid item>
                                        <Typography style={{width: '200px'}}>
                                        {po.number} {po.product_code} {po.fit_code}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField label="Price" variant="outlined"
                                                   size="small"
                                                   value={po.price}
                                                   style={{width: '80px'}}
                                                   type={"number"}
                                                   onChange={(e) => onInputChange(index, "price", e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <KeyboardDatePicker
                                            size={"small"}
                                            inputVariant="outlined"
                                            autoOk
                                            label="Exit date"
                                            format="DD/MM/YYYY"
                                            value={po.exit_date}
                                            style={{width: '160px'}}
                                            InputAdornmentProps={{position: "start"}}
                                            onChange={date => onInputChange(index, "exit_date", Utils.formatDate(date, 'YYYY-MM-DD'))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )
                    }

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={onConfirmClick} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )

};

export default EditExitPriceDialog;