import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router-dom";

import MaterialTable from 'material-table'
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import Server from './Server'

const Suppliers = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
    }));

    const classes = useStyles();

    const [suppliers, setSuppliers] = useState([]);

    useEffect( () => {
        new Server().fetchSuppliers((response) => {
            setSuppliers(response.data)
        })
    }, [])

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Suppliers
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                className={classes.poTable}
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >

                <Grid item>

                    <MaterialTable
                        columns={[
                            {title: 'Name', field: 'name'},
                            {title: 'Phone', field: 'phone'},
                            {title: 'Email', field: 'email'},
                            {title: 'Telex reminders', field: 'telex_payment_reminders', type: 'boolean'},
                            {title: 'Username', field: 'username'},
                            {title: 'Password', field: 'password'},
                        ]}
                        data={
                            suppliers
                        }
                        options={
                            {
                                paging: false,
                                showTitle: false,
                                search: false,
                                actionsColumnIndex: 6
                            }
                        }
                        actions={[]}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        {
                                            new Server().newSupplier(newData, (response) => {
                                                setSuppliers(suppliers => {
                                                    return suppliers.concat(response.data)
                                                })
                                            })

                                        }
                                        resolve()
                                    }, 1000)
                                }),

                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        new Server().updateSupplier(newData, () => {
                                            const data = suppliers;
                                            const index = data.indexOf(oldData);

                                            setSuppliers(suppliers => {
                                                return suppliers.map((currentSupplier, j) => {
                                                    if(j === index) {
                                                        return newData
                                                    } else {
                                                        return currentSupplier
                                                    }
                                                })
                                            })
                                        })
                                        resolve()
                                    }, 100)
                                }),
                        }}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default Suppliers
