import React, {useEffect, useState} from 'react';
import Server from "../../Server";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const EditVariantDialog = props => {

    const productCode = props.productCode;
    const variantId = props.variantId;
    const open = props.open;
    const onConfirm = props.onConfirm;
    const onClose = props.onClose;

    const [ean, setEan] = useState('');
    const [sku, setSku] = useState('');
    const [sizes, setSizes] = useState([]);
    const [size, setSize] = useState({name:''});

    useEffect(() => {
        if (open) {
            new Server().fetchPossibleSizes(productCode, response => {
                setSizes(response.data);
            });
            new Server().fetchVariant(variantId, response => {
                setSku(response.data.sku);
                setEan(response.data.ean);
                setSize(response.data.size);
            });
        }
    }, [open, productCode, variantId]);

    const confirm = () => {
        new Server().updateVariant(productCode, variantId, sku, ean, size.id, response => {
            if(response.status === 200) {
                onConfirm();
            } else {
                alert(JSON.stringify(response.data));
            }
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>

            <DialogTitle disableTypography>
                <Typography variant="h4">Edit variant</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                >
                    <Grid item>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                            label="Sku">
                        </TextField>
                    </Grid>

                    <Grid item>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={ean}
                            onChange={(e) => setEan(e.target.value)}
                            label="Ean">
                        </TextField>
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={sizes}
                            getOptionLabel={s => s.name}
                            size="small"
                            value={size}
                            onChange={(e, v, r) => setSize(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Size" variant="outlined"/>}>
                        </Autocomplete>
                    </Grid>

                </Grid>

                <DialogActions>
                    <Button onClick={onClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={confirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );
};

export default EditVariantDialog;