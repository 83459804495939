import React, {Fragment, useRef, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TableHead,
    Typography
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from '@material-ui/icons/Description';
import LoadingScreen from "../../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const BulkSubmission = props => {

    const classes = useStyles();
    const csvUploadInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [uploadResults, setUploadResults] = useState([]);

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            csvUploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.csv')) {
            alert('A csv file ending in .csv is required.')
            return;
        }
        setLoading(true);
        new Server().submitZalandoProductsCsv(file, (response) => {
            setLoading(false);
            csvUploadInputRef.current.value = null;

            if (response.status !== 200) {
                alert("An error occurred: " + JSON.stringify(response.data, null, 4));
                return;
            }
            setUploadResults(response.data);
        });
    };

    const openTemplate = () => {
        window.open("https://docs.google.com/spreadsheets/d/1asERcq4u90r-VUxKl4N-NNHnoIezuyMjWP6FxbH1ns8/edit?usp=sharing")
    };

    return (
        <Card>
            <CardHeader title="Bulk product submission"/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Open the template below.
                            <br/>
                            2 - Make a copy of the template.
                            <br/>
                            3 - Input product data following guidelines in the "Instructions" sheet.
                            <br/>
                            4 - Download product data sheet as csv.
                            <br/>
                            5 - Upload the csv file below.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row">

                            <Grid item>
                                <Button component="label" onClick={openTemplate}>
                                    <DescriptionIcon  className={classes.buttonIcon}/>
                                    Template
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload csv
                                    <input
                                        type="file"
                                        ref={csvUploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item>
                        {uploadResults?.length ?

                            <TableContainer>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small">Product</TableCell>
                                            <TableCell size="small">Status</TableCell>
                                            <TableCell size="small">Errors</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            uploadResults.map((result, index) => (
                                                <TableRow key={result.product_code}>
                                                    <TableCell size="small">{result.product_code}</TableCell>
                                                    <TableCell size="small">{result.status}</TableCell>
                                                    <TableCell size="small">
                                                        <Typography variant="body1" component="p">
                                                        {
                                                            result.errors.map(error => (
                                                                <Fragment key={error}>{error}<br/></Fragment>
                                                            ))
                                                        }
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>



                            : null }
                    </Grid>
                </Grid>
            </CardContent>
            <LoadingScreen loading={loading}/>
        </Card>
    );
};

export default BulkSubmission;