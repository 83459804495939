import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";

const LengthGroups = props => {

    const sizesForGroup = (sizeGroup) => {
        var sizes = "";
        for(var i=0; i<sizeGroup.sizes.length; i++) {
            sizes += sizeGroup.sizes[i] + ' ';
        }
        return sizes;
    };

    return (
        <Card>
            <CardHeader title={"Length groups"}/>
            <Divider/>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item>
                        <List component="nav" aria-label="secondary mailbox folder">
                            {props.lengthGroups.map((row) => {
                                return (
                                    <ListItem
                                        key={row.code}
                                        button
                                        selected={props.lengthGroup.code === row.code}
                                        onClick={(event) => props.setLengthGroup(row)}
                                    >
                                        <ListItemText primary={row.code} secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {row.gender}
                                                </Typography>
                                                <br/>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {row.product_type}
                                                </Typography>
                                                <br/>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {sizesForGroup(row)}
                                                </Typography>
                                            </React.Fragment>
                                        }/>
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default LengthGroups;