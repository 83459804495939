import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles';
import Server from "../Server";
import Header from "./Header";
import PosTable from "./PosTable";
import StockPager from "../Stock/StockPager";
import AuthService from "../AuthService";
import LoadingScreen from "../LoadingScreen";

const SupplierPos = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            height: '100%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            padding: theme.spacing(3),
        },

        conversationPlaceholder: {
            flexGrow: 1
        },
    }));

    const classes = useStyles();

    const [pos, setPos] = useState([]);

    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [pageSize, setPageSize] = useState(25);

    const [loading, setLoading] = useState(false);

    useEffect(() => {document.title = 'Purchase orders';}, []);

    useEffect( () => {
        fetchPosForPage(currentPageNumber, pageSize);
    }, []);

    const fetchPosForPage = (pageNumber, pageSize) => {
        setLoading(true);
        new Server().fetchSupplierPos(AuthService.loggedInId(), pageNumber, pageSize, (response) => {
            setLoading(false);
            setPos(response.data)
            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
        });
    };

    const onPageSizeChange = (event) => {
        fetchPosForPage(1, event.target.value);
    };

    const onNextPageClick = (event) => {
        fetchPosForPage(currentPageNumber + 1, pageSize);
    };

    const onPreviousPageClick = (event) => {
        fetchPosForPage(currentPageNumber - 1, pageSize);
    };

    return (
        <div className={classes.root}>

            <Header/>

            <PosTable className={classes.conversationPlaceholder} pos={pos}/>

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default SupplierPos;
