import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Button,Grid, Typography} from "@material-ui/core";
import Server from "../../Server";
import DescriptionIcon from "@material-ui/icons/Description";
import TestData from "../../TestData";
import PoShipments from "../../PurchaseOrders/PoDetails/PoShipments";
import Utils from "../../Utils";
import NoVariantItemsTable from "./NoVariantItemsTable";
import PoProducts from "./PoProducts";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import OrderInfo from "../../PurchaseOrders/PoDetails/OrderInfo";
import AuthService from "../../AuthService";
import LoadingScreen from "../../LoadingScreen";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(3)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const SupplierPoDetails = props => {

    const { match } = props;

    let {poNumber} = match.params;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [po, setPo] = useState(TestData.makePo());
    const [poProducts, setPoProducts] = useState([]);

    const server = new Server();

    useEffect(() => {document.title = po.number;}, []);

    useEffect(() => {
        setLoading(true);
        server.fetchPoProductSummary(poNumber, (response) => {
            setLoading(false);
            setPo(response.data.po);
            setPoProducts(response.data.po_product_summaries);
        });

    }, [poNumber]);

    const POItemsComponent = props => {
        if (po.items.length === 0) {
            return (<div>No items</div>)
        } else if (po.items[0].variant === null) {//items have no variants
            return (<NoVariantItemsTable po={po}/>)
        } else {//items have variants
            return (<PoProducts po={po} poProducts={poProducts}/>)
        }
    };

    const downloadPoCSV = () => {
        new Server().downloadPoCsv(po.number);
    };

    const DownloadPoCsvButton = () => {
        if(Utils.isPoWithVariants(po)) {
            return (
                <Button
                    onClick={e => downloadPoCSV()}
                    variant="contained">
                    <CloudDownloadIcon className={classes.buttonIcon}/>
                    PO Csv</Button>
            );
        }
        return null;
    };

    // prevent other suppliers from viewing this PO
    if (po.number && AuthService.isUnauthorizedSupplier(po.supplier.id)) {
        return (<div>unauthorized</div>);
    }

    return (
        <div className={classes.root}>

            {/*header start*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Purchase order details
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {poNumber}
                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DownloadPoCsvButton/>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            {/*header end*/}

            <Grid
                className={classes.poDetails}
                container
                spacing={2}
            >

                <Grid xs={3} item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <OrderInfo po={po} setPo={setPo}/>
                        </Grid>
                        {
                            Utils.isPoWithVariants(po) ?
                                <Grid item>
                                    <PoShipments poNumber={poNumber} po={po}/>
                                </Grid>
                                : null
                        }
                    </Grid>
                </Grid>

                <Grid item xs={9}>
                    <POItemsComponent/>
                </Grid>

            </Grid>
            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default SupplierPoDetails;