import React, {useState} from 'react';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import Utils from "../../Utils";
import Pricing from "../../Pricing";

const PRTable = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            minHeight: 0
        },
        tableContainer: {
            maxHeight: '100%',
        },

    }));
    const classes = useStyles();

    const products = props.products;
    const sizes = props.sizes;

    const ProductRow = (props) => {

        const useRowStyles = makeStyles({
            productImage: {
                float: 'left',
                width:  50,
                height: 50,
                objectFit: 'cover',
                borderRadius: '25%',
            },
        });
        const rowClasses = useRowStyles();

        const product = props.product;
        const sizes = props.sizes;

        const [open, setOpen] = useState(false);

        const getRowSpan = (product) => {
            return open ? product.pos.length + 1 : 1;
        };

        const getQty = (product, size) => {
          return product.qtys[size];
        };

        const getMainImageUrl = (product) => {
            if(product.image_url === undefined || product.image_url === null || product.image_url.length === 0) {
                return '/images/no-image.svg'
            } else {
                return product.image_url;
            }
        };

        return (<React.Fragment key={product.code}>
                <TableRow>
                    <TableCell rowSpan={getRowSpan(product)}><img className={rowClasses.productImage} src={getMainImageUrl(product)} alt="product"/></TableCell>
                    <TableCell rowSpan={getRowSpan(product)}>{product.code}</TableCell>
                    <TableCell rowSpan={getRowSpan(product)}>{Pricing.formatPrice(product.rrp, 'GBP')}</TableCell>
                    <TableCell rowSpan={getRowSpan(product)}>{Pricing.formatPrice(product.wholesale_price, 'GBP')}</TableCell>
                    <TableCell rowSpan={1}>{product.pos.length}</TableCell>
                    <TableCell rowSpan={1}>-</TableCell>{/*cost*/}
                    <TableCell rowSpan={1}>{Utils.formatDate(product.exit_date, 'DD/MM/YYYY')}</TableCell>
                    {
                        sizes.map((size) => (
                            <TableCell key={size} rowSpan={1}>{getQty(product, size)}</TableCell>
                        ))
                    }

                    <TableCell rowSpan={1}>{product.total}</TableCell>
                    <TableCell rowSpan={1}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                {
                    open ? product.pos.map((po) => (
                        <TableRow key={po.number}>
                            <TableCell>{po.number}</TableCell>
                            <TableCell>{Pricing.formatPrice(po.cost, po.currency)}</TableCell>
                            <TableCell>{Utils.formatDate(po.exit_date, 'DD/MM/YYYY')}</TableCell>
                            {
                                sizes.map((size) => (
                                    <TableCell key={size}>{getQty(po, size)}</TableCell>
                                ))
                            }
                            <TableCell>{po.total}</TableCell>
                            {/*<TableCell/>*/}
                        </TableRow>
                    )) : null

                }
            </React.Fragment>
        );
    };

    const columnWidth = (100 / (sizes.length + 9)) + '%'; // 100% divided by number of columns

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size="small" className={classes.tableContainer}>
                    <colgroup>{/*set fixed column widths*/}
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        {
                            sizes.map((size) => (
                                <col key={size} style={{width: columnWidth}}/>
                            ))
                        }
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                        <col style={{width: columnWidth}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>sku</TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>Wholesale</TableCell>
                            <TableCell>POs</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Exit</TableCell>
                            {
                                sizes.map((size) => (
                                    <TableCell key={size}>{size}</TableCell>
                                ))
                            }
                            <TableCell>total</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            products.map((product) => (
                                    <ProductRow key={product.code} product={product} sizes={sizes}/>
                                )
                            )
                        }
                    </TableBody>

                </Table>

            </TableContainer>
        </Card>
    );
};

export default PRTable;