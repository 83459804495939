import {makeStyles} from "@material-ui/styles";
import React, {useEffect, useState} from "react";
import ProductService from "../ProductService";
import {Grid} from "@material-ui/core";
import ProductInfo from "./ProductInfo";
import ProductTabs from "./ProductTabs";
import Server from "../../Server";
import ProductDetailsHeader from "./ProductDetailsHeader";

const ProductDetails = props => {

    const { match } = props;

    let { productCode } = match.params;

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4),
        },
        poDetails: {
            marginTop: theme.spacing(3)
        }
    }));
    const classes = useStyles();

    const [productInventory, setProductInventory] = useState(ProductService.emptyProductInventory());

    useEffect(() => {document.title = productCode;}, []);

    useEffect( () => {
       reloadProduct();
    }, [productCode]);

    const reloadProduct = () => {
        new Server().fetchProductInventory(productCode, response => {
            if(response.status === 200) {
                console.log(response.data);
                ProductService.flatten(response.data.product);
                setProductInventory(response.data);
            } else {
                alert(response.data);
            }
        });
    };

    return (
        <div className={classes.root}>

            <ProductDetailsHeader product={productInventory.product}/>

            <Grid
                className={classes.poDetails}
                alignItems="flex-start"
                container
                spacing={3}
            >

                <Grid xs={3} item>
                    <ProductInfo product={productInventory.product} reloadProduct={reloadProduct}/>
                </Grid>

                <Grid item xs={9}>
                    <ProductTabs productInventory={productInventory}/>
                </Grid>
            </Grid>
        </div>
    );

};

export default ProductDetails;