import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Server from "./Server";
import NavigationService from "./NavigationService";
import {useHistory} from "react-router-dom";


const EntitySearchDialog = props => {

    const searchType = props.type;
    const history = useHistory();
    const nav = new NavigationService(history);

    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const confirm = () => {
        if(!searchText){
            alert(`Please enter a value before searching.`);
            return;
        }
        new Server().searchEntity(searchType, searchText, response => {
            if(response.status === 200){
                switch(searchType){
                    case 'PO':
                        nav.goToPoDetails(searchText.trim());
                        break;
                    case 'Product':
                        nav.goToProductDetails(searchText.trim());
                        break;
                    case 'POShipment':
                        nav.goToPoShipmentDetails(searchText.trim());
                        break;
                    default:
                        break;
                }
            }
            else if(response.status === 404){
                alert(`${searchText} doesn't exist.`);
            }
            else {
                alert('Error searching: \n' + JSON.stringify(response.data, null, 2));
            }
        })
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            confirm();
        }
    };

    return (
        <div>
            <IconButton onClick={() => setOpen(true)}>
                <SearchIcon/>
            </IconButton>
            <Dialog open={open}>
                <DialogTitle disableTypography>
                    <Typography variant={"h4"}>{`${searchType} Search`}</Typography>
                </DialogTitle>
                <DialogContent>
                        <TextField 
                            value={searchText} 
                            onChange={(e) => setSearchText(e.target.value)}
                            variant={"outlined"}
                            size="small"
                            autoFocus
                            onKeyDown={onKeyDown}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirm}>Go</Button>
                </DialogActions>
            </Dialog>
        </div>


    )
}

export default EntitySearchDialog;