import React, {useEffect, useState} from "react";
import {Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import Server from "../../Server";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import moment from "moment";
import Pricing from "../../Pricing";
import {makeStyles} from "@material-ui/styles";
import ProductService from "../ProductService";

const PosTabContent = props => {

    const useStyles = makeStyles(theme => ({
        poNumberCell : { cursor: 'pointer'}
    }));
    
    const classes = useStyles();

    const product = props.product;

    const [productPOs, setProductPOs] = useState([]);

    const history = useHistory();
    const nav = new NavigationService(history);

    useEffect( () => {
        if (product.code !== 'ABC-123') {
            new Server().fetchProductPOs(product.code, (response) => {
                if(response.status !== 200 || !response){
                    alert('Error occured:' + JSON.stringify(response.data, null, 2)); 
                    return;
                }
                setProductPOs(response?.data);
            });
        }
       
    }, [product]);

    return (
        <Card hidden={props.hidden}>
            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Issued</TableCell>
                            <TableCell>Exit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            productPOs.length === 0?
                                <TableRow>
                                    <TableCell colSpan={7}>No purchase orders to show</TableCell>
                                </TableRow>: null
                        }
                        {productPOs.map((purchaseOrder, index)=>(

                                <TableRow key={index}>
                                    <TableCell onClick={(e)=> nav.goToPoDetails(purchaseOrder.number)} className={classes.poNumberCell}>{purchaseOrder.number}</TableCell>
                                    <TableCell>{purchaseOrder.supplier.name}</TableCell>
                                    <TableCell>{ProductService.calculateTotalUnits(purchaseOrder, product)}</TableCell>
                                    <TableCell>{Pricing.formatPrice(ProductService.calculateTotalValue(purchaseOrder, product), purchaseOrder.currency)}</TableCell>
                                    <TableCell>{purchaseOrder.status}</TableCell>
                                    <TableCell>{moment(purchaseOrder.issue_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(purchaseOrder.exit_date).format('DD/MM/YYYY')}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default PosTabContent;