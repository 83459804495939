import Card from "@material-ui/core/Card/Card";
import {Button, Grid, Typography} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import React, {useState} from "react";
import moment from "moment";
import Server from "../../Server";
import {makeStyles} from "@material-ui/styles";
import LoadingScreen from "../../LoadingScreen";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.5),
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const DeliveryReportByOrderDateCard = props => {
    const classes = useStyles();

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [loading, setLoading] = React.useState(false);

    const downloadReport = () => {
        const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
        const formattedToDate = moment(toDate).format('YYYY-MM-DD');
        setLoading(true);
        new Server().downloadDeliveryReportByOrderDate(formattedFromDate, formattedToDate, (response) => {
            setLoading(false);
            if(response.status !== 200) {
                alert(response.data);
            }
        });
    };

    return (
        <div>
        <Card className={classes.root}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent={"flex-start"}
                >
                    <Grid item xs={2}>
                        <Typography align="center">By order date</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            fullWidth
                            autoOk
                            label="From"
                            format="DD/MM/YYYY"
                            value={fromDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromDate(date)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            fullWidth
                            autoOk
                            label="To"
                            format="DD/MM/YYYY"
                            value={toDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToDate(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={downloadReport}
                        >download
                        </Button>
                    </Grid>

                </Grid>
        </Card>
            <LoadingScreen loading={loading}/>
        </div>
    );

};

export default DeliveryReportByOrderDateCard;