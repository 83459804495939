import React from 'react';
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Pricing from "../Pricing";
import Utils from "../Utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},
    productImage: {
        float: 'left',
        width:  50,
        height: 75,
        objectFit: 'cover',
        borderRadius: '5%',
    },
}));

const TransitProductsTable = props => {
    const classes = useStyles();

    const products = props.products;

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell>ETA</TableCell>
                            <TableCell>PO</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>PO Quantity</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>Shipped</TableCell>
                            <TableCell>Shipments</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            products.map((p, index) => (
                                <TableRow key={p.order_number}>

                                    <TableCell><img className={classes.productImage} src={p.image_url} alt=""/></TableCell>
                                    <TableCell>
                                        {p.product_code} {p.fit}
                                        <br/>
                                        Ssn:{p.season} Coll:{p.collection}
                                        <br/>
                                        {Pricing.formatPrice(p.cost_price, p.currency)} -- {Pricing.formatPrice(p.rrp, 'GBP')}
                                    </TableCell>
                                    <TableCell>{Utils.formatDate(p.exit_date, 'DD/MM/YYYY')}</TableCell>
                                    <TableCell>{Utils.formatDate(p.eta_date,  'DD/MM/YYYY')}</TableCell>
                                    <TableCell>{p.package_number}/{p.po_number}</TableCell>
                                    <TableCell>{p.supplier_name}</TableCell>
                                    <TableCell>{p.customer_name}</TableCell>
                                    <TableCell>{p.total_ordered}</TableCell>
                                    <TableCell>{Pricing.formatPrice(p.total_cost, p.currency)}</TableCell>
                                    <TableCell>{Pricing.formatPrice(p.total_rrp, 'GBP')}</TableCell>
                                    <TableCell>
                                        {p.total_shipped}
                                    </TableCell>
                                     <TableCell>
                                         {
                                             p.shipment_flex_ids.map((shipment_flex_id, index) => (
                                                     <div key={shipment_flex_id.number}>{shipment_flex_id.number} {shipment_flex_id.flex_id}</div>
                                                 )
                                             )
                                         }
                                     </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
};

export default TransitProductsTable;