import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    withStyles
} from "@material-ui/core";

const PLPreviewTable = props => {

    /*
   {
               preview_id: 1,
               po_number: 'PO-789',
               product_code: 'CPNY-22-EFGH',
               goods_description: 'RANDOM NICE CLOTHES',
               total_cartons: 5,
               total_units: 10,
               sizes: ['S','M','L'],
               totals: {S: 1, M: 1, L: 1},
               carton_ranges: [{from_carton: 1, to_carton: 1, total_units: 12, total_cartons: 5, preview_id: 0, size_map: {S: 1, M: 1, L: 1} }]
           }
    */

    const pl = props.pl;

    const getQtyForSize = (carton_range, size) => {
        if(carton_range.size_map.hasOwnProperty(size) && carton_range.size_map[size]) {
            return carton_range.size_map[size];
        } else {
            return '';
        }
    };

    const HeaderTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        footer: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: 14,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const CRTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>From</HeaderTableCell>
                        <HeaderTableCell>To</HeaderTableCell>
                        {
                            pl.sizes.map((size) => (
                                <HeaderTableCell key={size}>{size}</HeaderTableCell>
                            ))
                        }
                        <HeaderTableCell>
                            Total
                        </HeaderTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        pl.carton_ranges.map((carton_range, index) => (
                                <CRTableRow key={carton_range.preview_id}>
                                    <TableCell>
                                        {carton_range.from_carton}
                                    </TableCell>

                                    <TableCell>
                                        {carton_range.to_carton}
                                    </TableCell>

                                    {
                                        pl.sizes.map((size, sizeIndex) => (
                                            <TableCell key={size}>
                                                {getQtyForSize(carton_range, size)}
                                            </TableCell>
                                        ))
                                    }
                                    <TableCell>
                                        {carton_range.total_units}
                                    </TableCell>
                                </CRTableRow>
                            )
                        )
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <HeaderTableCell colSpan={2} align="right">
                            Totals
                        </HeaderTableCell>
                        {
                            pl.sizes.map((size, sizeIndex) => (
                                <HeaderTableCell key={size}>
                                    {pl.totals[size]}
                                </HeaderTableCell>
                            ))

                        }
                        <HeaderTableCell>
                            {pl.total_units}
                        </HeaderTableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )

};

export default PLPreviewTable;