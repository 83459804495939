class Pricing {

    static formatPrice(amount, currency, minDecimals=2, maxDecimals=2) {
        if (!currency) {
            return 'NA';
        }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: minDecimals,
            maximumFractionDigits: maxDecimals,
        });

        return formatter.format(amount);
    }

    static convertToGbp(value, fromCurrency, gbpEurRate, gbpUsdRate) {
        if (fromCurrency === 'GBP') {
            return value;
        }

        switch (fromCurrency) {
            case 'GBP':
                return value;

            case 'EUR':
                //convert EUR to GBP
                //1.10 EUR/GBP means 1gbp = 1.10 EUR
                return value/gbpEurRate;

            case 'USD':
                //convert USD to GBP
                //1.25 USD/GBP means 1gbp = 1.25 USD
                return value/gbpUsdRate;

            default:
                return -1;
        }
    }

    static calculateMargin(cost, price) {
        return ((1 - (cost/price)) * 100);
    }

    static calculatePreTax(units, unitPrice, discount) {
        let preTax = units * unitPrice;
        if(discount !== 0) {
            preTax = preTax - (preTax * (discount/100));
        }
        return preTax;
    }

    static calculateTaxForAmount(amount, tax) {
        if(tax === 0) return 0;
        return amount * (tax / 100);
    }

    static calculateRoTotals(items) {
        let totalPreTax = 0, totalWithTax = 0, totalCost = 0, totalMargin = 0, totalUnits = 0;
        items.forEach((item) => {
            totalUnits += (item.units);
            totalPreTax += (item.pre_tax);
            totalWithTax += (item.pre_tax) + (item.total_tax);
            totalCost += (item.cost) * (item.units);
        });
        totalMargin = totalPreTax - totalCost;
        return {
            preTax: totalPreTax,
            withTax: totalWithTax,
            cost: totalCost,
            margin: totalMargin,
            units: totalUnits
        };
    };
}

export default Pricing;