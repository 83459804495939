import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import TestData from "../../TestData";
import {Grid} from "@material-ui/core";
import Header from "../../ConsolidatedPackages/ConsolidatedPackageDetails/Header";
import PO from "../../ConsolidatedPackages/ConsolidatedPackageDetails/PO";
import Info from "../../ConsolidatedPackages/ConsolidatedPackageDetails/Info";
import Shipments from "../../ConsolidatedPackages/ConsolidatedPackageDetails/Shipments";
import AuthService from "../../AuthService";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(3)
    }
}));

const SupplierPackageDetails = props => {
    const number = props.match.params.id;

    const classes = useStyles();

    const [searchText, setSearchText] = useState('');
    const [pkg, setPkg] = useState(TestData.packageSummary());

    useEffect(() => {document.title = number;}, []);

    useEffect(() => {
        new Server().fetchPackageSummary(number, searchText, (response) => {
            if(response.status !== 200) {
                alert(response.data);
                return;
            }
            setPkg(response.data);
        });
    }, [number, searchText]);

    // prevent other suppliers from viewing this package
    if (pkg.number && AuthService.isUnauthorizedSupplier(pkg.supplier.id)) {
        return (<div>unauthorized</div>);
    }

    return (
        <div className={classes.root}>
            <Header pkg={pkg}/>
            <Grid
                className={classes.poDetails}
                container
                direction="column"
                spacing={3}
            >

                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                        <Grid item>
                            <Info number={number}/>
                        </Grid>
                        <Grid item>
                            <Shipments number={number}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        {
                            pkg.pos.map((po) => (
                                <Grid item key={po.id}>
                                    <PO number={number} po={po}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
};

export default SupplierPackageDetails;