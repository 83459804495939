import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Card, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";


const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = (props) => {
    const classes = useStyles();
    const {
        packageNum, setPackageNum,
        suppliers, selectedSupplier, setSelectedSupplier,
        customers, selectedCustomer, setSelectedCustomer,
        fromExitDate, setFromExitDate,
        toExitDate, setToExitDate,
        fromEtaDate, setFromEtaDate,
        toEtaDate, setToEtaDate,
        onApplyFilters, onClearFilters,
    } = props;

    const onApplyFilterClick = () => {
        onApplyFilters();
    };

    const onClearFilterClick = () => {
        onClearFilters();
    };

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" direction={"row"}>

                    {/*CON number*/}
                    <Grid item>
                        <TextField
                            label="CON package"
                            placeholder={"CON1234"}
                            size="small"
                            style={{width: 150}}
                            variant="outlined"
                            value={packageNum}
                            onChange={(e) => setPackageNum(e.target.value)}/>

                    </Grid>


                    <Grid item>
                        {/*supplier*/}
                        <Autocomplete
                            options={suppliers}
                            getOptionLabel={f => f.name}
                            value={selectedSupplier}
                            onChange={(e, v, r) => setSelectedSupplier(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*customer*/}
                        <Autocomplete
                            options={customers}
                            getOptionLabel={f => f.name}
                            value={selectedCustomer}
                            onChange={(e, v, r) => setSelectedCustomer(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Customer" variant="outlined"/>}
                        />
                    </Grid>

                    {/*exit date range*/}
                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            style={{width: 160}}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="Exit from"
                            format="DD/MM/YYYY"
                            value={fromExitDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromExitDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            style={{width: 160}}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="Exit to"
                            format="DD/MM/YYYY"
                            value={toExitDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToExitDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            style={{width: 160}}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="ETA from"
                            format="DD/MM/YYYY"
                            value={fromEtaDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromEtaDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            style={{width: 160}}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="ETA to"
                            format="DD/MM/YYYY"
                            value={toEtaDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToEtaDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <Button color="primary" onClick={onApplyFilterClick}>
                            Apply
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={onClearFilterClick}>
                            Clear
                        </Button>
                    </Grid>

                </Grid>
            </Card>
        </div>
    );
};

export default Filters;