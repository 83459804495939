import {Grid, TextField, Card} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {KeyboardDatePicker} from "@material-ui/pickers";

const Filters = props => {
    const useStyles = makeStyles(theme => ({
        rootCrd: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={2} alignItems="center">

                    <Grid item>
                        {/*channel*/}
                        <Autocomplete
                            disableClearable
                            options={props.channels}
                            getOptionLabel={channel => channel.name}
                            defaultValue={props.channels[0]}
                            value={props.selectedChannel}
                            onChange={(e, v, r) => props.setSelectedChannel(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Channel" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="From"
                            format="DD/MM/YYYY"
                            value={props.fromDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => props.setFromDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="To"
                            format="DD/MM/YYYY"
                            value={props.toDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => props.setToDate(date)}
                        />
                    </Grid>

                </Grid>
            </Card>
        </div>
    );
};

export default Filters;