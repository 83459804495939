import React, {useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Server from "../../Server";
import Utils from "../../Utils";
import LoadingScreen from "../../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const UploadVariantsDialog = props => {

    const {open, onConfirm, onClose} = props;

    const classes = useStyles();

    const inputRef = useRef();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const UploadButton = () => (
        <Button component="label" color="primary">
            <CloudUploadIcon  className={classes.buttonIcon} color="primary"/>
            upload
            <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                hidden
                onChange={(e) => {handleFileSelection(e.target.files)}}
            />
        </Button>
    );

    const handleFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            inputRef.current.value = null;
            return;
        }
        setLoading(true);
        new Server().uploadVariantsCsv(files[0], (response) => {
            setLoading(false);
            if(response.status === 200) {
                enqueueSnackbar("Variants added successfully", {variant: 'success'});
                onConfirm();
            } else if(response.status === 400) {
                alert(Utils.formatList(response.data, '\n'));
            } else {
                enqueueSnackbar("Error (see console)", {variant: 'error'});
                console.log(response.data);
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">New variants</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            - Products added on BrightPearl are automatically added here if sku and ean are set.
                            <br/>
                            - Any new products need to be added on BrightPearl first.
                            <br/>
                            - The BrightPearl product id is required in the csv column BP_ID.
                            <br/>
                            - The BrightPearl tax code is required in the csv column UK_TAX_CODE.
                            <br/>
                            - Expected csv headers: SKU,EAN,BP_ID,UK_TAX_CODE,PRODUCT_CODE
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <UploadButton/>
                    </Grid>
                </Grid>
            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    )
};

export default UploadVariantsDialog;