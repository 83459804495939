import Pricing from "../../Pricing";
import MaterialTable from "material-table";
import React from "react";


const NoVariantItemsTable = props => {
    const po = props.po;

    return (
        <div>
            <MaterialTable
                title="Items"
                columns={[
                    {title: 'SKU', field: 'sku', cellStyle: {width: '50%'}},
                    {title: 'Size', field: 'size', cellStyle: {width: '5%'}},
                    {title: 'EAN', field: 'ean', cellStyle: {width: '25%'}},
                    {title: 'Price', field: 'price', cellStyle: {width: '5%'}, render: rowData => <label>{Pricing.formatPrice(rowData.price, po.currency)}</label>},
                    {title: 'Units', field: 'units', cellStyle: {width: '5%'}},
                    {title: 'Shipped', field: 'units_shipped', editable: 'never', cellStyle: {width: '5%'}},
                    {title: 'Outstanding', field: 'units_outstanding', editable: 'never', cellStyle: {width: '5%'}},
                ]}
                options={
                    {
                        paging: false,
                        search: false,
                        showTitle: true,
                        toolbar: true,
                    }
                }
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No items'
                    }
                }}
                data={
                    po.items
                }

            />
        </div>
    );
}

export default NoVariantItemsTable;