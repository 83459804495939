import React, {useRef, useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingScreen from "../../../LoadingScreen";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {makeStyles} from "@material-ui/styles";
import Server from "../../../Server";
import PLExcelValidation from "./PLExcelValidation";
import ExcelPLsPreview from "./ExcelPLsPreview";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    redText: {
        color: theme.palette.red,
    },
}));

const ExcelPlUploadDialog = props => {

    const classes = useStyles();

    const shipment = props.shipment;

    const input1ref = useRef();

    const [file, setFile] = useState(null);
    const [step, setStep] = useState(0);//step 0 == validation      step 1 == preview   FIXME
    const [loading, setLoading] = useState(false);
    /*
    [
        {sheetName: 'sheet1', errors: []},
        {sheetName: 'sheet2', errors: [
                'RO number missing',
                'SKU missing',
                'SKU Header missing'
            ]
        },
     ]
     */
    const [validationResults, setValidationResults] = useState( []);
    const defaultPreview = {
        total_cartons: 0,
        total_units: 0,
        packing_lists: []
    };
    const [plPreview, setPlPreview] = useState(defaultPreview);

    const cancel = () => {
        setFile(null);
        setStep(0);
        setValidationResults([]);
        setPlPreview(defaultPreview);
        props.onClose();
    };

    const back = () => {
        if(step === 1) {
            setStep(0);
        }
    };

    const proceed = () => {
      if(step === 0) {
          proceedFromValidationStep();
      } else if (step === 1) {
          proceedFromPreviewStep();
      }
    };

    const getSheetNames = (valid) => {
        let validSheetNames = [];
        let invalidSheetNames = [];
        for(let i=0; i<validationResults.length; i++) {
            const sheetName = validationResults[i].sheet_name;
            if(validationResults[i].errors.length === 0) {
                validSheetNames.push(sheetName);
            } else {
                invalidSheetNames.push(sheetName);
            }
        }
        return valid ? validSheetNames : invalidSheetNames;
    };

    const proceedFromValidationStep = () => {
        const validSheetNames = getSheetNames(true);
        const invalidSheetNames = getSheetNames(false);

        //if all sheets are invalid show error
        if(validSheetNames.length === 0) {
            alert('All excel sheets contain errors. Fix the errors before proceeding.');
            return;
        }

        //if some sheets are invalid prompt user to confirm and proceed
        if(invalidSheetNames.length > 0) {
            let message = 'The following excel sheets will not be processed: \n';
            for(let i=0; i<invalidSheetNames.length; i++) {
                message += invalidSheetNames[i] + '\n';
            }
            message += '\nIf these sheets are not packing lists, click OK.\n';
            message += 'If one of these sheets is a packing list, then please correct the excel file before proceeding.\n';

            if(!window.confirm(message)) {
                return;
            }
        }

        //request preview for valid sheets
        setLoading(true);
        new Server().previewExcelPls(shipment.number, file, validSheetNames, (response) => {
            setLoading(false);
            if (response.status === 200) {
                setPlPreview(response.data);
                setStep(1);
            } else {
                alert(response.data);
            }
        })
    };

    const proceedFromPreviewStep = () => {
        //create PLs!
        const validSheetNames = getSheetNames(true);
        setLoading(true);
        new Server().createExcelPls(shipment.number, file, validSheetNames, (response) => {
            setLoading(false);
            if (response.status === 200) {
                window.location.reload();
            } else {
                alert(response.data);
            }
        })
    };

    const SelectExcelFileButton = () => (
            <Button variant="contained" component="label" color={"primary"}>
                <CloudUploadIcon  className={classes.buttonIcon}/>
                {file ? file.name : 'Upload .xlsx file'}
                <input
                    type="file"
                    ref={input1ref}
                    style={{ display: "none" }}
                    hidden
                    onChange={(e) => {handleExcelFileSelection(e.target.files)}}
                />
            </Button>
    );

    const downloadExcelPLTemplate = (templateSize) => {
        new Server().downloadExcelPLTemplate(templateSize, templateSize + '_excel_pl_template.xlsx');
    };

    const LargeTemplateButton = () => (
        <Button
            variant="contained"
            onClick={() => {downloadExcelPLTemplate('large')}}
        >
            <CloudDownloadIcon className={classes.buttonIcon}/>
            Empty template
        </Button>
    );

    const PrefilledTemplateButton = () => {
        const onClick = () => {
            const pkgNumber = prompt("Please enter the package number to ship: (e.g CON1234)", "");
            if (!pkgNumber) return;

            const filename = pkgNumber + '_prefilled_packinglists.xlsx'
            setLoading(true);
            new Server().downloadPrefilledExcelPLTemplate(pkgNumber, filename, (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert(response.data);
                }
            });
        };

        return (
            <Button
                variant="contained"
                onClick={onClick}
            >
                <CloudDownloadIcon className={classes.buttonIcon}/>
                Prefilled template
            </Button>
        );
    };

    const CustomersCsvButton = () => {
        return (
            <Button
                variant="contained"
                onClick={() => {
                    new Server().downloadCustomersCsv();
                }}
            >
                <CloudDownloadIcon className={classes.buttonIcon}/>
                Customer list
            </Button>
        )
    };

    const handleExcelFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            setFile(null)
            input1ref.current.value = null;
            return;
        }
        setLoading(true);
        new Server().validatePackingListExcelSheets(shipment.number, files[0], (response) => {
            setLoading(false);
            if(response.status === 200) {
                setValidationResults(response.data);
                setFile(files[0]);
            } else {
                alert(response.data);
            }
        });

        new Server().storePackingListExcel(shipment.number, files[0]);

    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle disableTypography><Typography variant="h4">Excel packing lists upload</Typography></DialogTitle>
            <DialogContent>

                <Paper hidden={step !== 0} elevation={0}>
                    <Grid  container direction="column" spacing={3}>
                        <Grid item>
                            <Typography className={classes.redText}>
                                14 may 2024 update <br/>
                                - Use the prefilled template below with prefilled fields: customer, po, customer po and sku.<br/>
                                - Prefilled templates are only available for packaged pos.<br/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <Grid item><PrefilledTemplateButton/></Grid>
                                <Grid item><SelectExcelFileButton/></Grid>
                                <Grid item><CustomersCsvButton/></Grid>
                                <Grid item><LargeTemplateButton/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography>For support, email amine@onesigma.co.uk with the excel file attached mentioning the PO and shipment number.</Typography>
                        </Grid>
                        <Grid item>
                            <PLExcelValidation results={validationResults}/>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper hidden={step !== 1} elevation={0}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <ExcelPLsPreview plPreview={plPreview}/>
                        </Grid>
                    </Grid>
                </Paper>

            </DialogContent>

            <DialogActions>
                <Button onClick={() => {cancel()}} color="default">
                    Cancel
                </Button>
                <Button onClick={() => {back()}} color="default">
                    Back
                </Button>
                <Button onClick={() => {proceed()}} color="primary">
                    Proceed
                </Button>
            </DialogActions>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
};

export default ExcelPlUploadDialog;