import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, Typography} from "@material-ui/core";
import BulkImageUpload from "./BulkImageUpload";
import BulkSubmission from "./BulkSubmission";
import BulkPriceSubmission from "./BulkPriceSubmission";
import BulkCadUpload from "./BulkCadUpload";

const BulkUpload = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Bulk Upload
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>


            {/*content start*/}
            <Grid
                className={classes.poTable}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item>
                    <BulkImageUpload/>
                </Grid>

                <Grid item>
                    <BulkCadUpload/>
                </Grid>

                <Grid item>
                    <BulkSubmission/>
                </Grid>

                <Grid item>
                    <BulkPriceSubmission/>
                </Grid>

            </Grid>
            {/*content end*/}

        </div>
    );
}

export default BulkUpload;