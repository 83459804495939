import React, {useState} from "react";
import {RIENumber} from "riek";
import Pricing from "../../../Pricing";

const EditablePrice = (props) => {
    const [amount, setAmount] = useState(props.amount);
    const currency = props.currency;

    const validateName = (obj) => {
        return obj !== '';
    };

    const onValueChange = (obj) => {
        setAmount(obj.value);
        props.onChange(obj.value);
    };

    return (
        <RIENumber
            value={amount}
            classEditing={props.classEditing}
            change={onValueChange}
            propName='value'
            validate={validateName}
            format={(amount) => {return Pricing.formatPrice(amount, currency, 2, 3)}}
        />
    );
};

export default EditablePrice;