import {Button, Grid, Typography} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import NewPoDialog from "./NewPoDialog";
import EntitySearchDialog from "../EntitySearchDialog";

const POsHeader = props => {
    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const searchType = 'PO';

    const [openNewPoDialog, setOpenNewPoDialog] = useState(false);

    const onNewPoClick = (event) => {
        setOpenNewPoDialog(true);
    };

    const onConfirmNewPoDialog = (code) => {
        // nav.goToEditProduct(code);
    };

    const onCloseNewPoDialog = () => {
        setOpenNewPoDialog(false);
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <Typography
                            component="h1"
                            variant="h3"
                        >
                            Purchase Orders
                        </Typography>
                    </Grid>
                    <Grid item>
                        <EntitySearchDialog type={searchType}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onNewPoClick}
                >
                    <AddCircleIcon className={classes.buttonIcon}/>
                    New PO
                </Button>
            </Grid>
            <NewPoDialog open={openNewPoDialog} onConfirm={onConfirmNewPoDialog} onClose={onCloseNewPoDialog}/>
        </Grid>
    );
};

export default POsHeader;