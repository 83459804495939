import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {
    Button,
    Grid,
    Typography
} from "@material-ui/core";

import MaterialTable from "material-table";
import Server from "../Server";
import Pricing from "../Pricing";

import {useHistory} from "react-router-dom";
import DebitNoteInfo from "./DebitNoteInfo";
import EditDebitNoteDialog from "./EditDebitNoteDialog";
import DebitNoteDocuments from "./DebitNoteDocuments";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";

const DebitDetails = props => {

    const { match } = props;

    let { debitNoteNumber } = match.params;

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3),
        },
        poDetails: {
            marginTop: theme.spacing(2)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const history = useHistory();

    const [debitNote, setDebitNote] = useState({
        number: 'DEB-123',
        supplier: {name: 'Sup X', email: 'supx@mail.com', phone: "0123456789"},
        currency: "GBP",
        created_date: new Date(),
        documents: [],
        items: [
            {
                item_description: 'item 1 desc',
                order_number: 'ord1 ',
                reason: 'random reason 1',
                amount: 10,
            },
            {
                item_description: 'item 2 desc',
                order_number: 'ord2 ',
                reason: 'random reason 2',
                amount: 20,
            }
        ]
    });

    useEffect(() => {document.title = debitNoteNumber;}, []);

    useEffect( () => {
        new Server().fetchDebitNote(debitNoteNumber, (response) => {
            setDebitNote(response.data);
        });

    }, []);


    const addEmptyItem = (event) => {
      new Server().addEmptyDebitNoteItem(debitNote.number, (response) => {
          if(response.status === 200) {
              setDebitNote(response.data);
          } else {
              alert('Error adding empty item: \nError:\n' +JSON.stringify(response.data, null, 2));
          }
      })
    };

    /*edit debit dialog*/
    const [openEditDebitDialog, setOpenEditDebitDialog] = useState(false);
    const showEditDebitDialog = () => {
        setOpenEditDebitDialog(true);
    };

    const onCloseEditDebitDialog = () => {
        setOpenEditDebitDialog(false);
    };

    const onConfirmEditDebitDialog = (newDebitNote) => {
        setOpenEditDebitDialog(false);
        setDebitNote(newDebitNote);
    };

    const onAddDocumentFile = (file) => {
      new Server().uploadDebitNoteDocument(debitNote.number, file, (response) => {
          if(response.status === 200) {
              setDebitNote(response.data);
          } else {
              alert('Error uploading document: \n'+JSON.stringify(response.data, null, 2));
          }
      })
    };

    const onDeleteDocument = (document_id) => {
        new Server().deleteDebitNoteDocument(debitNote.number, document_id, (response) => {
            if(response.status === 200) {
                setDebitNote(response.data);
            } else {
                alert('Error deleting document: \n'+JSON.stringify(response.data, null, 2));
            }
        })
    };

    const clearDebitNote = (event) => {
        new Server().clearDebitNote(debitNote.number, (response) => {
           if(response.status === 200) {
               setDebitNote(response.data);
           } else {
               alert('Error clearing debit note: \n' + JSON.stringify(response.data, null, 2));
           }
        });
    };

    const deleteDebitNote = (event) => {
        new Server().deleteDebitNote(debitNote.number, (response) => {
            if(response.status === 200) {
                history.goBack();
            } else {
                alert('Error deleting debit note: \n' + JSON.stringify(response.data, null, 2));
            }
        });
    };


    const ClearButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={clearDebitNote}
        >
            <DoneIcon className={classes.buttonIcon}/>
            Clear
        </Button>
    );

    const DeleteButton = () => (
        <Button
            color="default"
            variant="contained"
            onClick={deleteDebitNote}
        >
            <DeleteIcon className={classes.buttonIcon}/>
            Delete
        </Button>
    );


    return (
        <div className={classes.root}>

            {/*header start*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Debit note details
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {debitNoteNumber}

                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DeleteButton/>
                        </Grid>
                        <Grid item>
                            {debitNote.status === 'OPEN' ? <ClearButton/> : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/*header end*/}

            <Grid
                className={classes.poDetails}
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
            >

                {/* START horizontal order info + items*/}
                <Grid item>
                    <Grid
                        alignItems="flex-start"
                        container
                        spacing={2}
                    >
                        <Grid xs={3} item>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <DebitNoteInfo debitNote={debitNote} showEditDebitDialog={showEditDebitDialog} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DebitNoteDocuments debitNote={debitNote} onAddDocumentFile={onAddDocumentFile} onDeleteDocument={onDeleteDocument}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={9}>
                            <MaterialTable
                                title="Items"
                                columns={[
                                    {title: 'Item', field: 'item_description', cellStyle: {width: '30%'}},
                                    {title: 'Order', field: 'order_number', cellStyle: {width: '20%'}},
                                    {title: 'Reason', field: 'reason', cellStyle: {width: '30%'}},
                                    {title: 'Amount', field: 'amount', cellStyle: {width: '10%'}, render: rowData => <label>{Pricing.formatPrice(rowData.amount, debitNote.currency)}</label>},
                                ]}
                                options={
                                    {
                                        paging: false,
                                        search: false,
                                        showTitle: true,
                                        toolbar: true,
                                        actionsColumnIndex: 5
                                    }
                                }
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'No items'
                                    }
                                }}
                                data={
                                    debitNote.items
                                }
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add item',
                                        isFreeAction: true,
                                        onClick: addEmptyItem
                                    }
                                ]}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                if(debitNote.status === 'CLEARED' && oldData.amount !== newData.amount) {
                                                    //reject();
                                                    alert('Cannot edit amount. This debit note has already been cleared.');
                                                    reject();
                                                    return;
                                                }

                                                new Server().updateDebitNoteItem(debitNote.number, oldData.id, newData, (response) => {
                                                    if(response.status === 200) {
                                                        setDebitNote(response.data);
                                                    } else {
                                                        alert('Error updating item: \nError:\n' +JSON.stringify(response.data, null, 2))
                                                    }
                                                    resolve();
                                                })


                                            }, 1000)
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {

                                                new Server().deleteDebitNoteItem(debitNote.number, oldData, (response) => {
                                                   if(response.status === 200) {
                                                       setDebitNote(response.data);
                                                   } else {
                                                       alert('Error deleting item: \nError:\n' +JSON.stringify(response.data, null, 2))
                                                   }
                                                   resolve();
                                                });

                                            }, 1000)
                                        }),
                                }}

                            />
                        </Grid>

                    </Grid>
                </Grid>
                {/* END horizontal order info + items*/}


            </Grid>
            <EditDebitNoteDialog open={openEditDebitDialog}
                                 onClose={onCloseEditDebitDialog}
                                 onConfirm={onConfirmEditDebitDialog}
                                 debitNote={debitNote}/>

        </div>
    )
};

export default DebitDetails;