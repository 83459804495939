import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Server from "../../../Server";
import TestData from "../../../TestData";
import {Grid} from "@material-ui/core";
import Header from "./Header";
import Info from "./Info";
import Customer from "./Customer";
import Address from "./Address";
import Lines from "./Lines";
import TrackingEvents from "./TrackingEvents";
import Tracking from "./Tracking";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(3)
    }
}));

const ChannelOrderDetails = props => {
    const orderId = props.match.params.id;

    const classes = useStyles();
    const server = new Server();

    const [order, setOrder] = useState(TestData.emptyChannelOrder());

    useEffect(() => {document.title = orderId;}, []);

    useEffect(() => {
        server.fetchChannelOrder(orderId, (response) => {
            setOrder(response.data);
        });
    }, []);

    return (
        <div className={classes.root}>
            <Header order={order}/>

            <Grid
                className={classes.poDetails}
                container
                direction="column"
                spacing={3}
            >

                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                        <Grid item>
                            <Info order={order}/>
                        </Grid>

                        <Grid item>
                            <Customer customer={order.customer}/>
                        </Grid>

                        <Grid item>
                            <Address address={order.shipping_address}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Lines lines={order.lines} currency={order.currency}/>
                </Grid>

                {
                    order.tracking ?
                        <Grid item>

                            <Grid container direction="row" spacing={3}>

                                <Grid item>
                                    <Tracking order={order}/>
                                </Grid>

                                <Grid item>
                                    <TrackingEvents order={order}/>
                                </Grid>
                            </Grid>

                        </Grid>
                        : null
                }
            </Grid>

        </div>
    );
};

export default ChannelOrderDetails;