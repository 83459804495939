import {makeStyles} from "@material-ui/styles";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import NavigationService from "../NavigationService";
import Pricing from "../Pricing";

const PosTable = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },

    }));
    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);
    const pos = props.pos;

    const onPoRowClicked = (poNumber, newBrowserTab) => {
        nav.goToSupplierPoDetails(poNumber, true);
    };

    return (
        <Card className={classes.root}>

            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Issued</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell>Total units</TableCell>
                            <TableCell>Total value</TableCell>
                            <TableCell>Units shipped</TableCell>
                            <TableCell>Units outstanding</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            pos.map((po, index) => (
                                <TableRow key={index} onClick={(e)=> onPoRowClicked(po.number, e.metaKey)}>

                                    <TableCell>{po.number}</TableCell>
                                    <TableCell>{moment(po.issue_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(po.exit_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{po.total_units}</TableCell>
                                    <TableCell>{Pricing.formatPrice(po.total_value, po.currency)}</TableCell>
                                    <TableCell>{po.units_shipped}</TableCell>
                                    <TableCell>{po.units_outstanding}</TableCell>
                                    <TableCell>{po.status}</TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );

};

export default PosTable;