/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {List, ListItem, Button, colors, ListItemIcon, ListItemText} from '@material-ui/core';
import {StarBorder} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    nestedItem: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    },

}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const { pages, className, ...rest } = props;

    const classes = useStyles();

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            {pages.map(page => (
                <React.Fragment key={page.title}>
                    <ListItem
                        className={classes.item}
                        disableGutters
                        // key={page.title}
                    >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={page.href}
                        >
                            <div className={classes.icon}>{page.icon}</div>
                            {page.title}
                        </Button>
                    </ListItem>
                    {
                        page.nestedPages ?
                            <List component="div" disablePadding>
                                {
                                    page.nestedPages.map((nestedPage) => (
                                        <ListItem key={nestedPage.title} button disableGutters
                                                  className={classes.nestedItem}>
                                            <Button
                                                activeClassName={classes.active}
                                                className={classes.button}
                                                component={CustomRouterLink}
                                                to={nestedPage.href}
                                            >
                                                <div className={classes.icon}>{nestedPage.icon}</div>
                                                {nestedPage.title}
                                            </Button>
                                        </ListItem>
                                    ))
                                }
                            </List>

                            : null
                    }


                </React.Fragment>
            ))}
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
