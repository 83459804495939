import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from "@material-ui/core";
import Pricing from "../../../Pricing";

const ExcelPLsStats = props => {
    const useStyles = makeStyles(theme => ({
        item: {
            padding: theme.spacing(1.75),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                '&:not(:last-of-type)': {
                    borderRight: `1px solid ${theme.palette.divider}`
                }
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-of-type)': {
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        valueText: {
            fontWeight: "bold",
        },
        valueLabel: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const preview = props.preview;

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <Paper>
                    <Grid container
                          alignItems="center"
                          justifyContent="center"
                          direction="row">
                        <Grid item className={classes.item}>
                            <div className={classes.valueContainer}>
                                <Typography variant="h5"
                                            className={classes.valueText}>{preview.packing_lists.length}</Typography>
                                <Typography variant="h5" className={classes.valueLabel}>packing lists</Typography>
                            </div>
                        </Grid>
                        <Grid item className={classes.item}>
                            <div className={classes.valueContainer}>
                                <Typography variant="h5"
                                            className={classes.valueText}>{preview.total_cartons}</Typography>
                                <Typography variant="h5" className={classes.valueLabel}>cartons</Typography>
                            </div>
                        </Grid>
                        <Grid item className={classes.item}>
                            <div className={classes.valueContainer}>
                                <Typography variant="h5"
                                            className={classes.valueText}>{preview.total_units}</Typography>
                                <Typography variant="h5" className={classes.valueLabel}>units</Typography>
                            </div>
                        </Grid>
                        <Grid item className={classes.item}>
                            <div className={classes.valueContainer}>
                                <Typography variant="h5" className={classes.valueText}>{Pricing.formatPrice(preview.total_value, preview.currency)}</Typography>
                                <Typography variant="h5" className={classes.valueLabel}>total value</Typography>
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ExcelPLsStats;