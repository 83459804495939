import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";
import OrderSearchDialog from "./OrderSearchDialog";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const ChannelOrdersHeader = props => {
    const classes = useStyles();

    const [openSearchDialog, setOpenSearchDialog] = useState(false);

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <Typography
                            component="h1"
                            variant="h3"
                        >
                            Orders
                        </Typography>
                    </Grid>

                    <Grid item>
                        <IconButton size={"small"} onClick={() => setOpenSearchDialog(true)}>
                            <SearchIcon size={"small"}/>
                        </IconButton>
                        <OrderSearchDialog open={openSearchDialog} onClose={() => setOpenSearchDialog(false)}/>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
}

export default ChannelOrdersHeader;