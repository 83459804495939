import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import Server from './Server'
import Utils from "./Utils";

const Customers = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
    }));

    const classes = useStyles();

    const [customers, setCustomers] = useState([]);

    useEffect( () => {
        new Server().fetchCustomers((response) => {
            setCustomers(response.data);
        })
    }, [props])

    const validate = (customerData) => {
        let errors = [];

        if (customerData.name === null || customerData.name === '') {
            errors.push('Name is required.\n');    
        }

        if (customerData.email === null || customerData.email === '') {
            errors.push('Email is required.\n');    
        }
        if (!customerData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)) {
            errors.push('Invalid email.\n');
        }

        if (customerData.phone !== '' && !customerData.phone.match(/^[0-9]+$/)){
            errors.push("'Phone' must be empty or valid number.\n");
        }
        if (customerData.sales_tax === null || customerData.sales_tax === '') {
            errors.push('Sales Tax is required.\n');    
        }
        if (customerData.sales_markup === null || customerData.sales_markup === '') {
            errors.push('Sales Markup is required.\n');    
        }

        if (!Utils.isNumeric(customerData.sales_tax) || customerData.sales_tax < 0 || customerData.sales_tax > 100) {
            errors.push('Sales Tax must be a number between 0 and 100.\n');
        }

        if (!Utils.isNumeric(customerData.sales_markup) || customerData.sales_markup < 0) {
            errors.push('Sales Markup must be a positive number.\n');
        }

        let error_line = '';
        errors.forEach(error =>{
            error_line+= error;
        })
        return error_line;
    };

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Customers
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                className={classes.poTable}
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
            >

                <Grid item>

                    <MaterialTable
                        columns={[
                            {title: 'Name', field: 'name'},
                            {title: 'Phone', field: 'phone'},
                            {title: 'Email', field: 'email'},
                            {title: 'Sales Tax', field: 'sales_tax'},
                            {title: 'Sales Markup', field: 'sales_markup'},
                        ]}
                        data={
                            customers
                        }
                        options={
                            {
                                paging: false,
                                showTitle: false,
                                search: false,
                                actionsColumnIndex: 5
                            }
                        }
                        actions={[]}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        let customerData = {
                                            'name' : newData.name || '',
                                            'email' : newData.email || '',
                                            'phone' : newData.phone || '',
                                            'sales_tax' : newData.sales_tax || '',
                                            'sales_markup' : newData.sales_markup || '',
                                        };
                                        let errors = validate(customerData);
                                        if(errors.length!==0){
                                            reject();
                                            alert(errors);
                                            return;
                                        }

                                        new Server().newCustomer(customerData, (response) => {
                                            if (response.status !== 200) {
                                                reject();
                                                alert("Error " + JSON.stringify(response.data));
                                                return;
                                            }
                                            setCustomers(customers => {
                                                    return customers.concat(response.data);
                                                });
                                        });

                                        resolve()
                                    }, 1000)
                                }),

                            onRowUpdate: (newData, oldData) => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        let errors = validate(newData);
                                        if(errors.length!==0){
                                            reject();
                                            return alert(errors);
                                        }
                                        new Server().updateCustomer(newData, (response) => {
                                            if (response.status !== 200) {
                                                alert(JSON.stringify(response.data));
                                                reject();
                                                return;
                                            }

                                            const index = customers.indexOf(oldData);

                                            setCustomers(customers => {
                                                return customers.map((currentCustomer, j) => {
                                                    if(j === index) {
                                                        return newData;
                                                    } else {
                                                        return currentCustomer;
                                                    }
                                                })
                                            })
                                        })
                                        resolve()
                                    }, 100)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        new Server().deleteCustomer(oldData, (response) => {
                                            if(response.status !== 200) {
                                                alert(JSON.stringify(response.data));
                                                reject();
                                                return;

                                            }

                                            //set state
                                            const index = customers.indexOf(oldData);
                                            setCustomers(customers => {
                                                return customers.filter((currentCustomer, j) =>
                                                    j !== index
                                                )
                                            })

                                            resolve();

                                        })

                                    }, 1000)
                                }),
                        }}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default Customers
