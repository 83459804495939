import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {AppBar, Toolbar, Badge, Hidden, IconButton, Typography} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import AuthService from './AuthService';
import Utils from "./Utils";

import SkuSearch from "./SkuSearch";



const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: Utils.getTopBarColor()
    },
    flexGrow: {
        flexGrow: 1
    },
    signOutButton: {
        marginLeft: theme.spacing(1)
    },
    loggedinUser: {
        color: '#FFFFFF'
    },

}));

const Topbar = props => {
    const { className } = props;

    const classes = useStyles();

    const history = useHistory();
    const [notifications] = useState([]);

    const logout = event => {
        event.preventDefault();
        AuthService.logout();
        history.push('/auth/login')
    };

    const loggedInName = () => {
        console.log('called logged in name');
        let name = "";
        if (AuthService.isLoggedIn()) {
            name = AuthService.loggedInName();
        }
        return name;
    };

    return (
        <AppBar
            className={clsx(classes.root, className)}
        >
            <Toolbar >
                    <img
                        alt="Logo"
                        src="/images/logos/renfold_logo.png"
                        width="290"
                        height="40"
                    />
                <div className={classes.flexGrow} />
                <Hidden mdDown>

                    {AuthService.isAdminLoggedIn() ? <SkuSearch/> : null}

                    <Typography
                        component="h1"
                         variant="h4"
                        className={classes.loggedinUser}
                    >
                        {loggedInName()}
                    </Typography>
                    {/*<IconButton color="inherit">*/}
                    {/*    <Badge*/}
                    {/*        badgeContent={notifications.length}*/}
                    {/*        color="primary"*/}
                    {/*        variant="dot"*/}
                    {/*    >*/}
                    {/*        <NotificationsIcon />*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <IconButton
                        className={classes.signOutButton}
                        color="inherit"
                        onClick={logout}
                    >
                        <InputIcon />
                    </IconButton>
                </Hidden>

            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
