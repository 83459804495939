import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, MenuItem, FormControl, Select} from "@material-ui/core";
import {Card} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const StockPager = props => {
    const useStyles = makeStyles(theme => ({
        rootCrd: {
            padding: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    return (
        <div>
        <Card className={classes.rootCrd}>
            <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={props.pageSize}
                            onChange={props.onPageSizeChange}
                        >
                            <MenuItem value={10}>Show 10 items</MenuItem>
                            <MenuItem value={25}>Show 25 items</MenuItem>
                            <MenuItem value={50}>Show 50 items</MenuItem>
                            <MenuItem value={100}>Show 100 items</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <Grid container direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
                        <Grid item>
                            {/*previous page*/}
                            <Button
                                onClick={props.onPreviousPageClick}
                                disabled={!props.hasPreviousPage}
                            >
                                <NavigateBeforeIcon/>
                            </Button>
                        </Grid>
                        <Grid item>
                            {props.currentPageNumber} of {props.pageCount}
                        </Grid>
                        <Grid item>
                            {/*next page*/}
                            <Button
                                onClick={props.onNextPageClick}
                                disabled={!props.hasNextPage}
                            >
                                <NavigateNextIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
        </div>
    );
};

export default StockPager;