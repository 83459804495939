import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Server from "../Server";

const NewWarehouseDialog = props => {

    const [warehouseName, setWarehouseName] = useState('');
    const [warehouseCode, setWarehouseCode] = useState('');

    const confirm = () =>{
        if(warehouseCode.length > 7){
            alert("Warehouse code must have a maximum of 7 characters/digits.")
            return;
        }
        const data = {
            'warehouse_name' : warehouseName,
            'warehouse_code' : warehouseCode
        }
        new Server().newWarehouse(data, (response)=>{
            if(response.status === 200){
                props.onClose();
                window.location.reload();
            }
            else{
                alert(response.data);
            }
        })
    }

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">New warehouse</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <TextField fullWidth label="Warehouse Name" variant="outlined"
                                   value={warehouseName}
                                   onChange={(e) => setWarehouseName(e.target.value)}/>
                    </Grid>
                    <Grid item>
                        <TextField fullWidth label="Warehouse Code" variant="outlined"
                                   value={warehouseCode}
                                   onChange={(e) => setWarehouseCode(e.target.value)}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewWarehouseDialog