import React, {useState} from 'react';
import {Grid} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from "@material-ui/core/Paper";
import InventoryTabContent from "./InventoryTabContent";
import PosTabContent from "./PosTabContent";
import ShipmentsTabContent from "./ShipmentsTabContent";
import LocationsTabContent from "./LocationsTabContent";
import ModelTabContent from "./ModelTabContent";
import DesignTabContent from "./DesignTabContent";
import QAReportsTabContent from "./QAReportsTabContent";

const ProductTabs = props => {

    const productInventory = props.productInventory;

    const [tabIndex, setTabIndex] = useState(0);

    const onTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Paper>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={onTabChange}
                    >
                        <Tab label="Inventory"/>
                        <Tab label="Model"/>
                        <Tab label="Design"/>
                        <Tab label="QA Reports"/>
                        <Tab label="POs"/>
                        <Tab label="Shipments"/>
                        <Tab label="Locations"/>
                    </Tabs>
                </Paper>
            </Grid>

            <Grid item>
                <InventoryTabContent hidden={tabIndex !== 0} productInventory={productInventory}/>
                <ModelTabContent hidden={tabIndex !== 1} product={productInventory.product}/>
                <DesignTabContent hidden={tabIndex !== 2} product={productInventory.product}/>
                <QAReportsTabContent hidden={tabIndex !== 3} product={productInventory.product}/>
                <PosTabContent hidden={tabIndex !== 4} product={productInventory.product}/>
                <ShipmentsTabContent hidden={tabIndex !== 5} product={productInventory.product}/>
                <LocationsTabContent hidden={tabIndex !== 6} product={productInventory.product} productInventory={productInventory}/>
            </Grid>
        </Grid>
    );

};

export default ProductTabs;