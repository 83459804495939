import axios from 'axios';
import moment from "moment";
import AuthService from "./AuthService";
import Utils from "./Utils";

class Server {

    constructor(props) {
        var STAGING_SERVER_IP = '161.35.34.254'
        var PRODUCTION_SERVER_IP = '167.99.194.92'
        var current_host = window.location.host
        if(current_host.indexOf('localhost') > -1) {
            // console.log('DEV_ENV: ' + window.location.host)
            this.url = 'http://127.0.0.1:8000/';
        } else if(current_host.indexOf(STAGING_SERVER_IP) > -1){
            // console.log('STAGING_ENV: ' + window.location.host)
            this.url = 'http://161.35.34.254:8000/';
        } else {
            // console.log('PRODUCTION_ENV: ' + window.location.host)
            this.url = 'http://167.99.194.92:8001/';
        }
    }

    get(endpoint, responseHandler, headers){
        let new_headers = {'user-id': AuthService.loggedInId()};

        if(headers) {
            Object.assign(new_headers, headers)
        }
        const config = {headers: new_headers};

        axios.get(this.url + endpoint, config)
            .then(responseHandler)
            .catch((error) => {
                console.log('GET for endpoint: ' + endpoint + ' has caught error')
                console.log(error);
                responseHandler(error.response)
            })
    }

    post(endpoint, data, responseHandler, headers) {
        let new_headers = {'user-id': AuthService.loggedInId()};

        if(headers) {
            Object.assign(new_headers, headers)
        }
        const config = {headers: new_headers};

        axios.post(this.url + endpoint, data, config)
            .then(responseHandler)
            .catch((error) => {
                console.log('POST for endpoint: ' + endpoint + ' has caught error')
                console.log(error)
                responseHandler(error.response)
            });
    }

    searchSku(query, responseHandler) {
        this.post('search/sku/', {query: query}, responseHandler)
    }

    searchEntity(searchType, searchText, responseHandler) {
        this.post('search/entity/', {search_type: searchType, search_text: searchText}, responseHandler)
    }

    /*PORTS*/
    fetchPorts(responseHandler) {
        this.get('ports', responseHandler);
    }

    newPort(port, responseHandler) {
        this.post('ports/', port, responseHandler)
    }

    updatePort(port, responseHandler) {
        axios.put(this.url + 'ports/'+port.id+'/', port)
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            })
    }

    deletePort(port, responseHandler) {
        axios.delete(this.url + 'ports/'+port.id+'/')
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            })
    }

    /*CUSTOMERS*/
    fetchCustomers(responseHandler) {
        this.get('customers', responseHandler);
    }

    newCustomer(customer, responseHandler) {
        this.post('customers/createcustomer', customer, responseHandler)
    }

    updateCustomer(customer, responseHandler) {
        this.post('customers/'+customer.id+'/updatecustomer', customer, responseHandler)
    }

    deleteCustomer(customer, responseHandler) {
        this.get('customers/'+customer.id+'/deletecustomer', responseHandler);
    }


    /*SUPPLIERS*/
    fetchSuppliers(responseHandler) {
        this.get('suppliers', responseHandler);
    }

    newSupplier(supplier, responseHandler) {
        this.post('suppliers/', supplier, responseHandler)
    }

    updateSupplier(supplier, responseHandler) {
        axios.put(this.url + 'suppliers/'+supplier.id+'/', supplier)
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            })
    }

    deleteSupplier(supplier, responseHandler) {
        axios.delete(this.url + 'suppliers/'+supplier.id+'/')
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            })
    }

    /*POs*/

    newDraftPo(po, responseHandler) {
        this.post('purchaseorders/newDraftPo', po, responseHandler)
    }

    updatePo(number, supplier_id, currency, issueDate, exitDate, ref, responseHandler) {
        let data = {
            supplier_id: supplier_id,
            currency: currency,
            issue_date: issueDate,
            exit_date: exitDate,
            reference: ref
        };
        this.post('purchaseorders/'+number+'/update', data, responseHandler)
    }

    setPoItems(number, items, responseHandler) {
        this.post('purchaseorders/'+number+'/setitems', items, responseHandler)
    }

    updatePoProductPrice(poNumber, productCode, newPrice, responseHandler) {
        const data = {product_code: productCode, new_price: newPrice}
        this.post('purchaseorders/'+poNumber+'/updateProductPrice', data, responseHandler)
    }

    deletePOProduct(poNumber, productCode, responseHandler) {
        const data = {product_code: productCode};
        this.post('purchaseorders/'+poNumber+'/deleteProduct', data, responseHandler)
    }

    getPOProductsSizes(poNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/productsSizes', responseHandler);
    }

    addPOProductSizes(poNumber, data, responseHandler) {
        this.post('purchaseorders/'+poNumber+'/addProductSizes', data, responseHandler)
    }

    productionReport(data, responseHandler) {
        this.post('reports/production', data, responseHandler)
    }

    fetchPos(pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        this.get('purchaseorders/', responseHandler, headers);
    }

    fetchPosWithStatus(status, responseHandler) {
        this.get('purchaseorders/?status='+status, responseHandler);
    }

    fetchSupplierPos(supplierId, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        this.get('purchaseorders/supplier/'+supplierId, responseHandler, headers)
    }

    fetchSupplierNewPos(supplierId, responseHandler) {
        this.get('purchaseorders/supplier/'+supplierId+'/newPos', responseHandler)
    }

    fetchSupplierDraftAndRaisedPos(supplierId, responseHandler) {
        this.get('purchaseorders/supplier/'+supplierId+'/draftAndRaisedPos', responseHandler)
    }

    fetchPoProductSummary(poNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/productsSummary', responseHandler)
    }

    fetchPoStats(poNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/stats', responseHandler)
    }

    fetchNewPoNumber(responseHandler) {
        this.get('newponumber/', responseHandler);
    }

    editPoNumber(poNumber, newPoNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/newponumber/'+newPoNumber, responseHandler);
    }

    fetchPONumbers(responseHandler) {
        this.get('purchaseorders/numbers', responseHandler);
    }

    raisePurchaseOrder(poNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/raise', responseHandler);
    }

    deletePo(poNumber, responseHandler) {
        this.get('purchaseorders/'+poNumber+'/delete', responseHandler);
    }

    addProductToPO(poNumber, productCode, responseHandler){
        this.get('purchaseorders/'+poNumber+'/addproduct/'+productCode, responseHandler);
    }

    uploadPOCsv(poNumber, file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('purchaseorders/'+poNumber+'/uploadPOCsv', data, responseHandler)
    }

    /*PoShipments*/

    newPoShipment(currency, supplierId, responseHandler) {
        const data = {
            currency: currency,
            supplier_id: supplierId
        };
        this.post('poshipments/new', data, responseHandler)
    }

    newPoShipmentForPo(poNumber, responseHandler){
        this.get('purchaseorders/'+poNumber+'/newshipment', responseHandler);
    }

    getOrderedUnits(plNumber, responseHandler){
        this.post('packinglists/'+plNumber+'/orderedUnits', {}, responseHandler)
    }

    getPoShipments(poNumber, responseHandler){
        this.get('purchaseorders/'+poNumber+'/shipments', responseHandler);
    }

    fetchShipmentProductsSummary(shNumber, responseHandler) {
        this.get('poshipments/'+shNumber+'/productsSummary', responseHandler)
    }

    fetchSupplierPoShipments(supplierId, status, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const data = {'status': status};
        this.post('poshipments/supplier/'+supplierId, data, responseHandler, headers);
    }

    fetchTransitPOShipmentsStats(responseHandler) {
        this.get('poshipments/stats', responseHandler);
    }

    fetchPoShipments(status, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        this.get('poshipments/'+status, responseHandler, headers);
    }

    fetchShipmentPos(shNumber, responseHandler) {
        this.get('poshipments/'+shNumber+'/pos', responseHandler)
    }

    fetchShipmentStats(shNumber, responseHandler) {
        this.post('poshipments/'+shNumber+'/totals', {}, responseHandler)
    }

    downloadTotalsCsv(poShipmentNumber, responseHandler) {
        this.downloadFile(this.url + 'poshipments/'+poShipmentNumber+'/downloadShipmentTotalsCsv/', poShipmentNumber+'-totals.csv', responseHandler);
    }

    downloadShipmentChecklist(poShipmentNumber, responseHandler) {
        this.downloadFile(this.url + 'poshipments/'+poShipmentNumber+'/downloadChecklistCsv/', poShipmentNumber+'-checklist.csv', responseHandler);
    }

    downloadShipmentPLCsv(poShipmentNumber, responseHandler) {
        this.downloadFile(this.url + 'poshipments/'+poShipmentNumber+'/downloadPLCsv/', poShipmentNumber+'-packing_lists.csv', responseHandler);
    }

    validatePackingListExcelSheets(shNumber, file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('poshipments/'+shNumber+'/validateExcelPlSheets', data, responseHandler)
    }

    storePackingListExcel(shNumber, file) {
        const data = new FormData();
        data.append('file', file);

        this.post('poshipments/'+shNumber+'/storeExcelPlSheets', data, ()=>{});
    }

    previewExcelPls(shNumber, file, validSheetNames, responseHandler) {
        const data = new FormData();
        data.append('file', file);
        for(let i=0; i< validSheetNames.length; i++) {
            data.append('sheet_names', validSheetNames[i]);
        }
        this.post('poshipments/'+shNumber+'/previewExcelPlSheets', data, responseHandler)
    }

    downloadExcelPLTemplate(templateSize, filename) {
        const url = this.url + 'poshipments/excelPLTemplate?size='+templateSize;
        this.downloadFile(url, filename, null);
    }

    deleteShipment(shNumber, responseHandler) {
        this.get(`poshipments/${shNumber}/delete`, responseHandler);
    }

    deleteShipmentPackingLists(shNumber, responseHandler) {
        this.get(`poshipments/${shNumber}/packingLists/delete`, responseHandler);
    }

    receivePOShipment(shNumber, warehouseId, responseHandler) {
        this.get(`poshipments/${shNumber}/receive/${warehouseId}`, responseHandler);
    }

    updateShipmentInfo(shNumber, newShipmentData, responseHandler){
        let data = new FormData();
        for(let key in newShipmentData){
            data.append(key, newShipmentData[key])
        }
        this.post('poshipments/'+shNumber+'/updateShipmentInfo', data, responseHandler)
    }

    updateShipmentInvoice(shNumber, newInvoiceData, responseHandler){
        let data = new FormData();
        for(let key in newInvoiceData){
            data.append(key, newInvoiceData[key])
        }
        this.post('poshipments/'+shNumber+'/updateShipmentInvoice', data, responseHandler)
    }

    approvePOShipmentInvoice(shNumber, responseHandler) {
        this.get('poshipments/'+shNumber+'/approveInvoice', responseHandler);
    }

    markPOShipmentInvoiceAsPaid(shNumber, responseHandler) {
        this.get('poshipments/'+shNumber+'/invoicePaid', responseHandler);
    }

    submitShipment(shNumber, responseHandler){
        this.get('poshipments/'+shNumber+'/submit', responseHandler);
    }

    /* consolidated packages */

    fetchPackageNumbers(responseHandler) {
        this.get('consolidatedPackages/numbers', responseHandler);
    }

    newPackage(season, supplierId, currency, mode, countryId, responseHandler) {
        const data = {
            season: season,
            supplier_id: supplierId,
            currency: currency,
            mode: mode,
            country_id: countryId
        };
        this.post('consolidatedPackages/create', data, responseHandler, {})
    }

    fetchPackageInfo(number, responseHandler) {
        const headers = {};
        const data = {};
        this.post('consolidatedPackages/'+number+'/info', data, responseHandler, headers)
    }

    fetchPackageExitsAndPrices(number, responseHandler) {
        const headers = {};
        const data = {};
        this.post('consolidatedPackages/'+number+'/exitsAndPrices', data, responseHandler, headers)
    }

    updatePackageExitsAndPrices(number, data, responseHandler) {
        const headers = {};
        this.post('consolidatedPackages/'+number+'/exitsAndPrices/update', data, responseHandler, headers)
    }

    editPackageNumber(number, newNumber, responseHandler) {
        const headers = {};
        const data = {
            new_number: newNumber,
        };
        this.post('consolidatedPackages/'+number+'/editNumber', data, responseHandler, headers)
    }

    acknowledgePackage(number, responseHandler) {
        const headers = {};
        const data = {};
        this.post('consolidatedPackages/'+number+'/acknowledge', data, responseHandler, headers)
    }

    editPackagePoPrice(pkgNum, poNumber, newPrice, responseHandler) {
        const headers = {};
        const data = {
            po_number: poNumber,
            price: newPrice,
        };
        this.post('consolidatedPackages/'+pkgNum+'/editPoPrice', data, responseHandler, headers)
    }

    editPackagePoExit(pkgNum, poNumber, newDate, responseHandler) {
        const headers = {};
        const data = {
            po_number: poNumber,
            new_date: newDate,
        };
        this.post('consolidatedPackages/'+pkgNum+'/editPoExit', data, responseHandler, headers)
    }

    getPackageShipments(number, responseHandler) {
        const headers = {};
        const data = {};
        this.post('consolidatedPackages/'+number+'/shipments', data, responseHandler, headers)
    }

    editPackageInfo(number, season, supplierId, currency, mode, countryId, responseHandler) {
        const data = {
            number: number,
            season: season,
            supplier_id: supplierId,
            currency: currency,
            mode: mode,
            country_id: countryId
        };
        this.post('consolidatedPackages/'+number+'/info/update', data, responseHandler, {})
    }

    fetchPackagesPage(searchText, supplierId, statuses, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const data = {
            search_text: searchText,
            supplier_id: supplierId,
            statuses: statuses
        };
        this.post('consolidatedPackages/filter', data, responseHandler, headers)
    }

    uploadPOs(number, file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('consolidatedPackages/'+number+'/upload', data, responseHandler)
    }

    fetchPackageSummary(ssNumber, searchText, responseHandler) {
        const data = {
            search_text: searchText,
        };
        this.post('consolidatedPackages/'+ssNumber+'/summary', data, responseHandler, {})
    }

    raisePackage(number, responseHandler) {
        const data = {};
        this.post('consolidatedPackages/'+number+'/raise', data, responseHandler, {})
    }

    downloadPackageTotals(number) {
        const url = this.url + 'consolidatedPackages/' + number + '/totals';
        this.downloadFile(url, number + '_totals.xlsx', null);
    }

    downloadPackageTemplate() {
        const url = this.url + 'consolidatedPackages/template';
        this.downloadFile(url, 'package_template.xlsx', null);
    }

    deletePackage(number, responseHandler) {
        this.post('consolidatedPackages/'+number+'/delete', {}, responseHandler, {})
    }

    deletePackagePO(number, poNum, responseHandler) {
        const data = {
            po_number: poNum,
        };
        this.post('consolidatedPackages/'+number+'/deletePO', data, responseHandler, {})
    }

    downloadPackageExcel(number, responseHandler) {
        const filename = number + '.xlsx';
        this.downloadFile(this.url + 'consolidatedPackages/'+number+'/excel', filename, responseHandler);
    }

    downloadPrefilledExcelPLTemplate(pkgNumber, filename, responseHandler) {
        const url = this.url + 'consolidatedPackages/'+pkgNumber+'/prefilledPackingLists';
        this.downloadFile(url, filename, responseHandler);
    }

    downloadPackagePdf(number, responseHandler) {
        const filename = number + '.pdf';
        this.downloadFile(this.url + 'consolidatedPackages/'+number+'/pdf', filename, responseHandler);
    }

    /* TransitProducts */
    fetchTransitProducts(packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate, responseHandler) {
        const data = {
            package_number: packageNum,
            supplier_id: selectedSupplier ? selectedSupplier.id: -1,
            customer_id: selectedCustomer ? selectedCustomer.id: -1,
            from_exit_date: fromExitDate,
            to_exit_date: toExitDate,
            from_eta_date: fromEtaDate,
            to_eta_date: toEtaDate,
            include_size_breakdown: false,
        };
        this.post('reports/transitProducts', data, responseHandler)
    }

    emailTransitProductsExcel(packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate, responseHandler) {
        const data = {
            package_number: packageNum,
            supplier_id: selectedSupplier ? selectedSupplier.id: -1,
            customer_id: selectedCustomer ? selectedCustomer.id: -1,
            from_exit_date: fromExitDate,
            to_exit_date: toExitDate,
            from_eta_date: fromEtaDate,
            to_eta_date: toEtaDate,
            include_size_breakdown: true,
        };
        this.post('reports/transitProducts/excel', data, responseHandler)
    }

    /* design documents */

    fetchDesignDocuments(productCode, responseHandler) {
        this.get('products/'+productCode+'/design', responseHandler);
    }

    deleteDesignDocument(productCode, documentId, responseHandler) {
        this.get('products/'+productCode+'/design/' + documentId+ '/delete', responseHandler);
    }

    updateDesignFiles(code, files, type, responseHandler, progressHandler) {
        const data = new FormData();

        for (let i = 0; i < files.length; i++) {
            data.append('file', files[i]);
        }
        data.append('type', type);

        this.post('products/'+code+'/design/upload', data, responseHandler)
    }

    fetchDesignDocumentsPage(selectedProductCodes, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const data = {
            product_codes: selectedProductCodes,
        };
        this.post('designDocuments/filter', data, responseHandler, headers)
    }

    fetchSupplierDesignDocumentsPage(supplierId, selectedProductCodes, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const data = {
            product_codes: selectedProductCodes,
        };
        this.post('designDocuments/supplier/'+supplierId+'/filter', data, responseHandler, headers)
    }

    fetchProductCodesByDesignDocSupplier(supplierId, responseHandler) {
        this.get('designDocuments/supplier/'+supplierId+'/productCodes', responseHandler);
    }

    shareDesignDocs(selectedDocumentIds, selectedSupplierIds, responseHandler) {
        const data = {
            document_ids: selectedDocumentIds,
            supplier_ids: selectedSupplierIds,
        };
        this.post('designDocuments/share', data, responseHandler)
    }

    unshareDesignDocs(selectedDocumentIds, selectedSupplierIds, responseHandler) {
        const data = {
            document_ids: selectedDocumentIds,
            supplier_ids: selectedSupplierIds,
        };
        this.post('designDocuments/removeSupplier', data, responseHandler)
    }

    /* QA reports */

    fetchQAReports(productCode, responseHandler) {
        this.get('qaReports/byProduct/'+productCode, responseHandler);
    }

    fetchQAReport(id, responseHandler) {
        this.get('qaReports/'+id, responseHandler);
    }

    deleteQAReport(productCode, reportId, responseHandler) {
        this.get('qaReports/' + reportId+ '/delete', responseHandler);
    }

    addQAReport(reportNumber, productCodes, packageNumbers, poNumbers, file, expiryDate, responseHandler, progressHandler) {
        const data = new FormData();
        data.append('file', file);
        data.append('number', reportNumber);
        data.append('product_codes', Utils.formatList(productCodes, ','));
        data.append('po_numbers', Utils.formatList(poNumbers, ','));
        data.append('package_numbers', Utils.formatList(packageNumbers, ','));
        data.append('expiry_date', expiryDate);

        this.post('qaReports/add', data, responseHandler)
    }

    editQAReport(reportId, reportNumber, productCodes, packageNumbers, poNumbers, file, expiryDate, responseHandler, progressHandler) {
        const data = new FormData();
        data.append('file', file);
        data.append('number', reportNumber);
        data.append('product_codes', Utils.formatList(productCodes, ','));
        data.append('po_numbers', Utils.formatList(poNumbers, ','));
        data.append('package_numbers', Utils.formatList(packageNumbers, ','));
        data.append('expiry_date', expiryDate);

        this.post('qaReports/' + reportId + '/edit', data, responseHandler)
    }

    /* products */

    fetchProductAttributeValues(endpoint, responseHandler) {
        this.get(endpoint + '/', responseHandler);
    }

    newProduct(data, responseHandler) {
        this.post('products/newproduct', data, responseHandler)
    }

    fetchProduct(code, responseHandler) {
        this.get('products/'+code, responseHandler);
    }

    fetchProductInventory(code, responseHandler) {
        this.get('products/'+code+'/inventory', responseHandler);
    }

    fetchProductsInventory(data, responseHandler) {
        this.post('products/inventory', data, responseHandler)
    }

    fetchLinesheetProducts(data, responseHandler) {
        this.post('products/linesheetProducts', data, responseHandler)
    }

    fetchProductCodes(responseHandler) {
        this.get('products/codes', responseHandler);
    }

    fetchVariants(responseHandler) {
        this.get('variants/', responseHandler);
    }

    fetchProductVariants(code, responseHandler) {
        this.get('products/'+code+'/variants', responseHandler);
    }

    fetchPossibleSizes(code, responseHandler) {
        this.get('products/'+code+'/possibleSizes', responseHandler);
    }

    emailProductsExcel(responseHandler) {
        const data = {};
        this.post('products/export/excel', data, responseHandler, {})
    }

    fetchVariant(variantId, responseHandler) {
        this.get('variants/'+variantId, responseHandler);
    }

    updateVariant(product_code, variantId, sku, ean, sizeId, responseHandler) {
        const data = {
            variant_id: variantId,
            sku: sku,
            ean: ean,
            size_id: sizeId,
            product_code: product_code,
        }
        this.post('variants/'+variantId+'/update', data, responseHandler)
    }

    fetchProductShipments(code, responseHandler) {
        this.get('products/'+code+'/shipments', responseHandler);
    }

    fetchProductPOs(code, responseHandler) {
        this.get('products/'+code+'/purchaseorders', responseHandler);
    }

    fetchProductWarehouses(code, responseHandler) {
        this.get('products/'+code+'/warehouses', responseHandler);
    }

    updateProduct(code, data, responseHandler) {
        this.post('products/'+code+'/update', data, responseHandler)
    }

    sendImageUploadNotification(filenames, responseHandler) { //FIXME
        const data = {
            filenames: filenames
        };
        this.post('products/images/upload/notify', data, responseHandler)
    }

    fetchSizeNames(sizeGroupId, responseHandler) {
        this.get('sizeGroups/'+sizeGroupId+'/sizeNames', responseHandler);
    }

    fetchAvailableSizesForProduct(productCode, responseHandler) {
        this.get('products/'+productCode+'/availableSizes', responseHandler);
    }

    addProductVariant(productCode, selectedSize, ean, responseHandler) {
        const data = {
            size_name: selectedSize,
            ean: ean
        };
        this.post('products/'+productCode+'/addVariant', data, responseHandler)
    }

    deleteProductVariant(productCode, variantId, responseHandler) {
        const data = {
            variant_id: variantId,
        };
        this.post('products/'+productCode+'/deleteVariant', data, responseHandler)
    }

    editProductCode(code, newCode, responseHandler) {
        this.get('products/'+code+'/editCode/'+newCode, responseHandler);
    }

    updateProductImages(code, newImagesToUpload, newImageUrls, responseHandler) {
        const data = new FormData();
        newImagesToUpload.forEach(file => data.append('file', file));
        data.append('newImageUrls', JSON.stringify(newImageUrls));

        this.post('products/'+code+'/updateImages', data, responseHandler)
    }

    deleteProduct(code, responseHandler) {
        this.get('products/'+code+'/delete', responseHandler);
    }

    downloadProductCostsReport(responseHandler) {
        const filename = 'product_costs_'+ moment().format('DD_MM_YYYY') + '.csv';
        this.downloadFile(this.url + 'products/productCosts/report', filename, responseHandler);
    }

    /*warehouse*/
    fetchWarehouses(responseHandler) {
        this.get('warehouses/', responseHandler);
    }

    fetchWarehouseNames(responseHandler) {
        this.get('warehouses/names/', responseHandler);
    }

    fetchWarehouseCodes(responseHandler) {
        this.get('warehouses/codes/', responseHandler);
    }

    fetchWarehouseItems(code, pageNumber, pageSize, responseHandler) {
        const headers = {
          'page-number': pageNumber,
          'page-size': pageSize
        };
        this.get('warehouses/'+code+'/stockItems', responseHandler, headers);
    }

    newWarehouse(data, responseHandler){
        this.post('warehouses/', data, responseHandler)
    }

    newStockItem(code, data, responseHandler) {
        this.post('warehouses/'+code+'/newStockItem', data, responseHandler)
    }

    setStockItemProperty(id, data, responseHandler) {
        this.post('warehouses/stockItems/'+id+'/setProperty', data, responseHandler)
    }
    /*packing lists*/

    getPackingList(pl_number, responseHandler){
        this.get('packinglists/'+pl_number, responseHandler);
    }

    fetchNewPackingListNumber(responseHandler) {
        this.get('packinglists/newnumber', responseHandler);
    }

    fetchPotentialPackingListPos(shNumber, responseHandler) {
        this.get('packinglists/new/pos/'+shNumber, responseHandler);
    }

    createExcelPls(shNumber, file, validSheetNames, responseHandler) {
        const data = new FormData();
        data.append('file', file);
        for(let i=0; i< validSheetNames.length; i++) {
            data.append('sheet_names', validSheetNames[i]);
        }
        this.post('poshipments/'+shNumber+'/createExcelPackingLists', data, responseHandler)
    }

    deletePackingList(plNumber, responseHandler){
        this.get('packinglists/'+plNumber+'/delete', responseHandler);
    }

    updatePackingList(plNumber, data, responseHandler){
        this.post('packinglists/'+plNumber+'/update', data, responseHandler)
    }

    /*exchange rates*/
    fetchExchangeRate(base_currency, quote_currency, responseHandler){
        this.get('exchangerates/'+base_currency+'/'+quote_currency, responseHandler);
    }

    /*login*/
    login(email, password, responseHandler) {
        axios.post(this.url + 'login/', {
            email: email,
            password: password
        })
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            })
    }

    /*users*/
    fetchUsers(responseHandler) {
        this.get('users', responseHandler);
    }

    fetchUser(user_id, responseHandler) {
        this.get('users/'+user_id+'/', responseHandler);
    }

    createUser(name, email, password, responseHandler) {
        const data = {
            name: name,
            email: email,
            password: password,
            permissions: []
        };
        this.post('users/newUser', data, responseHandler)
    }

    updateUserDetails(userId, name, email, password, responseHandler) {
        const data = {
          name: name,
          email: email,
          password: password,
        };
        this.post('users/'+userId+'/updateDetails', data, responseHandler)
    }

    updateUserPermissions(userId, permissions, responseHandler) {
        const data = {
            permissions: permissions
        };
        this.post('users/'+userId+'/updatePermissions', data, responseHandler)
    }

    deleteUser(user, responseHandler) {
        this.get('users/'+user.id+'/deleteUser', responseHandler);
    }

    /* debit notes */

    fetchDebitSummary(responseHandler) {
        this.get('debitnotes/suppliersSummary/', responseHandler);
    }

    fetchDebitNote(debitNoteNumber, responseHandler) {
        this.get('debitnotes/'+debitNoteNumber+'/', responseHandler);
    }

    newDebitNote(data, responseHandler) {
        this.post('debitnotes/newDebitNote/', data, responseHandler)
    }

    fetchNewDebitNoteNumber(responseHandler) {
        this.get('debitnotes/newDebitNoteNumber/', responseHandler);
    }

    fetchDebitNotesForSupplier(supplier_id, responseHandler) {
        this.get('debitnotes/supplier/'+supplier_id+'/', responseHandler);
    }

    addEmptyDebitNoteItem(number, responseHandler) {
        this.get('debitnotes/'+number+'/addEmptyItem', responseHandler);
    }

    updateDebitNoteItem(number,itemId,  newItem, responseHandler) {
        this.post('debitnotes/'+number+'/updateItem/'+itemId, newItem, responseHandler)
    }

    clearDebitNote(number, responseHandler) {
        this.get('debitnotes/'+number+'/clear', responseHandler);
    }

    deleteDebitNote(number, responseHandler) {
        this.get('debitnotes/'+number+'/delete', responseHandler);
    }

    updateDebitNoteDetails(number, currency, createdDate, responseHandler) {
        const data = {
            currency: currency,
            created_date: createdDate
        };
        this.post('debitnotes/'+number+'/updateDetails', data, responseHandler)
    }

    deleteDebitNoteItem(number, item, responseHandler) {
        this.get('debitnotes/'+number+'/deleteItem/'+item.id, responseHandler);
    }

    uploadDebitNoteDocument(number, file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('debitnotes/'+number+'/uploadDocument', data, responseHandler)
    }

    deleteDebitNoteDocument(number, document_id, responseHandler) {
        this.get('debitnotes/'+number+'/deleteDocument/'+document_id, responseHandler);
    }

    /* zalando */
    fetchZalandoProducts(responseHandler) {
        this.get('zld/products/', responseHandler);
    }

    setPrice(eans, regularPrice, discountedPrice, responseHandler) {
        let data = [];
        for (let i = 0; i < eans.length; i++) {
            data.push({
                regular_price: regularPrice,
                discounted_price: discountedPrice,
                ean: eans[i],
            })
        }
        this.post('zld/setProductPrice/', data, responseHandler)
    }

    submitZalandoProduct(data, responseHandler) {
        this.post('zld/submitNewProduct/', data, responseHandler)
    }

    submitZalandoPricesCsv(csvFile, responseHandler) {
        const data = new FormData();
        data.append('file', csvFile);

        this.post('zld/bulkUpdatePrices', data, responseHandler)
    }

    submitZalandoProductsCsv(csvFile, responseHandler) {
        const data = new FormData();
        data.append('file', csvFile);

        this.post('zld/bulkUploadProducts', data, responseHandler)
    }

    getAttributeData(type, responseHandler) {
        this.get('zld/getAttributeData/' + type+ '/' , responseHandler);
    }

    fetchOrder(orderNumber, responseHandler){
        axios.get(`${this.url}zld/getOrder/${orderNumber}`)
            .then(responseHandler)
            .catch((error) => {
                responseHandler(error.response)
            });
    }

    /* file upload */

    uploadProductsCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('products/bulkUpload', data, responseHandler)
    }

    uploadDraftProductsCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('products/draft/upload', data, responseHandler)
    }

    uploadEanCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('products/ean/upload', data, responseHandler)
    }

    uploadSizegroupMigrationCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('products/sizegroups/edit', data, responseHandler)
    }

    downloadZppPricesCsv(filename, onComplete) {
        const url = this.url + 'zld/product/prices/csv';
        this.downloadFile(url, filename, onComplete);
    }

    uploadZalandoProductImage(sku, imageNumber, file, extension, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('zld/productImages/'+sku+'/'+imageNumber+'/upload/'+extension, data, responseHandler)
    }


    uploadZalandoProductCad(sku, imageNumber, file, extension, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('zld/productCads/'+sku+'/'+imageNumber+'/upload/'+extension, data, responseHandler)
    }

    deleteZalandoProductImage(sku, imageNumber, responseHandler) {
        this.get('zld/productImages/'+sku+'/'+imageNumber+'/delete', responseHandler);
    }

    /* file download */

    uploadStockReportWithNoImages(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('reports/uploadStockReportWithNoImages', data, responseHandler)
    }
    downloadStockReportWithImages(reference, filename, onComplete) {
        const url = this.url + 'reports/downloadStockReportWithImages/' + reference;
        this.downloadFile(url, filename, onComplete);
    }

    downloadCustomersCsv() {
        const url = this.url + 'customers/names/csv';
        this.downloadFile(url, 'customer_list.csv', null);
    }

    downloadDebitNoteDocument(debit_number, document_id, filename) {
        const url = this.url + 'debitnotes/'+debit_number+'/downloadDocument/'+document_id;
        this.downloadFile(url, filename, null);
    }

    downloadPOShipmentPackingList(shNumber, filename) {
        const url = this.url + 'poshipments/'+shNumber+'/downloadPackingListFile';
        this.downloadFile(url, filename, null);
    }

    downloadPoShipmentInvoice(invoiceId, filename) {
        this.downloadFile(this.url + 'invoices/'+invoiceId+'/download', filename, null);
    }

    downloadFile(full_url, filename, onComplete) {
        axios.get(full_url, {responseType: 'blob'})
            .then((response) => {
                const contentType = response.headers['content-type'];
                this.triggerDownloadLink(response.data, filename, contentType)
                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    downloadPoCsv(poNumber) {
        const filename = poNumber + '.csv';
        axios.get(this.url + 'purchaseorders/'+poNumber+'/poCSV', {responseType: 'blob'})
            .then((response) => {
                this.triggerDownloadLink(response.data, filename, 'application/csv');
            })
            .catch((error) => {
                console.log(error.response);
                alert('Error occured: \n' + JSON.stringify(error.response, null,  2));
            })
    }

    downloadStockCsv(warehouseCode) {
        this.downloadFile(this.url + 'warehouses/'+warehouseCode+'/stockItems/stockCSV', warehouseCode+'-stock.csv', null);
    }

    downloadZalandoStockReport(onComplete) {
        const filename = 'zalando_stock_'+ moment().format('DD_MM_YYYY') + '.xlsx';
        this.downloadFile(this.url + 'zld/products/stockReport', filename, onComplete);
    }

    downloadDeliveryReportByDispatchDate(fromDate, toDate, onComplete) {
        const filename = 'delivery_report_by_dispatch_date_'+fromDate+'_'+toDate+'.csv';
        this.downloadFile(this.url + 'reports/deliveryByDispatchDate?from='+fromDate+'&to='+toDate, filename, onComplete);
    }

    downloadDeliveryReportByOrderDate(fromDate, toDate, onComplete) {
        const filename = 'delivery_report_by_order_date_'+fromDate+'_'+toDate+'.csv';
        this.downloadFile(this.url + 'reports/deliveryByOrderDate?from='+fromDate+'&to='+toDate, filename, onComplete);
    }

    downloadZalandoSalesReport(fromDate, toDate) {
        const filename = 'zalando_sales_report_'+fromDate+'_'+toDate+'.tsv';
        axios.get(this.url + 'zld/downloadOrdersReport/?from='+fromDate+'&to='+toDate, {responseType: 'blob'})
            .then((response) => {
                this.triggerDownloadLink(response.data, filename, 'text/tsv');
            })
            .catch((error) => {
                console.log(error.response)
            })
    }

    triggerDownloadLink(responseData, filename, contentType) {
        var file = new Blob([responseData], {
            type: contentType
        });
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    downloadExcelProductionReport(data, onComplete) {
        const filename = 'production_report_'+ moment().format('DD_MM_YYYY') + '.xlsx';
        const url = this.url + 'reports/ProductionExcel';

        axios.post(url, data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    downloadLRExcel(linesheet) {
        axios.post(this.url + 'products/linesheetProducts/downloadLinesheetReportExcel', linesheet,
            {
                responseType: 'arraybuffer',
            }
        ).then((response) => {
            this.triggerDownloadLink(response.data, 'linesheetReport.xlsx', 'text/xlsx')
        })
        .catch((error) => {
            console.log(error.response);
            alert('Error occured: \n' + JSON.stringify(error.response, null, 2));
        })
    }

    /*channel orders*/

    fetchDeliveries(responseHandler) {
        this.get('channels/orders/deliveries', responseHandler);
    }

    setDelivered(orderIds, responseHandler) {
        const data = {
            order_ids: orderIds
        };
        this.post('channels/orders/deliveries/resolve', data, responseHandler)
    }

    downloadDeliveryReport(onComplete) {
        const filename = 'delivery_report.csv';
        this.downloadFile(this.url + 'channels/orders/deliveries/csv', filename, onComplete);
    }

    searchChannelOrders(query, limit, responseHandler) {
        const data = {
            search_query: query,
            limit: limit
        };
        this.post('channels/orders/search', data, responseHandler)
    }

    fetchChannelOrders(channel, statuses, fromDate, toDate, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const channelCode = channel ? channel.code : null;
        const data = {
            channel_code: channelCode,
            statuses: statuses,
            from_date: fromDate,
            to_date: toDate,
        };
        this.post('channels/orders/', data, responseHandler, headers)
    }

    fetchStockProducts(warehouse, code, department, fit, category, season, collection, coo, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const past7daysStart = moment().subtract(7, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
        const past7daysEnd = moment().subtract(1, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});
        const past30daysStart = moment().subtract(30, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
        const past30daysEnd = moment().subtract(1, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});

        const data = {
            warehouse: warehouse,
            code: code,
            department_id: department ? department.id: -1,
            fit_id: fit ? fit.id: -1,
            category_id: category ? category.id: -1,
            season: season,
            collection: collection,
            coo: coo,
            seven_day_start: past7daysStart,
            seven_day_end: past7daysEnd,
            thirty_day_start: past30daysStart,
            thirty_day_end: past30daysEnd
        };
        this.post('channels/variants/stockProducts', data, responseHandler, headers)
    }

    export_stock_products(warehouse, code, department, fit, category, season, collection, coo, onComplete) {
        const past7daysStart = moment().subtract(7, 'd').set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const past7daysEnd = moment().subtract(1, 'd').set({hour: 23, minute: 59, second: 59, millisecond: 999});
        const past30daysStart = moment().subtract(30, 'd').set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const past30daysEnd = moment().subtract(1, 'd').set({hour: 23, minute: 59, second: 59, millisecond: 999});

        const data = {
            warehouse: warehouse,
            code: code,
            department_id: department ? department.id : -1,
            fit_id: fit ? fit.id : -1,
            category_id: category ? category.id : -1,
            season: season,
            collection: collection,
            coo: coo,
            seven_day_start: past7daysStart,
            seven_day_end: past7daysEnd,
            thirty_day_start: past30daysStart,
            thirty_day_end: past30daysEnd
        };
        const filename = 'stock_report_'+ moment().format('DD_MM_YYYY__HH_mm') + '.xlsx';

        axios.post(this.url + 'channels/variants/stockProducts/export', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    fetchChannelTopSellingProducts(channelCode, fromDate, toDate, resultCount, responseHandler) {
        const past7daysStart = moment().subtract(7, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
        const past7daysEnd = moment().subtract(1, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});
        const data = {
            channel_code: channelCode,
            from_date: fromDate,
            to_date: toDate,
            stock_cover_start: past7daysStart,
            stock_cover_end: past7daysEnd,
            result_count: resultCount,
        };
        this.post('channels/variants/topSelling', data, responseHandler)
    }

    downloadTopSellingReport(outputType, channels, fromDate, toDate, resultCount, onComplete) {
        const past7daysStart = moment().subtract(7, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
        const past7daysEnd = moment().subtract(1, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});
        console.log('past7daysStart: ' + past7daysStart);
        console.log('past7daysEnd: ' + past7daysEnd);
        const data = {
            output_type: outputType,
            channels: channels,
            from_date: fromDate,
            to_date: toDate,
            stock_cover_start: past7daysStart,
            stock_cover_end: past7daysEnd,
            result_count: resultCount,
        };

        const filename = 'top_selling_'+ moment().format('DD_MM_YYYY__HH_mm') + '.xlsx';

        axios.post(this.url + 'channels/variants/topSelling/report', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    fetchChannelOrder(orderId, responseHandler) {
        this.get('channels/orders/' + orderId, responseHandler);
    }

    fetchChannelOrderStatusMetrics(channelCode, fromDate, toDate, responseHandler) {
        const data = {
            channel_code: channelCode,
            from_date: fromDate,
            to_date: toDate,
        };
        this.post('channels/orders/statuses/metrics', data, responseHandler)
    }

    fetchStatusOrders(pageSize, pageNumber, status, channelCode, fromDate, toDate, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };

        const data = {
            status_group: status,
            channel_code: channelCode,
            from_date: fromDate,
            to_date: toDate,
        };
        this.post('channels/orders/statuses/list', data, responseHandler, headers)
    }

    fetchVolumeOrders(pageSize, pageNumber, channelCode, day, timeScale, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };

        const data = {
            channel_code: channelCode,
            day: day,
            time_scale: timeScale,
        };
        this.post('channels/orders/volume/list', data, responseHandler, headers)
    }

    downloadStatusOrdersCsv(filename, status, channelCode, fromDate, toDate, onComplete) {
        const data = {
            status_group: status,
            channel_code: channelCode,
            from_date: fromDate,
            to_date: toDate,
        };

        axios.post(this.url + 'channels/orders/statuses/csv', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    downloadVolumeOrdersCsv(filename, channelCode, timeScale, day, onComplete) {
        const data = {
            channel_code: channelCode,
            time_scale: timeScale,
            day: day,
        };

        axios.post(this.url + 'channels/orders/volume/csv', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'text/csv');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    fetchChannelOrderVolumeMetrics(channelCode, timeScale, fromDate, toDate, responseHandler) {
        const data = {
            channel_code: channelCode,
            time_scale: timeScale,
            from_date: fromDate,
            to_date: toDate,
        };
        this.post('channels/orders/volume/metrics', data, responseHandler)
    }

    fetchChannels(responseHandler) {
        this.get('channels/', responseHandler);
    }

    fetchCouriers(responseHandler) {
        this.get('couriers/', responseHandler);
    }

    fetchOrderStatuses(responseHandler) {
        this.get('channels/orders/statuses/', responseHandler);
    }

    fetchTrackingEvents(orderId, responseHandler) {
        this.get('channels/orders/'+ orderId + '/tracking/events/', responseHandler);
    }

    getLatestTrackingEvent(orderId, responseHandler) {
        this.get('channels/orders/'+ orderId + '/tracking/events/refresh', responseHandler);
    }

    updateOrderStatus(orderId, action, responseHandler) {
        const data = {
            action: action
        };
        this.post('channels/orders/'+ orderId + '/updateStatus', data, responseHandler)
    }

    updateOrderTracking(orderId, courierCode, trackingNumber, shippingDescription, responseHandler) {
        const data = {
            order_id: orderId,
            courier_code: courierCode,
            tracking_number: trackingNumber,
            shipping_description: shippingDescription,
        };
        this.post('channels/orders/' + orderId + '/tracking/update', data, responseHandler)
    }

    /* channel variants*/

    fetchChannelVariants(searchText, pageNumber, pageSize, responseHandler) {
        const headers = {
            'page-number': pageNumber,
            'page-size': pageSize
        };
        const data = {
            search_query: searchText,
        };
        this.post('channels/variants/search', data, responseHandler, headers)
    }

    fetchChannelVariant(variantId, responseHandler) {
        this.get('channels/variants/'+variantId, responseHandler);
    }

    updateChannelVariant(variantId, data, responseHandler) {
        this.post('channels/variants/' + variantId + '/update', data, responseHandler);
    }

    downloadChannelVariantJlpCodes(responseHandler) {
        this.get('channels/variants/downloadJlpCodes', responseHandler);
    }

    downloadChannelVariantCsv(onComplete) {
        const formattedDate = Utils.formatDate(new Date(), 'YYYY-MM-DD__HH_mm');
        const filename = 'rcm_variants_'+formattedDate+'.csv';
        this.downloadFile(this.url + 'channels/variants/export', filename, onComplete);
    }

    uploadTvgProductCodesCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('channels/variants/uploadTvgCodes', data, responseHandler)
    }

    deleteJlpProductsCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('channels/variants/deleteJlpProducts', data, responseHandler)
    }

    uploadVariantsCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('channels/variants/upload', data, responseHandler)
    }

    bulkToggleVariantsCsv(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('channels/variants/bulkToggleChannels', data, responseHandler)
    }


    /* ratios */

    fetchRatios(selectedCustomer, selectedDepartment, selectedFit, selectedCategory, sizeRange, responseHandler) {
        const data = {
            customer_id: selectedCustomer? selectedCustomer.id : -1,
            department_id: selectedDepartment ? selectedDepartment.id : -1,
            fit_id: selectedFit ? selectedFit.id : -1,
            category_id: selectedCategory ? selectedCategory.id : -1,
            size_range: sizeRange,
        };
        this.post('sizeratios/', data, responseHandler)
    }

    downloadSizeRatiosExcel(onComplete) {
        const data = {};
        const filename = 'size_ratios_'+ moment().format('DD_MM_YYYY_HH_mm') + '.xlsx';
        axios.post(this.url + 'sizeratios/excel', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    downloadSizeRatioQuantitiesTemplate(onComplete) {
        const data = {};
        const filename = 'sizeratio_calculation_template.xlsx';
        axios.post(this.url + 'sizeratios/setquantities/template', data, {responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }

    uploadSizeRatiosExcel(file, responseHandler) {
        const data = new FormData();
        data.append('file', file);

        this.post('sizeratios/upload', data, responseHandler)
    }

    setSizeRatiosQuantities(file, onComplete){
        const new_headers = {'user-id': AuthService.loggedInId()};

        const data = new FormData();
        data.append('file', file);
        const filename = file.name;
        axios.post(this.url + 'sizeratios/setquantities/excel', data, {headers: new_headers, responseType: 'blob'})
            .then((response) => {

                this.triggerDownloadLink(response.data, filename, 'application/xlsx');

                if(onComplete) {
                    onComplete(response);
                }
            })
            .catch((error) => {
                console.log(error.response)
                if(onComplete) {
                    onComplete(error.response);
                }
            })
    }


    /*destinations*/

    fetchDestinations(responseHandler) {
        this.get('destinations', responseHandler);
    }

    newDestination(destination, responseHandler) {
        this.post('destinations/new', destination, responseHandler)
    }

    updateDestination(destination, responseHandler) {
        this.post('destinations/'+ destination.id + '/update', destination, responseHandler)
    }

    deleteDestination(destination, responseHandler){
        this.get('destinations/'+destination.id+'/delete', responseHandler);
    }

    /* countries */
    fetchCountries(responseHandler) {
        this.get('countries', responseHandler);
    }

}

export default Server;