import React, {useContext, useState} from 'react';
import moment from 'moment';
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableCell, IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditPoDialog from "./EditPoDialog";
import {PODetailsContext} from "./PoDetailsStore";
import Utils from "../../Utils";
import AuthService from "../../AuthService";

const OrderInfo = props => {

    const po = props.po;
    const setPo = props.setPo;

    const {state, dispatch} = useContext(PODetailsContext);

    const [editPoDialogOpen, setEditPoDialogOpen] = useState(false);

    const showEditPoDialog = () => {
        if(!Utils.isIn(po.status, ['RAISED', 'DRAFT','NEW'])) {
            alert('Cannot edit PO in status: '+po.status);
            return;
        }
        setEditPoDialogOpen(true);
    };

    const onCloseEditPoDialog = () => setEditPoDialogOpen(false);

    const onConfirmEditPoDialog = (updatedPo) => {
        setEditPoDialogOpen(false);
        setPo(updatedPo);
        dispatch({type: 'RELOAD_PO_STATS'});
    };

    return (
        <Card>
            <CardHeader title="Order info" action={
                <IconButton size="small" onClick={(e)=>{showEditPoDialog()}} disabled={AuthService.isSupplierLoggedIn()}>
                    <EditIcon fontSize="small"/>
                </IconButton>
            }/>
                <Table size={"small"}>
                    <TableBody>

                        <TableRow>
                            <TableCell>Supplier: {po.supplier.name}</TableCell>
                            <TableCell>Currency: {po.currency}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Issued: {moment(po.issue_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>Exit: {moment(po.exit_date).format('DD/MM/YYYY')}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2}>Created: {moment(po.created_date).format('DD/MM/YYYY HH:MM')}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2}>Reference: {po.reference}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

            <EditPoDialog po={po} open={editPoDialogOpen} onConfirm={onConfirmEditPoDialog} onClose={onCloseEditPoDialog}/>
        </Card>
    );
};

export default OrderInfo;
