

import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Backdrop} from "@material-ui/core";
import LabeledLinearProgress from "./Loading/LabeledLinearProgress";

const ProgressLoadingScreen = props => {
    const useStyles = makeStyles(theme => ({
        root: {width: '100%'},
        backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', },
    }));

    const classes = useStyles();

    return (
        <Backdrop
            className={classes.backdrop}
            open={props.loading}
        >
            <div className={classes.root}>
            <LabeledLinearProgress currentValue={props.currentValue} maxValue={props.maxValue}/>
            </div>
        </Backdrop>
    );
};

export default ProgressLoadingScreen;