import React, {useEffect, useState} from 'react';
import Server from "../../Server";
import {makeStyles} from "@material-ui/styles";
import TestData from "../../TestData";
import POShipmentDetailsHeader from "./POShipmentDetailsHeader";
import {Grid} from "@material-ui/core";
import ShipmentInfo from "./ShipmentInfo";
import ShipmentInvoice from "./ShipmentInvoice";
import PackingListsTabContent from "./PackingListsTabContent";
import ShipmentPos from "./ShipmentPos";
import Totals from "./Totals";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(2)
    }
}));

const POShipmentDetails = props => {

    const {match} = props;

    let {shNumber} = match.params;
    const classes = useStyles();
    const server = new Server();

    const [shipment, setShipment] = useState(TestData.emptyPOShipment());
    const [shProducts, setShProducts] = useState([]);

    useEffect(() => {document.title = shNumber;}, []);

    useEffect(() => {
        server.fetchShipmentProductsSummary(shNumber, (response) => {
            if(response.status !== 200) {
                alert(response.data);
                return;
            }

            setShipment(response.data.shipment);
            setShProducts(response.data.product_summaries);
        });
    }, [shNumber]);

    return (
        <div className={classes.root}>
            <POShipmentDetailsHeader shipment={shipment} shipmentProducts={shProducts}/>

            <Grid
                className={classes.poDetails}
                container
                direction="column"
                spacing={2}
                alignItems="flex-start"
            >

                <Grid item>
                    <Totals shNumber={shNumber}/>
                </Grid>

                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                        <Grid item>
                            <ShipmentInfo shipment={shipment} shipmentProducts={shProducts} setShipment={setShipment}/>
                        </Grid>

                        <Grid item>
                            <ShipmentInvoice shipment={shipment} setShipment={setShipment}/>
                        </Grid>

                        <Grid item>
                            <ShipmentPos shNumber={shNumber}/>
                        </Grid>
                    </Grid>
                </Grid>



                <Grid item>
                    <PackingListsTabContent shipment={shipment} shipmentProducts={shProducts}/>
                </Grid>

            </Grid>

        </div>
    );

};

export default POShipmentDetails;