import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import Utils from "../../Utils";
import Pricing from "../../Pricing";

const ChannelOrdersTable = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },
        NumberCell : { cursor: 'pointer'}
    }));

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const onOrderClick = (order, event) => {
        nav.goToChannelOrderDetails(order.id, true);
    };

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell>Order number</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total value</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            props.orders.map((order, index) => (
                                <TableRow key={order.id} onClick={(e) => onOrderClick(order, e)}>
                                    <TableCell>{order.channel.code}</TableCell>
                                    <TableCell className={classes.NumberCell}>{order.channel_order_number}</TableCell>
                                    <TableCell>{Utils.formatDate(order.date, 'DD/MM/YYYY HH:mm')}</TableCell>
                                    <TableCell>{Pricing.formatPrice(order.total_value, order.currency)}</TableCell>
                                    <TableCell>{order.status}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )

};

export default ChannelOrdersTable;