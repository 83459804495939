import React from 'react';
import {Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Pricing from "../../../Pricing";

const Lines = props => {
    const lines = props.lines;
    const currency = props.currency;

    return (
        <Card>
            <CardHeader title="Items"/>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Ch. line number</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        lines.map((line, index) => (
                            <TableRow key={line.id}>
                                <TableCell>{line.channel_number}</TableCell>
                                <TableCell>{line.variant.sku}</TableCell>
                                <TableCell>{line.variant.ean}</TableCell>
                                <TableCell>{line.description}</TableCell>
                                <TableCell>{line.units}</TableCell>
                                <TableCell>{Pricing.formatPrice(line.price, currency)}</TableCell>
                                <TableCell>{line.status}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>

            </Table>
        </Card>
    );
};

export default Lines;