import React from 'react';
import {Paper,Tabs, Tab} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const StockTabs = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            marginTop: theme.spacing(2)
        },
    }));
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper>
                <Tabs
                    value={props.tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={props.onTabChange}
                >
                    {
                        props.tabNames.map((tabName, index) => (
                            <Tab key={index} label={tabName}/>
                        ))
                    }
                </Tabs>
            </Paper>
        </div>
    );
};

export default StockTabs;