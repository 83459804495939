import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import Utils from "../../Utils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditQAReportDialog from "./EditQAReportDialog";

const AddQAReportButton = props => {
    const onReportAdded = props.onReportAdded;
    const [open, setOpen] = useState(false);

    const onCloseDialog = (event, reason) => {
        if(Utils.isIn(reason, ["escapeKeyDown", "backdropClick"])) {
            return;
        }
        setOpen(false);
    };

    const onConfirmDialog = () => {
        setOpen(false);
        onReportAdded();
    };

    return (
        <>
            <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
                <AddCircleIcon size="small"/>
            </IconButton>
            <EditQAReportDialog reportId={undefined} open={open} onClose={onCloseDialog}
                                onConfirm={onConfirmDialog}/>
        </>
    )
};

export default AddQAReportButton;