import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import Server from './Server'

const Destinations = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
    }));

    const classes = useStyles();

    const [destinations, setDestinations] = useState([])

    useEffect(()=>{
        new Server().fetchDestinations(response => {
            setDestinations(response.data);
        })
    }, [props])

    const validate = (destination) => {
        let errorMessage = '';
        if(!destination.name){
            errorMessage = "Destination name cannot be blank.";
            return errorMessage;
        }
        return errorMessage;
    }

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Destinations
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                className={classes.poTable}
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
            >

                <Grid item xs={6}>

                    <MaterialTable
                        columns={[
                            {title: 'Name', field: 'name'},
                        ]}
                        data={
                            destinations
                        }
                        options={
                            {
                                paging: false,
                                showTitle: false,
                                search: false,
                                actionsColumnIndex: 5
                            }
                        }
                        actions={[]}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    let destinationData = {
                                        'name': newData.name ? newData.name : '',
                                    };
                                    let errors = validate(destinationData);
                                    if (errors.length !== 0) {
                                        console.log('errors found');
                                        reject();
                                        return alert(errors);
                                    }

                                    new Server().newDestination(destinationData, (response) => {
                                        if(response.status !== 200) {
                                            alert(response.data);
                                        } else {
                                            setDestinations(destinations => {
                                                return destinations.concat(response.data);
                                            });
                                        }
                                    });
                                    resolve()
                                }),
                            onRowUpdate: (newData, oldData) =>

                                new Promise((resolve, reject) => {
                                    let errors = validate(newData);
                                    if (errors.length !== 0) {
                                        reject();
                                        return alert(errors);
                                    }
                                    new Server().updateDestination(newData, (response) => {
                                        if (response.status !== 200) {
                                            alert(response.data);
                                        } else {
                                            const index = destinations.indexOf(oldData);
                                            let updatedDestinations = JSON.parse(JSON.stringify(destinations));
                                            updatedDestinations = updatedDestinations.map((currentDestination, j) => {
                                                if (j === index) {
                                                    return newData;
                                                } else {
                                                    return currentDestination;
                                                }
                                            });
                                            setDestinations(updatedDestinations);
                                        }
                                    })
                                    resolve()
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    new Server().deleteDestination(oldData, (response) => {
                                        if (response.status !== 200) {
                                            alert(response.data);
                                        } else {
                                            const index = destinations.indexOf(oldData);
                                            setDestinations(destinations => {
                                                return destinations.filter((currentDestination, j) =>
                                                    j !== index
                                                )
                                            });
                                        }
                                    })
                                    resolve()
                                }),
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Destinations