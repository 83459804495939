import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import Filters from "./Filters";
import StockPager from "../Stock/StockPager";
import LoadingScreen from "../LoadingScreen";
import Header from "./Header";
import DDTable from "./DDTable";


const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const DesignDocs = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [selectedProductCodes, setSelectedProductCodes] = useState([]);

    const [designDocs, setDesignDocs] = useState([]);
    const [selectedDesignDocs, setSelectedDesignDocs] = useState([]);

    const [shouldRefreshData, setShouldRefreshData] = useState(false);

    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Design documents';}, []);

    useEffect(() => {
        setLoading(true);
        new Server().fetchDesignDocumentsPage(selectedProductCodes, currentPageNumber, pageSize, (response) => {
            setLoading(false);

            setDesignDocs(response.data);

            setShouldRefreshData(false);

            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
        });

    }, [selectedProductCodes, currentPageNumber, pageSize, shouldRefreshData]);

    const onPageSizeChange = (event) => {
        setPageSize(event.target.value);
    };

    const onNextPageClick = (event) => {
        setCurrentPageNumber(currentPageNumber + 1);
    };

    const onPreviousPageClick = (event) => {
        setCurrentPageNumber(currentPageNumber - 1);
    };

    return (
        <div className={classes.root}>

            <Header/>

            <Filters
                selectedProductCodes={selectedProductCodes} setSelectedProductCodes={setSelectedProductCodes}
            />

            <DDTable
                className={classes.conversationPlaceholder}
                designDocs={designDocs}
                selectedDesignDocs={selectedDesignDocs}
                setSelectedDesignDocs={setSelectedDesignDocs}
                setShouldRefreshData={setShouldRefreshData}
            />

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default DesignDocs;