import React from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    offIcon: {
        fill: theme.palette.red,
    },
    onIcon: {
        color: theme.palette.green,
    }
}));

const ChannelStatusIndicator = props => {
    const on = props.on;

    const classes = useStyles();

    if (on) {
        return (
            <CheckCircleOutlineIcon className={classes.onIcon}/>
        );
    }else {
        return (
            <NotInterestedIcon className={classes.offIcon}/>
        );
    }

};

export default ChannelStatusIndicator;