import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import ProductsHeader from "./ProductsHeader";
import ProductsTable from "./ProductsTable";
import StockPager from "../Stock/StockPager";
import ProductsFilters from "./ProductsFilters";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const Products = props => {

    const classes = useStyles();

    const [products, setProducts] = useState([]);

    const [selectedProductIds, setSelectedProductIds] = useState([]);//selected product IDs
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    const [brandFilter, setBrandFilter] = useState(null);
    const [seasonFilter, setSeasonFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);

    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {document.title = 'Products';}, []);

    useEffect(() => {
        const server = new Server();
        server.fetchProductAttributeValues('brands', (response) => {
            setBrands(response.data);
        });
        server.fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });
       reloadProducts(1, pageSize,null, null, null);
    }, []);

    const reloadProducts = (pageNum, pageSize, brand, category, season) => {
        const data = {
            brand_id: brand === null ? -1 : brand.id,
            category_id: category === null ? -1 : category.id,
            season: season === null ? 'any' : season,
            page_number: pageNum,
            page_size: pageSize
        };

        new Server().fetchProductsInventory(data, response => {
            if(response.status === 200) {
                setProducts(formatProducts(response.data));

                setBrandFilter(brand);
                setSeasonFilter(season);
                setCategoryFilter(category);

                setPageSize(response.data.page_info.page_size);
                setPageCount(response.data.page_info.page_count);
                setCurrentPageNumber(response.data.page_info.page_number);
                setHasNextPage(response.data.page_info.has_next);
                setHasPreviousPage(response.data.page_info.has_previous);

            } else {
                alert('Error fetching products: \n' + response.data);
            }
        });
    };

    const formatProducts = (inventory) => {
        let formattedProducts = [];
        inventory.product_inventories.forEach((product_inventory) => {
            let product = product_inventory.product;
            product.unallocated = product_inventory.unallocated;
            product.allocated = product_inventory.allocated;
            product.on_hand = product_inventory.on_hand;
            product.incoming = product_inventory.incoming;
            formattedProducts.push(product);
        });
        return formattedProducts;
    }

    const onApplyFilterClick = () => {
        reloadProducts(1, pageSize, brandFilter, categoryFilter, seasonFilter);
    };

    const onClearFiltersClick = () => {
      reloadProducts(1, pageSize,null, null, null);
    };

    const onPageSizeChange = (event) => {
        reloadProducts(1, event.target.value, brandFilter, categoryFilter, seasonFilter);
    };

    const onNextPageClick = (event) => {
        reloadProducts(currentPageNumber+1, pageSize, brandFilter, categoryFilter, seasonFilter);
    };

    const onPreviousPageClick = (event) => {
        reloadProducts(currentPageNumber-1, pageSize, brandFilter, categoryFilter, seasonFilter);
    };

    return (
        <div className={classes.root}>

            <ProductsHeader/>

            <ProductsFilters
                brands={brands} brandFilter={brandFilter} setBrandFilter={setBrandFilter}
                seasonFilter={seasonFilter} setSeasonFilter={setSeasonFilter}
                categories={categories} categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter}
                onApplyFilterClick={onApplyFilterClick} onClearFiltersClick={onClearFiltersClick}
            />

            <ProductsTable products={products} selected={selectedProductIds} setSelected={setSelectedProductIds} className={classes.conversationPlaceholder}/>

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />

        </div>
    )
};

export default Products;