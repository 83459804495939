import React from 'react';
import {Card, CardHeader, Table, TableBody, TableCell, TableRow} from "@material-ui/core";

const Address = props => {
    const address = props.address;

    return(
        <Card>
            <CardHeader title="Address"/>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Line 1: {address.line1}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Line 2: {address.line2}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Line 3: {address.line3}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Postcode: {address.postcode}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Country: {address.country_code}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default Address;