import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography, Button, Card, TextField, CardContent, List, ListItem, ListItemText, IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl} from "@material-ui/core";
import Server from "../Server";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import NewUserDialog from "./NewUserDialog";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditPermissionsDialog from "./EditPermissionsDialog";

const Users = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        },
    }));

    const classes = useStyles();

    const [users, setUsers] = useState([]);


    useEffect( () => {
        reloadUsers(null);
    }, []);


    const reloadUsers = (preselectedUser) => {
        new Server().fetchUsers((response) => {
            setUsers(response.data);


            if(preselectedUser === null) {
                handleListItemClick(null, response.data[0]);
            } else {
                handleListItemClick(null, preselectedUser);
            }

        })
    };

    //for user selection
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);

    const [name, setName] = useState('NA');
    const [email, setEmail] = useState('NA');
    const [password, setPassword] = useState('NA');
    const [showPassword, setShowPassword] = useState(false);


    //show/hide password

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    //edit permissions dialog
    const [openEditPermissionsDialog, setOpenEditPermissionsDialog] = useState(false);

    const editUserPermissions = () => {
        setOpenEditPermissionsDialog(true);
    };

    const onCloseEditPermissionsDialog = () => {
        setOpenEditPermissionsDialog(false);
    };

    const onConfirmEditPermissionsDialog = () => {
        setOpenEditPermissionsDialog(false);
    };

    //new user dialog
    const [openNewUserDialog, setOpenNewUserDialog] = useState(false);

    const onCloseNewUserDialog = () => {
        setOpenNewUserDialog(false);
    };

    const onConfirmNewUserDialog = (newUser) => {
        setOpenNewUserDialog(false);
        reloadUsers(newUser);
    };


    const handleListItemClick = (event, user) => {
        setShowPassword(false);
        new Server().fetchUser(user.id, (response) => {
            setUserDataFromResponse(response);
        });
    };


    const setUserDataFromResponse = (response) => {
        setSelectedUser(response.data);
        setSelectedIndex(response.data.id);
        setName(response.data.name);
        setEmail(response.data.email);
        setPassword(response.data.password);
    };

    const save = () => {
        console.log('save called  ');

        new Server().updateUserDetails(selectedUser.id, name, email, password, (response) => {
           if(response.status === 200) {
               //setUserDataFromResponse(response)
                reloadUsers(response.data);
           } else {
               alert('Error updating user: \n' + JSON.stringify(response.data, null, 2));
           }
        });
    };

    const deleteUser = () => {
        var nextAction = window.confirm('This user will be permanently deleted. Proceed?');
        if(!nextAction) return

        new Server().deleteUser(selectedUser, (response) => {
            if(response.status === 200) {
                reloadUsers(null);
            } else {
                alert('Error deleting user: \n' +JSON.stringify(response.data, null, 2));
            }
        })
    };

    const createNewUser = () => {
        setOpenNewUserDialog(true);
    };


    const SaveButton = () => (
        <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={save}
        >
            <SaveIcon className={classes.buttonIcon} />
            Save
        </Button>
    );

    const DeleteButton = () => (
        <Button
            color="default"
            variant="contained"
            fullWidth
            onClick={deleteUser}
        >
            <DeleteIcon className={classes.buttonIcon} />
            Delete
        </Button>
    );

    const EditPermissionsButton = () => (
        <Button
            color="default"
            variant="contained"
            fullWidth
            onClick={editUserPermissions}
        >
            <EditIcon className={classes.buttonIcon} />
            Edit permissions
        </Button>
    );

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Browse Users
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Users
                    </Typography>
                </Grid>

            </Grid>

            <Grid
                className={classes.poTable}
                container
                direction="row"
                spacing={2}
            >


                <Grid item xs={3}>
                    <Card>

                        <Button fullWidth color="primary" onClick={createNewUser}>create New user</Button>

                        <List component="nav">

                            {users.map((row) => {
                                return (
                                    <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedIndex === row.id}
                                        onClick={(event) => handleListItemClick(event, row)}
                                    >
                                        <ListItemText primary={row.name}
                                                      secondary={row.email } />
                                    </ListItem>
                                )
                            })

                            }
                        </List>

                    </Card>
                </Grid>

                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <TextField
                                    size={"small"}
                                        fullWidth
                                    label="Name"
                                    variant="outlined"
                                               value={name}
                                               onChange={(e) => setName(e.target.value)}/>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        fullWidth
                                        size={"small"}
                                        label="Email"
                                        variant="outlined"
                                               value={email}
                                               onChange={(e) => setEmail(e.target.value)}/>
                                </Grid>

                                <Grid item>
                                    <FormControl size={"small"}  fullWidth className={classes.textField} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            fullWidth
                                            size={"small"}
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(event) => {setPassword(event.target.value)}}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size={"small"}
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column" spacing={1} >

                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid item xs={6}>
                                                    <DeleteButton/>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <SaveButton/>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <EditPermissionsButton/>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>


            </Grid>
            <EditPermissionsDialog user={selectedUser} open={openEditPermissionsDialog} onClose={onCloseEditPermissionsDialog} onConfirm={onConfirmEditPermissionsDialog}/>
            <NewUserDialog open={openNewUserDialog} onClose={onCloseNewUserDialog} onConfirm={onConfirmNewUserDialog}/>
        </div>
    )
};

export default Users
