import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Link,
    TableContainer,
    TableHead,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    Grid, CircularProgress, Button
} from "@material-ui/core";
import {useSnackbar} from "notistack";
import Server from "../../Server";
import Utils from "../../Utils";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cell: {
        paddingTop: 6,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 6,
    }
}));

const DeliveriesTable = props => {
    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [deliveries, setDeliveries] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect( () => {
        reloadDeliveries();
    }, [])

    const reloadDeliveries = () => {
        setSelected([]);
        setDownloadLoading(true);
        new Server().fetchDeliveries((response) => {
            setDownloadLoading(false);
            setDeliveries(response.data);
        })
    };

    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }));

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();
        const { numSelected, markResolved, downloadCsv } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        Delivery exceptions
                    </Typography>
                )}

                {
                    numSelected === 0 ? (
                        <Tooltip title="Download CSV">
                            <IconButton size={"small"} onClick={downloadCsv}>
                                <CloudDownloadIcon/>
                            </IconButton>
                        </Tooltip>
                    ) : null
                }


                {
                    downloadLoading ? <CircularProgress size={20}/> : null
                }

                {numSelected > 0 ? (
                    <Button color="primary" variant="text" onClick={markResolved}>Resolve</Button>
                ) : null}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = deliveries.map((n) => n.order_id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, orderId) => {
        const selectedIndex = selected.indexOf(orderId);
        let newSelected = JSON.parse(JSON.stringify(selected));

        if (selectedIndex === -1) {//if not selected, add
            newSelected.push(orderId);
        } else {//if already selected, remove
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const rowCount = deliveries.length;
    const numSelected = selected.length;
    const DATE_FORMAT = 'DD/MM HH:mm';

    const getLocalOrderUrl = (orderId) => {
        return nav.getChannelOrderDetailsPath(orderId);
    };

    const markResolved = () => {
        new Server().setDelivered(selected, (response) => {
            if(response.status === 200) {
                reloadDeliveries();
            } else {
                enqueueSnackbar('Error resolving orders', { variant: 'error'});
                console.log(JSON.stringify(response.data))
            }
        })
    };

    const downloadCsv = () => {
        setDownloadLoading(true);
        new Server().downloadDeliveryReport((response) => {
            setDownloadLoading(false);
        })
    };

    return (
        <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} markResolved={markResolved} downloadCsv={downloadCsv}/>
            <TableContainer>
                <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    size="small"
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>Internal ID</TableCell>
                            <TableCell className={classes.cell}>BP ID</TableCell>
                            <TableCell className={classes.cell}>Channel</TableCell>
                            <TableCell className={classes.cell}>Number</TableCell>
                            <TableCell className={classes.cell}>Status</TableCell>
                            <TableCell className={classes.cell}>Ordered</TableCell>
                            <TableCell className={classes.cell}>Dispatched</TableCell>
                            <TableCell className={classes.cell}>Exp. delivery</TableCell>
                            <TableCell className={classes.cell}>Days late</TableCell>
                            <TableCell className={classes.cell}>Tracking</TableCell>
                            <TableCell className={classes.cell}>Last checked</TableCell>
                            <TableCell className={classes.cell}>Latest</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            deliveries.map((row, index) => {
                                const bpOrderUrl = Utils.getBpOrderUrl(row.bp_order_id);
                                const localOrderUrl = getLocalOrderUrl(row.order_id);
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.order_id}
                                        selected={isSelected(row.order_id)}
                                    >
                                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.order_id)}>
                                            <Checkbox
                                                size="small"
                                                checked={isSelected(row.order_id)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Link href={localOrderUrl} target="_blank" rel="noopener noreferrer">
                                                <span>{row.order_id}</span>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Link href={bpOrderUrl} rel="noopener noreferrer" target="_blank">
                                                <span>{row.bp_order_id}</span>
                                            </Link>
                                        </TableCell>
                                        <TableCell className={classes.cell}>{row.channel_name}</TableCell>
                                        <TableCell className={classes.cell}>{row.channel_number}</TableCell>
                                        <TableCell className={classes.cell}>{row.status}</TableCell>
                                        <TableCell className={classes.cell}>{Utils.formatDate(row.order_date, DATE_FORMAT)}</TableCell>
                                        <TableCell className={classes.cell}>{Utils.formatDate(row.dispatch_date, DATE_FORMAT)}</TableCell>
                                        <TableCell className={classes.cell}>{Utils.formatDate(row.latest_delivery_date, DATE_FORMAT)}</TableCell>
                                        <TableCell className={classes.cell}>{row.days_late}</TableCell>
                                        <TableCell className={classes.cell}>
                                            {row.carrier}
                                            <br/>
                                            <Link href={row.link} rel="noopener noreferrer" target="_blank">
                                                <span>{row.tracking_num}</span>
                                            </Link>
                                            <br/>
                                            {row.shipping_desc}
                                        </TableCell>
                                        <TableCell className={classes.cell}>{Utils.formatDate(row.last_check_date, DATE_FORMAT)}</TableCell>
                                        <TableCell className={classes.cell}>{row.last_carrier_message}</TableCell>
                                    </TableRow>
                                );
                            }
                            )
                        }

                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    );
}

export default DeliveriesTable;