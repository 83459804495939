import React, {useEffect, useState} from 'react';
import Server from "../Server";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";

const ShareDialog = props => {
    const {open, onConfirm, onClose, selectedDocumentIds} = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [suppliers, setSuppliers] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    useEffect(() => {

        new Server().fetchSuppliers((response) => {
            setSuppliers(response.data);
        });

    }, []);

    const confirm = () => {
        const selectedSupplierIds = selectedSuppliers.map((supplier) => supplier.id);
        new Server().shareDesignDocs(selectedDocumentIds, selectedSupplierIds, (response) => {
            if(response.status === 200) {
                enqueueSnackbar('Design documents successfully shared', { variant: 'success'});
                onConfirm();
            } else {
                enqueueSnackbar('Error sharing with suppliers', { variant: 'error'});
                console.log(JSON.stringify(response.data))
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">Share</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            size="small"
                            options={suppliers}
                            getOptionLabel={s => s.name}
                            value={selectedSuppliers}
                            onChange={(e, v, r) => setSelectedSuppliers(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Suppliers" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        Sharing triggers an email to the supplier.
                        <br/>
                        Supplier will be able to view documents but cannot edit them.
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="default">
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ShareDialog;
