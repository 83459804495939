import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Popover from "@material-ui/core/Popover";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from '@material-ui/core/ListSubheader';
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {alpha} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Server from "./Server";
import NavigationService from "./NavigationService";

const SkuSearch = props => {

    const useStyles = makeStyles(theme => ({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },

    }));

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState({pos:[], shipments:[], products:[], packages:[]});
    const [searching, setSearching] = useState(false);

    const onSearchQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const onSearchKeyDown = (e) => {
        if (e.keyCode === 13 && query !== '') {
            console.log('Enter pressed with query: ' + query);
            openPopover(e);
            setSearching(true);
            new Server().searchSku(query, (response) => {
               if(response.status === 200) {
                   setSearchResults(response.data);
               } else {
                   alert('Error searching: \n' + JSON.stringify(response.data, null, 2));
               }
               setSearching(false);
            });

        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showPo = (number) => {
        nav.goToPoDetails(number);
    };

    const showShipment = (shipment) => {
        nav.goToPoShipmentDetails(shipment.number);
    };

    const showProduct = (code) => {
        nav.goToProductDetails(code);
    }

    const showPackage = (number) => {
        nav.goToConsolidatedPackage(number);
    }

    const NoneListItem = () => (
        <ListItem>
            <ListItemText primary="None"/>
        </ListItem>
    );

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={onSearchQueryChange}
                onKeyDown={onSearchKeyDown}

            />
            <div>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                        style: { width: '35%' },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >

                    <Card className={classes.cardStyle}>
                        <CardContent>
                        { searching ? <Typography
                                component="h2"
                            >
                                Searching...
                            </Typography>
                            :
                            <Grid container direction="row" spacing={1}>

                                <Grid item>
                                    <List
                                        dense
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Products
                                            </ListSubheader>
                                        }>

                                        {
                                            searchResults.products.length === 0 ? <NoneListItem/> : null
                                        }
                                        {searchResults.products.slice(0,16).map((product, index) => (
                                            <ListItem
                                                key={product.code}
                                                button
                                                onClick={(event) => showProduct(product.code)}
                                            >
                                                <ListItemText primary={product.code}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>

                                <Grid item>
                                    <List
                                        dense
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Packages
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            searchResults.packages.length === 0 ? <NoneListItem/> : null
                                        }
                                        {searchResults.packages.slice(0,16).map((pkg, index) => (
                                            <ListItem
                                                key={pkg.number}
                                                button
                                                onClick={(event) => showPackage(pkg.number)}
                                            >
                                                <ListItemText primary={pkg.number}/>
                                            </ListItem>
                                        ))}

                                    </List>
                                </Grid>


                                <Grid item>
                                    <List
                                        dense
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Purchase orders
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            searchResults.pos.length === 0 ? <NoneListItem/> : null
                                        }
                                        {searchResults.pos.slice(0,16).map((po, index) => (
                                            <ListItem
                                                key={po.number}
                                                button
                                                onClick={(event) => showPo(po.number)}
                                            >
                                                <ListItemText primary={po.number}/>
                                            </ListItem>
                                        ))}

                                    </List>
                                </Grid>

                                <Grid item>
                                    <List
                                        dense
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Shipments
                                            </ListSubheader>
                                        }>

                                        {
                                            searchResults.shipments.length === 0 ? <NoneListItem/> : null
                                        }
                                        {searchResults.shipments.slice(0,16).map((shipment, index) => (
                                            <ListItem
                                                key={shipment.number}
                                                button
                                                onClick={(event) => showShipment(shipment)}
                                            >
                                                <ListItemText primary={shipment.number}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>

                            </Grid>
                        }
                        </CardContent>
                    </Card>
                </Popover>
            </div>
        </div>

    );

};

export default SkuSearch;