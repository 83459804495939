import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Server from "../../../Server";
import LoadingScreen from "../../../LoadingScreen";
import Utils from "../../../Utils";

const ModelImage = props => {

    const useStyles = makeStyles(theme => ({
        image:{
            width: 100
        },
        removeIcon: {
            position: "absolute",
        }
    }));
    const classes = useStyles();

    const [img1Src, setImg1Src] = useState('/images/no-image.svg');

    const productCodeNotSet = () => {
        return props.productCode === null || props.productCode === undefined || props.productCode === '';
    };

    const onNewImageFileSelected = (event) => {
        if(productCodeNotSet()) {
            alert('No product code set. Product code required to upload image.');
            resetFileSelector();
            return;
        }
        const file = event.target.files[0];
        const extension = Utils.parseFileExtension(file.name);
        if(extension !== 'jpg') {
            alert('Zalando expect a .jpg image file.');
            return;
        }
        new Server().uploadZalandoProductImage(props.productCode.trim(), props.imageIndex, file, extension, (response) => {
            if(response.status===200) {
               console.log('Uploaded '+props.productCode + ' ' + props.imageIndex);
           } else {
               alert('Error uploading image! \nError:\n' + JSON.stringify(response.data, null, 2));
           }
        });

        props.onImageSelected(file, props.imageIndex);
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setImg1Src(reader.result);
        }
    };
    const input1Ref = useRef();

    const imageIsSet = () => {
        return img1Src !== 'images/no-image.svg';
    };

    const removeImage = () => {
        if(productCodeNotSet()) {
            alert('No product code set. Product code required to upload image.');
            resetFileSelector();
            return;
        }

        new Server().deleteZalandoProductImage(props.productCode, props.imageIndex, (response) => {
            if(response.status===200) {
                console.log('Deleted '+props.productCode + ' ' + props.imageIndex);
            } else {
                alert('Error deleting image! \nError:\n' + JSON.stringify(response.data, null, 2));
            }
        });

        setImg1Src('images/no-image.svg');
        resetFileSelector();
        props.onImageSelected(null, props.imageIndex);
    };

    const resetFileSelector = () => {
        input1Ref.current.value = null;
    };

    return (
        <div>
            <input
                type="file"
                ref={input1Ref}
                style={{display: "none"}}
                onChange={onNewImageFileSelected}
            />
            {imageIsSet() ? <HighlightOffIcon  className={classes.removeIcon} onClick={removeImage}/> : null}
            <img src={img1Src} className={classes.image} onClick={()=>input1Ref.current.click()}/>
        </div>
    )


};

export default ModelImage;