import {Button, Grid, TextField, Card} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import ProductData from "./ProductData";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const ProductsFilters = props => {
    const useStyles = makeStyles(theme => ({
        rootCrd: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={2} alignItems="center">

                    <Grid item>
                        {/*brand*/}
                        <Autocomplete
                            options={props.brands}
                            getOptionLabel={brand => brand.name}
                            value={props.brandFilter}
                            onChange={(e, v, r) => props.setBrandFilter(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Brand" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*season*/}
                        <Autocomplete
                            options={ProductData.seasons}
                            getOptionLabel={season => season}
                            value={props.seasonFilter}
                            onChange={(e, v, r) => props.setSeasonFilter(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*category*/}
                        <Autocomplete
                            options={props.categories}
                            getOptionLabel={category => category.name}
                            value={props.categoryFilter}
                            onChange={(e, v, r) => props.setCategoryFilter(v)}
                            autoHighlight
                            size="small"
                            category={{width: 150}}
                            renderInput={params => <TextField {...params} label="Category" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={props.onApplyFilterClick}
                            color="primary"
                        >
                            Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={props.onClearFiltersClick}
                        >
                            Clear
                        </Button>
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default ProductsFilters;