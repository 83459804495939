import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from "@material-ui/core/TableHead/TableHead";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from "moment";
import Server from "../Server";
import Pricing from "../Pricing";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const Debits = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3),
        },
        poDetails: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const history = useHistory();

    const [suppliers, setSuppliers] = useState([]);
    const [notes, setNotes] = useState([]);

    useEffect(() => {document.title = 'Debit notes';}, []);

    useEffect( () => {
        new Server().fetchDebitSummary((response) => {
            setSuppliers(response.data);
            handleListItemClick(null, response.data[0]);
        });
    }, []);


    const createDebit = () => {
        history.push('/NewDebit')
    };

    const NewDebitButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={createDebit}
        >
            <AddCircleIcon className={classes.buttonIcon} />
            New debit
        </Button>
    );


    const viewDebitNote = (debitNoteNumber) => {
        history.push("/debitDetails/"+debitNoteNumber);
    };

    //for supplier selection
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, supplier) => {
        setSelectedIndex(supplier.supplier_id);
        new Server().fetchDebitNotesForSupplier(supplier.supplier_id, (response) => {
           setNotes(response.data);
        });
    };

    const getTotalAmount = (note) => {
      let total = 0;
      for(var i=0; i<note.items.length; i++) {
          total += parseFloat(note.items[i].amount);
      }
      return total;
    };

    return (
        <div className={classes.root}>

            {/*header start*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={2}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Debits

                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <NewDebitButton/>
                </Grid>
            </Grid>
            {/*header end*/}

            <Grid
                className={classes.poDetails}
                alignItems="flex-start"
                container
                spacing={3}
            >


                <Grid item xs={2}>
                    <Card>

                        <List component="nav" aria-label="secondary mailbox folder">

                            {suppliers.map((row) => {
                                return (
                                    <ListItem
                                        key={row.supplier_id}
                                        button
                                        selected={selectedIndex === row.supplier_id}
                                        onClick={(event) => handleListItemClick(event, row)}
                                    >
                                        <ListItemText primary={row.supplier_name+ '  ' + Pricing.formatPrice(row.open_debits_amount, row.currency)}
                                                      secondary={"Open: " + row.open_debit_count } />
                                    </ListItem>
                                )
                            })

                            }
                        </List>

                    </Card>
                </Grid>

                <Grid item xs={5}>
                    <Card >
                        <TableContainer >
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Debit number</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notes.map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}
                                                      onClick={() => viewDebitNote(row.number)}>
                                                <TableCell >
                                                    {row.number}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(row.date_created).format('YYYY-MM-DD')}
                                                </TableCell>
                                                <TableCell>
                                                    {getTotalAmount(row)}
                                                </TableCell>

                                                <TableCell>
                                                    {row.status}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Card>
                </Grid>


            </Grid>

        </div>
    )
};

export default Debits;