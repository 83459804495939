import React, {useEffect, useState} from 'react';
import Server from "../Server";
import LoadingScreen from "../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import Header from "./Header";
import StockPager from "../Stock/StockPager";
import PackagesTable from "./PackagesTable";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const Packages = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [packages, setPackages] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Consolidated packages';}, []);

    useEffect(() => {
        setLoading(true);
        new Server().fetchPackagesPage(searchText, null, ['DRAFT', 'RAISED', 'COMPLETE'], currentPageNumber, pageSize, (response) => {
            setLoading(false);

            setPackages(response.data);

            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
        });

    }, [searchText, currentPageNumber, pageSize]);

    const onPageSizeChange = (event) => {
        setPageSize(event.target.value);
    };

    const onNextPageClick = (event) => {
        setCurrentPageNumber(currentPageNumber + 1);
    };

    const onPreviousPageClick = (event) => {
        setCurrentPageNumber(currentPageNumber - 1);
    };

    const onSearchTextChanged = (newSearchTextValue) => {
        setSearchText(newSearchTextValue);
    };

    return (
        <div className={classes.root}>

            <Header/>

            {/*<Filters*/}
            {/*    onSearchTextChanged={onSearchTextChanged}*/}
            {/*/>*/}

            <PackagesTable
                className={classes.conversationPlaceholder}
                splits={packages}
            />

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default Packages;