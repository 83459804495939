import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Card, Grid, TextField} from "@material-ui/core";
import Server from "../../Server";
import {Autocomplete} from "@material-ui/lab";
import ProductData from "../../Products/ProductData";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LoadingScreen from "../../LoadingScreen";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1.5),
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Filters = props => {
    const classes = useStyles();

    const server = new Server();

    const [loading, setLoading] = useState(false);

    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState(null);

    const [genders, setGenders] = useState([]);
    const [gender, setGender] = useState(null);

    const [ages, setAges] = useState([]);
    const [age, setAge] = useState(null);

    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);

    const [factor, setFactor] = useState(1);
    const [quantity, setQuantity] = useState(0);

    const operators = ['>', '<', '>=', '<=', '='];
    const [operator, setOperator] = useState('>');

    const seasons = ProductData.seasons;
    const [season, setSeason] = useState('SS22');

    useEffect(() => {
        console.log('fetching brands');
        server.fetchProductAttributeValues('brands', (response) => {
            setBrands(response.data);
        });
        server.fetchProductAttributeValues('genders', (response) => {
            setGenders(response.data);
        });
        server.fetchProductAttributeValues('ages', (response) => {
            setAges(response.data);
        });
        server.fetchProductAttributeValues('productTypes', (response) => {
            setTypes(response.data);
        });
    }, []);

    const getResults = (isExcel) => {
        if(!season) {
            alert('Season needs to be specified.');
            return;
        }

        const data = {
            brand: brand?.name,
            season: season,
            type: type?.name,
            gender: gender?.name,
            age: age?.name,
            factor: factor,
            quantity: quantity,
            operator: operator,
        };
        setLoading(true);
        if(isExcel) {
            server.downloadExcelProductionReport(data, (response) => {
                setLoading(false);
                if (response.status !== 200) {
                    alert(response.data);
                }
            });
        } else {
            server.productionReport(data, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    props.setSizegroupProducts(response.data);
                } else {
                    alert(response.data);
                }
            });
        }
    };

    return (
        <div>
            <Card className={classes.root}>
                {/*product filters*/}
                <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                        <Autocomplete
                            options={brands}
                            getOptionLabel={brand => brand?.name}
                            value={brand}
                            onChange={(e, v, r) => setBrand(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Brand" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={seasons}
                            getOptionLabel={s => s}
                            value={season}
                            onChange={(e, v, r) => setSeason(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Season*" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={types}
                            getOptionLabel={t => t?.name}
                            value={type}
                            onChange={(e, v, r) => setType(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Type" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={genders}
                            getOptionLabel={t => t?.name}
                            value={gender}
                            onChange={(e, v, r) => setGender(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Gender" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={ages}
                            getOptionLabel={t => t?.name}
                            value={age}
                            onChange={(e, v, r) => setAge(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Age" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <TextField label="Factor"
                                   style={{width: 75}}
                                   variant="outlined"
                                   size="small"
                                   type="number"
                                   value={factor} onChange={(e) => setFactor(e.target.value)}
                        />
                    </Grid>

                    <Grid item>
                        <h3 style={{paddingLeft: 15}}>Quantity:</h3>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={operators}
                            value={operator}
                            onChange={(e, v, r) => setOperator(v)}
                            autoHighlight
                            size="small"
                            renderInput={params => <TextField {...params} label="" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <TextField label="Quantity" variant="outlined"
                                   size="small"
                                   type="number"
                                   style={{width: 75}}
                                   value={quantity} onChange={(e) => setQuantity(e.target.value)}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={() => getResults(false)}
                        >
                            Search
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={e => getResults(true)}>
                            <CloudDownloadIcon className={classes.buttonIcon}/>
                            excel
                        </Button>
                    </Grid>

                </Grid>
            </Card>
            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default Filters;