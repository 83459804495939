import React, {useState} from "react";
import {
    Card,
    CardHeader,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Pricing from "../../Pricing";
import EditShipmentInvoiceDialog from './EditShipmentInvoiceDialog'
import Server from "../../Server";
import {makeStyles} from "@material-ui/styles";
import Utils from "../../Utils";
import NavigationService from "../../NavigationService";
import AuthService from "../../AuthService";

const useStyles = makeStyles(theme => ({
    noInvoiceText: {
        padding: theme.spacing(1)
    },
    redText: {
        color: theme.palette.red,
    },
    greenText: {
        color: theme.palette.green,
    }
}));

const ShipmentInvoice = props => {

    const classes = useStyles();

    const shipment = props.shipment;
    const shipmentInvoice = shipment.invoice;

    const[openEditInvoiceDialog, setOpenEditInvoiceDialog] = useState(false);
    
    const openDialog = () => {
        setOpenEditInvoiceDialog(true);
    };

    const handleOnClose = () =>{
        setOpenEditInvoiceDialog(false);
    }

    const invoiceFileName = (trim) => {
        if (shipment.invoice === null || shipment.invoice.file === undefined || shipment.invoice.file === '') {
            return 'No file.';
        }
        let name = shipment.invoice.file.replace('/media/invoices/', '');
        name = name.replace('/media/', '');
        return trim ? Utils.trim(name, 30) : name;
    };

    const approveInvoice = () => {
        new Server().approvePOShipmentInvoice(shipment.number, (response) => {
           if(response.status === 200) {
               new NavigationService().reload();
           } else {
               alert('Error: \n' + JSON.stringify(response.data, null, 2));
           }
        });
    };

    const markInvoicePaid = () => {
        if(!shipment.invoice?.approved && !window.confirm("Invoice has not been approved. Mark as paid anyway?")) {
            return
        }
        new Server().markPOShipmentInvoiceAsPaid(shipment.number, (response) => {
            if(response.status === 200) {
                new NavigationService().reload();
            } else {
                alert('Error: \n' + JSON.stringify(response.data, null, 2));
            }
        });
    };

    const enableApproveButton = () => {
        if (AuthService.isSupplierLoggedIn()) {
            return false
        }
        return shipmentInvoice && shipmentInvoice.approved === false;
    };

    const enableMarkPaidButton = () => {
        if (AuthService.isSupplierLoggedIn()) {
            return false
        }
        return shipmentInvoice && shipmentInvoice.is_paid === false;
    };

    const InvoiceDetails = () => (
        <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>File:
                            <span href={void(0)} onClick={() => new Server().downloadPoShipmentInvoice(shipment.invoice.id, invoiceFileName(false))}>
                                <u>{invoiceFileName(true)}</u>
                            </span>
                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell>Number: {shipmentInvoice?.number}</TableCell>
                        <TableCell>Amount: {Pricing.formatPrice(shipmentInvoice?.amount, shipment.currency)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {
                                shipmentInvoice.approved ?
                                    <span className={classes.greenText}>APPROVED</span>
                                    :
                                    <span className={classes.redText}>PENDING APPROVAL</span>
                            }
                        </TableCell>

                        <TableCell>
                            {
                                shipmentInvoice.is_paid ?
                                    <span className={classes.greenText}>PAID</span>
                                    :
                                    <span className={classes.redText}>PENDING PAYMENT</span>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Button size="small" color="primary" disabled={!enableApproveButton()} onClick={approveInvoice}>
                                Approve
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Button size="small" color="primary" disabled={!enableMarkPaidButton()} onClick={markInvoicePaid}>
                                Mark as paid
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
    );

    return (
        <Card>
            <CardHeader title="Invoice"
                        action={
                            <IconButton size="small" onClick={(e) => {
                                openDialog()
                            }}>
                                <EditIcon fontSize="small"/>
                            </IconButton>
                        }/>
            {
                shipmentInvoice ?
                    <InvoiceDetails/> :
                    <Typography className={classes.noInvoiceText} align="center">No invoice</Typography>
            }
            <EditShipmentInvoiceDialog open={openEditInvoiceDialog} onClose={handleOnClose} shipment={shipment}
                                       setShipment={props.setShipment}/>
        </Card>
    )
};

export default ShipmentInvoice;