import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import React, {useState} from "react";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import PublishIcon from '@material-ui/icons/Publish';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Server from "../../Server";
import Utils from "../../Utils";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import AddProductDialog from "./AddProductDialog";
import NavigationService from "../../NavigationService";
import AddSizesDialog from "./AddSizesDialog";
import CsvUploadDialog from "./CsvUploadDialog";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const PoDetailsHeader = props => {

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const po = props.po;
    const setPo = props.setPo;

    const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
    const [openAddSizesDialog, setOpenAddSizesDialog] = useState(false);
    const [openCsvUploadDialog, setOpenCsvUploadDialog] = useState(false);

    const raisePo = (event) => {
        if (props.poProducts.length === 0) {
            alert('Cannot raise a PO with no products');
            return;
        }
        new Server().raisePurchaseOrder(po.number, (response) => {
            if (response.status === 200) {
                nav.reload();
            } else {
                alert('Could not raise ' + po.number + ' Error: \n' + JSON.stringify(response.data, null, 2));
                }
            })
    };

    const deletePo = (event) => {
        if (window.confirm('Delete forever?')) {
            new Server().deletePo(po.number, (response) => {
                if (response.status === 200) {
                    history.push("/PurchaseOrders");
                } else {
                    alert('Could not delete ' + po.number + ' Error: \n' + JSON.stringify(response.data, null, 2));
                }
            });
        }
    };

    const onEditPoNumber = () => {
        if (!Utils.isIn(po.status, ['DRAFT','NEW'])) {
            alert('Cannot edit PO number with status: ' + po.status);
            return;
        }
        const newPoNumber = prompt("Please enter the new PO number:", "PO-");
        if (!Utils.isValidPoNumber(newPoNumber)) {
            alert('Invalid format. Expected format is PO-DDD where D is a digit.');
            return;
        } else {
            new Server().editPoNumber(po.number, newPoNumber, (response) => {
                if (response.status === 200) {
                    reloadPage(newPoNumber);
                } else {
                    alert('Error occured: \n' + JSON.stringify(response.data));
                }
            });
        }
    };

    const reloadPage = (newPoNumber) => {
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('PO-');
        const newUrl = currentUrl.substr(0, index) + newPoNumber;
        window.location.href = newUrl;
    };

    const downloadPoCSV = () => {
        new Server().downloadPoCsv(po.number);
    };

    const RaiseButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={raisePo}
        >
            <PublishIcon className={classes.buttonIcon}/>
            Raise
        </Button>
    );

    const DeleteButton = () => (
        <Button
            variant="contained"
            onClick={deletePo}
        >
            <DeleteIcon className={classes.buttonIcon}/>
            Delete
        </Button>
    );

    const AddProductButton = () => (
        <Button
            variant="contained"
            onClick={() => setOpenAddProductDialog(true)}
        >
            <AddCircleIcon className={classes.buttonIcon}/>
            Add Product
        </Button>
    );

    const AddSizesButton = () => (
        <Button
            variant="contained"
            onClick={() => setOpenAddSizesDialog(true)}
        >
            <AddCircleIcon className={classes.buttonIcon}/>
            Add sizes
        </Button>
    );


    const DownloadPoCsvButton = () => {
        if(Utils.isPoWithVariants(po)) {
            return (
                <Button
                    onClick={e => downloadPoCSV()}
                    variant="contained">
                    <CloudDownloadIcon className={classes.buttonIcon}/>
                    totals</Button>
            );
        }
        return null;
    };

    const POCsvUploadButton = () => (
        <Button variant="contained" component="label" onClick={() => setOpenCsvUploadDialog(true)}>
            <CloudUploadIcon  className={classes.buttonIcon}/>
            csv
        </Button>
    );

    const poStatusIn = (validStatuses) => {
      return validStatuses.indexOf(po.status) !== -1;
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h2"
                    gutterBottom
                    variant="overline"
                >
                    Purchase order details
                </Typography>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    {po.number}
                    <IconButton size="small" aria-label="edit" onClick={onEditPoNumber}>
                        <EditIcon fontSize="small"/>
                    </IconButton>
                    {po.status}
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        {(poStatusIn(['DRAFT', 'RAISED'])) ? <POCsvUploadButton/> : null }
                    </Grid>
                    <Grid item>
                        {(poStatusIn(['DRAFT', 'RAISED'])) ? <AddProductButton/> : null }
                    </Grid>
                    <Grid item>
                        {(poStatusIn(['DRAFT', 'RAISED'])) ? <AddSizesButton/> : null }
                    </Grid>
                    <Grid item>
                        <DownloadPoCsvButton/>
                    </Grid>
                    <Grid item>
                        {(poStatusIn(['DRAFT', 'NEW', 'VOID'])) ?
                            <DeleteButton/> : null}
                    </Grid>
                    <Grid item>
                        {(poStatusIn(['DRAFT'])) ? <RaiseButton/> : null}
                    </Grid>
                </Grid>
                <AddProductDialog open={openAddProductDialog} onClose={() => setOpenAddProductDialog(false)} poProducts={props.poProducts} po={props.po} setPo={props.setPo}/>
                <AddSizesDialog open={openAddSizesDialog} onClose={() => setOpenAddSizesDialog(false)} po={props.po}/>
                <CsvUploadDialog open={openCsvUploadDialog} onClose={() => setOpenCsvUploadDialog(false)} po={props.po}/>
            </Grid>
        </Grid>
    );
};

export default PoDetailsHeader;