import React, {useEffect, useState} from 'react';
import {
    Dialog, DialogContent,
    DialogTitle, Grid, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import NavigationService from "../../../NavigationService";
import Utils from "../../../Utils";
import Pricing from "../../../Pricing";
import {makeStyles} from "@material-ui/styles";
import Server from "../../../Server";
import Pager from "./Pager";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles(theme => ({
    tableContainer: {},
    NumberCell: {cursor: 'pointer'}
}));

const StatusOrdersDialog = props => {

    const selectedStatus = props.selectedStatus;
    const selectedChannel = props.selectedChannel;
    const fromDate = props.fromDate;
    const toDate = props.toDate;
    const open = props.open;
    const onClose = props.onClose;

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const [orders, setOrders] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const pageSize = 10;

    const onNextPageClick = (event) => {
        fetchOrders(pageNumber + 1)
    };

    const onPreviousPageClick = (event) => {
        fetchOrders(pageNumber - 1)
    };

    const fetchOrders = (pageNum) => {
        new Server().fetchStatusOrders(pageSize, pageNum, selectedStatus, selectedChannel.code, fromDate, toDate, response => {
            setOrders(response.data);

            const headers = response.headers;
            setPageCount(parseInt(headers['page-count']));
            setPageNumber(parseInt(headers['page-number']));
        });
    };

    useEffect(()=>{
        if (open) {
            fetchOrders(1);
        }
    }, [open])

    const onOrderClick = (order, event) => {
        nav.goToChannelOrderDetails(order.id, true)
    };

    const formatTitle = () => {
        return selectedStatus + " orders from " + Utils.formatDate(fromDate, "DD/MM/YYYY") + " to " + Utils.formatDate(toDate, "DD/MM/YYYY");
    };

    const downloadCsv = () => {
        const filename = selectedStatus + "_orders_" + Utils.formatDate(fromDate, "DD_MM_YYYY") + "__" + Utils.formatDate(toDate, "DD_MM_YYYY") + ".csv";
        new Server().downloadStatusOrdersCsv(filename, selectedStatus, selectedChannel.code, fromDate, toDate, response => {
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle disableTypography>
                <Grid container direction={"row"} spacing={1}>
                    <Grid item>
                        <Typography variant={"h4"}>{formatTitle()}</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Download CSV">
                            <IconButton size={"small"} onClick={(e)=>{downloadCsv()}}>
                                <CloudDownloadIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>


            </DialogTitle>
            <DialogContent>
                <TableContainer className={classes.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Channel</TableCell>
                                <TableCell>Order number</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Total value</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                orders.map((order, index) => (
                                    <TableRow key={index} onClick={(e) => onOrderClick(order, e)}>
                                        <TableCell>{order.channel.code}</TableCell>
                                        <TableCell
                                            className={classes.NumberCell}>{order.channel_order_number}</TableCell>
                                        <TableCell>{Utils.formatDate(order.date, 'DD/MM/YYYY HH:mm')}</TableCell>
                                        <TableCell>{Pricing.formatPrice(order.total_value, order.currency)}</TableCell>
                                        <TableCell>{order.status}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>

                    </Table>

                    {/*pager*/}
                    <Pager pageNumber={pageNumber} pageCount={pageCount} onPreviousPageClick={onPreviousPageClick} onNextPageClick={onNextPageClick}/>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};

export default StatusOrdersDialog;