import React from 'react';
import {Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const Fabric = props => {
    return (
        <Grid container spacing={3}>
            <Grid item>
                <Autocomplete
                    size="small"
                    options={props.materials}
                    getOptionLabel={material => material.name}
                    value={props.material}
                    onChange={(e, v, r) => props.setMaterial(v)}
                    autoHighlight
                    style={{width: 300}}
                    renderInput={params => <TextField {...params} label=""
                                                      variant="outlined"/>}
                />
            </Grid>
            <Grid item>
                <TextField size="small" fullWidth label="Percentage" variant="outlined"
                           value={props.percentage}
                           onChange={(e) => props.setPercentage(e.target.value)}/>
            </Grid>
        </Grid>
    );
};

export default Fabric;