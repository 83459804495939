import moment from "moment";

class Utils {

    static is_development_environment() {
        var current_host = window.location.host;
        return (current_host.indexOf('localhost') > -1);
    }

    static is_production_environment() {
        var current_host = window.location.host;
        return (current_host.indexOf('portal.renfoldbrands.com') > -1) || (current_host.indexOf('167.99.194.92') > -1);
    }

    static is_staging_environment() {
        var current_host = window.location.host;
        return (current_host.indexOf('161.35.34.254') > -1);
    }

    static getTopBarColor() {
        if (Utils.is_production_environment()) {
            return '#000000';
        } else if (Utils.is_staging_environment()) {
            return '#B00020';
        } else {
            return '#3f51b5';
        }
    }

    static randomInt(limit) {
        return Math.floor(Math.random() * limit)
    };

    static getBpOrderUrl(bpOrderId) {
        return 'https://euw1.brightpearlapp.com/patt-op.php?scode=invoice&oID=' + bpOrderId;
    }

    static isIn(item, items) {
        for(let i=0; i<items.length; i++) {
            if(item === items[i]) return true;
        }
        return false;
    }

    static isPoWithVariants(po) {
        if(po.items.length === 0) return true;

        return po.items[0].variant !== undefined && po.items[0].variant !== null;
    }

    static trim(filename, expectedLength = 17) {
        if(filename.length >= expectedLength) {
            return filename.substring(0, expectedLength) + "..";
        }
        return filename;
    }

    static formatList(list, separator=' ') {
        let text = '';
        for(let i=0; i<list.length; i++) {
            text += list[i]
            if(i<list.length-1) {
                text += separator;
            }
        }
        return text;
    }

    static parseFileExtension(filename) {
        const parts = filename.split('.');
        if(parts.length < 2) {
            return '';
        } else {
            return parts[parts.length - 1];
        }
    };

    static sum(property, objList) {
        let sum = 0;
        objList.map((item) => { sum += item[property]; return 0; });
        return sum;
    };

    static sumObjectValues(obj) {
        let sum = 0;
        for (let key in obj) {
            sum += obj[key];
        }
        return sum;
    };

    static concatObjectsProperty(objects, property) {
        let text = '';
        for(let i=0; i<objects.length; i++) {
            text += objects[i][property] + ' ';
        }
        return text;
    }

    static formatPoNumbers(plusSeparatedNums) {
        if(plusSeparatedNums === undefined) {
            return '';
        }
        var nums = plusSeparatedNums.split('+');
        var poNumbersWithSpaces = '';
        for(var i=0;i<nums.length;i++) {
            poNumbersWithSpaces += nums[i] + ' '
        }
        return '(' + poNumbersWithSpaces.trim() + ')';
    }

    static formatDate = (date, format) => {
        if (date != null) {
            return moment(date).format(format);//'DD/MM/YYYY'
        } else {
            return 'None';
        }
    };

    static isValidInteger(value) {
        return !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value, 10));
    }

    static isValidPoNumber(poNumber) {
        let regex = /PO-\d+$/;
        return poNumber != null && poNumber !== "" && poNumber.match(regex)
    }

    static isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    static isValidRoNumber(number) {
        let regex = /[-_a-zA-Z0-9]+/;
        if(!number) return false;
        if(number.indexOf(' ') !== -1) return false;
        return number !== "" && number.match(regex);
    }

    static isValidDebitNumber(debitNumber) {
        let regex = /DEB-\d+$/;
        return debitNumber != null && debitNumber !== "" && debitNumber.match(regex)
    }

    static isValidDate(date) {
        // First check for the pattern
        if(!/^\d{4}\-\d{2}\-\d{2}$/.test(date)){
            return false;
        }
            
        // Parse the date parts to integers
        var parts = date.split("-");
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);
    
        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month === 0 || month > 12){
            return false;
        }
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
    
        // Adjust for leap years
        if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)){
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }

    static formatOptionalDate(date) {
        if(date) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return "NA";
        }
    }

    static formatUserAndDate(user, date) {
        const userName = user ? user.name : 'NA'
        const formattedDate = Utils.formatDate(date, 'DD/MM/YYYY HH:mm')
        return userName + ' on ' + formattedDate;
    }

    static validatePackageInfoInput(supplier, currency, season, mode, coo) {
        if(!supplier) {
            alert("Select a supplier");
            return false;
        }
        if(!currency) {
            alert("Set a currency");
            return false;
        }

        if(!season) {
            alert("Set a season");
            return false;
        }

        if(!mode) {
            alert("Set a mode");
            return false;
        }

        if(!coo) {
            alert("Set a country of origin");
            return false;
        }
        return true;
    }
}

export default Utils;