import React from 'react';
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import {Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
        overflow: 'scroll',
    },
    greenFill: {
        color: 'green',
    }

}));

const RatiosTable = props => {

    const classes = useStyles();

    const ratioRanges = props.ratioRanges;
    const setRatioRanges = props.setRatioRanges;
    const SIZEGROUPS = props.sizeGroups;

    // const SIZEGROUPS = [
    //     {
    //         'name': 'Standard', 'priority': 1,
    //         'sizes': ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '4XL', '5XL']
    //     },
    //     {
    //         'name': 'Numeric', 'priority': 2,
    //         'sizes': ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28']
    //     },
    //     {
    //         'name': 'Jeans', 'priority': 3,
    //         'sizes': ['28-30', '28-32', '28-34', '30-30', '30-32', '30-34', '32-30', '32-32', '32-34', '34-30', '34-32',
    //             '34-34', '36-30', '36-32', '36-34']
    //     },
    //     {'name': 'One size', 'priority': 4, 'sizes': ['O']},
    //     {
    //         'name': 'Kid', 'priority': 5,
    //         'sizes': ['0-3', '3-6', '6-9', '9-12', '6-12', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9-10', '11-12', '13-14']
    //     },
    //     {'name': 'Baby', 'priority': 6, 'sizes': ['0-3', '3-6', '6-12', '6-9', '9-12']},
    //
    //     {'name': 'Dual standard', 'priority': 8, 'sizes': ['S-M', 'M-L', 'L-XL', 'XL-XXL']},
    //     {'name': 'Dual numeric', 'priority': 9, 'sizes': ['6-8', '10-12', '14-16', '18-20', '22-24', '26-28']},
    //     {'name': 'None', 'priority': 10, 'sizes': ['None']},
    // ]

    const sizeWidth = 55;
    const columns = [
        { columnId: "department", width: 75 },
        { columnId: "category", width: 150 },
        { columnId: "customer", width: 125 },
        { columnId: "fit", width: 35 },
        { columnId: "size_range", width: 150 },
        { columnId: "size0_perc", width: sizeWidth },
        { columnId: "size1_perc", width: sizeWidth },
        { columnId: "size2_perc", width: sizeWidth },
        { columnId: "size3_perc", width: sizeWidth },
        { columnId: "size4_perc", width: sizeWidth },
        { columnId: "size5_perc", width: sizeWidth },
        { columnId: "size6_perc", width: sizeWidth },
        { columnId: "size7_perc", width: sizeWidth },
        { columnId: "size8_perc", width: sizeWidth },
        { columnId: "size9_perc", width: sizeWidth },
        { columnId: "size10_perc", width: sizeWidth },
        { columnId: "size11_perc", width: sizeWidth },
        { columnId: "size12_perc", width: sizeWidth },
        { columnId: "size13_perc", width: sizeWidth },
        { columnId: "size14_perc", width: sizeWidth },
        { columnId: "size15_perc", width: sizeWidth },
    ];



    let sgHeaders = [];
    SIZEGROUPS.forEach((sg) => {
        let cells = [
            { type: "header", text: "" },
            { type: "header", text: "" },
            { type: "header", text: "" },
            { type: "header", text: "" },
            { type: "header", text: sg.name },
        ];
        sg.sizes.forEach((size) => {
            cells.push({ type: "header", text: size.name },)
        })
        sgHeaders.push(
            {
                rowId: sg.name + 'Header',
                cells: cells
            }
        )
    });
    sgHeaders.reverse();

    const headerRow = {
        rowId: "header",
        cells: [
            {type: "header", text: "Department"},
            {type: "header", text: "Category"},
            {type: "header", text: "Customer"},
            {type: "header", text: "Fit"},
            {type: "header", text: "Size range"},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
            {type: "header", text: ""},
        ]
    };

    const getRows = (ranges) => {
        if (SIZEGROUPS.length === 0) {
            return [];
        }
        return [
            // sgHeaders[7],
            // sgHeaders[6],
            // sgHeaders[5],
            // sgHeaders[4],
            // sgHeaders[3],
            // sgHeaders[2],
            // sgHeaders[1],
            // sgHeaders[0],
            ...sgHeaders,
            headerRow,
            ...ranges.map((row, idx) => ({
                rowId: idx,
                cells: [
                    { type: "text", text: row.department },
                    { type: "text", text: row.category },
                    { type: "text", text: row.customer },
                    { type: "text", text: row.fit },
                    { type: "text", text: row.size_range },
                    { type: "text", text: row.size0_perc },
                    { type: "text", text: row.size1_perc },
                    { type: "text", text: row.size2_perc },
                    { type: "text", text: row.size3_perc },
                    { type: "text", text: row.size4_perc },
                    { type: "text", text: row.size5_perc },
                    { type: "text", text: row.size6_perc },
                    { type: "text", text: row.size7_perc },
                    { type: "text", text: row.size8_perc },
                    { type: "text", text: row.size9_perc },
                    { type: "text", text: row.size10_perc },
                    { type: "text", text: row.size11_perc },
                    { type: "text", text: row.size12_perc },
                    { type: "text", text: row.size13_perc },
                    { type: "text", text: row.size14_perc },
                    { type: "text", text: row.size15_perc },
                ]
            }))
        ];
    }

    const rows = getRows(ratioRanges);

    const applyChangesToRatioRanges = (
        changes,
        prev
    ) => {
        changes.forEach((change) => {
            const personIndex = change.rowId;
            const fieldName = change.columnId;
            prev[personIndex][fieldName] = change.newCell.text;
        });
        return [...prev];
    };

    const handleChanges = (changes) => {
        setRatioRanges((prev) => applyChangesToRatioRanges(changes, prev));
    };


    return (
        <Card className={classes.root}>
            <div className={classes.tableContainer}>
                {
                    SIZEGROUPS.length > 0 ?
                        <ReactGrid
                            rows={rows} columns={columns}
                            onCellsChanged={handleChanges}
                            stickyTopRows={SIZEGROUPS.length + 1}
                            stickyLeftColumns={5}
                        /> : null
                }

            </div>

        </Card>
    );
};

export default RatiosTable;
