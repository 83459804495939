import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Utils from "../Utils";
import Server from "../Server";

const StockTable = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },
    }));
    const classes = useStyles();
    const items = props.items;

    const edit = (property, item) => {
        var value = prompt("Enter new "+property+" value:", "0");
        if (!Utils.isValidInteger(value)) {
            alert('Invalid number.');
            return;
        } else {
            const data = {
                property: property,
                value: value
            }
            new Server().setStockItemProperty(item.id, data, (response) => {
                if(response.status === 200) {
                    props.onItemPropertyChanged();
                } else {
                    alert('Error: \n'+JSON.stringify(response.data));
                }
            })
        }
    }

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>

                    <TableHead >
                        <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>available</TableCell>
                            <TableCell>committed</TableCell>
                            <TableCell>on-hand</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.variant.sku}</TableCell>
                                    <TableCell>{item.variant.ean}</TableCell>
                                    <TableCell onClick={() => edit('available', item)}>{item.available}</TableCell>
                                    <TableCell onClick={() => edit('committed', item)}>{item.committed}</TableCell>
                                    <TableCell>{(item.available+item.committed)}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )

};

export default StockTable;