import React, {useState, useEffect} from "react";
import {RIENumber} from "riek";

const EditableInteger = props => {

    const [value, setValue] = useState(props.value);
    const validateName = (obj) => {
        return obj !== '';
    };

    const onValueChange = (obj) => {
        setValue(obj.value);
        props.onChange(obj.value);
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <RIENumber
            value={value}
            classEditing={props.classEditing}
            change={onValueChange}
            propName='value'
            validate={validateName}
            isDisabled={props.disabled}
        />

    );
};
export default EditableInteger;