import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";
import Utils from "../Utils";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},
    errorIcon: {
        fill: theme.palette.red,
    },
    successIcon: {
        color: theme.palette.green,
    }
}));

const PackagesTable = props => {

    const classes = useStyles();

    const splits = props.splits;

    const history = useHistory();
    const nav = new NavigationService(history);

    const AckIcon = (props) => {
        const pkg = props.pkg;
        if (pkg.acknowledged) {
            return (<CheckCircleIcon className={classes.successIcon}/>)
        } else {
            return (<ErrorIcon className={classes.errorIcon} />)
        }
    };

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>number</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell>supplier</TableCell>
                            <TableCell>acknowledged</TableCell>
                            <TableCell>season</TableCell>
                            <TableCell>created</TableCell>
                            <TableCell>updated</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            splits.map((pkg, index) => (
                                <TableRow key={pkg.id} onClick={() => nav.goToConsolidatedPackage(pkg.number)}>
                                    <TableCell>{pkg.number}</TableCell>
                                    <TableCell>{pkg.status}</TableCell>
                                    <TableCell>{pkg.supplier.name}</TableCell>
                                    <TableCell><AckIcon pkg={pkg}/></TableCell>
                                    <TableCell>{pkg.season}</TableCell>
                                    <TableCell>{Utils.formatUserAndDate(pkg.created_by, pkg.created_on)}</TableCell>
                                    <TableCell>{Utils.formatUserAndDate(pkg.updated_by, pkg.updated_on)}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )

};

export default PackagesTable;