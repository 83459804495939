import TableContainer from "@material-ui/core/TableContainer";
import moment from "moment";
import {Card, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import React from "react";
import Pricing from "../../Pricing";

const InventoryTabContent = props => {

    const productInventory = props.productInventory;

    const mult = (var1, var2) => {
        if (var1 === null || var1 === undefined || var1 === '' || isNaN(var1)) {
            return 0;
        }
        if (var2 === null || var2 === undefined || var2 === '' || isNaN(var2)) {
            return 0;
        }
        return (var1 * var2).toFixed(2);
    };

    const productCost = productInventory.product.cost;
    const productPrice = productInventory.product.rrp;

    const costs = {
        available: mult(productInventory.available, productCost),
        committed: mult(productInventory.committed, productCost),
        on_hand: mult(productInventory.on_hand, productCost),
        incoming: mult(productInventory.incoming, productCost),
    };

    const prices = {
        available: mult(productInventory.available, productPrice),
        committed: mult(productInventory.committed, productPrice),
        on_hand: mult(productInventory.on_hand, productPrice),
        incoming: mult(productInventory.incoming, productPrice),
    };

    const formatPrice = (amount) => {
        return Pricing.formatPrice(amount, 'GBP');
    };

    const formatNextDeliveryDate = (date) => {
        if (date != null) {
            return moment(date).format('DD/MM/YYYY');
        } else {
            return 'None';
        }
    };

    return (
        <Card hidden={props.hidden}>
            {/*<CardContent>*/}
            <TableContainer>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Size</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>Available</TableCell>
                            <TableCell>Committed</TableCell>
                            <TableCell>On hand</TableCell>
                            <TableCell>Incoming</TableCell>
                            <TableCell>Zalando</TableCell>
                            <TableCell>Next delivery</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productInventory.variant_inventories.map((variant_inv, index) => (

                                <TableRow key={index}>
                                    <TableCell>
                                        {variant_inv.variant.size.name}
                                    </TableCell>
                                    <TableCell>
                                        {variant_inv.variant.ean}
                                    </TableCell>
                                    <TableCell>
                                        {variant_inv.available}
                                    </TableCell>
                                    <TableCell>
                                        {variant_inv.committed}
                                    </TableCell>
                                    <TableCell>
                                        {variant_inv.on_hand}
                                    </TableCell>
                                    <TableCell>
                                        {variant_inv.incoming}
                                    </TableCell>
                                    <TableCell>
                                        -
                                    </TableCell>
                                    <TableCell>
                                        {formatNextDeliveryDate(variant_inv.next_delivery)}
                                    </TableCell>
                                </TableRow>

                            )
                        )}

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Totals</TableCell>
                            <TableCell>{productInventory.available}</TableCell>
                            <TableCell>{productInventory.committed}</TableCell>
                            <TableCell>{productInventory.on_hand}</TableCell>
                            <TableCell>{productInventory.incoming}</TableCell>
                            <TableCell> - </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>{formatPrice(costs.available)}</TableCell>
                            <TableCell>{formatPrice(costs.committed)}</TableCell>
                            <TableCell>{formatPrice(costs.on_hand)}</TableCell>
                            <TableCell>{formatPrice(costs.incoming)}</TableCell>
                            <TableCell> - </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>{formatPrice(prices.available)}</TableCell>
                            <TableCell>{formatPrice(prices.committed)}</TableCell>
                            <TableCell>{formatPrice(prices.on_hand)}</TableCell>
                            <TableCell>{formatPrice(prices.incoming)}</TableCell>
                            <TableCell> - </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            {/*</CardContent>*/}
        </Card>
    );
};

export default InventoryTabContent;