import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Header from "./Header";
import Filters from "./Filters";
import LoadingScreen from "../LoadingScreen";
import TransitProductsTable from "./TransitProductsTable";
import Server from "../Server";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const TransitProducts = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [packageNum, setPackageNum] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const defaultFromExitDate = null;
    const defaultToExitDate = null;
    const [fromExitDate, setFromExitDate] = useState(defaultFromExitDate);
    const [toExitDate, setToExitDate] = useState(defaultToExitDate);

    const defaultFromEtaDate = moment().subtract(1, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
    const defaultToEtaDate = moment().add(7, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});
    const [fromEtaDate, setFromEtaDate] = useState(defaultFromEtaDate);
    const [toEtaDate, setToEtaDate] = useState(defaultToEtaDate);

    const [products, setProducts] = useState([]);

    useEffect(() => {document.title = 'Transit products';}, []);

    useEffect(() => {
        new Server().fetchCustomers((response) =>{
            setCustomers(response.data);
        });

        new Server().fetchSuppliers((response) => {
            setSuppliers(response.data);
        });

        fetchProducts('', null, null, defaultFromExitDate, defaultToExitDate, defaultFromEtaDate, defaultToEtaDate);
    }, []);


    const onApplyFilters = () => {
        fetchProducts(packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate);
    };

    const onClearFilters = () => {
        setPackageNum('');
        setSelectedSupplier(null);
        setSelectedCustomer(null);
        setFromExitDate(defaultFromExitDate);
        setToExitDate(defaultToExitDate);
        setFromEtaDate(defaultFromEtaDate);
        setToEtaDate(defaultToEtaDate);
        fetchProducts('', null, null, defaultFromExitDate, defaultToExitDate, defaultFromEtaDate, defaultToEtaDate);
    };

    const fetchProducts = (packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate) => {
        setLoading(true);
        new Server().fetchTransitProducts(packageNum, selectedSupplier, selectedCustomer, fromExitDate, toExitDate, fromEtaDate, toEtaDate, (response) => {
            setLoading(false);
            if (response.status !== 200) {
                alert(JSON.stringify(response.data));
                return;
            }
            setProducts(response.data);
        });
    };

    return (
        <div className={classes.root}>

            <Header
                packageNum={packageNum}
                selectedSupplier={selectedSupplier}
                selectedCustomer={selectedCustomer}
                fromExitDate={fromExitDate}
                toExitDate={toExitDate}
                fromEtaDate={fromEtaDate}
                toEtaDate={toEtaDate}
            />

            <Filters
                packageNum={packageNum} setPackageNum={setPackageNum}
                suppliers={suppliers} selectedSupplier={selectedSupplier} setSelectedSupplier={setSelectedSupplier}
                customers={customers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer}
                fromExitDate={fromExitDate} setFromExitDate={setFromExitDate}
                toExitDate={toExitDate} setToExitDate={setToExitDate}
                fromEtaDate={fromEtaDate} setFromEtaDate={setFromEtaDate}
                toEtaDate={toEtaDate} setToEtaDate={setToEtaDate}
                onApplyFilters={onApplyFilters} onClearFilters={onClearFilters}
            />

            <TransitProductsTable
                className={classes.conversationPlaceholder}
                products={products}
            />

            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default TransitProducts;