import React, {Fragment, useEffect, useState} from 'react'
import {Card} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Server from "../../Server";
import NavigationService from "../../NavigationService";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";


const LocationsTabContent = props => {

    const formatNextDeliveryDate = (date) => {
        if (date != null) {
            return moment(date).format('DD/MM/YYYY');
        } else {
            return 'None';
        }
    };

    const useStyles = makeStyles(theme => ({
        wNumberCell : { cursor: 'pointer'}
    }));
    
    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const product = props.product;

    const [warehouses, setWarehouses] = useState([]);
    const [totals, setTotals] = useState({});

    useEffect( () => {
        if (product.code !== 'ABC-123') {
            new Server().fetchProductWarehouses(product.code, (response) => {
                if(response.status !== 200){
                    alert('Error occured:' + JSON.stringify(response.data, null, 2)); 
                    return;
                }
                setWarehouses(response?.data);
                setTotals(calculateTotals(response?.data));
            });
        }
        
    }, [product]);

    const calculateTotals = (warehouses) => {
        let newTotals = { 
            available: 0,
            on_hand: 0,
            incoming: 0,
        };
        warehouses.forEach((warehouse) =>{
            newTotals['available']+= sum('available', warehouse.product_variants);
            newTotals['on_hand']+= sum('on_hand', warehouse.product_variants);
            newTotals['incoming']+= sum('incoming', warehouse.product_variants);
        });
        return newTotals;
    }

    const sum = (property, inventoryData) => {
        let sum = 0;
        inventoryData.forEach((item) => { 
            sum += item[property];
        })
        return sum;
    };

    const Row = props => {
        const warehouse = props.warehouse;
        const [open, setOpen] = React.useState(false);
        return (
            <Fragment>
                    <TableRow>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </TableCell>
                        <TableCell onClick={(e)=> nav.goToStock()} className={classes.wNumberCell}>
                            {warehouse.warehouse_name}
                        </TableCell>
                        <TableCell>
                            {sum('available', warehouse.product_variants)}
                        </TableCell>
                        <TableCell>
                            {sum('on_hand', warehouse.product_variants)}
                        </TableCell>
                        <TableCell>
                            {sum('incoming', warehouse.product_variants)}
                        </TableCell>
                    </TableRow>

                    {/* expanded row content */}
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Available</TableCell>
                                                <TableCell>On hand</TableCell>
                                                <TableCell>Incoming</TableCell>
                                                <TableCell>Next delivery</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {warehouse.product_variants.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{item.size}</TableCell>
                                                            <TableCell>{item.available}</TableCell>
                                                            <TableCell>{item.on_hand}</TableCell>
                                                            <TableCell>{item.incoming}</TableCell>
                                                            <TableCell>{formatNextDeliveryDate(item.next_delivery)}</TableCell>
                                                        </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>

            </Fragment>
        );
    };

    return (
        <Card hidden={props.hidden}>
            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Available</TableCell>
                            <TableCell>On hand</TableCell>
                            <TableCell>Incoming</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {warehouses.length === 0?
                                <TableRow>
                                    <TableCell colSpan={5}>No warehouses to show</TableCell>
                                </TableRow>: null
                        }
                        {warehouses.map((warehouse, index) => (

                                <Row warehouse={warehouse} key={index} index={index}/>
                                )
                        )} 
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Totals</TableCell>
                            <TableCell>{totals.available}</TableCell>
                            <TableCell>{totals.on_hand}</TableCell>
                            <TableCell>{totals.incoming}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default LocationsTabContent;