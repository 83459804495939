import React, {useEffect} from 'react';
import ZalandoSalesReport from "../ZalandoSalesReport";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const Orders = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
    }));

    const classes = useStyles();

    useEffect(() => {document.title = 'ZPP sales';}, []);

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Orders
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>


            {/*content start*/}
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >

                <Grid item>
                    <ZalandoSalesReport/>
                </Grid>

            </Grid>
            {/*content end*/}

        </div>
    );
}

export default Orders;