import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import StockHeader from "./StockHeader";
import StockTable from "./StockTable";
import StockPager from "./StockPager";
import StockTabs from "./StockTabs";
import Server from "../Server";

const Stock = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            height: '100%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            padding: theme.spacing(3),
        },

        conversationPlaceholder: {
            flexGrow: 1
        },
    }));
    const classes = useStyles();

    const [warehouseCodes, setWarehouseCodes] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Stock';}, []);

    useEffect(() => {
        const server = new Server();

        server.fetchWarehouseCodes((response) => {
            setWarehouseCodes(response.data);
            fetchItemsForWarehouse(response.data[0], currentPageNumber, pageSize);
        });

    }, []);

    const fetchItemsForWarehouse = (warehouseCode, pageNumber, pageSize) => {
        new Server().fetchWarehouseItems(warehouseCode, pageNumber, pageSize, (response) => {
            setItems(response.data);
            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
        });
    };

    const onTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        fetchItemsForWarehouse(warehouseCodes[newTabIndex], 1, pageSize);
    };

    const onPageSizeChange = (event) => {
        fetchItemsForWarehouse(warehouseCodes[tabIndex], 1, event.target.value)
    };

    const onNextPageClick = (event) => {
        fetchItemsForWarehouse(warehouseCodes[tabIndex], currentPageNumber + 1, pageSize)
    };

    const onPreviousPageClick = (event) => {
        fetchItemsForWarehouse(warehouseCodes[tabIndex], currentPageNumber - 1, pageSize)
    };

    const onStockItemAdded = () => {
        fetchItemsForWarehouse(warehouseCodes[tabIndex], currentPageNumber, pageSize);
    };

    const onItemPropertyChanged = () => {
        fetchItemsForWarehouse(warehouseCodes[tabIndex], currentPageNumber, pageSize);
    };


    return (
        <div className={classes.root}>

            <StockHeader onStockItemAdded={onStockItemAdded}
                         warehouseCodes={warehouseCodes} tabIndex={tabIndex}
            />
            <StockTabs tabNames={warehouseCodes} tabIndex={tabIndex} onTabChange={onTabChange}/>
            <StockTable onItemPropertyChanged={onItemPropertyChanged} items={items} className={classes.conversationPlaceholder}/>
            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
        </div>
    );
}

export default Stock;