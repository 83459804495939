import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Utils from "../Utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    paper: {
        width: '100%',
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    cell: {
        paddingTop: 6,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 6,
    }

}));

const DDTable = props => {

    const docs = props.designDocs;

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>

            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell size="small">Product</TableCell>
                            <TableCell size="small">File</TableCell>
                            <TableCell size="small">Type</TableCell>
                            <TableCell size="small">Added</TableCell>
                            <TableCell size="small">Last modified</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            docs.map((doc, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={doc.id}
                                >
                                    <TableCell size="small">{doc.product.code}</TableCell>
                                    <TableCell size="small">
                                        <Link href={doc.url} rel="noopener noreferrer" target="_blank">
                                            <span>{doc.filename}</span>
                                        </Link>
                                    </TableCell>
                                    <TableCell size="small">{doc.doc_type}</TableCell>

                                    <TableCell size="small">{Utils.formatUserAndDate(doc.created_by, doc.created_date)}</TableCell>
                                    <TableCell size="small">{Utils.formatUserAndDate(doc.last_modified_by, doc.last_modified)}</TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Paper>
    )

};

export default DDTable;