import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import Utils from "../../Utils";
import EditVariantButton from "./EditVariantButton";
import ChannelStatusIndicator from "./ChannelStatusIndicator";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'}
}));

const VariantsTable = props => {

    const classes = useStyles();

    const variants = props.variants;
    const refreshData = props.refreshData;

    const history = useHistory();
    const nav = new NavigationService(history);

    const formatQtyAndDate = (qty, date) => {
        const formattedDate = Utils.formatDate(date, 'DD/MM/YYYY HH:mm')
        return qty + ' on ' + formattedDate;
    };

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>Sku</TableCell>
                            <TableCell>Ean</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>UK tax</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>ZPP qty</TableCell>
                            <TableCell>JLP</TableCell>
                            <TableCell>NXT</TableCell>
                            <TableCell>TVG</TableCell>
                            <TableCell>BP id</TableCell>
                            <TableCell>JLP id</TableCell>
                            <TableCell>TVG id</TableCell>
                            <TableCell>FAR id</TableCell>
                            <TableCell>ZPP id</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            variants.map((variant, index) => (
                                <TableRow key={variant.id}>
                                    <TableCell>{variant.id}</TableCell>
                                    <TableCell>{variant.sku}</TableCell>
                                    <TableCell>{variant.ean}</TableCell>
                                    <TableCell>{variant.product_code}</TableCell>
                                    <TableCell>{variant.uk_tax_code}</TableCell>
                                    <TableCell>{formatQtyAndDate(variant.syn_available_units, variant.syn_stock_last_updated)}</TableCell>
                                    <TableCell>{formatQtyAndDate(variant.zpp_available_units, variant.zpp_stock_last_updated)}</TableCell>
                                    <TableCell><ChannelStatusIndicator on={variant.active_on_jlp}/></TableCell>
                                    <TableCell><ChannelStatusIndicator on={variant.active_on_nxt}/></TableCell>
                                    <TableCell><ChannelStatusIndicator on={variant.active_on_tvg}/></TableCell>
                                    <TableCell>{variant.brightpearl_id}</TableCell>
                                    <TableCell>{variant.jl_id}</TableCell>
                                    <TableCell>{variant.tvg_id}</TableCell>
                                    <TableCell>{variant.far_id}</TableCell>
                                    <TableCell>{variant.zpp_id}</TableCell>
                                    <TableCell>
                                        <EditVariantButton variantId={variant.id} onConfirm={refreshData}/>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )

};

export default VariantsTable;