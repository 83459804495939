import React, {useEffect, useState} from 'react';
import Server from "../../Server";
import {TableCell, TableRow} from "@material-ui/core";

const DocumentFileUpload = props => {
    const id = props.id;
    const files = props.files;
    const productCode = props.productCode;
    const type = props.type;

    const [status, setStatus] = useState("uploading..");

    useEffect(() => {

        new Server().updateDesignFiles(productCode, files, type, (response) => {
                if (response.status === 200) {
                    setStatus("done");
                } else {
                    setStatus("error");
                    alert(response.data);
                }
            });

    }, []);

    return (
        <TableRow key={id}>
            <TableCell size="small">
                {
                    files?.map(
                        (file, index) => (
                            <div key={file.name}>
                                <span>{file.name}</span>
                                <br/>
                            </div>
                        )
                    )
                }
            </TableCell>
            <TableCell size="small">{type}</TableCell>
            <TableCell size="small">{status}</TableCell>
        </TableRow>
    );
};

export default DocumentFileUpload;