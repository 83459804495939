import React, {useEffect, useState} from 'react';
import { Chart } from "react-google-charts";
import Utils from "../../../Utils";

const GoogleStackedChart = props => {

    const rawData = props.rawData;
    const selectedChannel = props.selectedChannel;
    const onBarClick = props.onBarClick;

    // const default_data = [
    //     ["Month", "JLP", { role: "style" }, "NXT", { role: "style" }],
    //     ["Jan", 8.94, "#eb4034", 10, "#348feb"],
    //     ["Feb", 10.49, "#eb4034", 20, "#348feb"],
    // ];

    const [data, setData] = useState([]);

    useEffect(() => {
        if(rawData && rawData.length > 1) {

            // change string numbers into ints
            const formattedData = convertStringNumbersToInt(rawData);
            //add styling
            const styledData = addStyles(formattedData);
            setData(styledData);

        }
    }, [rawData]);

    const convertStringNumbersToInt = (data) => {
        for(let i=1; i<data.length; i++) {
            for(let j=1; j<data[i].length; j++) {
                if(Utils.isValidInteger(data[i][j])) {
                    data[i][j] = parseInt(data[i][j]);
                }
            }
        }
        return data;
    };

    const addStyles = (data) => {
        //FIXME
        return data;
    };

    const chartEvents = [
        {
            eventName: "select",
            callback({ chartWrapper }) {
                console.log("Selected ", chartWrapper.getChart().getSelection());
                // console.log(data);
                const selection = chartWrapper.getChart().getSelection();
                const row = selection[0].row
                const column = selection[0].column

                const value = data[row + 1][column]
                const day = data[row + 1][0]
                const channelCode = data[0][column]

                console.log(`Val: ${value} day: ${day} channel: ${channelCode}`);
                onBarClick(day, channelCode);

            }
        }
    ];

    const options = {
        isStacked: selectedChannel.code === "ALL",
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            options={options}
            data={data}
            chartEvents={chartEvents}
        />
    );
};

export default GoogleStackedChart;
