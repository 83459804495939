import React from 'react';
import {CardHeader, Divider, Grid, TextField, Card, CardContent, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Fabric from "./Fabric";

const Materials = props => {
    return (
        <Card>
            <CardHeader title={"Materials"}/>
            <Divider/>
            <CardContent>
                <Grid container direction="row" spacing={3}>
                    <Grid item>
                        {/*material composition 1 START*/}
                        <Grid
                            container
                            spacing={2}
                            direction={"column"}
                        >
                            <Grid item>
                                Composition 1
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.materialProperties}
                                    getOptionLabel={m => m.name + ' - ' + m.description}
                                    value={props.materialProperty}
                                    onChange={(e, v, r) => props.setMaterialProperty(v)}
                                    autoHighlight
                                    fullWidth
                                    renderInput={params => <TextField {...params} label="Type"
                                                                      variant="outlined"/>}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 1*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material1} setMaterial={props.setMaterial1}
                                    percentage={props.materialPc1} setPercentage={props.setMaterialPc1}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 2*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material2} setMaterial={props.setMaterial2}
                                    percentage={props.materialPc2} setPercentage={props.setMaterialPc2}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 3*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material3} setMaterial={props.setMaterial3}
                                    percentage={props.materialPc3} setPercentage={props.setMaterialPc3}
                                />
                            </Grid>
                        </Grid>
                        {/*material composition 1 END*/}
                    </Grid>
                    <Grid item>
                        {/*material composition 2 START*/}
                        <Grid
                            container
                            spacing={2}
                            direction={"column"}
                        >
                            <Grid item>
                                Composition 2 (optional)
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.materialProperties}
                                    getOptionLabel={m => m.name + ' - ' + m.description}
                                    value={props.materialProperty2}
                                    onChange={(e, v, r) => props.setMaterialProperty2(v)}
                                    autoHighlight
                                    fullWidth
                                    renderInput={params => <TextField {...params} label="Type"
                                                                      variant="outlined"/>}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 4*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material4} setMaterial={props.setMaterial4}
                                    percentage={props.materialPc4} setPercentage={props.setMaterialPc4}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 5*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material5} setMaterial={props.setMaterial5}
                                    percentage={props.materialPc5} setPercentage={props.setMaterialPc5}
                                />
                            </Grid>
                            <Grid item>
                                {/*fabric 6*/}
                                <Fabric
                                    materials={props.materials}
                                    material={props.material6} setMaterial={props.setMaterial6}
                                    percentage={props.materialPc6} setPercentage={props.setMaterialPc6}
                                />
                            </Grid>
                            <Grid item>
                                <Typography component="h2"
                                            gutterBottom
                                            variant="overline"
                                >* Composition 2 will be ignored if the type is not set.</Typography>
                            </Grid>
                        </Grid>
                        {/*material composition 2 END*/}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        );
}

export default Materials;