import React, {useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from "@material-ui/core";
import Server from "../../Server";
import Utils from "../../Utils";
import LoadingScreen from "../../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import DialogActions from "@material-ui/core/DialogActions";

const UploadDialog = props => {

    const history = useHistory();
    const nav = new NavigationService(history);

    const number = props.number;

    const uploadRef = useRef();

    const [loading, setLoading] = useState(false);

    const UploadButton = () => (
        <Button component="label" color="primary">
            upload
            <input
                type="file"
                ref={uploadRef}
                style={{ display: "none" }}
                hidden
                onChange={(e) => {handleFileSelection(e.target.files)}}
            />
        </Button>
    );

    const handleFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            uploadRef.current.value = null;
            return;
        }
        setLoading(true);
        new Server().uploadPOs(number, files[0], (response) => {
            setLoading(false);
            if(response.status === 200) {
                nav.reload();
            } else if(response.status === 400) {
                alert(Utils.formatList(response.data, '\n'));
            } else {
                alert(response.data);
            }
        });
    };

    const DownloadTemplateButton = (props) => {
        const downloadTemplate = () => {
            new Server().downloadPackageTemplate();
        };
        return (
            <Button onClick={downloadTemplate} color="secondary">
                template
            </Button>
        );
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Update package data</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            - Initial PO loading<br/>
                            Leave PO and ORDER fields blank<br/>
                            New products will be created with given sizes<br/>
                            New sizes will be added to existing products<br/>
                            <br/>

                            - Add a new PO to an existing package<br/>
                            Leave PO and ORDER fields blank<br/>
                            The PRODUCT and FIT must not already exist in this package<br/>
                            <br/>

                            - Add a new order to an existing PO<br/>
                            Set PO number<br/>
                            Leave ORDER field blank<br/>
                            <br/>

                            - Update PO exit date and price<br/>
                            PO and ORDER must be filled<br/>
                            Set new exit date and price<br/>
                            <br/>

                            - Update ORDER quantities<br/>
                            PO and ORDER must be filled<br/>
                            Set new size breakdown<br/>
                            <br/>

                            - Update ORDER customer<br/>
                            PO and ORDER must be filled<br/>
                            Set new customer<br/>
                            Customer must match a customer registered on portal.<br/>
                            <br/>

                            <b>Excel column headers should match the template</b><br/>
                            <br/>

                            SIZE RANGE column is optional<br/>
                            <br/>

                            Existing products must have a category and department registered on portal.<br/>
                            <br/>

                        </Typography>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="default">
                    Cancel
                </Button>
                <DownloadTemplateButton/>
                <UploadButton/>
            </DialogActions>
            <LoadingScreen loading={loading}/>
        </Dialog>
    )
};

export default UploadDialog;