import React, {useEffect, useState} from "react";
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import Server from "../../Server";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import AuthService from "../../AuthService";

const ShipmentPos = props => {

    const history = useHistory();
    const nav = new NavigationService(history);

    const [poRefs, setPoRefs] = useState([]);

    useEffect(()=>{
        new Server().fetchShipmentPos(props.shNumber, response => {
            setPoRefs(response.data);
        })
    }, [props.shNumber])

    const goToPoRef = (ref) => {
        if (AuthService.isAdminLoggedIn()) {
            if(ref.package_number) {
                nav.goToConsolidatedPackage(ref.package_number);
            } else { //packageless po
                nav.goToPoDetails(ref.po_number, true);
            }
        } else {//supplier
            if(ref.package_number) {
                nav.gotoSupplierConsolidatedPackage(ref.package_number);
            } else {//packageless po
                nav.goToSupplierPoDetails(ref.po_number, true);
            }
        }
    };

    const POsTable = () => (
        <Table size="small">
            <TableBody>
                {
                    poRefs.map((poRef) => (
                        <TableRow key={poRef.po_number}>
                            <TableCell onClick={() => goToPoRef(poRef)}>{poRef.po_number}</TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    );

    const NoPos = () => (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell>No POs linked. Add packing lists to see linked POs.</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    return (
        <Card>
            <CardHeader title="Linked POs"/>
            {poRefs?.length ? <POsTable/> : <NoPos/>}
        </Card>
    )
};

export default ShipmentPos;