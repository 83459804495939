import React, {useState} from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NewStockDialog from "./NewStockDialog";
import NewWarehouseDialog from "./NewWarehouseDialog";
import Server from "../Server";
import Utils from "../Utils";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const StockHeader = props => {
    const useStyles = makeStyles(theme => ({
        root: {
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const [openNewStockDialog, setOpenNewStockDialog] = useState(false);
    const [openNewWarehouseDialog, setOpenNewWarehouseDialog] = useState(false);

    const warehouseCode = props.warehouseCodes[props.tabIndex]

    const onConfirmNewStockDialog = () => {
        setOpenNewStockDialog(false);
        props.onStockItemAdded();
    };

    const onCloseNewStockDialog = () => {
        setOpenNewStockDialog(false);
    };

    const onCloseNewWarehouseDialog = () => {
        setOpenNewWarehouseDialog(false);
    }

    const downloadStockCSV = () => {
        new Server().downloadStockCsv(warehouseCode);
    };

    return (<Grid
        className={classes.root}
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
    >
        <Grid item>
            <Typography
                component="h1"
                variant="h3"
            >
                Stock
            </Typography>
        </Grid>
        <Grid item>
            <Grid container spacing={1}>

                <Grid item>
                    <Button
                        onClick={e => downloadStockCSV()}
                        variant="contained">
                        <CloudDownloadIcon className={classes.buttonIcon}/>
                        Stock CSV</Button>
                </Grid>
                <Grid item>
                    <Button
                        color="default"
                        variant="contained"
                        onClick={()=> setOpenNewStockDialog(true)}
                    >
                        <AddCircleIcon className={classes.buttonIcon}/>
                        Add stock item
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={()=> setOpenNewWarehouseDialog(true)}
                    >
                        <AddCircleIcon className={classes.buttonIcon}/>
                        New Warehouse
                    </Button>
                </Grid>
            </Grid>
        </Grid>


        <NewWarehouseDialog open={openNewWarehouseDialog} onClose={onCloseNewWarehouseDialog}/>
        <NewStockDialog open={openNewStockDialog} onConfirm={onConfirmNewStockDialog} onClose={onCloseNewStockDialog}/>
    </Grid>);
};

export default StockHeader;

