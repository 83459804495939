import React, {useState, useEffect} from "react";
import {Button, TextField, Grid, Typography, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import PackingListTable from "./PackingListTable";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import DialogActions from "@material-ui/core/DialogActions";
import OrderedUnits from "./OrderedUnits";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    deleteButton: {
        color: theme.palette.red,
    }
}));

const EditPackingListDialog = (props) => {

    const classes = useStyles();
    const shipment = props.shipment;
    const plNumber = props.plNumber;
    const isEditablePL = shipment.status === 'DRAFT';
    const defaultSizes = ['S', 'M', 'L'];

    const [packingList, setPackingList] = useState(null)
    const [goodsDescription, setGoodsDescription] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [cartonRanges, setCartonRanges] = useState([]);
    const [sizes, setSizes] = useState(defaultSizes);

    const [poNumber, setPoNumber] = useState('');

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);

    useEffect( () => {
        if(props.open) {
            setupExistingPackingList();
        }
    }, [props.open]);

    const setupExistingPackingList = () => {
        new Server().getPackingList(plNumber, (response) => {
            if(response.status !== 200){
                alert('An error occurred: \n' + response.status);
                return;
            }
            let pl = response.data;
            setPackingList(response.data);
            setPoNumber(parsePoNumber(pl));
            setProduct(pl.product);
            setGoodsDescription(pl.goods_description);
            setCustomerName(pl.customer?.name);
            setCartonRanges(pl.carton_ranges);
            setSizes(pl?.sizes);
        })
    };

    const parsePoNumber = (pl) => {
        console.log(pl);
        if (pl.po)
            return pl.po.number
        else
            return pl.product_po.number
    }

    const onProductChange = (selectedProduct) => {
        if (selectedProduct !== null) {

            if (!isEmptyPackingList() || goodsDescription !== '') {
                let confirmation = window.confirm("All your changes will be lost! Proceed?");
                if (confirmation === true) {
                    setGoodsDescription('');
                }
            }
            setProduct(selectedProduct);
        }
    };

    const updatePackingList = () => {
        const cartonRanges = buildCartonRanges();

        new Server().updatePackingList(packingList.number, cartonRanges, response =>{
            if (response.status !== 200) {
                alert('Error updating packing list! \nError:\n' + JSON.stringify(response.data, null, 2));
            } else {
                window.location.reload(); 
            }
        });
    };

    const isEmptyPackingList = () => {
        for(let i=0; i<cartonRanges.length; i++){
            if(!isEmptyCartonRange(cartonRanges[i])){
                return false;
            }
        }
        return true;
    };

    const isEmptyCartonRange = (cartonRange) => {
        for(let i=0; i<cartonRange.size_quantities.length; i++) {
            let quantity = cartonRange.size_quantities[i].quantity;
            if(quantity != 0) {
                return false;
            }
        }
        return true;
    };

    const buildSizeQuantities = (cartonRange) => {
        let {index, from_carton, to_carton, ...newCartonRange} = cartonRange;
        let quantities = [];
        newCartonRange.size_quantities.forEach( sizeQuant =>{
            quantities.push({size:sizeQuant.size.name, quantity:sizeQuant.quantity})
        })
        return quantities;
    };

    const buildCartonRanges = () => {
        return cartonRanges.map((cartonRange) => {
            return {
                from_carton: cartonRange.from_carton,
                to_carton: cartonRange.to_carton,
                quantities: buildSizeQuantities(cartonRange)
            }
        });
    };

    const deletePackingList = () => {
        let confirmAlert = window.confirm("Are you sure you want to delete " + packingList.number + "?");
        if (confirmAlert) {
            new Server().deletePackingList(packingList.number, response => {
                window.location.reload();
            });
        }
    };

    const save = (e) => {
        updatePackingList()
    };

    const getDialogTitle = () => {
        return packingList?.number;
    };

    const onCancel = () => {
        props.onClose()
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}  disableBackdropClick={true}
                disableEscapeKeyDown={true}
        >
            <DialogTitle disableTypography><Typography variant="h4">{getDialogTitle()}</Typography></DialogTitle>
            <DialogContent>
                <div className={classes.root}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>

                        {/*po / product*/}
                        <Grid item>
                            <Grid container direction={"row"} spacing={1} justifyContent={"flex-start"}>

                                {/*PO*/}
                                <Grid item>
                                    <TextField
                                        label="PO number"
                                        value={poNumber}
                                        onChange={(e) => setPoNumber(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        style={{width: 250}}
                                        disabled
                                    />

                                </Grid>

                                {/* Product Dropdown */}
                                <Grid item>
                                    <Autocomplete
                                        size="small"
                                        options={products}
                                        getOptionLabel={products => products !== null ? products.code : ""}
                                        value={product}
                                        onChange={(e, v, r) => onProductChange(v)}
                                        autoHighlight
                                        style={{width: 250}}
                                        renderInput={params => <TextField {...params} label="Product" variant="outlined"/>}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*customer row*/}
                        <Grid item>
                            <Grid container direction={"row"} spacing={1} justifyContent={"flex-start"}>
                                <Grid item>
                                    <TextField
                                        label="Customer"
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                        variant={"outlined"}
                                        size="small"
                                        style={{width: 250}}
                                        disabled/>
                                </Grid>

                                <Grid item>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*goods description*/}
                        <Grid item>
                            <TextField
                                size="small"
                                label="Goods Description" variant="outlined"
                                value={goodsDescription}
                                style={{width: 508}}
                                onChange={(e) => setGoodsDescription(e.target.value)}
                                disabled/>
                        </Grid>

                        <Grid item>
                            <OrderedUnits plNumber={plNumber} />
                        </Grid>


                        <Grid item>
                            <PackingListTable size={sizes}
                                              cartonRanges={cartonRanges}
                                              setCartonRanges={setCartonRanges}
                                              isEditablePL={isEditablePL}/>
                        </Grid>

                    </Grid>
                </div>
            </DialogContent>

            <DialogActions>

                <Button className={classes.deleteButton} color="default" onClick={(e) => deletePackingList()}>
                    Delete
                </Button>
                <Button onClick={onCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={save} color="primary">
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default EditPackingListDialog;