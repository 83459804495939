import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";
import {makeStyles} from "@material-ui/styles";
import {Typography, Card, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Utils from "../Utils";


const ProductsTable = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },
        productImage: {
            float: 'left',
            width:  50,
            height: 50,
            objectFit: 'cover',
            borderRadius: '25%',
        },

    }));
    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const selected = props.selected;
    const setSelected = props.setSelected;
    const products = props.products;

    const onChangeSelection = (event, product, index) => {
        const selectedIndex = selected.indexOf(product.id);
        let newSelected = JSON.parse(JSON.stringify(selected));
        if (selectedIndex === -1) {//if not selected, add
            newSelected.push(product.id);
        } else {//if already selected, remove
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const toggleAllSelected = (event) => {
        if (event.target.checked) {
            setSelected(products.map((n) => n.id));
            return;
        }
        setSelected([]);
    };

    const isSelected = (product) => {
        return selected.indexOf(product.id) !== -1;
    };

    const getMainImageUrl = (product) => {
        if(product.images === undefined || product.images === null || product.images.length === 0) {
            return '/images/no-image.svg'
        } else {
            return product.images[0].url;
        }
    };

    const onProductRowClicked = (product, event) => {
        nav.goToProductDetails(product.code, event);
    };

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    {/*size="small"*/}
                    <TableHead >
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    size={"small"}
                                    indeterminate={selected.length > 0 && selected.length < products.length}
                                    checked={products.length > 0 && selected.length === products.length}
                                    inputProps={{'aria-label': 'select all desserts'}}
                                    onChange={(e) => toggleAllSelected(e)}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Unallocated</TableCell>
                            <TableCell>Allocated</TableCell>
                            <TableCell>On hand</TableCell>
                            <TableCell>Incoming</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            products.map((product, index) => (
                                <TableRow key={index} onClick={(e) => onProductRowClicked(product, e.metaKey)}>
                                    <TableCell>
                                        <Checkbox
                                            size={"small"}
                                            checked={isSelected(product)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => onChangeSelection(e, product, index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <img className={classes.productImage} src={getMainImageUrl(product)} alt="product"/>
                                    </TableCell>
                                    <TableCell>{product.code}</TableCell>
                                    <TableCell>{product.unallocated}</TableCell>
                                    <TableCell>{product.allocated}</TableCell>
                                    <TableCell>{product.on_hand}</TableCell>
                                    <TableCell>{product.incoming}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Card>
    );
};

export default ProductsTable;