import React, {useEffect, useState} from 'react';
import Server from "../../../Server";
import {Card, CardContent, CardHeader, Divider, Grid} from "@material-ui/core";
import GPercentagePie from "./GPercentagePie";
import StatusOrdersDialog from "./StatusOrdersDialog";

const OrderStatusStats = props => {

    const selectedChannel = props.selectedChannel;
    const fromDate = props.fromDate;
    const toDate = props.toDate;

    const [metrics, setMetrics] = useState({
        'NEW': 0,
        'PROCESSING': 0,
        'DISPATCHED': 0,
        'CANCELLED': 0,
        'DELIVERED': 0,
        'DELIVERY_ERROR': 0,
        'ERROR': 0,
        'TOTAL': 0,
    });

    const [selectedStatus, setSelectedStatus] = useState('NEW');
    const [openStatusOrdersDialog, setOpenStatusOrdersDialog] = useState(false);

    useEffect(() => {
        new Server().fetchChannelOrderStatusMetrics(selectedChannel.code, fromDate, toDate, (response) => {
            setMetrics(response.data);
        });
    }, [props.fromDate, props.toDate, props.selectedChannel]);

    const onPieClick = status => {
        setSelectedStatus(status);
        setOpenStatusOrdersDialog(true);
    };

    return (
        <Card>
            <CardHeader title="Orders by status"/>
            <Divider/>
            <CardContent>
                <Grid container direction={"row"}>
                    <Grid item>
                        <GPercentagePie status="NEW" onClick={onPieClick} quantity={metrics['NEW']} total={metrics['TOTAL']} quantityDescription={"new"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="PROCESSING" onClick={onPieClick} quantity={metrics['PROCESSING']} total={metrics['TOTAL']} quantityDescription={"processing"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="DISPATCHED" onClick={onPieClick} quantity={metrics['DISPATCHED']} total={metrics['TOTAL']} quantityDescription={"dispatched"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="CANCELLED" onClick={onPieClick} quantity={metrics['CANCELLED']} total={metrics['TOTAL']} quantityDescription={"cancelled"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="DELIVERED" onClick={onPieClick} quantity={metrics['DELIVERED']} total={metrics['TOTAL']} quantityDescription={"delivered"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="DELIVERY_ERROR" onClick={onPieClick} quantity={metrics['DELIVERY_ERROR']} total={metrics['TOTAL']} quantityDescription={"delivery error"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="ERROR" onClick={onPieClick} quantity={metrics['ERROR']} total={metrics['TOTAL']} quantityDescription={"error"}/>
                    </Grid>
                    <Grid item>
                        <GPercentagePie status="TOTAL" onClick={onPieClick} quantity={metrics['TOTAL']} total={metrics['TOTAL']} quantityDescription={"total"}/>
                    </Grid>
                </Grid>
            </CardContent>
            <StatusOrdersDialog
                selectedStatus={selectedStatus}
                selectedChannel={selectedChannel}
                fromDate={fromDate}
                toDate={toDate}
                open={openStatusOrdersDialog}
                onClose={() => setOpenStatusOrdersDialog(false)}
            />
        </Card>
    );
};

export default OrderStatusStats;