import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import {Grid, IconButton, Typography} from "@material-ui/core";
import ProductModelInfo from "./ProductModelInfo";
import ProductEans from "./ProductEans";
import ProductValidator from "../ProductValidator";
import NavigationService from "../../NavigationService";
import Server from "../../Server";
import EditIcon from "@material-ui/icons/Edit";

const EditProduct = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        },

    }));

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const {match} = props;
    let {productCode} = match.params;

    useEffect(() => {document.title = 'Edit '+productCode;}, []);

    const onEditProductCode = () => {
        const newProductCode = prompt("Please enter the new product code:");
        const validator = new ProductValidator(null);
        if(!validator.isValidProductCode(newProductCode)){
            return;
        }

        new Server().editProductCode(productCode, newProductCode, (response) => {
            if (response.status === 200) {
                alert('New product code successfully set.');
                nav.goToProductDetails(newProductCode);
            } else {
                alert('Error occured: \n'+JSON.stringify(response.data));
            }
        });
    };

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Product details
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {productCode}
                        <IconButton size="small" aria-label="edit" onClick={onEditProductCode}>
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    </Typography>
                </Grid>

            </Grid>

            <div className={classes.poTable}>

                <Grid
                    container
                    spacing={3}
                    direction="column"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <ProductModelInfo productCode={productCode}/>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <ProductMaterials product={product} setProduct={setProduct} fabrics={fabrics}/>*/}
                    {/*</Grid>*/}

                    <Grid item>
                        <ProductEans productCode={productCode}/>
                    </Grid>

                </Grid>
            </div>
        </div>
    );
};

export default EditProduct;