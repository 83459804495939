import React from 'react';
import {Grid} from "@material-ui/core";
import PLPreview from "../../../PackingLists/PLPreview";
import ExcelPLsStats from "./ExcelPLsStats";

const ExcelPLsPreview = props => {

    const testPreview = {
        total_cartons: 20,
        total_units: 50,
        total_value: '350',
        currency: 'USD',
        packing_lists: [
            {
                sheet_name: 'sheet 1',
                preview_id: 0,
                po_number: 'PO-123',
                product_code: 'CPNY-22-ABCD',
                goods_description: 'WOMEN OVERSIZE JOGGER',
                total_cartons: 5,
                total_units: 10,
                total_value: '150',
                currency: 'USD',
                sizes: ['S','M','L'],
                totals: {S: 1, M: 1, L: 1},
                carton_ranges: [{from_carton: 1, to_carton: 1, total_units: 12, total_cartons: 5, preview_id: 0, size_map: {S: 1, M: 1, L: 1} }]
            },
            {
                sheet_name: 'sheet 2',
                preview_id: 1,
                po_number: 'PO-789',
                product_code: 'CPNY-22-EFGH',
                goods_description: 'RANDOM NICE CLOTHES',
                total_cartons: 5,
                total_units: 10,
                total_value: '100',
                currency: 'USD',
                sizes: ['S','M','L'],
                totals: {S: 1, M: 1, L: 1},
                carton_ranges: [{from_carton: 1, to_carton: 1, total_units: 12, total_cartons: 5, preview_id: 0, size_map: {S: 1, M: 1, L: 1} }]
            }
        ],
    };

        const preview = props.plPreview
     // const preview = testPreview;

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <ExcelPLsStats preview={preview}/>
            </Grid>

            <Grid item>
                <Grid container direction="column" spacing={2}>
                    {
                        preview.packing_lists.map((pl) => (
                                <Grid item key={pl.preview_id}>
                                    <PLPreview pl={pl}/>
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );

};

export default ExcelPLsPreview;