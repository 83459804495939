import React, {useState} from "react";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CardMedia from '@material-ui/core/CardMedia';
import {makeStyles} from "@material-ui/styles";
import Pricing from "../../Pricing";
import EditImagesDialog from "./EditImagesDialog";

const ProductInfo = props => {

    const useStyles = makeStyles(theme => ({
        media: {
             // height: 500,
        },
        previewImagesDiv: {
            position: "absolute",
        },
        imagePreview: {
            width: '25px'
        },
        mainImageContainer: {
          position: "relative",
        },
        topRightDiv: {
            position: "absolute",
            top: "0px",
            right: "0px",
        },
        bottomRightDiv: {
          position: "absolute",
          bottom: "0px",
          right: "0px",
        },
        priceCostContainer: {
            marginRight: "10px"
        }
    }));
    const classes = useStyles();

    const product = props.product;

    const [displayedImageIdx, setDisplayedImageIdx] = useState(0);
    const [openImagesDialog, setOpenImagesDialog] = useState(false);

    const onCloseImagesDialog = () => {
        setOpenImagesDialog(false);
    };

    const onConfirmImagesDialog = () => {
        setOpenImagesDialog(false);
        props.reloadProduct();
    };

    const getMainImageUrl = () => {
      if(product.images === undefined || product.images === null || product.images.length === 0) {
          return '/images/no-image.svg'
      } else {
          return product.images[displayedImageIdx].url;
      }
    };

    const edit = () => {
       setOpenImagesDialog(true);
    };

    return (
        <Card>
            <div className={classes.mainImageContainer}>
                <div className={classes.previewImagesDiv}>
                    {
                        product.images.map((image, index) => (
                                <img key={index} src={image.url} className={classes.imagePreview} alt="product"
                                     onClick={() => setDisplayedImageIdx(index)}/>
                            )
                        )
                    }
                </div>
                <div className={classes.topRightDiv}>
                    <IconButton onClick={edit}>
                        <EditIcon/>
                    </IconButton>
                </div>
                <div className={classes.bottomRightDiv}>
                    <Grid container direction="column" className={classes.priceCostContainer}>
                        <Grid item>
                            <Typography variant="h3">{Pricing.formatPrice(product.rrp, 'GBP')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">{Pricing.formatPrice(product.cost, 'GBP')}</Typography>
                        </Grid>
                    </Grid>


                </div>
                <CardMedia
                    className={classes.media}
                    component="img"
                    image={getMainImageUrl()}
                    title="Product image"
                />
            </div>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {product?.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {product.brand?.name} - {product.season}
                </Typography>
            </CardContent>
            <EditImagesDialog product={product} open={openImagesDialog} onClose={onCloseImagesDialog} onConfirm={onConfirmImagesDialog}/>
        </Card>
    );

};

export default ProductInfo;