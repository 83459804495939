import {Card, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const ModelTabContent = props => {

    const product = props.product;

    const getSupplierName = () => {
        if(product.supplier)
            return product.supplier.name
        else
            return 'None'
    };

    return (
        <Card hidden={props.hidden}>
            <CardContent>

                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Table>
                            <TableBody>

                                <TableRow>
                                    <TableCell>Brand</TableCell>
                                    <TableCell>{product.brand?.name}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Season</TableCell>
                                    <TableCell>{product.season}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Department</TableCell>
                                    <TableCell>{product.department?.code}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Fit</TableCell>
                                    <TableCell>{product.fit?.code}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>{product.category?.name}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Collection</TableCell>
                                    <TableCell>{product.collection}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Color</TableCell>
                                    <TableCell>{product.color}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Size group</TableCell>
                                    <TableCell>{product.size_group.name}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>HS code</TableCell>
                                    <TableCell>{product.hscode}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item>
                        <Table>
                            <TableBody>

                                <TableRow>
                                    <TableCell>Tax code</TableCell>
                                    <TableCell>{product.tax_code}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Weight (g)</TableCell>
                                    <TableCell>{product.weight}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>{product.cost}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>RRP</TableCell>
                                    <TableCell>{product.rrp}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>COO</TableCell>
                                    <TableCell>{product.coo}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>{getSupplierName()}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Created on</TableCell>
                                    <TableCell>{moment(product.created_date).format('DD/MM/YYYY')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );
};

export default ModelTabContent;