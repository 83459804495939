import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import Server from "../../Server";
import Utils from "../../Utils";
import Pricing from "../../Pricing";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";

const useStyles = makeStyles(theme => ({
    tableContainer: {},
    NumberCell: {cursor: 'pointer'}
}));

const OrderSearchDialog = props => {

    const open = props.open;
    const onClose = props.onClose;

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const [orders, setOrders] = useState([]);
    const [searchText, setSearchText] = useState('');

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            confirm();
        }
    };

    const confirm = () => {
        if (!isValidSearchQuery()) {
            return;
        }
        new Server().searchChannelOrders(searchText, 20, (response) => {
            if (response.status !== 200) {
                alert('Error updating tracking \n' + JSON.stringify(response.data));
                return;
            }
            setOrders(response.data);
        });
    };

    const isValidSearchQuery = () => {
        if (!searchText) {
            alert("No search query specified. Input BP id, order number or internal id. (Min. 4 characters/digits)");
            return false;
        }
        if (searchText.length < 4) {
            alert("Invalid search query. Minimum 4 characters.")
            return false;
        }
        return true;
    };

    const onOrderClick = (order, event) => {
        nav.goToChannelOrderDetails(order.id, true)
    };

    return (
        <Dialog open={open} onClose={props.onClose} fullWidth={true} maxWidth={'md'}>
            <DialogTitle disableTypography>
                <Typography variant={"h4"}>{'Channel orders search'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>

                    <Grid item>

                        {/*search field + button*/}
                        <Grid container direction={"row"} alignItems="center">
                            <Grid item>
                                <TextField
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    variant={"outlined"}
                                    size="small"
                                    autoFocus
                                    style={{width: 300}}
                                    onKeyDown={onKeyDown}
                                    placeholder={"BP ID, order number or internal ID"}
                                />
                            </Grid>
                            <Grid item>
                                <Button onClick={confirm}>
                                    search
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item>
                        {
                            orders.length ?
                                <TableContainer className={classes.tableContainer}>
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Channel</TableCell>
                                                <TableCell>Order number</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Total value</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                orders.map((order, index) => (
                                                    <TableRow key={index} onClick={(e) => onOrderClick(order, e)}>
                                                        <TableCell>{order.channel.code}</TableCell>
                                                        <TableCell
                                                            className={classes.NumberCell}>{order.channel_order_number}</TableCell>
                                                        <TableCell>{Utils.formatDate(order.date, 'DD/MM/YYYY HH:mm')}</TableCell>
                                                        <TableCell>{Pricing.formatPrice(order.total_value, order.currency)}</TableCell>
                                                        <TableCell>{order.status}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                                : null
                        }
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    );
};

export default OrderSearchDialog;