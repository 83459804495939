import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import Server from "../../Server";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import {makeStyles} from "@material-ui/styles";

const ProductDetailsHeader = props => {
    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const product = props.product;
    const deleteProduct = () => {
        new Server().deleteProduct(product.code, response => {
            if(response.status === 200) {
                nav.goToProducts();
            } else {
                alert('Error: \n' + response.data);
            }
        });
    }

    const EditButton = () => (
        <Button
            variant="contained"
            onClick={() => nav.goToEditProduct(product.code)}
        >
            <EditIcon className={classes.buttonIcon}/>
            Edit
        </Button>
    );

    const DeleteButton = () => (
        <Button
            variant="contained"
            onClick={deleteProduct}
        >
            <DeleteIcon className={classes.buttonIcon}/>
            Delete
        </Button>
    );

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h2"
                    gutterBottom
                    variant="overline"
                >
                    Product details
                </Typography>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    {product.code}
                </Typography>
            </Grid>


            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <EditButton/>
                    </Grid>
                    <Grid item>
                        <DeleteButton/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProductDetailsHeader;