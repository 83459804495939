import {Button, Grid, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, {useState} from "react";
import POShipmentStats from "./POShipmentStats";
import EntitySearchDialog from "../EntitySearchDialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/styles";
import NewPOShipmentDialog from "./NewPOShipmentDialog";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const POShipmentsHeader = props => {
    const classes = useStyles();
    const searchType = "POShipment";

    const [openNewShipmentDialog, setOpenNewShipmentDialog] = useState(false);

    const onNewPOShipmentClick = () => {
        setOpenNewShipmentDialog(true);
    };

    const onConfirmNewShipmentDialog = (code) => {
    };

    const onCloseNewShipmentDialog = () => {
        setOpenNewShipmentDialog(false);
    };

    return (
        <Grid container direction="column" spacing={1}>
            {/*title - stats - new shipment button*/}
            <Grid item>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid item>
                                <Typography
                                    component="h1"
                                    variant="h3"
                                >
                                    POShipments
                                </Typography>
                            </Grid>
                            <Grid item>
                                <EntitySearchDialog type={searchType}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onNewPOShipmentClick}
                        >
                            <AddCircleIcon className={classes.buttonIcon}/>
                            New
                        </Button>
                    </Grid>
                    <NewPOShipmentDialog open={openNewShipmentDialog} onConfirm={onConfirmNewShipmentDialog}
                                         onClose={onCloseNewShipmentDialog}/>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container direction="column"  alignItems="flex-start" justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <POShipmentStats/>
                    </Grid>

                    <Grid item>
                        <Paper>
                            <Tabs
                                value={props.tabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={props.onTabChange}
                                variant="fullWidth"
                            >
                                <Tab label="DRAFT"/>
                                <Tab label="TRANSIT"/>
                                <Tab label="RECEIVED"/>
                            </Tabs>
                        </Paper>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    );
}

export default POShipmentsHeader;