import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import Pricing from "../../Pricing";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},
    productImage: {
        float: 'left',
        width:  50,
        height: 75,
        objectFit: 'cover',
        borderRadius: '5%',
    },
}));


const ProductsTable = props => {

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>Delivery</TableCell>
                            <TableCell>COO</TableCell>
                            <TableCell>7 day sales</TableCell>
                            <TableCell>30 day sales</TableCell>
                            <TableCell>Stock cover</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            props.products.map((product, index) => (
                                <TableRow key={product.product_code} >
                                    <TableCell><img className={classes.productImage} src={product.image_url} alt={product.product_code}/></TableCell>
                                    <TableCell>
                                        {product.product_code}<br/>
                                        {product.name}<br/>
                                        Ssn:{product.season} Col.:{product.collection}<br/>
                                        Dpt:{product.department} Fit:{product.fit}<br/>
                                        {product.category}
                                    </TableCell>
                                    <TableCell className={classes.NumberCell}>
                                        {Pricing.formatPrice(product.rrp_gbp, 'GBP')}<br/>
                                        {Pricing.formatPrice(product.rrp_eur, 'EUR')}
                                    </TableCell>
                                    <TableCell>{product.next_delivery}</TableCell>
                                    <TableCell>{product.coo}</TableCell>
                                    <TableCell>{product.seven_day_sold}</TableCell>
                                    <TableCell>{product.thirty_day_sold}</TableCell>
                                    <TableCell>{product.stock_cover}</TableCell>
                                    <TableCell>{product.total_stock}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )

};

export default ProductsTable;