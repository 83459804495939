import React, {useRef, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import LoadingScreen from "../LoadingScreen";
import Utils from "../Utils";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    redText: {
        color: theme.palette.red,
        fontWeight: 'bold',
    },
}));

const MigrateSizegroupsDialog = props => {
    const {open, onConfirm, onClose} = props;

    const classes = useStyles();
    const csvUploadInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            csvUploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.csv')) {
            alert('A csv file ending in .csv is required.')
            return;
        }

        setLoading(true);
        setErrors([]);
        new Server().uploadSizegroupMigrationCsv(file, (response) => {
            setLoading(false);
            if (response.status === 200) {
                alert('Product sizes updated successfully.');
                onClose();
            } else {
                csvUploadInputRef.current.value = null;
                setErrors(response.data);
            }
        });
    };

    return (

        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">Migrate product size groups</Typography></DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            Update product size groups and assign new sizes in bulk.<br/>
                            - Expected csv headers: PRODUCT CODE, SIZE, NEW SIZE, NEW SIZEGROUP<br/>
                            - All product codes must already exist in portals.<br/>
                            - Current product sizes must be registered on portal.<br/>
                            - All sizes on portal for a given product must be included in the csv and assigned a new size and group.<br/>
                            - New size must be part of the new size group.<br/>
                            - A product can only have 1 size group. The same product code cannot be assigned 2 different size groups.<br/>
                        </Typography>
                    </Grid>

                    <Grid item hidden={errors.length === 0}>
                        <Typography className={classes.redText}>Correct the following errors and re-upload</Typography>
                        {
                            errors.map((error) => (
                                <Typography key={error}>{error}</Typography>
                            ))
                        }
                    </Grid>


                    <Grid item>
                        <Grid container direction="row">

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload csv
                                    <input
                                        type="file"
                                        ref={csvUploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>

    );
};

export default MigrateSizegroupsDialog;