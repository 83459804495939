import React, {useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Server from "../../Server";
import Utils from "../../Utils";
import LoadingScreen from "../../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const UploadTvgCodesDialog = props => {

    const {open, onConfirm, onClose} = props;

    const classes = useStyles();

    const inputRef = useRef();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const UploadButton = () => (
        <Button component="label" color="primary">
            <CloudUploadIcon  className={classes.buttonIcon} color="primary"/>
            upload
            <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                hidden
                onChange={(e) => {handleFileSelection(e.target.files)}}
            />
        </Button>
    );

    const handleFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            inputRef.current.value = null;
            return;
        }
        setLoading(true);
        new Server().uploadTvgProductCodesCsv(files[0], (response) => {
            setLoading(false);
            if(response.status === 200) {
                enqueueSnackbar("TVG product codes updated successfully", {variant: 'success'});
                onConfirm();
            } else if(response.status === 400) {
                alert(Utils.formatList(response.data, '\n'));
            } else {
                enqueueSnackbar("Error (see console)", {variant: 'error'});
                console.log(response.data);
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Update TVG product codes</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            - Required CSV can be generated from the Very product list excel file.
                            <br/>
                            - Remove any parentheses/numbers from the header "Cat No (133)"
                            <br/>
                            - Variant sku is determined by combining the "Ref" and "Size"
                            <br/>
                            - e.g Ref=CWNOS63CJAG27 and Size=18 would match sku CWNOS63CJAG27-18
                            <br/>
                            - Expected headers: Cat No,Description,Option,Colour,Size,Ref
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <UploadButton/>
                    </Grid>
                </Grid>
            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    )
};

export default UploadTvgCodesDialog;