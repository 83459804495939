import {Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";


const Header = props => {

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <Typography
                            component="h1"
                            variant="h3"
                        >
                            Design documents
                        </Typography>
                    </Grid>

                    <Grid item>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
}

export default Header;