import React, {useState} from "react";
import NewProductDialog from "./NewProductDialog";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Grid, Typography, Button} from '@material-ui/core';
import EntitySearchDialog from "../EntitySearchDialog";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Server from "../Server";
import LoadingScreen from "../LoadingScreen";
import BulkUploadDialog from "./BulkUploadDialog";
import DraftUploadDialog from "./DraftUploadDialog";
import EanUploadDialog from "./EanUploadDialog";
import MigrateSizegroupsDialog from "./MigrateSizegroupsDialog";

const ProductsHeader = props => {

    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));
    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const [loading, setLoading] = useState(false);

    const searchType = 'Product';

    const BulkUploadButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        return (
            <>
                <Button
                    color="default"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                >
                    <CloudUploadIcon className={classes.buttonIcon} />
                    Bulk upload
                </Button>
                <BulkUploadDialog open={openDialog} onConfirm={() => setOpenDialog(false)} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const DraftUploadButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        return (
            <>
                <Button
                    color="default"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                >
                    <CloudUploadIcon className={classes.buttonIcon} />
                    Draft upload
                </Button>
                <DraftUploadDialog open={openDialog} onConfirm={() => setOpenDialog(false)} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const EanUploadButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        return (
            <>
                <Button
                    color="default"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                >
                    <CloudUploadIcon className={classes.buttonIcon} />
                    Set EANs
                </Button>
                <EanUploadDialog open={openDialog} onConfirm={() => setOpenDialog(false)} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const MigrateSizegroupsButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        return (
            <>
                <Button
                    color="default"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                >
                    <CloudUploadIcon className={classes.buttonIcon} />
                    migrate size groups
                </Button>
                <MigrateSizegroupsDialog open={openDialog} onConfirm={() => setOpenDialog(false)} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const CostsCsvButton = (props) => {
        const downloadCostsCSV = () => {
            setLoading(true);
            new Server().downloadProductCostsReport((response)=>{
                setLoading(false);
                if(response.status !== 200){
                    alert('An error occurred: \n' + response.status);
                }
            });
        };

        return (
            <Button
                color="default"
                variant="contained"
                onClick={downloadCostsCSV}
            >
                <AttachMoneyIcon className={classes.buttonIcon} />
                Costs
            </Button>
        );
    };

    const NewProductButton = () => {
        const [openDialog, setOpenDialog] = useState(false);

        const onNewProductClick = () => {
            setOpenDialog(true);
        };

        const onConfirmNewProductDialog = (code) => {
            nav.goToEditProduct(code);
        };

        const onCloseNewProductDialog = () => {
            setOpenDialog(false);
        };

        return (
            <>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onNewProductClick}
                >
                    <AddCircleIcon className={classes.buttonIcon}/>
                    New Product
                </Button>
                <NewProductDialog open={openDialog} onConfirm={onConfirmNewProductDialog}
                                  onClose={onCloseNewProductDialog}/>
            </>
        );
    };

    const ProductsExportButton = (props) => {
        const exportProducts = () => {
            const confirmation = window.confirm('An email will be sent with the excel file attached.\nExporting all products will take a few minutes.\nDRAFT products are not included.\nProceed?')
            if(confirmation){
                setLoading(true);
                new Server().emailProductsExcel(() => setLoading(false));
            }
        };
        return (
            <Button
                color="default"
                variant="contained"
                onClick={exportProducts}
            >
                <CloudDownloadIcon className={classes.buttonIcon} />
                export
            </Button>
        );
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <Typography
                            component="h1"
                            variant="h3"
                        >
                            Products
                        </Typography>
                    </Grid>
                    <Grid item>
                        <EntitySearchDialog type={searchType}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={1}>

                    <Grid item>
                        <ProductsExportButton/>
                    </Grid>

                    <Grid item>
                        <DraftUploadButton/>
                    </Grid>

                    <Grid item>
                        <MigrateSizegroupsButton/>
                    </Grid>

                    <Grid item>
                        <EanUploadButton/>
                    </Grid>

                    <Grid item>
                        <BulkUploadButton/>
                    </Grid>

                    <Grid item>
                        <CostsCsvButton/>
                    </Grid>

                    <Grid item>
                        <NewProductButton/>
                    </Grid>
                </Grid>
            </Grid>

            <LoadingScreen loading={loading}/>
        </Grid>
    )
};

export default ProductsHeader;