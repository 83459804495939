import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, LinearProgress, Typography} from "@material-ui/core";
import Server from "../Server";
import Pricing from "../Pricing";

const POStatsDialog = props => {

    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState({
        "total_units": 0,
        "total_value": 0,
        "units_shipped": 0,
        "shipped_value": 0,
        "currency": "GBP"
    });
    useEffect(() => {
        if (props.open === true) {
            setLoading(true);
            const server = new Server();
            server.fetchPoStats(props.poNumber, response => {
                setLoading(false);
                if(response.status === 200) {
                    setStats(response.data);
                } else {
                    alert('Unable to get PO stats.');
                    props.onClose();
                }
            });
        }
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle disableTypography><Typography variant="h5">{props.poNumber}</Typography></DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <LinearProgress />
                        :
                        <React.Fragment>
                            <Typography>Total units: {stats.total_units}</Typography>
                            <Typography>Total value: {Pricing.formatPrice(stats.total_value, stats.currency)}</Typography>
                            <Typography>Total shipped: {stats.units_shipped}</Typography>
                            <Typography>Shipped value: {Pricing.formatPrice(stats.shipped_value, stats.currency)}</Typography>
                            <Typography>Outstanding: {stats.total_units - stats.units_shipped}</Typography>
                        </React.Fragment>
                }
            </DialogContent>
        </Dialog>
    );

};

export default POStatsDialog;