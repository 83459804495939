import {Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const NewDebitDocuments = props => {

    const useStyles = makeStyles(theme => ({
        content: {
            padding: 0
        },
        actions: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    return (
      <Card>
          <CardHeader title="Documents"/>
          <Divider/>
          <CardContent className={classes.content}>
              <List component="nav" dense={true}>
                  {
                      props.documents.map((document, index) => {
                          return  <ListItem key={index} >
                              <ListItemText primary={document.name} />
                              <IconButton
                                  className={classes.signOutButton}
                                  color="inherit"
                                  onClick={() => props.onRemoveDocument(document)}
                              >
                                  <DeleteIcon />
                              </IconButton>
                          </ListItem>
                      })
                  }
              </List>
          </CardContent>
          <CardActions className={classes.actions}>
              <div>
                  <Button component="label">
                      <EditIcon className={classes.buttonIcon}/>
                      Add documents
                      <input
                          type="file"
                          style={{ display: "none" }}
                          multiple
                          onChange={(event) => {props.onAddDocuments(event.target.files)}}
                      />
                  </Button>

              </div>
          </CardActions>
      </Card>
  );
};

export default NewDebitDocuments;