import React, {useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NewPackageDialog from "./NewPackageDialog";
import AuthService from "../AuthService";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {
    const classes = useStyles();

    const NewPackageButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        const onConfirm = () => {
            setOpenDialog(false);
        };
        return (
            <>
                <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                    <AddCircleIcon className={classes.buttonIcon}/>
                    New package
                </Button>
                <NewPackageDialog open={openDialog} onConfirm={onConfirm} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >

            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Consolidated POs
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                {AuthService.isAdminLoggedIn() ? <NewPackageButton/> : null}
            </Grid>

        </Grid>
    );
}

export default Header;