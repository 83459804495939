import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Server from "../Server";

const OrderedUnits = props => {

    const plNumber = props.plNumber;
    const defaultOrderedUnits = {product: 'NA', ordered_units: [], sizes: []};

    const [orderedUnits, setOrderedUnits] = useState(defaultOrderedUnits);

    useEffect(() => {
        if(plNumber) {
            new Server().getOrderedUnits(plNumber, (response) =>{
                if(response.status === 200 && response.data){
                    setOrderedUnits(response.data);
                }
            });
        }
    }, [props.po, props.product]);

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} style={{ width: 150 }}>Ordered units</TableCell>
                    {orderedUnits.sizes.map((size, index) => (
                        <TableCell style={{width: 80}} key={size}>{size}</TableCell>
                    ))}
                    <TableCell ></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>{orderedUnits.product_code}</TableCell>
                    {orderedUnits.sizes.map((size, index) => (
                        <TableCell key={size}>{orderedUnits.ordered_units[size]}</TableCell>
                    ))}
                    <TableCell></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

};

export default OrderedUnits;