import Utils from "../Utils";

class ProductService {

    static isProductVariant(variant, product) {
        for (let i=0; i<product.variants.length; i++) {
            if (variant.id === product.variants[i].id) {
                return true;
            }
        }
        return false;
    }

    static calculateTotalUnits(order, product) {
        let total = 0;
        for (let i=0; i<order.items.length; i++) {
            if (this.isProductVariant(order.items[i].variant, product)) {
                total += order.items[i].units;
            }
        }
        return total;
    }

    static calculateTotalValue(order, product) {
        let value = 0;
        for (let i=0; i<order.items.length; i++) {
            if (this.isProductVariant(order.items[i].variant, product)) {
                value += (order.items[i].units * order.items[i].price);
            }
        }
        return value;
    }

    static saveProductLocally(product) {
        console.log('Saving product...');
        console.log(product);
        let jsonProducts = localStorage.getItem('PRODUCTS');
        if (jsonProducts === null) {
            jsonProducts = "{}";
        }
        let products = JSON.parse(jsonProducts);
        products[product.code] = product;
        localStorage.setItem('PRODUCTS', JSON.stringify(products))
    }

    static getLocalProducts() {
        const productsJson = localStorage.getItem('PRODUCTS');
        if(productsJson !== null && productsJson !== undefined && productsJson !== '') {
            const productsMap = JSON.parse(productsJson);
            console.log('Loading products from storage');
            console.log(productsMap);
            const productsArr = Object.values(productsMap);
            return productsArr;
        } else {
            return [];
        }
    }

    static getLocalProduct(code) {
        return this.getLocalProducts().filter((product) => {return product.code === code})[0];
    }

    static productExists(code) {
        const searchResults = this.getLocalProducts().filter((product) => {return product.code === code});
        return searchResults.length > 0;
    }

    static flatten(product) {
        for(let i=0 ;i<product.variants.length; i++) {
            product.variants[i].size = product.variants[i].size.name;
        }
    }

    static emptyProductInventory() {
        return {
            product: this.emptyProduct('ABC-123'),
            variant_inventories: [],
            unallocated: 0,
            allocated: 0,
            on_hand: 0,
            incoming: 0,
            available: 0,
            committed: 0,
            next_delivery: new Date(),
        }
    }

    static emptyProduct(code) {
        return {
            code: code,
            name: '',
            brand: {name:''},
            season: '',
            fit: {code:''},
            department: {code:''},
            category: {name:''},
            subcategory: {name:''},
            color: '',
            supplier_color: '',
            tax_code: '',
            weight: 0.00,
            size_group: {name: '', sizes: []},
            materials: {
                primary: [],
                secondary: [],
            },
            hscode: '',
            cost: 0,
            rrp: 0,
            coo: '',
            location: '',
            warehouse: {name:''},
            warehouse_name: '',
            supplier: {id: 0, name: ''},
            variants: [],
            images: []
        };
    }

    static LOCATION_NAMES = ['FLOSTREAM', 'ABSTRAKT LTD', 'Creative Freight', 'Simarco LTD', 'Walker Logistics']

    static testLocationInventories() {
        let locations = [];
        for(let i=0; i<this.LOCATION_NAMES.length; i++){
            locations.push(
                {
                   name: this.LOCATION_NAMES[i],
                    available: Utils.randomInt(20),
                    on_hand: Utils.randomInt(20),
                    incoming: Utils.randomInt(20),
                }
            )
        }
        return locations;
    }

    static createTestProducts() {
        const products = [
            {
                code: 'LDN-20-050',
                name: 'LDN DNM Indigo Denim Shorts ',
                type: 'trousers',
                brand: 'URBAN THREADS',
                body: 'regular',
                season: 'SS20',
                genders: ['male'],
                style: ['trousers'],
                category: ['trousers & chinos'],
                ages: ['adult'],
                color: 'dark blue',
                supplier_color: 'dark blue',
                size_group: 'Jeans',
                materials: {
                    primary: [{id: 1, fabric: 'polyester', percentage: 98},{id: 1, fabric: 'elastane', percentage: 2}],
                    secondary: [],
                },
                cost: 5.30,
                rrp: 24.99,
                coo: 'BANGLADESH',
                location: 'FLOSTREAM',
                created_date: new Date(),
                eans: [
                    {sku: 'LDN-20-050-28-32', ean: '5060679549274', size: '28-32'},
                    {sku: 'LDN-20-050-30-32', ean: '5060679549281', size: '30-32'},
                    {sku: 'LDN-20-050-32-32', ean: '5060679549298', size: '32-32'},
                    {sku: 'LDN-20-050-34-32', ean: '5060679549304', size: '34-32'},
                    {sku: 'LDN-20-050-36-32', ean: '5060679549311', size: '36-32'},
                ],
                images: [
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-050-FRONT.jpg?v=1595236949',
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-050-BACK.jpg?v=1595236950',
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-050-SIDE.jpg?v=1595236950'
                ]
            },

            {
                code: 'URB-20-171',
                name: 'Urban Threads Short sleeve oversized yarn dye stripe tee',
                type: 't-shirt',
                brand: 'URBAN THREADS',
                body: 'regular',
                season: 'SS20',
                genders: ['male'],
                style: ['t-shirt'],
                category: ['t-shirts & vests'],
                ages: ['adult'],
                color: 'multi-coloured',
                supplier_color: 'multi-coloured',
                size_group: 'Standard',
                materials: {
                    primary: [{id: 1, fabric: 'cotton', percentage: 100}],
                    secondary: [],
                },
                cost: 2.00,
                price: 3.30,
                rrp: 17.99,
                coo: 'BANGLADESH',
                location: 'FLOSTREAM',
                created_date: new Date(),
                eans: [
                    {sku: 'URB-20-171-XS', ean: '5060679548727', size: 'XS'},
                    {sku: 'URB-20-171-S', ean: '5060679548734', size: 'S'},
                    {sku: 'URB-20-171-M', ean: '5060679548741', size: 'M'},
                    {sku: 'URB-20-171-L', ean: '5060679548758', size: 'L'},
                    {sku: 'URB-20-171-Xl', ean: '5060679548765', size: 'XL'},
                ],
                images: [
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/XDTi0HrQQxil8SovWzAb_URB-20-171-FRONT.jpg?v=1593168424',
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/i3YawGj9S8yospMVYlWn_URB-20-171-SIDE.jpg?v=1593168424',
                ]
            },


            {
                code: 'LDN-20-053',
                name: 'LDN DNM Mid Wash Denim Trucker Jacket with Removable Jersey Hood',
                type: 'jacket',
                brand: 'URBAN THREADS',
                body: 'regular',
                season: 'SS20',
                genders: ['male'],
                style: ['denim jacket'],
                category: ['jackets'],
                ages: ['adult'],
                color: 'blue',
                supplier_color: 'blue',
                size_group: 'Standard',
                materials: {
                    primary: [{id: 1, fabric: 'cotton', percentage: 100}],
                    secondary: [],
                },
                cost: 12.00,
                rrp: 45.00,
                coo: 'CHINA',
                location: 'FLOSTREAM',
                created_date: new Date(),
                eans: [
                    {sku: 'LDN-20-053-XS', ean: '5060679549878', size: 'XS'},
                    {sku: 'LDN-20-053-S', ean: '5060679549885', size: 'S'},
                    {sku: 'LDN-20-053-M', ean: '5060679549892', size: 'M'},
                    {sku: 'LDN-20-053-L', ean: '5060679549908', size: 'L'},
                    {sku: 'LDN-20-053-Xl', ean: '5060679549915', size: 'XL'},
                ],
                images: [
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-053-FRONT.jpg?v=1594131768',
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-053-BACK.jpg?v=1594131768',
                    'https://cdn.shopify.com/s/files/1/0088/8048/8514/products/LDN-20-053-SIDE.jpg?v=1594131768'
                ]
            },

        ];

        products.map((product) => {this.saveProductLocally(product)});
    }

}

export default ProductService;