import React, {useEffect, useState} from 'react';
import Server from "../../Server";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";

const EditVariantDialog = props => {

    const variantId = props.variantId;
    const open = props.open;
    const onConfirm = props.onConfirm;
    const onClose = props.onClose;

    const [id, setId] = useState(-1); //non editable
    const [sku, setSku] = useState(''); //non editable

    const [ean, setEan] = useState('');
    const [productCode, setProductCode] = useState('');
    const [jlpId, setJlpId] = useState('');
    const [farId, setFarId] = useState('');
    const [tvgId, setTvgId] = useState('');
    const [zppId, setZppId] = useState('');
    const [bpId, setBpId] = useState(-1);
    const [jlpActive, setJlpActive] = useState(false);
    const [nxtActive, setNxtActive] = useState(false);
    const [tvgActive, setTvgActive] = useState(false);

    useEffect(() => {
        if (open) {
            new Server().fetchChannelVariant(variantId, response => {
                const variant = response.data;
                setId(variant.id)
                setSku(variant.sku);
                setEan(variant.ean);
                setProductCode(variant.product_code)
                setJlpId(variant.jl_id);
                setFarId(variant.far_id);
                setTvgId(variant.tvg_id);
                setZppId(variant.zpp_id);
                setBpId(variant.brightpearl_id);
                setJlpActive(variant.active_on_jlp);
                setNxtActive(variant.active_on_nxt);
                setTvgActive(variant.active_on_tvg);
            });
        }
    }, [open]);

    const confirm = () => {
        const data = {
            'id': id,
            'ean': ean,
            'product_code': productCode,
            'jlp_id': jlpId,
            'far_id': farId,
            'zpp_id': zppId,
            'tvg_id': tvgId,
            'brightpearl_id': bpId,
            'active_on_jlp': jlpActive,
            'active_on_nxt': nxtActive,
            'active_on_tvg': tvgActive,
        };
        new Server().updateChannelVariant(variantId, data, response => {
            if(response.status === 200) {
                onConfirm();
            } else {
                alert(JSON.stringify(response.data));
            }
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">

            <DialogTitle disableTypography>
                <Typography variant="h4">Edit variant</Typography>
            </DialogTitle>


            <DialogContent>

                <Typography gutterBottom>Take care when editing these values. Incorrect ids will prevent orders from getting processed.<br/></Typography>

                <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                >
                    <Grid item xs={4}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={id}
                                    disabled
                                    label="ID"
                                >
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={sku}
                                    disabled
                                    label="Sku"
                                >
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={ean}
                                    onChange={(e) => setEan(e.target.value)}
                                    label="EAN">
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={productCode}
                                    onChange={(e) => setProductCode(e.target.value)}
                                    label="Product code">
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={bpId}
                                    onChange={(e) => setBpId(e.target.value)}
                                    label="BP id">
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={4}>
                        <Grid container direction={"column"} spacing={1}>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={jlpId}
                                    onChange={(e) => setJlpId(e.target.value)}
                                    label="JLP code">
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={farId}
                                    onChange={(e) => setFarId(e.target.value)}
                                    label="FAR code">
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={tvgId}
                                    onChange={(e) => setTvgId(e.target.value)}
                                    label="TVG code">
                                </TextField>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={zppId}
                                    onChange={(e) => setZppId(e.target.value)}
                                    label="ZPP code">
                                </TextField>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={jlpActive}
                                            onChange={(e) => setJlpActive(e.target.checked)}
                                            name="jlpActive"
                                            color="primary"
                                        />
                                    }
                                    label="JLP enabled"
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={nxtActive}
                                            onChange={(e) => setNxtActive(e.target.checked)}
                                            name="nxtActive"
                                            color="primary"
                                        />
                                    }
                                    label="NXT enabled"
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={tvgActive}
                                            onChange={(e) => setTvgActive(e.target.checked)}
                                            name="tvgActive"
                                            color="primary"
                                        />
                                    }
                                    label="TVG enabled"
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

                <DialogActions>
                    <Button onClick={onClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={confirm} color="primary">
                        Save
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    )
        ;
};

export default EditVariantDialog;