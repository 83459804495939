import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    errorIcon: {
        fill: theme.palette.red,
    },
    successIcon: {
        color: theme.palette.green,
    }
}));

const PLExcelValidation = (props) => {

    const classes = useStyles();

    const validationResults = props.results;

    const [expanded, setExpanded] = React.useState([]);

    const handleClick = (index) => {
        let newExpanded = JSON.parse(JSON.stringify(expanded));
        if(!newExpanded) {
            for(let i=0; i<validationResults.length; i++) {
                newExpanded.push(false);
            }
        } else {
            newExpanded[index] = !newExpanded[index]
        }
        setExpanded(newExpanded);
    };

    const isExpanded = (index) => {
        if(!expanded || index >= expanded.length) {
            return false;
        }
        return expanded[index];
    };

    const hasErrors = (validatedSheet) => {
        return validatedSheet.errors.length > 0;
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {
                validationResults.map((validatedSheet, index) => (
                    <React.Fragment key={validatedSheet.sheet_name}>
                        <ListItem button onClick={() => handleClick(index)}>
                            <ListItemIcon>
                                {hasErrors(validatedSheet) ? <ErrorIcon className={classes.errorIcon} />: <CheckCircleIcon className={classes.successIcon}/>}
                            </ListItemIcon>
                            <ListItemText primary={validatedSheet.sheet_name}/>
                            {hasErrors(validatedSheet) ? (isExpanded(index) ? <ExpandLess/> : <ExpandMore/>) : null}
                        </ListItem>
                        <Collapse in={isExpanded(index)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    validatedSheet.errors.map((error, index) => (
                                        <ListItem key={error} button className={classes.nested}>
                                            <ListItemText primary={error}/>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))
            }
        </List>
    );
};

export default PLExcelValidation;