class ProductData {

    static types = [
        'underwear',
        'shirt',
        't-shirt',
        'trousers',
        'dress',
        'jacket',
        'long set',
        'short set',
        'hoodie',
        'shorts',
        'crop top',
        'bralette',
        'gifting',
        'socks',
        'sweatshirt',
        'bottoms',
        'dressing gown',
        'swimwear'
    ];

    static brands = [
        'CHELSEA PEERS',
        'URBAN THREADS',
        'BROOKLYN SUPPLY CO',
        'PRIMROSE LDN',
        'NOCOZO'
    ];

    static colors = [
        'light grey',
        'grey',
        'dark grey',
        'silver',
        'stone',
        'mottled light grey',
        'mottled grey',
        'mottled dark grey',
        'metallic grey',
        'gunmetal',
        'light yellow',
        'yellow',
        'dark yellow',
        'apricot',
        'orange',
        'gold',
        'mottled light yellow',
        'mottled yellow',
        'mottled dark yellow',
        'mottled apricot',
        'mottled orange',
        'burnt orange denim',
        'rose gold-coloured',
        'mustard yellow',
        'light red',
        'red',
        'dark red',
        'bordeaux',
        'coral',
        'salmon',
        'mottled light red',
        'mottled red',
        'mottled dark red',
        'mottled bordeaux',
        'metallic red',
        'light pink',
        'pink',
        'lilac',
        'purple',
        'berry',
        'dark purple',
        'mauve',
        'mottled light pink',
        'mottled pink',
        'mottled lilac',
        'mottled purple',
        'mottled berry',
        'mottled dark purple',
        'light blue',
        'blue',
        'dark blue',
        'navy',
        'royal blue',
        'turquoise',
        'blue-grey',
        'mottled light blue',
        'mottled blue',
        'mottled dark blue',
        'mottled royal blue',
        'mottled turquoise',
        'light green',
        'green',
        'dark green',
        'olive',
        'teal',
        'khaki',
        'evergreen',
        'mint',
        'mottled light green',
        'mottled green',
        'mottled dark green',
        'mottled olive',
        'mottled teal',
        'metallic green',
        'light brown',
        'brown',
        'dark brown',
        'beige',
        'ochre',
        'copper',
        'taupe',
        'cognac',
        'sand',
        'camel',
        'mottled light brown',
        'mottled brown',
        'mottled dark brown',
        'mottled beige',
        'mottled ochre',
        'bronze',
        'tan',
        'anthracite',
        'black',
        'mottled anthracite',
        'mottled black',
        'metallic black',
        'light-blue denim',
        'dark-blue denim',
        'stone blue denim',
        'moon washed',
        'neon yellow',
        'neon green',
        'neon blue',
        'neon pink',
        'nude',
        'multi-coloured',
        'not defined',
        'blue denim',
        'rinsed denim',
        'black denim',
        'grey denim',
        'raw denim',
        'coloured denim',
        'white denim',
        'selvage denim',
        'bleached denim',
        'metallic blue',
        'blue-black denim',
        'dyed denim',
        'laser denim',
        'dirty denim',
        'coated denim',
        'destroyed denim',
        'used denim',
        'neon orange',
        'silver-coloured',
        'gold-coloured',
        'white',
        'off-white',
        'transparent',
    ];

    static materials = [
        'elastolefin',
        'lyocell',
        'mohair',
        'alpaca',
        'asbestos',
        'polypropylene',
        'acrylic',
        'Elastomultiester',
        'glass fibre',
        'chlorofibre',
        'linen',
        'viscose',
        'yak',
        'guanako',
        'polyimide',
        'aramid',
        'jute',
        'ramie',
        'other fibres',
        'elastane',
        'sunn',
        'camel',
        'fluorofibre',
        'elastodiene',
        'flax',
        'henequen',
        'kapok',
        'modal',
        'polyester',
        'paper',
        'coir (coconut)',
        'leather',
        'acetate',
        'nylon',
        'angora',
        'broom',
        'vicuna',
        'trivinyl',
        'sisal',
        'alginate',
        'wool',
        'metal',
        'maguey',
        'down',
        'polyamide',
        'alfa',
        'silk',
        'modacrylic',
        'beaver',
        'otter',
        'feathers',
        'melamine',
        'abaca (Manila hemp)',
        'polycarbamide',
        'cotton',
        'polyurethane',
        'polylactide',
        'llama',
        'vinylal',
        'cashgora',
        'animal hair',
        'polyethylene',
        'cupro',
        'cashmere',
        'protein',
        'true hemp',
        'textile',
        'triacetate',
        'recycled polyester',
        'organic cotton'
    ];

    static seasons = [
        'SS20',
        'AW21',
        'SS21',
        'AW22',
        'SS22',
        'AW23',
        'SS23',
        'AW24',
        'SS24',
        'AW25',
        'SS25',
        'NOS',
    ];
}

export default ProductData;