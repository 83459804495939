import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import PurchaseOrders from './PurchaseOrders/PurchaseOrders';
import Main from './Main';
import Account from './Account';
import PoDetails from "./PurchaseOrders/PoDetails/PoDetails";
import SupplierPos from "./SupplierPos/SupplierPos";
import SupplierPoDetails from "./SupplierPos/SupplierPoDetails/SupplierPoDetails"
import SupplierPOShipmentDetails from "./SupplierPOShipments/SupplierPOShipmentDetails";
import POShipments from "./POShipments/POShipments"
import Ports from "./Ports"
import Customers from "./Customers"
import Suppliers from "./Suppliers"
import ZalandoProducts from "./ZalandoProducts/ZalandoProducts"
import NewZalandoProduct from "./ZalandoProducts/NewProduct/NewZalandoProduct"
import Users from "./users/Users"
import SignIn from "./SignIn"
import Minimal from "./Minimal"
import Debits from "./debits/Debits";
import NewDebit from "./debits/NewDebit";
import DebitDetails from "./debits/DebitDetails";
import NotFoundView from "./NotFoundView";
import SupplierMain from "./SupplierMain";
import NavigationService from "./NavigationService";
import Products from "./Products/Products";
import EditProduct from "./Products/EditProduct/EditProduct";
import ProductDetails from "./Products/ProductDetails/ProductDetails";
import Stock from "./Stock/Stock";
import LinesheetReport from "./Reports/LinesheetReport/LinesheetReport";
import POShipmentDetails from "./POShipments/POShipmentDetails/POShipmentDetails";
import SupplierPOShipments from "./SupplierPOShipments/SupplierPOShipments";
import Reports from "./Reports/Reports";
import StockReport from "./Reports/StockReport/StockReport";
import ProductionReport from "./Reports/ProductionReport/ProductionReport";
import DeliveryReport from "./Reports/DeliveryReport/DeliveryReport";
import Destinations from "./Destinations";
import Zalando from "./ZalandoProducts/Zalando";
import Orders from "./ZalandoProducts/Orders/Orders";
import BulkUpload from "./ZalandoProducts/BulkUpload/BulkUpload";
import ChannelOrders from "./Channels/Orders/ChannelOrders";
import ChannelOrderDetails from "./Channels/Orders/OrderDetails/ChannelOrderDetails";
import ChannelsOverview from "./Channels/Overview/ChannelsOverview";
import Deliveries from "./Channels/Deliveries/Deliveries";
import SizeRatios from "./SizeRatios/SizeRatios";
import Welcome from "./Welcome/Welcome";
import DesignDocUpload from "./Products/ProductDetails/DesignDocUpload";
import PDFBuilder from "./PDFBuilder/PDFBuilder";
import SupplierDesignDocs from "./SupplierDesignDocs/SupplierDesignDocs";
import DesignDocs from "./DesignDocs/DesignDocs";
import ChannelVariants from "./Channels/Variants/ChannelVariants";
import ChannelVariantDetails from "./Channels/Variants/VariantDetails/ChannelVariantDetails";
import TopSelling from "./Channels/TopSelling/TopSelling";
import TransitProducts from "./TransitProducts/TransitProducts";
import Packages from "./ConsolidatedPackages/Packages";
import PackageDetails from "./ConsolidatedPackages/ConsolidatedPackageDetails/PackageDetails";
import SupplierPackages from "./SupplierPackages/SupplierPackages";
import SupplierPackageDetails from "./SupplierPackages/Details/SupplierPackageDetails";
import GenericReport from "./Reports/GenericReport/GenericReport";


const Routes = () => {

    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to={NavigationService.getRedirectPathFromRootUrl()}
            />

            <RouteWithLayout
                component={SignIn}
                exact
                layout={Minimal}
                path={NavigationService.LOGIN}
            />


            {/*admin pages*/}
            <RouteWithLayout
                component={Welcome}
                exact
                layout={Main}
                path={NavigationService.WELCOME}
            />

            <RouteWithLayout
                component={ChannelsOverview}
                exact
                layout={Main}
                path={NavigationService.CHANNELS_OVERVIEW}
            />

            <RouteWithLayout
                component={ChannelOrders}
                exact
                layout={Main}
                path={NavigationService.CHANNEL_ORDERS}
            />

            <RouteWithLayout
                component={ChannelOrderDetails}
                exact
                layout={Main}
                path={NavigationService.CHANNEL_ORDER_DETAILS}
            />

                <RouteWithLayout
                    component={ChannelVariants}
                    exact
                    layout={Main}
                    path={NavigationService.CHANNEL_VARIANTS}
                />

                <RouteWithLayout
                    component={ChannelVariantDetails}
                    exact
                    layout={Main}
                    path={NavigationService.CHANNEL_VARIANT_DETAILS}
                />

                <RouteWithLayout
                    component={TopSelling}
                    exact
                    layout={Main}
                    path={NavigationService.TOP_SELLING}
                />

            <RouteWithLayout
                component={Deliveries}
                exact
                layout={Main}
                path={NavigationService.DELIVERIES}
            />

                <RouteWithLayout
                    component={Packages}
                    exact
                    layout={Main}
                    path={NavigationService.CONSOLIDATED_PACKAGES}
                />

                <RouteWithLayout
                    component={PackageDetails}
                    exact
                    layout={Main}
                    path={NavigationService.CONSOLIDATED_PACKAGE_DETAILS}
                />

            <RouteWithLayout
                component={TransitProducts}
                layout={Main}
                path={NavigationService.TRANSIT_PRODUCTS}
            />

                <RouteWithLayout
                component={PurchaseOrders}
                exact
                layout={Main}
                path={NavigationService.PURCHASE_ORDERS}
            />

            <RouteWithLayout
                component={PoDetails}
                exact
                layout={Main}
                path={NavigationService.PO_DETAILS}
            />

            <RouteWithLayout
                component={POShipments}
                layout={Main}
                path={NavigationService.PO_SHIPMENTS}
            />

            <RouteWithLayout
                component={POShipmentDetails}
                exact
                layout={Main}
                path={NavigationService.PO_SHIPMENT_DETAILS}
            />

            <RouteWithLayout
                component={Products}
                layout={Main}
                path={NavigationService.PRODUCTS}
            />

            <RouteWithLayout
                component={EditProduct}
                layout={Main}
                path={NavigationService.EDIT_PRODUCT}
            />

            <RouteWithLayout
                component={ProductDetails}
                layout={Main}
                path={NavigationService.PRODUCT_DETAILS}
            />

                <RouteWithLayout
                    component={DesignDocs}
                    layout={Main}
                    path={NavigationService.DESIGN_DOCS}
                />

            <RouteWithLayout
                component={DesignDocUpload}
                exact
                layout={Minimal}
                path={NavigationService.DESIGN_DOC_UPLOAD}
            />

            <RouteWithLayout
                component={SizeRatios}
                layout={Main}
                path={NavigationService.SIZE_RATIOS}
            />

            <RouteWithLayout
                component={Stock}
                layout={Main}
                path={NavigationService.STOCK}
            />

            <RouteWithLayout
                component={Reports}
                layout={Main}
                path={NavigationService.REPORTS}
            />

            <RouteWithLayout
                component={StockReport}
                layout={Main}
                path={NavigationService.STOCK_REPORT}
            />

            <RouteWithLayout
                component={ProductionReport}
                layout={Main}
                path={NavigationService.PRODUCTION_REPORT}
            />

            <RouteWithLayout
                component={DeliveryReport}
                layout={Main}
                path={NavigationService.DELIVERY_REPORT}
            />

            <RouteWithLayout
                component={LinesheetReport}
                layout={Main}
                path={NavigationService.LINESHEET_REPORT}
            />

                <RouteWithLayout
                    component={GenericReport}
                    layout={Main}
                    path={NavigationService.GENERIC_REPORT}
                />

            <RouteWithLayout
                component={Account}
                exact
                layout={Main}
                path="/account"
            />

            <RouteWithLayout
                component={Ports}
                exact
                layout={Main}
                path={NavigationService.PORTS}
            />

            <RouteWithLayout
                component={Customers}
                exact
                layout={Main}
                path={NavigationService.CUSTOMERS}
            />

            <RouteWithLayout
                component={Destinations}
                exact
                layout={Main}
                path={NavigationService.DESTINATIONS}
            />

            <RouteWithLayout
                component={Suppliers}
                exact
                layout={Main}
                path={NavigationService.SUPPLIERS}
            />

            <RouteWithLayout
                component={Users}
                exact
                layout={Main}
                path={NavigationService.USERS}
            />

            <RouteWithLayout
                component={Zalando}
                exact
                layout={Main}
                path={NavigationService.ZALANDO}
            />

            <RouteWithLayout
                component={Orders}
                exact
                layout={Main}
                path={NavigationService.ZALANDO_ORDERS}
            />

            <RouteWithLayout
                component={BulkUpload}
                exact
                layout={Main}
                path={NavigationService.ZALANDO_BULK_UPLOAD}
            />

            <RouteWithLayout
                component={ZalandoProducts}
                exact
                layout={Main}
                path={NavigationService.ZALANDO_PRODUCTS}
            />

            <RouteWithLayout
                component={NewZalandoProduct}
                exact
                layout={Main}
                path={NavigationService.NEW_ZALANDO_PRODUCT}
            />

            <RouteWithLayout
                component={Debits}
                exact
                layout={Main}
                path={NavigationService.DEBITS}
            />

            <RouteWithLayout
                component={NewDebit}
                exact
                layout={Main}
                path={NavigationService.NEW_DEBIT}
            />

            <RouteWithLayout
                component={DebitDetails}
                layout={Main}
                path={NavigationService.DEBIT_DETAILS}
            />

            <RouteWithLayout
                component={PDFBuilder}
                layout={Main}
                path={NavigationService.PDF_BUILDER}
            />

            {/*supplier pages*/}
                <RouteWithLayout
                    component={SupplierDesignDocs}
                    layout={SupplierMain}
                    path={NavigationService.SUPPLIER_DESIGN_DOCS}
                />

            <RouteWithLayout
                component={SupplierPackages}
                layout={SupplierMain}
                exact
                path={NavigationService.SUPPLIER_CONSOLIDATED_PACKAGES}
            />

            <RouteWithLayout
                component={SupplierPackageDetails}
                layout={SupplierMain}
                path={NavigationService.SUPPLIER_PACKAGE_DETAILS}
            />

            <RouteWithLayout
                component={SupplierPos}
                layout={SupplierMain}
                path={NavigationService.SUPPLIER_POS}
            />

            <RouteWithLayout
                component={SupplierPoDetails}
                layout={SupplierMain}
                path={NavigationService.SUPPLIER_PO_DETAILS}
            />

            <RouteWithLayout
                component={SupplierPOShipments}
                layout={SupplierMain}
                path={NavigationService.SUPPLIER_PO_SHIPMENTS}
            />

            <RouteWithLayout
                component={SupplierPOShipmentDetails}
                layout={SupplierMain}
                path={NavigationService.SUPPLIER_PO_SHIPMENT_DETAILS}
            />

            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={Minimal}
                path={NavigationService.NOT_FOUND}
            />
            <Redirect to={NavigationService.NOT_FOUND}/>
        </Switch>
    );
};

export default Routes;