import {Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";
import React from "react";
import ModelImage from "./ModelImage";

const ModelImages = props => {

    const onImageSelected = (file, imageIndex) => {
        props.onImageSelected(file, imageIndex);
    };

    return (
        <Card>
            <CardHeader title="Images"/>
            <Divider/>
            <CardContent>

                <Grid container direction="column">
                    <Grid item >
                        <Typography
                            gutterBottom
                            variant="h6"
                        >
                            Note: Images are automatically resized to fit Zalando requirements.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={1}>

                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={1}/>
                            </Grid>
                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={2}/>
                            </Grid>
                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={3}/>
                            </Grid>
                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={4}/>
                            </Grid>
                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={5}/>
                            </Grid>
                            <Grid item>
                                <ModelImage productCode={props.productCode} onImageSelected={onImageSelected}
                                            imageIndex={6}/>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ModelImages;
