import React from 'react';
import {Box, Grid} from "@material-ui/core";
import ShipmentProduct from "./ShipmentProduct";

const PackingListsTabContent = props => {

    const shipmentProducts = props.shipmentProducts;
    const shipment = props.shipment;

    return (
        <Box>
            {shipmentProducts.length === 0 ? " No packing lists to show." :
            <Grid container direction="column" spacing={2}>
                {
                    shipmentProducts.map((shipmentProduct) => (
                        <Grid item key={shipmentProduct.product.code}>
                            <ShipmentProduct shipment={shipment} shipmentProduct={shipmentProduct}/>
                        </Grid>
                    ))
                }
            </Grid>}
        </Box>
    );
};
export default PackingListsTabContent;