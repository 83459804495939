
class ZalandoData {
    static OUTLINES = ['watch', 'underwear_combination', 'tights', 'stocking', 'scarf', 'other_accessoires', 'one_piece_underwear', 'one_piece_nightwear', 'nightdress', 'night_trouser', 'headphones', 'gloves', 'glasses', 'bikini_top', 'bikini_combination', 'bicycle', 'shoe_accessoires', 'travel_equipment', 'skates', 'bicycle_equipment', 'tent', 'corsage', 'umbrella', 'toys', 'sleeping_bag', 'boards', 'racket', 'ski', 'fitness', 'ball', 'beach_shirt', 'beach_accessoires', 'other_equipment', 'protector', 'keychain', 'first_shoe', 'suit_accessoires', 'bustier', 'etui', 'bathrobe', 'undershirt', 'night_shirt', 'nightwear_combination', 'headgear', 'one_piece_beachwear', 'jewellery', 'vest', 'combination_clothing', 'belt', 'beach_trouser', 'wallet', 'case', 'backpack', 'backless_slipper', 'ballerina_shoe', 'underpant', 'one_piece_suit', 'skirt', 'bra', 'bag', 'jacket', 'pumps', 'sandals', 'boots', 'ankle_boots', 'cardigan', 'dress', 'shirt', 'coat', 'low_shoe', 'pullover', 't_shirt_top', 'sneaker', 'trouser'];

    static outlineLabels() {
        return this.OUTLINES;
    }

    static brands() {
        return [
            {name: 'BROOKLYN SUPPLY', code: 'BP9'},
            {name: 'CHELSEA PEERS', code: 'CF9'},
            {name: 'URBAN THREADS', code: 'URF'},
        ]
    }

    static seasons() {
        return [
            {code: 'fs20', name:	'Spring-Summer 2020'},
            {code: 'hw20', name:	'Fall-Winter 2020'},
            {code: 'fs21', name:	'Spring-Summer 21'},
            {code: 'hw21', name:	'Fall-Winter 21'},
            {code: 'year_round_basics', name:	'All-season Basic Article'},
        ]
    }

    static colors() {
        return [
            {name: 'white', code: '100'},
            {name: 'red', code: '200'},
            {name: 'blue', code: '300'},
            {name: 'green', code: '400'},
        ]
    }

    static materialProperties() {
        return ['upper_material_clothing', 'upper_material_bottom'];
    }

    static defaultMaterialProperty() {
        return {
            name: 'upper_material_clothing',
            description: 'Outer fabric material'
        }
    }

    static materials() {
        return [
            {'code': 'ela', 'name': 'elastolefin'}, {'code': 'cly', 'name': 'lyocell'}, {
                'code': 'wm',
                'name': 'mohair'
            }, {'code': 'wp', 'name': 'alpaca'}, {'code': 'as', 'name': 'asbestos'}, {
                'code': 'pp',
                'name': 'polypropylene'
            }, {'code': 'pc', 'name': 'acrylic'}, {'code': 'elm', 'name': 'Elastomultiester'}, {
                'code': 'gf',
                'name': 'glass fibre'
            }, {'code': 'cl', 'name': 'chlorofibre'}, {'code': 'li', 'name': 'linen'}, {
                'code': 'cv',
                'name': 'viscose'
            }, {'code': 'wy', 'name': 'yak'}, {'code': 'wu', 'name': 'guanako'}, {
                'code': 'pi',
                'name': 'polyimide'
            }, {'code': 'ara', 'name': 'aramid'}, {'code': 'jute', 'name': 'jute'}, {
                'code': 'ra',
                'name': 'ramie'
            }, {'code': 'sonst', 'name': 'other fibres'}, {'code': 'el', 'name': 'elastane'}, {
                'code': 'sn',
                'name': 'sunn'
            }, {'code': 'wk', 'name': 'camel'}, {'code': 'fl', 'name': 'fluorofibre'}, {
                'code': 'elt',
                'name': 'elastodiene'
            }, {'code': 'fla', 'name': 'flax'}, {'code': 'he', 'name': 'henequen'}, {
                'code': 'kp',
                'name': 'kapok'
            }, {'code': 'cmd', 'name': 'modal'}, {'code': 'pes', 'name': 'polyester'}, {
                'code': 'pr',
                'name': 'paper'
            }, {'code': 'cc', 'name': 'coir (coconut)'}, {'code': 'le', 'name': 'leather'}, {
                'code': 'ac',
                'name': 'acetate'
            }, {'code': 'ny', 'name': 'nylon'}, {'code': 'wn', 'name': 'angora'}, {
                'code': 'gi',
                'name': 'broom'
            }, {'code': 'wg', 'name': 'vicuna'}, {'code': 'tv', 'name': 'trivinyl'}, {
                'code': 'sis',
                'name': 'sisal'
            }, {'code': 'al', 'name': 'alginate'}, {'code': 'wo', 'name': 'wool'}, {
                'code': 'me',
                'name': 'metal'
            }, {'code': 'mg', 'name': 'maguey'}, {'code': 'da', 'name': 'down'}, {
                'code': 'pa',
                'name': 'polyamide'
            }, {'code': 'alf', 'name': 'alfa'}, {'code': 'si', 'name': 'silk'}, {
                'code': 'mac',
                'name': 'modacrylic'
            }, {'code': 'bv', 'name': 'beaver'}, {'code': 'fo', 'name': 'otter'}, {
                'code': 'fed',
                'name': 'feathers'
            }, {'code': 'mel', 'name': 'melamine'}, {'code': 'mr', 'name': 'abaca (Manila hemp)'}, {
                'code': 'pb',
                'name': 'polycarbamide'
            }, {'code': 'co', 'name': 'cotton'}, {'code': 'pu', 'name': 'polyurethane'}, {
                'code': 'pla',
                'name': 'polylactide'
            }, {'code': 'wl', 'name': 'llama'}, {'code': 'vil', 'name': 'vinylal'}, {
                'code': 'tkg',
                'name': 'cashgora'
            }, {'code': 'haar', 'name': 'animal hair'}, {'code': 'pe', 'name': 'polyethylene'}, {
                'code': 'cup',
                'name': 'cupro'
            }, {'code': 'ka', 'name': 'cashmere'}, {'code': 'rp', 'name': 'protein'}, {
                'code': 'ha',
                'name': 'true hemp'
            }, {'code': 'tx', 'name': 'textile'}, {'code': 'tr', 'name': 'triacetate'}
        ];
    }

    static allSizeGroups() {
        return [
            {
                'code': '2F00000A3A',
                'product_type': 'Pants, leggings, jeans, sports pants ...',
                'gender': 'Female',
                'size_type': 'Length',
                'sizes': ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']
            },
            {
                'code': '2MAE000A2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Male',
                'size_type': 'Confection',
                'sizes': ['3XS', 'XXS', 'XS', 'S', 'S/M', 'M', 'M/L', 'L', 'L/XL', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL', '9XL', '10XL']
            },
            {
                'code': '2MON000X2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Male',
                'size_type': 'Confection',
                'sizes': ['One Size']
            },
            {
                'code': '2FKO000E2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Female',
                'size_type': 'Confection',
                'sizes': ['30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54', '56', '58', '60']
            },
            {
                'code': '2FKO000U2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Female',
                'size_type': 'Confection',
                'sizes': ['4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34']
            },
            {
                'code': '2MAH000S2B',
                'product_type': 'Pants, leggings, jeans, sports pants ...',
                'gender': 'Male',
                'size_type': 'Width',
                'sizes': ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '52', '54', '56', '58']
            },
            {
                'code': '2FHU000U3A',
                'product_type': 'Pants, leggings, jeans, sports pants ...',
                'gender': 'Female',
                'size_type': 'Length',
                'sizes': ['18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '52', '54']
            },
            {
                'code': '2MPW000E3A',
                'product_type': 'Pants, leggings, jeans, sports pants ...',
                'gender': 'Male',
                'size_type': 'Length',
                'sizes': ['L26', 'L28', 'L30', 'L32', 'L34', 'L36']
            },
            {
                'code': '2FLB000E2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Female',
                'size_type': 'Confection',
                'sizes': ['One Size']
            },
            {
                'code': '2FAE000A2A',
                'product_type': 'Pants, leggings, jeans, sports pants, shirt, bra, underwear, ...',
                'gender': 'Female',
                'size_type': 'Confection',
                'sizes': ['3XS', '3XS/XXS', 'XXS', 'XXS/XS', 'XS', 'XS/S', 'S', 'S/M', 'M', 'M/L', 'L', 'L/XL', 'XL', 'XL/XXL', 'XXL', 'XXL/3XL', '3XL', '3XL/4XL', '4XL', '4XL/5XL', '5XL', '5XL/6XL', '6XL', '6XL/7XL']
            }
        ]
    }

    static sizeGroups() {
        return this.allSizeGroups().filter(group =>
            group.size_type !== 'Length'
        );
    }

    static lengthGroups() {
        var lengthGroups = this.allSizeGroups().filter(group =>
            group.size_type === 'Length'
        );
        lengthGroups.unshift({
            'code': 'NONE',
            'product_type': '-',
            'gender': '-',
            'size_type': '-',
            'sizes': ['-']
        });
        return lengthGroups;
    }
}



export default ZalandoData;