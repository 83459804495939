import {Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import {Autocomplete} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import ProductData from "../ProductData";
import Server from "../../Server";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import {useSnackbar} from "notistack";

const ProductModelInfo = props => {

    const productCode = props.productCode;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [hscode, setHscode] = useState('');
    const [cost, setCost] = useState(0);
    const [rrp, setRrp] = useState(0);
    const [coo, setCoo] = useState('');
    const [collection, setCollection] = useState('');
    const [weight, setWeight] = useState(0.00);

    const seasons = ProductData.seasons;
    const [season, setSeason] = useState('');

    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState(null);

    const [fits, setFits] = useState([]);
    const [fit, setFit] = useState(null);

    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState(null);

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);

    const [taxCodes, setTaxCodes] = useState([]);
    const [taxCode, setTaxCode] = useState('');

    const [sizeGroups, setSizeGroups] = useState({name: ''});
    const [sizeGroup, setSizeGroup] = useState({name: ''});


    useEffect( () => {
        const server = new Server();

        server.fetchProductAttributeValues('brands', (response) => {
            setBrands(response.data);
        });

        server.fetchProductAttributeValues('fits', (response) => {
            setFits(response.data);
        });

        server.fetchProductAttributeValues('departments', (response) => {
            setDepartments(response.data);
        });

        server.fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });

        server.fetchProductAttributeValues('taxCodes', (response) => {
            setTaxCodes(response.data);
        });

        server.fetchProductAttributeValues('sizeGroups', (response) => {
            setSizeGroups(response.data);
        });

        server.fetchProduct(productCode, response => {
            if(response.status === 200) {
                setName(response.data.name);
                setSeason(response.data.season)
                setBrand(response.data.brand);
                setFit(response.data.fit);
                setDepartment(response.data.department);
                setCategory(response.data.category);
                setSizeGroup(response.data.size_group);
                setColor(response.data.color);
                setHscode(response.data.hscode);
                setCost(response.data.cost);
                setRrp(response.data.rrp);
                setCoo(response.data.coo);
                setTaxCode(response.data.tax_code);
                setWeight(response.data.weight);
                setCollection(response.data.collection);
            } else {
                alert('Error getting product \n' + JSON.stringify(response.data));
            }
        });
    }, [productCode]);

    const defaultFieldWidth = 300;

    const save = () => {
        const data = {
            name: name,
            brand: brand,
            category: category,
            fit: fit,
            department: department,
            hscode: hscode,
            season: season,
            color: color,
            cost: cost,
            rrp: rrp,
            coo: coo,
            tax_code: taxCode,
            weight: weight,
            collection: collection,
            size_group: sizeGroup,
        };
        console.log("save >>>>>")
        console.log(data);
        new Server().updateProduct(productCode, data, response => {
            if(response.status === 200) {
                // show sucess notif
                enqueueSnackbar('Product updated', { variant: 'success'});
            } else {
                enqueueSnackbar(
                    'Error saving product',
                    {
                        variant: 'error',
                        autoHideDuration: 3000,
                        action: () => (
                            <Button color="secondary" onClick={() => { alert(JSON.stringify(response.data)); }}>
                                details
                            </Button>
                        )
                    }
                );
                console.log(JSON.stringify(response.data))
            }
        });
    };

    return (
        <Card>
            <CardHeader title="Model info" action={
                <Button size="small" color="primary" onClick={save}>save</Button>
            }/>
            <Divider/>
            <CardContent>

                <Grid container direction="column" spacing={2}>
                    {/*product code/name*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <TextField fullWidth disabled label="Product code" variant="outlined"
                                           style={{width: defaultFieldWidth}}
                                           size="small"
                                           value={productCode} />
                            </Grid>
                            <Grid item>
                                <TextField fullWidth label="Name" variant="outlined"
                                           size="small"
                                           style={{width: defaultFieldWidth}}
                                           value={name} onChange={(e) => setName(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*brand/season*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={brands}
                                    getOptionLabel={brand => brand?.name}
                                    value={brand}
                                    onChange={(e, v, r) => setBrand(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Brand" variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={seasons}
                                    getOptionLabel={season => season}
                                    value={season}
                                    onChange={(e, v, r) => setSeason(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*fit/department*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={departments}
                                    getOptionLabel={department => department?.code}
                                    value={department}
                                    onChange={(e, v, r) => setDepartment(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Department"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={fits}
                                    getOptionLabel={fit => fit?.code}
                                    value={fit}
                                    onChange={(e, v, r) => setFit(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Fit" variant="outlined"/>}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*category/subcategory*/}
                    <Grid item>
                        <Grid container direction="row" spacing={1}>
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={categories}
                                    getOptionLabel={category => category?.name}
                                    value={category}
                                    onChange={(e, v, r) => setCategory(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Category"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>

                            </Grid>

                        </Grid>
                    </Grid>

                    {/*colors/hscode*/}
                    <Grid item>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Color"
                                    variant="outlined"
                                    size="small"
                                    style={{width: defaultFieldWidth}}
                                    value={color} onChange={(e) => setColor(e.target.value)}/>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="HS code"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    style={{width: defaultFieldWidth}}
                                    value={hscode}
                                    onChange={(e) => setHscode(e.target.value)}/>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*size groups*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={sizeGroups}
                                    getOptionLabel={s => s?.name}
                                    value={sizeGroup}
                                    onChange={(e, v, r) => setSizeGroup(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Size group"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Collection"
                                    variant="outlined"
                                    size="small"
                                    style={{width: defaultFieldWidth}}
                                    value={collection}
                                    onChange={(e) => setCollection(e.target.value)}/>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*tax code / weight*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <TextField fullWidth label="Weight (g)" variant="outlined"
                                           size="small"
                                           type="number"
                                           style={{width: defaultFieldWidth}}
                                           value={weight}
                                           onChange={(e) => setWeight(e.target.value)}/>
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={taxCodes}
                                    getOptionLabel={tr => tr}
                                    value={taxCode}
                                    onChange={(e, v, r) => setTaxCode(v)}
                                    autoHighlight
                                    style={{width: defaultFieldWidth}}
                                    renderInput={params => <TextField {...params} label="Tax code"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*cost/RRP*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <TextField fullWidth label="Cost" variant="outlined"
                                           size="small"
                                           type="number"
                                           style={{width: defaultFieldWidth}}
                                           value={cost}
                                           onChange={(e) => setCost(e.target.value)}/>
                            </Grid>

                            <Grid item>
                                <TextField fullWidth label="RRP" variant="outlined"
                                           size="small"
                                           type="number"
                                           style={{width: defaultFieldWidth}}
                                           value={rrp}
                                           onChange={(e) => setRrp(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*COO*/}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item>
                                <TextField label="COO"
                                           variant="outlined"
                                           size="small"
                                           style={{width: defaultFieldWidth}}
                                           value={coo}
                                           onChange={(e) => setCoo(e.target.value)}/>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );

};

export default ProductModelInfo;