import React, {useState} from 'react';
import {Button, Grid, IconButton, Menu, MenuItem, Tooltip, Typography} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import NavigationService from "../../NavigationService";
import {useHistory} from "react-router-dom";
import UploadDialog from "./UploadDialog";
import PublishIcon from "@material-ui/icons/Publish";
import AuthService from "../../AuthService";
import EditIcon from "@material-ui/icons/Edit";
import LoadingScreen from "../../LoadingScreen";
import EditExitPriceDialog from "./EditExitPriceDialog";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {

    const pkg = props.pkg;

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const [loading, setLoading] = useState(false);

    const POUploadButton = () => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <Button variant="contained" component="label" onClick={() => setOpen(true)}>
                    <CloudUploadIcon className={classes.buttonIcon}/>
                    Upload
                </Button>
                <UploadDialog number={pkg.number} open={open} onClose={() => setOpen(false)}/>
            </>
        );
    };

    const PrefilledPlButton = () => {
        const onClick = () => {
            const filename = pkg.number + '_prefilled_packinglists.xlsx'
            setLoading(true);
            new Server().downloadPrefilledExcelPLTemplate(pkg.number, filename, (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert(response.data);
                }
            });
        };

        return (
            <Tooltip title="Generate a packing list excel file with prefilled data">
                <Button
                    variant="contained"
                    onClick={onClick}
                >
                    <CloudDownloadIcon className={classes.buttonIcon}/>
                    Prefilled pl
                </Button>
            </Tooltip>
        )
    };

    const ExportMenuButton = () => {
        const [anchorEl, setAnchorEl] = useState(null);

        const openMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const closeMenu = () => {
            setAnchorEl(null);
        };

        const onPdfClick = () => {
            closeMenu();
            setLoading(true);
            new Server().downloadPackagePdf(pkg.number, (response) => {
                setLoading(false);
                if (response.status !== 200) {
                    alert('Error occurred. Notify admin.');
                }
            });
        };

        const onExcelClick = () => {
            closeMenu();
            setLoading(true);
            new Server().downloadPackageExcel(pkg.number, (response) => {
                setLoading(false);
                if (response.status !== 200) {
                    alert('Error occurred. Notify admin.');
                }
            });
        };

        const onTotalsClick = () => {
            closeMenu();
            new Server().downloadPackageTotals(pkg.number);
        };

        return (
            <div>
                <Button variant="contained" onClick={openMenu}>
                    <CloudDownloadIcon className={classes.buttonIcon}/>
                    Export
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                >
                    <MenuItem onClick={onPdfClick}>pdf</MenuItem>
                    <MenuItem onClick={onExcelClick}>excel</MenuItem>
                    <MenuItem onClick={onTotalsClick}>totals</MenuItem>

                </Menu>
            </div>
        );
    };

    const DeleteButton = () => {
        const deletePackage = (event) => {
            if (window.confirm('Delete forever?')) {
                new Server().deletePackage(pkg.number, (response) => {
                    if (response.status === 200) {
                        nav.goToConsolidatedPackages()
                    } else {
                        alert(response.data);
                    }
                });
            }
        };

        return (
            <Button
                variant="contained"
                onClick={deletePackage}
            >
                <DeleteIcon className={classes.buttonIcon}/>
                Delete
            </Button>
        );
    };

    const EditExitPriceButton = () => {
        const [open, setOpen] = useState(false);

        const onConfirm = () => {
            reloadPage(pkg.number);
        };

        const onClose = () => {
            setOpen(false);
        };

        return (
            <>
                <Tooltip title="Edit exit dates and prices">
                    <Button
                        variant="contained"
                        onClick={() => setOpen(true)}
                    >
                        <EditIcon className={classes.buttonIcon}/>
                        Edit
                    </Button>
                </Tooltip>
                <EditExitPriceDialog pkgNumber={pkg.number} open={open} onConfirm={onConfirm} onClose={onClose}/>
            </>
        );
    };

    const RaiseButton = (props) => {
        const raise = (event) => {
            if (pkg.pos.length === 0) {
                alert('Cannot raise a package with no pos');
                return;
            }
            new Server().raisePackage(pkg.number, (response) => {
                if (response.status === 200) {
                    nav.reload();
                } else {
                    alert(JSON.stringify(response.data, null, 2));
                }
            })
        };

        return (
            <Button
                color="primary"
                variant="contained"
                onClick={raise}
            >
                <PublishIcon className={classes.buttonIcon}/>
                Raise
            </Button>
        );
    };

    const EditNumberButton = (props) => {
        const onClick = () => {
            const newNumber = prompt("New CON number:", "CON");
            new Server().editPackageNumber(pkg.number, newNumber, (response) => {
                if (response.status === 200) {
                    reloadPage(newNumber);
                } else {
                    alert(response.data);
                }
            });
        };

        return (
            <IconButton size="small" aria-label="edit" onClick={onClick}>
                <EditIcon fontSize="small"/>
            </IconButton>
        );
    };

    const reloadPage = (newNumber) => {
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('CON');
        const newUrl = currentUrl.substr(0, index) + newNumber;
        window.location.href = newUrl;
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h2"
                    gutterBottom
                    variant="overline"
                >
                    Consolidated package details
                </Typography>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    {pkg.number}
                    <EditNumberButton/>
                    {pkg.status}
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        {AuthService.isAdminLoggedIn() ? <EditExitPriceButton/> : null}
                    </Grid>
                    <Grid item>
                        {AuthService.isAdminLoggedIn() ? <DeleteButton/> : null}
                    </Grid>
                    <Grid item>
                        {AuthService.isAdminLoggedIn() ? <POUploadButton/> : null}
                    </Grid>
                    <Grid item>
                        <PrefilledPlButton/>
                    </Grid>
                    <Grid item>
                        <ExportMenuButton/>
                    </Grid>
                    <Grid item>
                        {(pkg.status === 'DRAFT' && AuthService.isAdminLoggedIn()) ? <RaiseButton/> : null}
                    </Grid>
                </Grid>
            </Grid>
            <LoadingScreen loading={loading}/>
        </Grid>
    );
};

export default Header;