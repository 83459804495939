import Card from "@material-ui/core/Card/Card";
import {Button, CardContent, CardHeader, Divider, Grid} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import React, {useState} from "react";
import moment from "moment";
import Server from "../Server";
import OrderFinderDialog from "./OrderFinderDialog"

const ZalandoSalesReport = props => {

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [openOrderFinderDialog, setOpenOrderFinderDialog] = useState(false);

    const downloadReport = () => {
        const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
        const formattedToDate = moment(toDate).format('YYYY-MM-DD');
        new Server().downloadZalandoSalesReport(formattedFromDate, formattedToDate);
    };

    const openOrderFinder = () => {
        setOpenOrderFinderDialog(true);
    };

    const onCancelOrderFinder = () => {
        setOpenOrderFinderDialog(false);
    };
    return (
        <Card>
            <CardContent>

                <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <KeyboardDatePicker
                            fullWidth
                            autoOk
                            label="From"
                            format="DD/MM/YYYY"
                            value={fromDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            fullWidth
                            autoOk
                            label="To"
                            format="DD/MM/YYYY"
                            value={toDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToDate(date)}
                        />
                    </Grid>
                    <Grid item>

                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={downloadReport}

                        >download
                        </Button>
                    </Grid>
                    <Grid item>

                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={openOrderFinder}
                        >Order finder
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <OrderFinderDialog 
                               open={openOrderFinderDialog}
                               onCancel={onCancelOrderFinder}/>
        </Card>
    );

};

export default ZalandoSalesReport;