import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Server from "../Server";

const EditDebitNoteDialog = props => {

    const debitNote = props.debitNote;


    const [initialised, setInitialised] = useState(false);
    const [currency, setCurrency] = useState(debitNote.currency);
    const [createdDate, setCreatedDate] = useState(debitNote.created_date);
    const currencies = ['GBP','USD','EUR'];

    if (props.open === true && !initialised) {
        console.log('initialising.. ');
        setCurrency(debitNote.currency);
        setCreatedDate(debitNote.created_date);
        setInitialised(true);
    }


    const cancel = () => {
        props.onClose();
    };

    const confirm = () => {

        new Server().updateDebitNoteDetails(debitNote.number, currency, createdDate, (response) => {
            if (response.status === 200) {
                props.onConfirm(response.data);
            } else {
                alert('Error updating debit note: \n' + JSON.stringify(response.data));
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Debit note</DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>

                        <Autocomplete
                            options={currencies}
                            getOptionLabel={crncy => crncy}
                            value={currency}
                            onChange={(e, v, r) => {
                                setCurrency(v)
                            }}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>


                    <Grid item>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Created date"
                            format="DD/MM/YYYY"
                            value={createdDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setCreatedDate(date)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default EditDebitNoteDialog;