import Server from "./Server";

class AuthService {
    static isLoggedIn() {
        return localStorage.getItem("auth_id") !== null && localStorage.getItem("auth_id") !== '';
    }

    static isSupplierLoggedIn() {
        return localStorage.getItem("is_supplier") === "true" && this.isLoggedIn();
    }

    static isAdminLoggedIn() {
        return localStorage.getItem("is_supplier") === "false" && this.isLoggedIn();
    }

    static storeAuthDetails(id, name, is_supplier, permissions){
        localStorage.setItem('auth_id', id);
        localStorage.setItem('is_supplier', is_supplier);
        localStorage.setItem('auth_name', name);
        localStorage.setItem('auth_permissions', JSON.stringify(permissions));
    }

    static updatePermissions(permissions) {
        localStorage.setItem('auth_permissions', JSON.stringify(permissions));
    }

    static loggedInId() {
        return localStorage.getItem('auth_id');
    }

    static loggedInName() {
        return localStorage.getItem('auth_name');
    }

    static isUnauthorizedSupplier(supplierId) {
        return supplierId + '' !== AuthService.loggedInId()
    }

    static logout() {
        console.log('Logging out..');
        localStorage.removeItem("auth_id");
        localStorage.removeItem("is_supplier");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("auth_permissions");
    }

    /* permissions */
    static hasNoPermissions() {
        const permissions = JSON.parse(localStorage.getItem('auth_permissions'));
        return permissions === null || permissions === undefined || permissions.length === 0;
    }

    static includesPermission(resource, action) {
        const permissions = JSON.parse(localStorage.getItem('auth_permissions'));
        return permissions.filter((permission) => permission.resource === resource && permission.action === action).length >= 1;
    }

    static isDeveloper(){
        return window.location.host.indexOf('localhost') > -1;
    }

    static canViewPurchaseOrders() {
        return this.includesPermission('PURCHASE_ORDERS', 'VIEW');
    }

    static canViewShipments() {
        return this.includesPermission('SHIPMENTS', 'VIEW');
    }

    static canViewSuppliers() {
        return this.includesPermission('SUPPLIERS', 'VIEW');
    }

    static canViewProducts() {
        return this.includesPermission('PRODUCTS', 'VIEW');
    }

    static canViewDesignDocs() {
        return this.includesPermission('DESIGN_DOCUMENTS', 'VIEW');
    }

    static canViewSizeRatios() {
        return this.includesPermission('SIZE_RATIOS', 'VIEW');
    }

    static canViewTransitProducts() {
        return this.includesPermission('TRANSIT_PRODUCTS', 'VIEW');
    }

    static canViewConsolidatedPackages() {
        return this.includesPermission('CONSOLIDATED_PACKAGES', 'VIEW');
    }

    static canViewChannelsOverview() {
        return this.includesPermission('CHANNELS_OVERVIEW', 'VIEW');
    }

    static canViewChannelsTopSelling() {
        return this.includesPermission('CHANNELS_TOP_SELLING', 'VIEW');
    }

    static canViewChannelOrders() {
        return this.includesPermission('CHANNEL_ORDERS', 'VIEW');
    }

    static canViewDeliveries() {
        return this.includesPermission('CHANNEL_DELIVERIES', 'VIEW');
    }

    static canViewChannelVariants() {
        return this.includesPermission('CHANNEL_VARIANTS', 'VIEW');
    }

    static canViewStock() {
        return this.includesPermission('WAREHOUSES', 'VIEW');
    }

    static canViewReports() {
        return this.includesPermission('REPORTS', 'VIEW');
    }

    static canViewCustomers() {
        return this.includesPermission('CUSTOMERS', 'VIEW');
    }

    static canViewPorts() {
        return this.includesPermission('PORTS', 'VIEW');
    }

    static canViewUsers() {
        return this.includesPermission('USERS', 'VIEW');
    }

    static canViewZalandoProducts() {
        return this.includesPermission('ZALANDO_PRODUCTS', 'VIEW');
    }

    static canViewDebits() {
        return this.includesPermission('DEBITS', 'VIEW');
    }

    static canViewDestinations() {
        return this.includesPermission('DESTINATIONS', 'VIEW');
    }

}

export default AuthService;