import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table
} from '@material-ui/core';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {Autocomplete} from "@material-ui/lab";
import Utils from "../../Utils";
import DocumentFileUpload from "./DocumentFileUpload";


const useStyles = makeStyles(theme => ({
    root: {
        width: 350,
        padding: theme.spacing(2),
    },
}));

const DesignDocUpload = props => {

    const { match } = props;

    let { productCode } = match.params;

    const classes = useStyles();
    const history = useHistory();
    const uploadButtonRef = useRef();

    const types = ['TECH_PACK', 'SPEC_FILE', 'ART_WORK', 'MISC'];
    const [type, setType] = useState(types[0]);
    const [fileUploads, setFileUploads] = useState([]);

    const handleFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            uploadButtonRef.current.value = null;
            return;
        }

        if(isInvalidType()) {
            alert('Select the type of documents being uploaded.');
            return;
        }

        const newFileUploads = fileUploads.slice();
        let fileList = [];
        for (let i=0; i<files.length; i++) {
            console.log(files[i]);
            fileList.push(files[i]);
        }
        newFileUploads.push({id: Utils.randomInt(1000000), productCode: productCode, files: fileList, type: type});
        setFileUploads(newFileUploads);

        uploadButtonRef.current.value = null;
    };

    const isInvalidType = () => {
        return (!type || types.indexOf(type) === -1);
    };

    const UploadButton = () => (
        <Button variant="text" component="label" color="primary">
            <CloudUploadIcon  className={classes.buttonIcon} color="primary"/>
            upload
            <input
                type="file"
                ref={uploadButtonRef}
                style={{ display: "none" }}
                hidden
                multiple
                onChange={(e) => {handleFileSelection(e.target.files)}}
            />
        </Button>
    );

    return (
        <Card>
            <CardHeader title={"Designs for "+productCode}/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Select document(s) type.
                            <br/>
                            2 - Click upload.
                            <br/>
                            3 - Select all relevant files of the specified type and click OK.
                            <br/>
                            4 - Upload further document types by repeating steps above.
                            <br/>
                            - Upload may take a few minutes depending on file sizes and connection speed.
                            <br/>
                            - Do not close this window until all uploads are complete.
                            <br/>
                            - Uploaded documents will be immediately available to the relevant suppliers.
                            <br/>
                            - Uploaded documents will be overwritten if they have the same name.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" alignItems="center">

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={types}
                                    getOptionLabel={t => t}
                                    value={type}
                                    onChange={(e, v, r) => setType(v)}
                                    autoHighlight
                                    style={{width: 250}}
                                    renderInput={params => <TextField {...params} label="Document(s) type" variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <UploadButton/>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item>
                        <Table size="small" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small">File name</TableCell>
                                    <TableCell size="small">Type</TableCell>
                                    <TableCell size="small">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    fileUploads.map(
                                        (fileUpload, index) => (
                                            <DocumentFileUpload
                                                key={fileUpload.id}
                                                id={fileUpload.id}
                                                files={fileUpload.files}
                                                productCode={fileUpload.productCode}
                                                type={fileUpload.type}
                                            />
                                        )
                                    )

                                }
                            </TableBody>
                        </Table>
                    </Grid>



                </Grid>
            </CardContent>
        </Card>
    );
};

export default DesignDocUpload;