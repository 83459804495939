import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Server from "../../Server";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    redText: {
        color: theme.palette.red
    }
}));

const AddVariantDialog = props => {

    const productCode = props.productCode;

    const classes = useStyles();

    const [sizes, setSizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null);
    const [ean, setEan] = useState('');

    useEffect(() => {
        if (props.open) {
            new Server().fetchAvailableSizesForProduct(productCode, response => {
                setSizes(response.data);
            });
        }
    }, [props.open]);

    const confirm = () => {
        if (!selectedSize) {
            alert('Select a size!');
            return;
        }
        new Server().addProductVariant(productCode, selectedSize, ean, (response) => {
            if (response.status === 200) {
                props.onConfirm();
            } else {
                alert(response.data);
            }
        });
    };

    return (
        <Dialog open={props.open}>

            <DialogTitle disableTypography>
                <Typography variant="h4">Add variant</Typography>
            </DialogTitle>

            <DialogContent>

                <Grid container direction="column" spacing={1}>

                    {
                        sizes.length === 0 ?
                            <Grid item>
                                <Typography className={classes.redText}>WARNING: Cannot add variants for size group "None". Set the correct size group for this product first.</Typography>
                            </Grid>
                            : null
                    }


                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justifyContent="flex-start"
                        >
                            <Grid item>
                                <Autocomplete
                                    options={sizes}
                                    size="small"
                                    value={selectedSize}
                                    onChange={(e, v, r) => v && setSelectedSize(v)}
                                    autoHighlight
                                    fullWidth
                                    renderInput={params => <TextField {...params} label="Size" variant="outlined"/>}>
                                </Autocomplete>
                            </Grid>

                            <Grid item>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={ean}
                                    onChange={(e) => setEan(e.target.value)}
                                    label="Ean">
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <DialogActions>
                    <Button onClick={props.onClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={confirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );
};

export default AddVariantDialog;