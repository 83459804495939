import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import Pricing from "../../Pricing";
import DesignDialog from "../../PurchaseOrders/PoDetails/PoItems/DesignDialog";

const PoProductTable = props => {

    const po = props.po;
    const poProduct = props.poProduct;


    const totalQty = (sizeMap) => {
        let total = 0;
        for (let size in sizeMap) {
            total += sizeMap[size];
        }
        return total;
    };

    const DesignButton = (props) => {

        const [open, setOpen] = useState(false);

        return (
            <>
                <Button variant="text" component="label" color="primary" onClick={() => setOpen(true)}>
                    design
                </Button>
                <DesignDialog productCode={poProduct.product.code} open={open} onClose={() => setOpen(false)}/>
            </>
        )

    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {poProduct.product.code}
                            <DesignButton/>
                        </TableCell>

                        <TableCell colSpan={poProduct.sizes.length} align="left">
                            {Pricing.formatPrice(poProduct.price, po.currency)}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell></TableCell>
                        {
                            poProduct.sizes.map((size) => (
                                <TableCell key={size}>{size}</TableCell>
                            ))
                        }
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow>
                        <TableCell>Total</TableCell>
                        {
                            poProduct.sizes.map((size) => (
                                <TableCell key={size}>{poProduct.totals[size]}</TableCell>
                            ))
                        }
                        <TableCell> {totalQty(poProduct.totals)} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Shipped</TableCell>
                        {
                            poProduct.sizes.map((size) => (
                                <TableCell key={size}>{poProduct.shipped_qtys[size]}</TableCell>
                            ))
                        }
                        <TableCell>{totalQty(poProduct.shipped_qtys)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Oustanding</TableCell>
                        {
                            poProduct.sizes.map((size) => (
                                <TableCell key={size}>{poProduct.outstanding_qtys[size]}</TableCell>
                            ))
                        }
                        <TableCell>{totalQty(poProduct.outstanding_qtys)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PoProductTable;