import React, {useState} from 'react';
import {Button, Grid, Tooltip, Typography} from "@material-ui/core";
import LoadingScreen from "../../../LoadingScreen";
import Utils from "../../../Utils";
import Server from "../../../Server";

const Header = props => {
    const order = props.order;

    const [loading, setLoading] = useState(false);

    const updateStatus = (action) => {
        if (!Utils.isIn(action, ['acknowledge', 'dispatch', 'cancel', 'deliver'])) {
            alert('Invalid action ' + action);
            return;
        }

        if (action === 'cancel' && isTvgMultilineOrder()) {
            alert('WARNING TVG multi-line orders cannot be cancelled from portal. Please split and cancel this order on the BluJay portal.');
        }

        setLoading(true);
        new Server().updateOrderStatus(order.id, action, (response) => {
            setLoading(false);
            if (response.status !== 200) {
                alert('Error updating status \n' + JSON.stringify(response.data));
                return;
            }
            window.location.reload();
        });
    };

    const isTvgMultilineOrder = () => {
        return order.channel.code === 'TVG' && order.lines.length > 1;
    };

    const canAcknowledge = () => {
        return order.status === 'FORWARDED';
    };

    const canDispatch = () => {
        return order.status === 'READY_TO_DISPATCH';
    };

    const canCancel = () => {
        return order.status === 'ACKNOWLEDGED';
    };

    const canDeliver = () => {
        return Utils.isIn(order.status, ['DISPATCHED', 'DELIVERY_ERROR'])
    };

    const DispatchButton = () => (
        <Tooltip title="Dispatches order on channel">
            <Button
                variant="contained"
                onClick={() => updateStatus('dispatch')}
            >
                Dispatch
            </Button>
        </Tooltip>
    );

    const CancelButton = () => (
        <Tooltip title="Cancels order on channel">
            <Button
                variant="contained"
                onClick={() => updateStatus('cancel')}
            >
                Cancel
            </Button>
        </Tooltip>
    );

    const AckButton = () => (
        <Tooltip title="Confirms order on channel">
            <Button
                variant="contained"
                onClick={() => updateStatus('acknowledge')}
            >
                Acknowledge
            </Button>
        </Tooltip>
    );

    const DeliverButton = () => (
        <Tooltip title="Marks order as delivered">
            <Button
                variant="contained"
                onClick={() => updateStatus('deliver')}
            >
                Deliver
            </Button>
        </Tooltip>
    );

    return (
        <div>
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Order details
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {order.channel.code} {order.channel_order_number} {order.status}
                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            {canAcknowledge() ? <AckButton/> : null}
                        </Grid>
                        <Grid item>
                            {canCancel() ? <CancelButton/> : null}
                        </Grid>
                        <Grid item>
                            {canDispatch() ? <DispatchButton/> : null}
                        </Grid>
                        <Grid item>
                            {canDeliver() ? <DeliverButton/> : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default Header;