import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import Pricing from "../Pricing";

const POShipmentStats = props => {

    const useStyles = makeStyles(theme => ({
        item: {
            padding: theme.spacing(1.75),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                '&:not(:last-of-type)': {
                    borderRight: `1px solid ${theme.palette.divider}`
                }
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-of-type)': {
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        valueText: {
            fontWeight: "bold",
        },
        valueLabel: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const [stats, setStats] = useState({
        value: '0.00',
        cartons: '-',
        units: '-',
        gbpUsdRate: '-',
        gbpEurRate: '-',
    })

    useEffect(() => {
        new Server().fetchTransitPOShipmentsStats((response) => {
            console.log('fetching stats')
            setStats(response.data);
        });
    }, []);

    return (
        <Paper>
            <Grid container
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row">
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{Pricing.formatPrice(stats?.value, 'GBP')}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>in transit</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{stats?.cartons}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>cartons</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{stats?.units}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>units</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{stats?.GBP_EUR}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>EUR/GBP</Typography>

                    </div>
                </Grid>
                <Grid item className={classes.item}>
                    <div className={classes.valueContainer}>
                        <Typography variant="h5" className={classes.valueText}>{stats?.GBP_USD}</Typography>
                        <Typography variant="h5" className={classes.valueLabel}>USD/GBP</Typography>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default POShipmentStats;