import React from 'react';
import {Grid, Typography} from "@material-ui/core";

const Header = props => {
    return (
        <div>
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Overview
                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                </Grid>
            </Grid>

        </div>
    );
};

export default Header;