import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {
    Grid,
} from "@material-ui/core";

import Server from "../../Server";
import OrderInfo from "./OrderInfo";
import PoStats from "../PoStats";
import TestData from "../../TestData";
import PoDetailsHeader from "./PoDetailsHeader";
import PoProducts from "./PoItems/PoProducts";
import PoShipments from "./PoShipments";
import {PoDetailsProvider} from "./PoDetailsStore";
import Utils from "../../Utils";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
        marginTop: theme.spacing(2)
    }
}));

const PoDetails = props => {

    const {match} = props;

    let {poNumber} = match.params;

    const classes = useStyles();

    const [po, setPo] = useState(TestData.makePo());
    const [poProducts, setPoProducts] = useState([]);
    const server = new Server();

    useEffect(() => {document.title = poNumber;}, []);

    useEffect(() => {
        server.fetchPoProductSummary(poNumber, (response) => {
            if(response.status !== 200) {
                alert(response.data);
                return;
            }
            setPo(response.data.po);
            setPoProducts(response.data.po_product_summaries);
        });
    }, [poNumber]);


    const POItemsComponent = props => {
        if (po.items.length === 0) {
            return (<div>No items</div>)
        } else {//items have variants
            return (<PoProducts po={po}
                                poProducts={poProducts}
                                setPoProducts={setPoProducts}
            />)
        }
    };

    return (
        <PoDetailsProvider>
            <div className={classes.root}>

                <PoDetailsHeader po={po} setPo={setPo} poProducts={poProducts}/>

                <Grid
                    className={classes.poDetails}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >

                    {/*totals*/}
                    <Grid item>
                        <PoStats poNumber={poNumber}/>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid xs={3} item>
                                <Grid container direction="column" spacing={2}>

                                    <Grid item>
                                        <OrderInfo po={po} setPo={setPo}/>
                                    </Grid>

                                    {
                                        Utils.isPoWithVariants(po) ?
                                        <Grid item>
                                            <PoShipments poNumber={poNumber} po={po}/>
                                        </Grid>
                                        : null
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={9}>
                                <POItemsComponent/>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </div>
        </PoDetailsProvider>
    )
};

export default PoDetails;