import React, {useRef, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LoadingScreen from "../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import Utils from "../Utils";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const UploadDialog = props => {
    const classes = useStyles();

    const {open, onClose, onRatiosUploaded} = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const uploadInputRef = useRef();

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            uploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.xlsx')) {
            alert('An excel file ending in .xlsx is required.')
            return;
        }

        setLoading(true);
        new Server().uploadSizeRatiosExcel(file, (response) => {
            setLoading(false);
            if (response.status === 200) {
                enqueueSnackbar('Size ratios uploaded', {variant: 'success'});
                onRatiosUploaded();
                onClose();
            } else {
                uploadInputRef.current.value = null;
                enqueueSnackbar("Error (see console)", {variant: 'error'});
                alert(Utils.formatList(response.data, '\n'));
                console.log(response.data);
            }
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">Bulk upload size ratios</Typography></DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Download existing ratios
                            <br/>
                            2 - Add/update ratios
                            <br/>
                            3 - Upload updated ratios
                            <br/>
                            - Do not edit headers
                            <br/>
                            - Percentages need to be formatted as percentages in excel not text
                            <br/>
                            - Percentage symbol % must be included. Example percentage: 25.00%
                            <br/>
                            - Every row must have at least 1 percentage
                            <br/>
                            - New ratios will <b>overwrite all previous ratios</b>
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row">

                            <Grid item>

                            </Grid>

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload
                                    <input
                                        type="file"
                                        ref={uploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
};

export default UploadDialog;
