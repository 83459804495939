import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid} from "@material-ui/core";
import Filters from "./Filters"
import moment from "moment";
import OrderStatusStats from "./OrderStatus/OrderStatusStats";
import OrderVolumeMetrics from "./OrderVolume/OrderVolumeMetrics";
import Header from "./Header";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    poDetails: {
    }
}));

const ChannelsOverview = props => {
    const classes = useStyles();

    useEffect(() => {document.title = 'Channels overview';}, []);

    const thirtyDaysAgo = moment().subtract(30,'d')

    const channels = [
        {name: "All", code: "ALL"},
        {name: "John Lewis", code: "JLP"},
        {name: "Next", code: "NXT"},
        {name: "Very", code: "TVG"},
        {name: "Chelsea Peers UK", code: "SHU"},
        {name: "Zalando", code: "ZPP"},
        {name: "Faire", code: "FAR"},
    ];

    const [fromDate, setFromDate] = useState(thirtyDaysAgo);
    const [toDate, setToDate] = useState(new Date());
    const [selectedChannel, setSelectedChannel] = useState(channels[0]);

    return (
        <div className={classes.root}>

            <Header/>

            <Grid
                className={classes.poDetails}
                container
                direction="column"
                spacing={2}
            >

                <Grid item>
                    <Filters channels={channels}
                             selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
                             fromDate={fromDate} setFromDate={setFromDate}
                             toDate={toDate} setToDate={setToDate}
                             />
                </Grid>

                <Grid item>
                    <OrderStatusStats selectedChannel={selectedChannel} fromDate={fromDate} toDate={toDate}/>
                </Grid>

                <Grid item>
                    <OrderVolumeMetrics selectedChannel={selectedChannel} fromDate={fromDate} toDate={toDate}/>
                </Grid>

            </Grid>
        </div>
    );
}

export default ChannelsOverview;