import React from "react";
import {
    Card,
    CardContent,
    CardHeader, Checkbox,
    Divider,
    Grid,
    TextField,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {Autocomplete} from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const Info = props => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Card>
            <CardHeader title="Model info"/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={3}>

                    <Grid item xs={11}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item>
                                <TextField size="small" fullWidth label="Product code" variant="outlined"
                                           style={{width: 300}}
                                           value={props.productCode}
                                           onChange={(e) => props.setProductCode(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Name/title */}
                    <Grid item>
                        <TextField size="small" fullWidth label="Title" variant="outlined"
                        value={props.name} onChange={(e) => props.setName(e.target.value)}/>
                    </Grid>

                    {/* description */}
                    <Grid item>
                        <TextField size="small" fullWidth label="Description" variant="outlined"
                                   value={props.description} onChange={(e) => props.setDescription(e.target.value)}/>
                    </Grid>

                    {/* certificate */}
                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={props.certificates}
                            getOptionLabel={c => c.name}
                            value={props.certificate}
                            onChange={(e, v, r) => props.setCertificate(v)}
                            autoHighlight
                            // style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Sustainability tag" variant="outlined"/>}
                        />
                    </Grid>

                    {/* brands/seasons */}
                    <Grid item>
                        <Grid
                            container
                            spacing={3}
                        >

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.brands}
                                    getOptionLabel={brand => brand.name}
                                    value={props.brand}
                                    onChange={(e, v, r) => props.setBrand(v)}
                                    autoHighlight
                                    style={{width: 300}}
                                    renderInput={params => <TextField {...params} label="Brand"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.seasons}
                                    getOptionLabel={season => season.name}
                                    value={props.season}
                                    onChange={(e, v, r) => props.setSeason(v)}
                                    autoHighlight
                                    style={{width: 300}}
                                    renderInput={params => <TextField {...params} label="Season"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*outlines/sport/bodies*/}
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                        >

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.outlines}
                                    getOptionLabel={outline => outline}
                                    value={props.outline}
                                    onChange={(e, v, r) => props.setOutline(v)}
                                    autoHighlight
                                    style={{width: 300}}
                                    renderInput={params => <TextField {...params} label="Outline"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    limitTags={2}
                                    multiple
                                    options={props.sportOptions}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, v, r) => props.setSports(v)}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                size="small"
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Sports (optional)"  />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    {/*color/supplier color*/}
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={props.colors}
                                    getOptionLabel={color => color.name}
                                    value={props.color}
                                    onChange={(e, v, r) => props.setColor(v)}
                                    autoHighlight
                                    style={{width: 300}}
                                    renderInput={params => <TextField {...params} label="Color"
                                                                      variant="outlined"/>}
                                />
                            </Grid>

                            <Grid item>
                                <TextField size="small" fullWidth label="Supplier color" variant="outlined"
                                           style={{width: 300}}
                                           value={props.supplierColor}
                                           onChange={(e) => props.setSupplierColor(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*genders/ages/bodies*/}
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item>
                                <ToggleButtonGroup size="small" value={props.genders}
                                                   onChange={(e, v) => props.setGenders(v)}
                                                   aria-label="text formatting">
                                    <ToggleButton value="target_gender_male" aria-label="male">
                                        male
                                    </ToggleButton>
                                    <ToggleButton value="target_gender_female" aria-label="female">
                                        female
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>

                            <Grid item>
                                <ToggleButtonGroup size="small" value={props.ages} onChange={(e, v) => props.setAges(v)}
                                                   aria-label="text formatting">
                                    <ToggleButton value="target_age_group_adult" aria-label="adult">
                                        adult
                                    </ToggleButton>
                                    <ToggleButton value="target_age_group_teen" aria-label="teen">
                                        teen
                                    </ToggleButton>
                                    <ToggleButton value="target_age_group_kid" aria-label="kid">
                                        kid
                                    </ToggleButton>
                                    <ToggleButton value="target_age_group_baby" aria-label="baby">
                                        baby
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>

                            <Grid item>
                                <ToggleButtonGroup
                                    size="small" value={props.bodies}
                                    onChange={(e, v) => props.setBodies(v)}
                                >
                                    {
                                        props.bodyOptions.map((bodyOption) => (
                                            <ToggleButton key={bodyOption.code} value={bodyOption.code}>
                                                {bodyOption.name}
                                            </ToggleButton>
                                        ))
                                    }
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default Info;