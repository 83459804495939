import React from 'react';
import {Card, CardMedia, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ShipmentProductTable from "./ShipmentProductTable";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        cover: {
            width: 150,
        },
    }
));

const ShipmentProduct = props => {

    const classes = useStyles();

    const shipmentProduct = props.shipmentProduct;
    const shipment = props.shipment;

    const getMainImageUrl = () => {
        const url = shipmentProduct.image_url;
        return url || '/images/no-image.svg';
    };

    return (
        <Card className={classes.root}>
            <div className={classes.productContainer}>

                <CardMedia
                    className={classes.cover}
                    component="img"
                    image={getMainImageUrl()}
                    title={shipmentProduct.product.code}
                />

            </div>
            <div className={classes.details}>
                <ShipmentProductTable shipment={shipment} shipmentProduct={shipmentProduct}/>
            </div>
        </Card>
    );
};

export default ShipmentProduct;