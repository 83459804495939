import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, Typography} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {

    const classes = useStyles();

    return (
        <div>
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Generic report
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default Header;