import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import MaterialTable from "material-table";
import TelegramIcon from "@material-ui/icons/Telegram";
import Server from "../Server";
import Utils from "../Utils";
import NewDebitDocuments from "./NewDebitDocuments";

const NewDebit = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4),
        },
        details: {
            display: 'flex'
        },
        poDetails: {
            marginTop: theme.spacing(3)
        },
        itemInputs: {
            display: 'flex',
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const history = useHistory();

    const [suppliers, setSuppliers] = useState([]);

    const [documents, setDocuments] = useState([]);

    const [newDebitNoteNumber, setNewDebitNoteNumber] = useState('DEB-XYZ');
    const [selectedSupplier, setSupplier] = useState('');
    const [selectedCurrency, setCurrency] = useState('GBP');
    const [createdDate, setCreatedDate] = useState(new Date());
    const [items, setItems] = useState([]);

    // item inputs
    //ORDER NUMBER	REASON	AMOUNT TO DEBIT
    const [itemDescription, setItemDescription] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [reason, setReason] = useState('');
    const [amount, setAmount] = useState(0);

    const itemDescriptionElement = useRef();

    useEffect( () => {
        new Server().fetchSuppliers((response) => {
            setSuppliers(response.data)
        });
        new Server().fetchNewDebitNoteNumber((response) => {
            setNewDebitNoteNumber(response.data)
        });
    }, []);


    const clearItemFields = () => {
        setItemDescription('');
        setOrderNumber('');
        setReason('');
        setAmount(0);
    };

    const addItem = (e) => {
        if(itemDescription === '' || orderNumber === '' || reason === '' || amount === 0){
            alert('All fields are required.');
            return;
        }
        setItems(items => {
            return items.concat({
                item_description: itemDescription,
                order_number: orderNumber,
                reason: reason,
                amount: amount,
            });
        });

        clearItemFields();

        itemDescriptionElement.current.focus();
    };

    const submitDebitNote = () => {

        if(items.length === 0) {
            alert('Add some items!');
            return;
        }
        if(selectedSupplier === '') {
            alert('Select a supplier');
            return;
        }

        if(!Utils.isValidDebitNumber(newDebitNoteNumber)) {
            alert('Invalid format. Expected format is DEB-ddd where d is a digit.');
            return;
        }

        console.log('supplier: ----> ' + selectedSupplier);
        let data = {
            number: newDebitNoteNumber,
            supplier: selectedSupplier,
            created_date: createdDate,
            currency: selectedCurrency,
            items: items
        };
        new Server().newDebitNote(data, (response) => {
            if(response.status === 200) {
                uploadDocuments(response.data.number);
            } else {
                alert('Error creating debit note. \nError: '+JSON.stringify(response.data, null, 2));
            }
        });
    };

    const uploadDocuments = (number) => {
        for(var i=0; i<documents.length; i++) {
            new Server().uploadDebitNoteDocument(number, documents[i], (response) => {});
        }
        alert('Debit note successfully created..');
        history.push("/debits");
    };

    const SubmitButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={submitDebitNote}
        >
            <TelegramIcon className={classes.buttonIcon} />
            Submit
        </Button>
    );

    const onAddDocuments = (files) => {
        setDocuments((oldDocuments) => {
            var newDocuments = oldDocuments.slice();
            for (var i = 0; i < files.length; i++) {
                console.log('onadddocument ' + files[i].name);
                newDocuments.push(files[i]);
            }
            return newDocuments;
        });
    };

    const onRemoveDocument = (file) => {
        console.log('onremovedocument ' + file.name);
        setDocuments((oldDocuments) => {
                return oldDocuments.filter(doc => doc.name !== file.name)
            }
        );
    };

    return (
        <div className={classes.root}>


            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        New Debit Note
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {newDebitNoteNumber}
                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <SubmitButton/>
                </Grid>
            </Grid>




            <Grid
                className={classes.poDetails}
                alignItems="flex-start"
                container
                justifyContent="space-between"
                spacing={3}
            >

                <Grid xs={2} item>

                    <Grid container direction="column" spacing={3}>
                        <Grid item>

                            <Card>
                                <CardContent>
                                    <div className={classes.details}>
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item xs
                                            >
                                                <TextField fullWidth label="Debit note number" variant="outlined"
                                                           value={newDebitNoteNumber}
                                                           onChange={(e) => setNewDebitNoteNumber(e.target.value)}/>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <FormControl fullWidth variant="outlined"
                                                             className={classes.formControl}>
                                                    <InputLabel id="supplier-label">Supplier</InputLabel>
                                                    <Select
                                                        labelId="supplier-label"
                                                        id="supplier-select"
                                                        value={selectedSupplier}
                                                        onChange={(e) => setSupplier(e.target.value)}
                                                        label="Supplier"
                                                    >
                                                        {suppliers.map((supplier, index) => (
                                                            <MenuItem key={index}
                                                                      value={supplier}>{supplier.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                            >
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="Created"
                                                    format="DD/MM/YYYY"
                                                    value={createdDate}
                                                    InputAdornmentProps={{position: "start"}}
                                                    onChange={date => setCreatedDate(date)}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs>
                                                <FormControl fullWidth variant="outlined"
                                                             className={classes.formControl}>
                                                    <InputLabel id="currency-label">Currency</InputLabel>
                                                    <Select
                                                        labelId="currency-label"
                                                        id="currency-select"
                                                        value={selectedCurrency}
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                        label="Currency"
                                                    >
                                                        <MenuItem value={'GBP'}>GBP</MenuItem>
                                                        <MenuItem value={'USD'}>USD</MenuItem>
                                                        <MenuItem value={'EUR'}>EUR</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <NewDebitDocuments documents={documents} onAddDocuments={onAddDocuments} onRemoveDocument={onRemoveDocument}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={10} item>
                    <div className={classes.details}>

                        <Grid
                            container
                            spacing={3}
                        >

                            <Grid item
                                  xs={12} className={classes.itemInputs}>
                                <Card>
                                    <CardContent>

                                        {/*ITEM INPUTS*/}

                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            // justifyContent="space-evenly"
                                            alignItems="flex-start"
                                        >
                                            {/*//item description ORDER NUMBER	REASON	AMOUNT TO DEBIT*/}
                                            <Grid item xs={3}>
                                                <TextField fullWidth inputRef={itemDescriptionElement} label="Item" variant="outlined" value={itemDescription}
                                                           onChange={(e) => setItemDescription(e.target.value)}/>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <TextField label="Order number" variant="outlined" value={orderNumber}
                                                           onChange={(e) => setOrderNumber(e.target.value)}/>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField fullWidth label="Reason" variant="outlined" value={reason}
                                                           onChange={(e) => setReason(e.target.value)}/>
                                            </Grid>

                                            <Grid item xs={1}>
                                                <TextField label="Amount" variant="outlined" value={amount}
                                                           onChange={(e) => setAmount(e.target.value)}/>
                                            </Grid>


                                            <Grid item xs={2}>

                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={addItem}

                                                >add item
                                                </Button>
                                            </Grid>


                                        </Grid>



                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item
                                  xs={12}>
                                <MaterialTable
                                    columns={[
                                        {title: 'Item', field: 'item_description', cellStyle: {width: '30%'}},
                                        {title: 'Order number', field: 'order_number', cellStyle: {width: '20%'}},
                                        {title: 'Reason', field: 'reason', cellStyle: {width: '30%'}},
                                        {title: 'Amount', field: 'amount' , cellStyle: {width: '10%'}},
                                    ]}
                                    options={
                                        {
                                            paging: false,
                                            search: false,
                                            showTitle: false,
                                            toolbar: false,
                                            actionsColumnIndex: 5
                                        }
                                    }
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'No items'
                                        }

                                    }}
                                    data={
                                        items
                                    }
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    const index = items.indexOf(oldData);
                                                    console.log(JSON.stringify(newData, null, 2));
                                                    setItems(items => {
                                                        return items.map((currentItem, j) => {
                                                            if(j === index) {
                                                                return newData
                                                            } else {
                                                                return currentItem
                                                            }
                                                        })
                                                    });

                                                    resolve();
                                                }, 1000)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    const index = items.indexOf(oldData);
                                                    setItems(items => {
                                                        return items.filter((currentItem, j) =>
                                                            j !== index
                                                        )
                                                    });
                                                    resolve();
                                                }, 1000)
                                            }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>


        </div>
    )
};

export default NewDebit;