import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router-dom";

import MaterialTable from 'material-table'
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import Server from './Server'
import TestData from "./TestData";

const Ports = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3),
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const [ports, setPorts] = useState(TestData.makePorts(3));

    useEffect( () => {
        new Server().fetchPorts((response) => {
            setPorts(response.data)
        })
    }, [])

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Ports
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                className={classes.poTable}
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
            >

                <Grid item xs={6}>

                <MaterialTable
                    columns={[
                        {title: 'Name', field: 'name'},
                        {title: 'Country', field: 'country'},
                        {title: 'Duty value', field: 'duty_value', type: 'numeric'},
                    ]}
                    data={
                        ports
                    }
                    options={
                        {
                            paging: false,
                            showTitle: false,
                            search: false,
                            actionsColumnIndex: 3
                        }
                    }
                    actions={[]}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    new Server().newPort(newData, (response) => {
                                        setPorts(ports => {
                                            return ports.concat(response.data)
                                        })
                                    })
                                    resolve()
                                }, 1000)
                            }),

                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    new Server().updatePort(newData, () => {
                                        const data = ports;
                                        const index = data.indexOf(oldData);

                                        setPorts(ports => {
                                            return ports.map((currentPort, j) => {
                                                if(j === index) {
                                                    return newData
                                                } else {
                                                    return currentPort
                                                }
                                            })
                                        })
                                    })
                                    resolve()
                                }, 100)
                            }),
                    }}
                />
                </Grid>
            </Grid>

        </div>
    )
}

export default Ports
