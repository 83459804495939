import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, {useState} from "react";
import EditQAReportDialog from "./EditQAReportDialog";
import Utils from "../../Utils";

const EditQAReportButton = props => {
    const reportId = props.reportId;
    const onReportEdited = props.onReportEdited;
    const [open, setOpen] = useState(false);

    const onCloseDialog = (event, reason) => {
        if(Utils.isIn(reason, ["escapeKeyDown", "backdropClick"])) {
            return;
        }
        setOpen(false);
    };

    const onConfirmDialog = () => {
        setOpen(false);
        onReportEdited();
    };

    return (
        <>
            <IconButton size="small" color="default" onClick={() => setOpen(true)}>
                <EditIcon size="small"/>
            </IconButton>
            <EditQAReportDialog reportId={reportId} open={open} onClose={onCloseDialog}
                                onConfirm={onConfirmDialog}/>
        </>
    )
};

export default EditQAReportButton;