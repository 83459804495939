import {Card} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import Server from "../../Server";
import Pricing from "../../Pricing";
import moment from "moment";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import {makeStyles} from "@material-ui/styles";

const ShipmentsTabContent = props => {

    const useStyles = makeStyles(theme => ({
        NumberCell : { cursor: 'pointer'}
    }));

    const classes = useStyles();

    const product = props.product;

    const [productShipments, setProductShipments] = useState([]);

    const history = useHistory();
    const nav = new NavigationService(history);

    useEffect( () => {
        if (product.code !== 'ABC-123') {
            new Server().fetchProductShipments(product.code, (response) => {
                if(response.status !== 200) {
                    alert('Error occured:' + JSON.stringify(response.data, null, 2)); 
                    return;
                }
                setProductShipments(response?.data);
            });
        }
       
    }, [product]);

    const getEtaOrReceivedDate = (shipment) => { 
        if (shipment.status === 'RECEIVED') {
            return moment(shipment.date_received).format('DD/MM/YYYY');
        }
        return '-';
    };

    return (
        <Card hidden={props.hidden}>
            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Received/ETA</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            productShipments.length === 0?
                                <TableRow>
                                    <TableCell colSpan={6}>No shipments to show</TableCell>
                                </TableRow>: null
                        }
                        {productShipments.map((shipment, index)=>(
                                <TableRow key={index}>
                                    <TableCell onClick={(e)=> nav.goToPoShipmentDetails(shipment.shipment_number)} className={classes.NumberCell}>{shipment.shipment_number}</TableCell>
                                    <TableCell>{shipment.supplier_name}</TableCell>
                                    <TableCell>{shipment.units_shipped}</TableCell>
                                    <TableCell>{Pricing.formatPrice(shipment.value, shipment.currency)}</TableCell>
                                    <TableCell>{shipment.status}</TableCell>
                                    <TableCell>{getEtaOrReceivedDate(shipment)}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default ShipmentsTabContent;