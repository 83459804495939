import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {},

}));

const Header = props => {

    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Production report
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;