import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, Typography, Dialog, DialogActions, DialogTitle, DialogContent} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const EditImagesDialog = props => {
    const useStyles = makeStyles(theme => ({
        image:{
            width: 100
        },
        removeIcon: {
            position: "absolute",
        },
        addImageContainer: {
            position: "relative",
            height: "100%",
            minHeight: "70px",
            width: "50px",
            // backgroundColor:'#3f51b5'
        },
        addImageIcon: {
            position: "absolute",
            top: "40%",
            left: "25%",
        }
    }));
    const classes = useStyles();

    const [code, setCode] = useState('');
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);

    const inputRefs = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const newImageInputRef = useRef();

    useEffect(() => {
        if(props.open === true) {
            setImages(JSON.parse(JSON.stringify(props.product.images)));
            setCode(props.product.code);
        }

    }, [props.open]);

    const confirm = () => {
        let newImagesToUpload = [];
        let newImageUrls = [];
        images.map(image => {
            if (image.file !== null && image.file !== undefined) {
                newImagesToUpload.push(image.file);
                newImageUrls.push('no url');
            } else {
                newImageUrls.push(image.url);
            }
        });
        setUploading(true);
        new Server().updateProductImages(code, newImagesToUpload, newImageUrls, (response) => {
            setUploading(false);
            if (response.status === 200) {
                props.onConfirm();
            } else {
                alert('Error updating images: \n' + JSON.stringify(response.data));
            }
        });
    }

    const onNewImageFileSelected = (event, index) => {

        const file = event.target.files[0];

        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            let newImages = [...images];
            if(index === -1) { //add new image
                newImages.push({
                    url: reader.result,
                    file: file,
                    index: images.length + 1
                });
            } else { //update existing image
                newImages[index].url = reader.result;
                newImages[index].file = file;
            }

            setImages(newImages);
        }

    };

    const showRemoveIcon = (index) => {
        return index > 0;
    };

    const removeImage = (index) => {
        let newImages = [];
        for(let i=0; i< images.length; i++) {
            if(i!==index) {
                newImages.push(images[i]);
            }
        }
        setImages(newImages);
    };

    return (
        <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle><Typography component="h1" variant="h4">Edit images</Typography></DialogTitle>

            <DialogContent>

                <Grid container direction="row" spacing={1}>

                    {
                        images.map((image, index) => (
                                <Grid item key={index}>
                                    <div>
                                        <input
                                            type="file"
                                            ref={inputRefs[index]}
                                            style={{display: "none"}}
                                            onChange={(e) => onNewImageFileSelected(e, index)}
                                        />
                                        {showRemoveIcon(index) ? <HighlightOffIcon  className={classes.removeIcon} onClick={(e) => removeImage(index)}/> : null}
                                       <img src={image.url} className={classes.image} onClick={()=>inputRefs[index].current.click()}/>
                                    </div>
                                </Grid>
                        )
                        )
                    }

                    <Grid item>
                        <div className={classes.addImageContainer}>
                            <input
                                type="file"
                                ref={newImageInputRef}
                                style={{display: "none"}}
                                onChange={(e) => onNewImageFileSelected(e, -1)}
                            />
                            <AddCircleIcon className={classes.addImageIcon} onClick={()=>newImageInputRef.current.click()}/>
                            {/*<img src='/images/no-image.svg' className={classes.image} onClick={()=>newImageInputRef.current.click()}/>*/}
                        </div>
                    </Grid>

                </Grid>

                {uploading ? <Typography>Uploading.. please wait.</Typography> : null}

            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditImagesDialog;