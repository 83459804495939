import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import LoadingScreen from "../../LoadingScreen";
import Header from "./Header";
import Filters from "./Filters";
import TopSellingTable from "./TopSellingTable";
import Server from "../../Server";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const TopSelling = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const channels = [
        {name: "All", code: "ALL"},
        {name: "Chelsea Peers UK", code: "SHU"},
        {name: "John Lewis", code: "JLP"},
        {name: "Next", code: "NXT"},
        {name: "Very", code: "TVG"},
        {name: "Zalando", code: "ZPP"},
        {name: "Faire", code: "FAR"},
    ];

    const resultCountOptions = [
        {name: "10", value: 10},
        {name: "25", value: 25},
        {name: "50", value: 50},
        {name: "All", value: -1}
    ];

    const yesterdayStart = moment().subtract(1, 'd').set({hour:0, minute: 0, second: 0, millisecond:0});
    const yesterdayEnd = moment().subtract(1, 'd').set({hour:23, minute: 59, second: 59, millisecond:999});
    const [fromDate, setFromDate] = useState(yesterdayStart);
    const [toDate, setToDate] = useState(yesterdayEnd);
    const [selectedChannel, setSelectedChannel] = useState(channels[0]);
    const [selectedResultCount, setSelectedResultCount] = useState(resultCountOptions[0]);

    const [products, setProducts] = useState([]);

    useEffect(() => {document.title = 'Top selling';}, []);

    useEffect(() => {
        fetchProducts("ALL", yesterdayStart, yesterdayEnd, 10);
    }, []);

    const fetchProducts = (selectedChannelCode, fromDate, toDate, selectedResultCountValue) => {
        setLoading(true);
        new Server().fetchChannelTopSellingProducts(selectedChannel.code, fromDate, toDate, selectedResultCount.value, (response) => {
            setLoading(false);
            setProducts(response.data);
        });
    };

    const onApplyFilters = () => {
        fetchProducts(selectedChannel.code, fromDate, toDate, selectedResultCount.value);
    };

    const onClearFilters = () => {
        setFromDate(yesterdayStart);
        setFromDate(yesterdayEnd);
        setSelectedChannel(channels[0]);
        setSelectedResultCount(resultCountOptions[0]);
        fetchProducts(selectedChannel.code, fromDate, toDate, selectedResultCount.value);
    };

    return (
        <div className={classes.root}>

            <Header
                channels={channels} selectedChannel={selectedChannel}
                fromDate={fromDate}
                toDate={toDate}
                selectedResultCount={selectedResultCount}
            />

            <Filters
                channels={channels} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
                fromDate={fromDate} setFromDate={setFromDate}
                toDate={toDate} setToDate={setToDate}
                resultCountOptions={resultCountOptions} selectedResultCount={selectedResultCount} setSelectedResultCount={setSelectedResultCount}
                onApplyFilters={onApplyFilters} onClearFilters={onClearFilters}
            />

            <TopSellingTable
                className={classes.conversationPlaceholder}
                products={products}
            />

            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default TopSelling;