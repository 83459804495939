import React from 'react';
import {makeStyles} from "@material-ui/styles";
import DeliveryReportHeader from "./DeliveryReportHeader";
import {Grid} from "@material-ui/core";
import DeliveryReportByDispatchDateCard from "./DeliveryReportByDispatchDateCard";
import DeliveryReportByOrderDateCard from "./DeliveryReportByOrderDateCard";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
}));

const DeliveryReport = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <DeliveryReportHeader/>
                </Grid>

                <Grid item xs={8}>
                    <DeliveryReportByDispatchDateCard/>
                </Grid>
                <Grid item xs={8}>
                    <DeliveryReportByOrderDateCard/>
                </Grid>
            </Grid>

        </div>
    );
}

export default DeliveryReport;