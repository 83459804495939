import React, {useState} from 'react';
import {Card, CardHeader, Table, TableBody, TableCell, TableRow, Link, IconButton, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditTrackingDialog from "./EditTrackingDialog";

const Tracking = props => {

    const order = props.order;

    const[openEditTrackingDialog, setOpenEditTrackingDialog] = useState(false);

    const get_tracking_url = () => {
        const courierCode = order.tracking.courier.code;
        const number = order.tracking.number;
        const postcode = order.shipping_address.postcode.trim().replaceAll(' ', '');

        if (courierCode === 'YODEL') {
            return `https://www.yodel.co.uk/tracking/${number}/${postcode}?headless=true`;
        }

        if (courierCode === 'DPD-UK') {
            return `https://www.dpd.co.uk/apps/tracking/?reference=${number}`;
        }

        return 'https://www.google.com'
    };

    const editTracking = () => {
        setOpenEditTrackingDialog(true);
    };


    return (
        <Card>
            <CardHeader title="Tracking" action={
                <Tooltip title="Edit tracking details">
                    <IconButton onClick={(e)=>{editTracking()}}>
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
            }/>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Carrier: {order.tracking.courier.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Number: {order.tracking.number}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Description: {order.tracking.shipping_description}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Link href={get_tracking_url()} rel="noopener noreferrer" target="_blank">
                                <span>
                                    {order.tracking.courier.name} tracking page
                                </span>
                            </Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <EditTrackingDialog open={openEditTrackingDialog} onClose={() => setOpenEditTrackingDialog(false)} order={order}/>
        </Card>
    );
};

export default Tracking;