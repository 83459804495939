import {Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Server from "../../../Server";
import {PODetailsContext} from "../PoDetailsStore";
import EditablePrice from "./EditablePrice";
import DeleteIcon from "@material-ui/icons/Delete";
import Utils from "../../../Utils";
import DesignDialog from "./DesignDialog";

const useStyles = makeStyles((theme) => ({
    editable: {
        maxWidth: '50px',
        borderWidth:'0px',
        border:'none',
    },
}));

const PoProductTable = props => {
    const classes = useStyles();

    const po = props.po;
    const [poProduct, setPoProduct] = useState(props.poProduct)
    const {state, dispatch} = useContext(PODetailsContext);

    const server = new Server();

    const totalQty = (sizeMap) => {
        let total = 0;
        for (let size in sizeMap) {
            total += sizeMap[size];
        }
        return total;
    };

    const editPrice = (newPrice) => {
        server.updatePoProductPrice(po.number, poProduct.product.code, newPrice, (response) => {
            if(response.status === 200) {
                let newPoProduct = JSON.parse(JSON.stringify(poProduct));
                newPoProduct.price = newPrice;
                setPoProduct(newPoProduct);

                dispatch({type: 'RELOAD_PO_STATS'});
            } else {
                alert('Error: \n'+JSON.stringify(response.data, null, 2));
            }
        })
    };

    const deletePOProduct = (poproduct) => {
      server.deletePOProduct(po.number, poProduct.product.code, (response) =>{
          if(response.status === 200) {
              window.location.reload();
          } else {
              alert(response.data);
          }
      })
    };

    const DesignButton = (props) => {

        const [open, setOpen] = useState(false);

        return (
            <>
                <Button variant="text" component="label" color="primary" onClick={() => setOpen(true)}>
                    design
                </Button>
                <DesignDialog productCode={poProduct.product.code} open={open} onClose={() => setOpen(false)}/>
            </>
        )

    };

    return (
        <TableContainer>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        {poProduct.product.code}
                        <DesignButton/>
                    </TableCell>

                    <TableCell colSpan={poProduct.sizes.length} align="left">
                        <EditablePrice classEditing={classes.editable}
                                       amount={poProduct.price}
                                       currency={po.currency}
                                       onChange={(newAmount) => editPrice(newAmount)}
                        />

                    </TableCell>

                    <TableCell align="right">
                        <IconButton size="small" disabled={!Utils.isIn(po.status, ['DRAFT', 'RAISED'])} onClick={() => deletePOProduct(poProduct)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell></TableCell>
                    {
                        poProduct.sizes.map((size) => (
                            <TableCell key={size}>{size}</TableCell>
                        ))
                    }
                    <TableCell>Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Total</TableCell>
                    {
                        poProduct.sizes.map((size) => (
                            <TableCell key={size}>{poProduct.totals[size]}</TableCell>
                        ))
                    }
                    <TableCell> {totalQty(poProduct.totals)} </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Shipped</TableCell>
                    {
                        poProduct.sizes.map((size) => (
                            <TableCell key={size}>{poProduct.shipped_qtys[size]}</TableCell>
                        ))
                    }
                    <TableCell>{totalQty(poProduct.shipped_qtys)}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Oustanding</TableCell>
                    {
                        poProduct.sizes.map((size) => (
                            <TableCell key={size}>{poProduct.outstanding_qtys[size]}</TableCell>
                        ))
                    }
                    <TableCell>{totalQty(poProduct.outstanding_qtys)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export default PoProductTable;