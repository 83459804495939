import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Server from "../../Server";

const LinesheetReportHeader = props => {

    const classes = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }))();

    const linesheet = props.linesheet;

    const downloadExcel = () => {
        new Server().downloadLRExcel(linesheet);
    };

    const DownloadExcelButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={downloadExcel}
        >
            <CloudDownloadIcon className={classes.buttonIcon}/>
            Download Excel
        </Button>
    );

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Linesheet Report
                </Typography>

            </Grid>

            <Grid item>
                <DownloadExcelButton/>
            </Grid>
        </Grid>
    );

};

export default LinesheetReportHeader;