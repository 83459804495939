import React from 'react';
import {Card, CardHeader, Table, TableBody, TableCell, TableRow} from "@material-ui/core";

const Customer = props => {
    const customer = props.customer;

    return(
        <Card>
            <CardHeader title="Customer"/>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Name: {customer.full_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone: {customer.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email: {customer.email}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default Customer;