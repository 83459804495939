import {makeStyles} from "@material-ui/styles";
import {
    Card, Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import POStatsDialog from "./POStatsDialog";
import NavigationService from "../NavigationService";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const PosTable = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },

    }));
    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);
    const pos = props.pos;

    const [statsPONumber, setStatsPoNumber] = useState('');
    const [openStatsDialog, setOpenStatsDialog] = useState(false);

    const onPoRowClicked = (poNumber, newBrowserTab) => {
        nav.goToPoDetails(poNumber, newBrowserTab);
    };

    const showStatsDialog = (e, poNumber) => {
        e.stopPropagation();
        setStatsPoNumber(poNumber);
        setOpenStatsDialog(true);
    };

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Issued</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            pos.map((po, index) => (
                                <TableRow key={index} onClick={(e)=> onPoRowClicked(po.number, e.metaKey)}>
                                    <TableCell>{po.number}</TableCell>
                                    <TableCell>{po.supplier.name}</TableCell>
                                    <TableCell>{moment(po.issue_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(po.exit_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>
                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                            <Grid item>{po.status}</Grid>
                                            <Grid item>
                                                <InfoOutlinedIcon fontSize="small" onClick={(e)=>showStatsDialog(e, po.number)}/>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <POStatsDialog open={openStatsDialog} poNumber={statsPONumber} onClose={()=>setOpenStatsDialog(false)}/>
        </Card>
    );

};

export default PosTable;