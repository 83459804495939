import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditVariantDialog from "./EditVariantDialog";

const EditVariantButton = (props) => {
    const variantId = props.variantId;
    const productCode = props.productCode;
    const onConfirm = props.onConfirm;

    const [open, setOpen] = useState(false);

    const confirm = () => {
        setOpen(false);
        onConfirm();
    };

    return (
        <>
            <IconButton size="small" color="default" onClick={() => setOpen(true)}>
                <EditIcon size="small"/>
            </IconButton>
            <EditVariantDialog productCode={productCode} variantId={variantId} open={open} onClose={() => setOpen(false)}
                               onConfirm={confirm}/>
        </>
    );
};
export default EditVariantButton;