import React, {useEffect, useState} from 'react';
import Server from "../../Server";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableRow,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AddSizesDialog = props => {

    const po = props.po;

    const [productsState, setProductsState] = useState([]);

    useEffect(() => {
        if(props.open) {
            new Server().getPOProductsSizes(po.number, (response) => {
               if(response.status !== 200) {
                   alert(response.data);
                   return
               }
               let products = response.data;
               for(let i=0; i<products.length; i++) {
                   products[i].selected_size = null;
               }
               setProductsState(products);
            });
        }
    }, [props.open]);


    const confirm = () => {
        console.log(productsState);

        let data = [];
        for(let i=0; i< productsState.length; i++) {
            if(productsState[i].selected_size !== null) {
                data.push({
                    code: productsState[i].code,
                    selected_size: productsState[i].selected_size
                });
            }
        }
        new Server().addPOProductSizes(po.number, data, (response) => {
            if(response.status === 200) {
                window.location.reload();
            } else {
                alert(response.data);
            }
        });
    };

    const setSelectedSize = (productCode, newSize) => {
        let newProductsState = JSON.parse(JSON.stringify(productsState));
        for(let i=0; i<newProductsState.length; i++) {
            if(newProductsState[i].code === productCode) {
                newProductsState[i].selected_size = newSize;
            }
        }
        setProductsState(newProductsState);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Add sizes</Typography></DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableBody>

                            {
                                productsState.map((productState) => (
                                    <TableRow key={productState.code}>
                                        <TableCell>{productState.code}</TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                size="small"
                                                options={productState.sizes}
                                                value={productState.selected_size}
                                                onChange={(e, v, r) => setSelectedSize(productState.code, v)}
                                                autoHighlight
                                                renderInput={params => <TextField {...params} label="Size"
                                                                                  variant="outlined"/>}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AddSizesDialog;