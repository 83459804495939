import {Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Server from "../../Server";
import Utils from "../../Utils";
import DesignDialog from "../../PurchaseOrders/PoDetails/PoItems/DesignDialog";
import Pricing from "../../Pricing";
import AuthService from "../../AuthService";

const useStyles = makeStyles((theme) => ({
    editable: {
        maxWidth: '50px',
        borderWidth:'0px',
        border:'none',
    },
    clickable: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

const POTable = props => {
    const classes = useStyles();

    const po = props.po;
    const pkgNum = props.pkgNum;

    const server = new Server();

    const totalQty = (sizeMap) => {
        let total = 0;
        for (let size in sizeMap) {
            total += sizeMap[size];
        }
        return total;
    };

    const deletePOProduct = (poproduct) => {
        const confirmation = window.confirm('Delete forever?')
        if (!confirmation){
            return;
        }

        server.deletePackagePO(pkgNum, po.number, (response) =>{
            if(response.status === 200) {
                window.location.reload();
            } else {
                alert(response.data);
            }
        })
    };

    const DesignButton = (props) => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <Button variant="text" component="label" color="primary" onClick={() => setOpen(true)}>
                    design
                </Button>
                <DesignDialog productCode={po.product_code} open={open} onClose={() => setOpen(false)}/>
            </>
        )
    };

    const canDeletePO = () => {
        return Utils.isIn(po.status, ['DRAFT']) && AuthService.isAdminLoggedIn();
    };

    const ExitText = (props) => {

        const edit = () => {
            if(AuthService.isSupplierLoggedIn()) {
                return;
            }

            const newDate = prompt("New exit date (dd/mm/yyyy):", "");
            if (!newDate) return;

            new Server().editPackagePoExit(pkgNum, po.number, newDate, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    alert(response.data);
                }
            });
        };

        return (
            <span>
                Exit: <span className={classes.clickable} onClick={edit}>{Utils.formatDate(po.exit_date, 'DD/MM/YYYY')}</span>
            </span>
        )
    };

    const PriceText = (props) => {

        const edit = () => {
            if(AuthService.isSupplierLoggedIn()) {
                return;
            }

            const newPrice = prompt("New price (" + po.currency + "):", "");
            if (!newPrice) return;

            new Server().editPackagePoPrice(pkgNum, po.number, newPrice, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    alert(response.data);
                }
            });
        };

        return (
            <span className={classes.clickable} onClick={edit}>{Pricing.formatPrice(po.price, po.currency)}</span>
        )
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>

                        <TableCell colSpan={po.sizes.length + 2}  align="left">
                            PO: {po.number}&nbsp;
                            Product: {po.product_code}&nbsp;
                            Fit: {po.fit_code}&nbsp;
                            <ExitText/>&nbsp;
                            <DesignButton/>
                            <PriceText/>
                        </TableCell>

                        <TableCell align="right">
                            <IconButton size="small" disabled={!canDeletePO()} onClick={() => deletePOProduct(po)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: 100}}>order</TableCell>
                        <TableCell style={{width: 150}}>customer</TableCell>
                        {
                            po.sizes.map((size) => (
                                <TableCell style={{width: 80}} key={size}>{size}</TableCell>
                            ))
                        }
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {
                        po.orders.map((order) => (
                            <TableRow key={order.number}>
                                <TableCell>{order.number}</TableCell>
                                <TableCell>{order.customer_name}</TableCell>
                                {
                                    po.sizes.map((size) => (
                                        <TableCell key={size}>{order.qtys[size]}</TableCell>
                                    ))
                                }
                                <TableCell> {totalQty(order.qtys)} </TableCell>
                            </TableRow>
                        ))
                    }

                    <TableRow>
                        <TableCell/>
                        <TableCell align="right">Total</TableCell>
                        {
                            po.sizes.map((size) => (
                                <TableCell key={size}>{po.totals[size]}</TableCell>
                            ))
                        }
                        <TableCell> {totalQty(po.totals)} </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell/>
                        <TableCell align="right">Shipped</TableCell>
                        {
                            po.sizes.map((size) => (
                                <TableCell key={size}>{po.shipped_qtys[size]}</TableCell>
                            ))
                        }
                        <TableCell>{totalQty(po.shipped_qtys)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell/>
                        <TableCell align="right">Oustanding</TableCell>
                        {
                            po.sizes.map((size) => (
                                <TableCell key={size}>{po.outstanding_qtys[size]}</TableCell>
                            ))
                        }
                        <TableCell>{totalQty(po.outstanding_qtys)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default POTable;