import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TableHead,
    Typography
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LoadingScreen from "../../LoadingScreen";
import {useSnackbar} from "notistack";

const BulkPriceSubmission = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const csvUploadInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadResults, setUploadResults] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            csvUploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.csv')) {
            alert('A csv file ending in .csv is required.')
            return;
        }

        setUploadResults([]);
        setLoading(true);
        new Server().submitZalandoPricesCsv(file, (response) => {
            setLoading(false);
            csvUploadInputRef.current.value = null;

            if (response.status !== 200) {
                alert("An error occurred: " + JSON.stringify(response.data, null, 4));
                return;
            }
            const errors = response.data.filter((row) => row.status !== 'ACCEPTED');
            if(errors.length === 0) {
                enqueueSnackbar('Prices submitted successfully', { variant: 'success'});
            } else {
                enqueueSnackbar('Errors occurred!', { variant: 'error'});
                setUploadResults(errors);
            }
        });
    };

    const openTemplate = () => {
        window.open("https://docs.google.com/spreadsheets/d/17CFIM6bnXi3FpHEnymK4DjAyW4oKg4DVWLzzZeapmxA/edit?usp=sharing")
    };

    const downloadPrices = () => {
        setDownloadLoading(true);
        new Server().downloadZppPricesCsv("zpp_prices.csv", (response) => {
            setDownloadLoading(false);
        })
    };

    return (
        <Card>
            <CardHeader title="Bulk price submission"/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Make a copy of the template.
                            <br/>
                            2 - Download current prices.
                            <br/>
                            3 - Copy products to update to the template.
                            <br/>
                            4 - Set up to 570 new prices.
                            <br/>
                            5 - Upload the csv file below.
                            <br/>
                            - Set prices in EUR
                            <br/>
                            - Prices will be converted to the relevant currency for each sales country
                            <br/>
                            - Setting a discounted price of 0 removes the discounted price.
                            <br/>
                            - Blank fields are not allowed.
                            <br/>
                            - ZPP may take up to an hour to process new prices.
                            <br/>
                            - ZPP has a limit of <b>570 price updates per min</b>.
                            <br/>
                            - Csv should not contain more than 570 prices. Wait 1 minute between file uploads.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" alignItems="center">

                            <Grid item>
                                <Button component="label" onClick={downloadPrices}>
                                    <CloudDownloadIcon  className={classes.buttonIcon}/>
                                    Current prices
                                </Button>
                            </Grid>

                            {
                                downloadLoading ?
                                    <Grid item>
                                        <CircularProgress size={15}/>
                                    </Grid>
                                    : null
                            }

                            <Grid item>
                                <Button component="label" onClick={openTemplate}>
                                    <DescriptionIcon  className={classes.buttonIcon}/>
                                    Template
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload csv
                                    <input
                                        type="file"
                                        ref={csvUploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item>
                        {uploadResults?.length ?

                            <TableContainer>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small">EAN</TableCell>
                                            <TableCell size="small">Status</TableCell>
                                            <TableCell size="small">Description</TableCell>
                                            <TableCell size="small">Channel ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            uploadResults.map((result, index) => (
                                                <TableRow key={result.ean + result.sales_channel_id}>
                                                    <TableCell size="small">{result.ean}</TableCell>
                                                    <TableCell size="small">{result.status + ' ' + result.status_code}</TableCell>
                                                    <TableCell size="small">{result.description}</TableCell>
                                                    <TableCell size="small">{result.sales_channel_id}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>



                            : null }
                    </Grid>
                </Grid>
            </CardContent>
            <LoadingScreen loading={loading}/>
        </Card>
    );
};

export default BulkPriceSubmission;