import React, {useEffect, useState} from "react";
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditShipmentInfoDialog from './EditShipmentInfoDialog'
import moment from "moment";
import Pricing from "../../Pricing";
import Server from "../../Server";
import Utils from "../../Utils";

const ShipmentInfo = props => {

    const shipmentProducts = props.shipmentProducts;
    let shipment = props.shipment;
    const [openEditInfoDialog, setOpenEditInfoDialog] = useState(false);
    const [ports, setPorts] = useState([]);
    const [destinations, setDestinations] = useState([]);

    useEffect(()=>{
        new Server().fetchPorts(response => {
            setPorts(response.data);
        })
        new Server().fetchDestinations(response => {
            setDestinations(response.data);
        })
    }, [])

    const openDialog = () => {
        setOpenEditInfoDialog(true)
    };

    const handleOnClose = () =>{
        setOpenEditInfoDialog(false)
    };

    const packingListFileName = (trim) => {
        if(!shipment?.packing_list_file) {
            return 'N/A';
        } else {
            let name = shipment.packing_list_file.replace('/media/packing_lists/','');
            if (trim) {
                name = Utils.trim(name);
            }
            return name;
        }
    };

    const downloadPackingListFile = () => {
        new Server().downloadPOShipmentPackingList(shipment.number, packingListFileName(false));
    };

    return(
        <Card>
            <CardHeader title="Shipment Details"
            action={
                <IconButton size="small" onClick={(e)=>{openDialog()}}>
                    <EditIcon fontSize="small"/>
                </IconButton>
            }/>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Supplier: {shipment.supplier.name}</TableCell>
                        <TableCell>Created: {moment(shipment.created_date).format('YYYY-MM-DD')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>From: {shipment.departure_port?.name}</TableCell>
                        <TableCell>Destination: {shipment.destination?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>Flex ID: {shipment.flex_id}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Packing lists: <span href={void(0)} onClick={downloadPackingListFile}><u>{packingListFileName(true)}</u></span></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>At port ETA: {Utils.formatOptionalDate(shipment.port_eta)}</TableCell>
                        <TableCell>At warehouse ETA: {Utils.formatOptionalDate(shipment.warehouse_eta)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <EditShipmentInfoDialog open={openEditInfoDialog} onClose={handleOnClose} shipment={shipment} setShipment={props.setShipment} ports={ports} destinations={destinations}/>
        </Card>
    );
};

export default ShipmentInfo;