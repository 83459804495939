import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const PLPreviewSummary = props => {

    const pl = props.pl;
    /*
   RO number RO-456 PO number PO-789
   Product: CPNY-22-EFGH
   Description: RANDOM NICE CLOTHES
   Cartons: 5 Units: 10
    */

    return (
        <div>
            <Typography variant="h3">Sheet: {pl.sheet_name}</Typography>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs>
                    <React.Fragment>
                        <Typography>PO number: {pl.po_number}</Typography>
                        <Typography>Product: {pl.product_code}</Typography>
                        <Typography>Description: {pl.goods_description}</Typography>
                    </React.Fragment>
                </Grid>

                <Grid item xs>
                    <React.Fragment>
                        <Typography>Customer: {pl.customer_name}</Typography>
                        <Typography>C. Order: {pl.customer_order_description}</Typography>
                    </React.Fragment>
                </Grid>

                <Grid item xs>
                    <React.Fragment>
                        <Typography>{pl.total_cartons} cartons</Typography>
                        <Typography>{pl.total_units} units</Typography>
                    </React.Fragment>
                </Grid>
            </Grid>

        </div>
    );
};

export default PLPreviewSummary