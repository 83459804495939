import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import LinesheetReportFilters from "./LinesheetReportFilters";
import LinesheetReportProductsTable from "./LinesheetReportProductsTable";
import Server from "../../Server";
import LinesheetReportHeader from "./LinesheetReportHeader";

const LinesheetReport = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            height: '100%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            padding: theme.spacing(3),
        },

        conversationPlaceholder: {
            flexGrow: 1
        },
    }));
    const classes = useStyles();
    const [linesheet, setLinesheet] = useState(null);
    const [linesheetSection, setLinesheetSection] = useState(null);
    const [sizeGroupOption, setSizeGroupOption] = useState(null);
    const [sizeGroupOptions, setSizeGroupOptions] = useState([]);
    const [ages, setAges] = useState([]);
    const [brands, setBrands] = useState([]);
    const [bodies, setBodies] = useState([])
    const [genders, setGenders] = useState([]);
    const [styles, setStyles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currency, setCurrency] = useState('GBP');
    const [locations, setLocations] = useState([]);

    const [ageFilter, setAgeFilter] = useState(null);
    const [brandFilter, setBrandFilter] = useState(null);
    const [bodyFilter, setBodyFilter] = useState(null);
    const [genderFilter, setGenderFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [styleFilter, setStyleFilter] = useState(null);
    const [locationFilter, setLocationFilter] = useState(null);
    const [factor, setFactor] = useState(0.00);
    const [quantity, setQuantity] = useState('0');
    const [operator, setOperator] = useState('>');

    useEffect(() => {
        const server = new Server();
        server.fetchProductAttributeValues('brands', (response) => {
            setBrands(response.data);
        });
        server.fetchProductAttributeValues('bodies', (response) => {
            setBodies(response.data);
        });
        server.fetchProductAttributeValues('genders', (response) => {
            setGenders(response.data);
        });
        server.fetchProductAttributeValues('ages', (response) => {
            setAges(response.data);
        });
        server.fetchProductAttributeValues('styles', (response) => {
            setStyles(response.data);
        });
        server.fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });
        server.fetchWarehouses((response) => {
            setLocations(response.data);
        });
        server.fetchProductAttributeValues('sizeGroups', (response) => {
            setSizeGroupOptions(response.data);
        });
    }, []);


    const getLinesheetProducts = () => {
        const data = {
            brand_id: brandFilter === null ? -1 : brandFilter.id,
            category_id: categoryFilter === null ? -1 : categoryFilter.id,
            body_id: bodyFilter === null ? -1 : bodyFilter.id,
            gender_id: genderFilter === null ? -1 : genderFilter.id,
            age_id: ageFilter === null ? -1 : ageFilter.id,
            style_id: styleFilter === null ? -1 : styleFilter.id,
            location_id: locationFilter === null? -1 : locationFilter.id,
            currency: currency,
            factor: factor,
            quantity: quantity,
            operator: operator,
        };
        new Server().fetchLinesheetProducts(data, response => {
            if(response.status ===200) {
                setLinesheet(response.data);
                if(sizeGroupOption){
                    selectLinesheetSizeGroup(sizeGroupOption, response.data);
                }
            }
        })
    }

    const selectLinesheetSizeGroup = (selectedSizeGroupOption, newLinesheet=null) => {
        setSizeGroupOption(selectedSizeGroupOption);
        if(newLinesheet){
            for(let i=0; i<newLinesheet.length; i++){
                if(newLinesheet[i]['size_group_name'] === selectedSizeGroupOption['name']){
                    setLinesheetSection(newLinesheet[i]);
                }
            }
        }
    }

    const deleteLinesheetRow = (index) => {
        let linesheetSectionRows = [...linesheetSection['rows']];
        linesheetSectionRows.splice(index, 1);
        let modifiedLinesheet = [...linesheet];
        let linesheetSectionIndex;
        for(let i =0; i<linesheet.length; i++){
            if(linesheetSection['size_group_name'] === linesheet[i]['size_group_name']){
                linesheet[i]['rows'] = linesheetSectionRows;
                linesheetSectionIndex = i;
            }
        }
        setLinesheetSection(modifiedLinesheet[linesheetSectionIndex])
        setLinesheet(modifiedLinesheet)
    };

    return (
        <div className={classes.root}>

            <LinesheetReportHeader linesheet={linesheet}/>

            <LinesheetReportFilters
                brands={brands} brandFilter={brandFilter} setBrandFilter={setBrandFilter}
                bodies={bodies} bodyFilter={bodyFilter} setBodyFilter={setBodyFilter}
                genders={genders} setGenders={setGenders} genderFilter={genderFilter} setGenderFilter={setGenderFilter}
                ages={ages} setAges={setAges} ageFilter={ageFilter} setAgeFilter={setAgeFilter}
                styles={styles} setStyles={setStyles} styleFilter={styleFilter} setStyleFilter={setStyleFilter}
                categories={categories} categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter}
                currency={currency} setCurrency={setCurrency}
                locations={locations} setLocations={setLocations} locationFilter={locationFilter} setLocationFilter={setLocationFilter}
                factor={factor} setFactor={setFactor} quantity={quantity} setQuantity={setQuantity} operator={operator} setOperator={setOperator}
                sizeGroupOptions={sizeGroupOptions} sizeGroupOption={sizeGroupOption} setSizeGroupOption={setSizeGroupOption}
                getLinesheetProducts={getLinesheetProducts}
                selectLinesheetSizeGroup={selectLinesheetSizeGroup}
                linesheet={linesheet}
            />

            <LinesheetReportProductsTable className={classes.conversationPlaceholder}
                sizeGroupOption={sizeGroupOption} setSizeGroupOption={setSizeGroupOption} linesheetSection={linesheetSection} deleteLinesheetRow={deleteLinesheetRow}
            />

        </div>
    );
};

export default LinesheetReport;