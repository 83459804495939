import React from 'react';
import {Card, CardContent, Grid} from "@material-ui/core";
import PLPreviewSummary from "./PLPreviewSummary";
import PLPreviewTable from "./PLPreviewTable";

const PLPreview = props => {

    const pl = props.pl;
    /*
    {
                preview_id: 1,
                po_number: 'PO-789',
                product_code: 'CPNY-22-EFGH',
                goods_description: 'RANDOM NICE CLOTHES',
                total_cartons: 5,
                total_units: 10,
                sizes: ['S','M','L'],
                totals: {S: 1, M: 1, L: 1},
                carton_ranges: [{from_carton: 1, to_carton: 1, total_units: 12, total_cartons: 5, preview_id: 0, size_map: {S: 1, M: 1, L: 1} }]
            }
     */
    return (
        <Card>
            <CardContent>
                <Grid container direction="column" spacing={1} justifyContent="flex-start">
                    <Grid item xs>
                        <PLPreviewSummary pl={pl}/>
                    </Grid>

                    <Grid item xs>
                        <PLPreviewTable pl={pl}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PLPreview;