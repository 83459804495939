import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Pricing from "../Pricing";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";
import Utils from "../Utils";
import { ThumbUp, Payment } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},
    InvoiceCell: { display:'flex', justifyContent: 'space-between'},
    errorIcon: {
        fill: theme.palette.red,
        marginLeft: '4px'
    },
    successIcon: {
        color: theme.palette.green,
        marginLeft: '4px'
    }
}));


const POShipmentsTable = props => {

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const onShipmentClick = (shipment, event) => {
        nav.goToPoShipmentDetails(shipment.shipment_number, event.metaKey)
    };

    const AckIcon = (invoice) => {
            return <ThumbUp className={invoice?.approved ? classes.successIcon : classes.errorIcon}/>
    }

    const PaidIcon = (invoice) => {
        return <Payment className={invoice?.is_paid ? classes.successIcon : classes.errorIcon}/>
    }

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Shipment</TableCell> 
                            <TableCell>Status</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Destination</TableCell>
                            <TableCell>FlexID</TableCell>
                            <TableCell>Port ETA</TableCell>
                            <TableCell>Units (Cartons)</TableCell>
                            <TableCell>Total Value</TableCell>
                            <TableCell>Entry Value</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            props.poShipments.map((shipment, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        onClick={(e) => onShipmentClick(shipment, e)}
                                        className={classes.NumberCell}>
                                        {shipment.shipment_number} {shipment.supplier_name}
                                    </TableCell>
                                    <TableCell>{shipment.status}</TableCell>
                                    <TableCell className={classes.InvoiceCell}>{shipment.invoice?.number || '-'} <div>{AckIcon(shipment.invoice)} {PaidIcon(shipment.invoice)}</div></TableCell>
                                    <TableCell>{shipment.destination}</TableCell>
                                    <TableCell>{shipment.flex_id}</TableCell>
                                    <TableCell>{Utils.formatOptionalDate(shipment.port_eta)}</TableCell>
                                    <TableCell>{shipment.total_units} ({shipment.cartons})</TableCell>
                                    <TableCell>{Pricing.formatPrice(shipment.total_value, shipment.currency)}</TableCell>
                                    <TableCell>{Pricing.formatPrice(shipment.entry_value, 'GBP')}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )

};

export default POShipmentsTable;