import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LabeledLinearProgress = (props) => {
    const useStyles = makeStyles(theme => ({
        progressText: { color: '#fff', fontSize: 20}
    }));

    const classes = useStyles();

    const getValue = () => {
        const value = Math.round((props.currentValue/props.maxValue)*100);
        console.log("Value: "+value);
        return value;
    }

    return (
        <Box display="flex" alignItems="center" flexDirection="column" >
            <Box width="40%" >
                <LinearProgress variant="determinate" value={getValue()}  />
            </Box>
            <Box minWidth={35} mt={2}>
                <div className={classes.progressText}>{`${props.currentValue} / ${props.maxValue}`}</div>
            </Box>
        </Box>
    );
}

export default LabeledLinearProgress;