import React from 'react';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const Pager = props => {

    const pageNumber = props.pageNumber;
    const pageCount = props.pageCount;
    const onPreviousPageClick = props.onPreviousPageClick;
    const onNextPageClick = props.onNextPageClick;

    const hasNextPage = pageNumber < pageCount;
    const hasPreviousPage = pageNumber > 1;

    return (
        <Grid container direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
            <Grid item>
                {/*previous page*/}
                <Button
                    onClick={onPreviousPageClick}
                    disabled={!hasPreviousPage}
                >
                    <NavigateBeforeIcon/>
                </Button>
            </Grid>
            <Grid item>
                {pageNumber} of {pageCount}
            </Grid>
            <Grid item>
                {/*next page*/}
                <Button
                    onClick={onNextPageClick}
                    disabled={!hasNextPage}
                >
                    <NavigateNextIcon/>
                </Button>
            </Grid>
        </Grid>
    );
};

export default Pager;