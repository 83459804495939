import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Topbar from './TopBar';
import Sidebar from './Sidebar';
import {useHistory} from "react-router-dom";
import AuthService from "./AuthService";


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    },
    shiftContent: {
        paddingLeft: 200//was 240
    },
    content: {
        height: '100%'
    }
}));

const SupplierMain = props => {
    const { children } = props;

    const classes = useStyles();
    const history = useHistory();

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    //if not logged in, show login page
    if (!AuthService.isSupplierLoggedIn()) {
        history.push('/auth/login');
    }

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: true
            })}
        >
            <Topbar onSidebarOpen={handleSidebarOpen} />
            <Sidebar
                onClose={handleSidebarClose}
                open={true}
                variant={'persistent'}
            />
            <main className={classes.content}>
                {children}
                {/*<Footer />*/}
            </main>
        </div>
    );
};

SupplierMain.propTypes = {
    children: PropTypes.node
};

export default SupplierMain;
