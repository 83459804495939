import React, {useEffect, useState} from "react";
import Server from "../Server";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {Autocomplete} from "@material-ui/lab";
import ProductData from "./ProductData";
import ProductValidator from "./ProductValidator";

const NewProductDialog = props => {

    const [code, setCode] = useState('');
    const [season, setSeason] = useState(null);

    const [brand, setBrand] = useState(null);
    const [brands, setBrands] = useState([]);

    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState(null);

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);

    const server = new Server();

    useEffect(() => {
        server.fetchProductAttributeValues('brands', (response) => {
            setBrands(response.data);
        });

        server.fetchProductAttributeValues('departments', (response) => {
            setDepartments(response.data);
        });

        server.fetchProductAttributeValues('categories', (response) => {
            setCategories(response.data);
        });
    }, [props.open]);

    const confirm = () => {
        if(isValidProduct()) {
            const data = {
                code: code,
                brand: brand,
                season: season,
                category: category,
                department: department,
            };

            new Server().newProduct(data, response => {
                if(response.status === 200) {
                    //go to edit product
                    props.onConfirm(code);
                } else {
                    alert('Error occurred: \n'+JSON.stringify(response.data));
                }
            });

        }
    };

    const isValidProduct = () => {
        const validator = new ProductValidator(null);
        if(!validator.isValidProductCode(code)){
            return false;
        }

        if(!propertyIsSet(season)) {
            alert('Season not set');
            return false;
        }

        if(!propertyIsSet(brand)) {
            alert('Brand not set');
            return false;
        }

        if(!propertyIsSet(department)) {
            alert('Department not set');
            return false;
        }

        if(!propertyIsSet(category)) {
            alert('Category not set');
            return false;
        }
        return true;
    };

    const propertyIsSet = (property) => {
      return property !== null && property !== undefined && property !== '';
    };

    const onCancel = (closeReason) => {
        if (closeReason === 'backdropClick'){
            return;
        }
        if (code || season || brand) {
            if(window.confirm("All selections will be lost. Confirm?")) {
                props.onClose();
                setCode('');
                setSeason(null);
                setBrand(null);
                setDepartment(null);
                setCategory(null);
            }
        } else {
            props.onClose();
        }
    };

    return (
        <Dialog open={props.open} onClose={onCancel} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">New product</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <TextField label="Product code" variant="outlined"
                                    size="small"
                                   value={code}
                                    fullWidth
                                   onChange={(e) => setCode(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={ProductData.seasons}
                            getOptionLabel={s => s}
                            value={season}
                            onChange={(e, v, r) => setSeason(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={brands}
                            getOptionLabel={b => b.name}
                            value={brand}
                            onChange={(e, v, r) => setBrand(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Brand" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={departments}
                            getOptionLabel={department => department?.code}
                            value={department}
                            onChange={(e, v, r) => setDepartment(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Department"
                                                              variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={categories}
                            getOptionLabel={category => category?.name}
                            value={category}
                            onChange={(e, v, r) => setCategory(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Category"
                                                              variant="outlined"/>}
                        />
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default NewProductDialog;