import React, {useRef, useState} from 'react';
import {Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LoadingScreen from "../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const QtyDialog = props => {
    const classes = useStyles();

    const {open, onClose} = props;

    const [loading, setLoading] = useState(false);

    const uploadInputRef = useRef();

    const downloadTemplate = () => {
        setLoading(true);
        new Server().downloadSizeRatioQuantitiesTemplate((response) => {
            setLoading(false);
        });
    };

    const handleFileSelection = (selectedFiles) => {
        if(!selectedFiles) {
            alert('No files selected!');
            uploadInputRef.current.value = null;
            return;
        }

        const file = selectedFiles[0];
        if (!file.name.endsWith('.xlsx')) {
            alert('An excel file ending in .xlsx is required.')
            uploadInputRef.current.value = null;
            return;
        }

        setLoading(true);
        new Server().setSizeRatiosQuantities(file, (response) => {
            setLoading(false);
            uploadInputRef.current.value = null;
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">Generate product quantities</Typography></DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Download template<br/>
                            2 - Add order data and totals<br/>
                            3 - Upload<br/>
                            - Do not edit headers<br/>
                            - Totals must be integers<br/>
                            - Errors are shown as a comment in the relevant cell<br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row">

                            <Grid item>
                                <Button component="label" onClick={downloadTemplate}>
                                    <DescriptionIcon  className={classes.buttonIcon}/>
                                    Template
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button component="label" color="primary">
                                    <CloudUploadIcon  className={classes.buttonIcon}/>
                                    Upload
                                    <input
                                        type="file"
                                        ref={uploadInputRef}
                                        style={{ display: "none" }}
                                        hidden
                                        onChange={(e) => {handleFileSelection(e.target.files)}}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
}

export default QtyDialog;