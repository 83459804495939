import React, {useEffect, useRef, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Typography,
    Button,
    DialogActions, Tooltip, IconButton,
} from '@material-ui/core';
import {Autocomplete} from "@material-ui/lab";
import Server from "../../Server";
import Utils from "../../Utils";
import {makeStyles} from "@material-ui/styles";
import AuthService from "../../AuthService";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const EditInfoDialog = props =>{
    const number = props.number;

    const classes = useStyles();

    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);

    const seasons = ['NOS', 'AW24', 'SS24', 'SS25', 'AW25', 'SS26', 'AW26', 'SS27', 'AW27', 'SS28', 'AW28', 'SS29', 'AW29'];
    const [season, setSeason] = useState('');

    const [currency, setCurrency] = useState('');

    const [countries, setCountries] = useState([]);
    const [coo, setCoo] = useState(null);

    const modes = ['SEA', 'AIR', 'LND'];
    const [mode, setMode] = useState(null);


    useEffect(() => {
        if(props.open) {
            new Server().fetchSuppliers(response => {
                setSuppliers(response.data);
            });

            new Server().fetchCountries(response => {
                setCountries(response.data);
            });

            new Server().fetchPackageInfo(number, response => {
                const data = response.data;
                setSupplier(data.supplier);
                setSeason(data.season);
                setCurrency(data.currency);
                setCoo(data.coo);
                setMode(data.mode);
            });
        }
    },[props.open])

    const confirm = () => {
        if (!Utils.validatePackageInfoInput(supplier, currency, season, mode, coo)) {
            return;
        }

        new Server().editPackageInfo(number, season, supplier.id, currency, mode, coo.id, (response) => {
            if (response.status === 200) {
                props.onConfirm();
            } else {
                alert('Error:\n' + JSON.stringify(response.data, null, 2));
            }
        });
        props.onClose();
    };

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Edit Shipment details</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={3}>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={suppliers}
                            getOptionLabel={s => s.name}
                            value={supplier}
                            onChange={(e, v, r) => setSupplier(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                            disabled={AuthService.isSupplierLoggedIn()}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={['GBP', 'EUR', 'USD']}
                            getOptionLabel={t => t}
                            value={currency}
                            onChange={(e, v, r) => setCurrency(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={seasons}
                            getOptionLabel={t => t}
                            value={season}
                            onChange={(e, v, r) => setSeason(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={countries}
                            getOptionLabel={t => t.name}
                            value={coo}
                            onChange={(e, v, r) => setCoo(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="COO" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={modes}
                            getOptionLabel={t => t}
                            value={mode}
                            onChange={(e, v, r) => setMode(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Mode" variant="outlined"/>}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditInfoDialog;