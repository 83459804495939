import React, {useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import Server from "../../../Server";
import Utils from "../../../Utils";
import UpdateIcon from '@material-ui/icons/Update';
import LoadingScreen from "../../../LoadingScreen";

const TRACKED_COURIERS_CODES = ['DPD-UK', 'YODEL'];

const TrackingEvents = props => {

    const order = props.order;

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if (props.order.id) {
            new Server().fetchTrackingEvents(props.order.id, response => {
                setEvents(response.data);
            });
        }
    }, [props.order])

    const isUnsupportedCourier = (courierCode) => {
        return !Utils.isIn(courierCode, TRACKED_COURIERS_CODES);
    };

    const getLatestTracking = () => {
        const courierCode = order.tracking.courier.code;
        if(isUnsupportedCourier(courierCode)) {
            alert('Courier ' + courierCode + ' cannot be tracked');
            return;
        }

        setLoading(true);
        new Server().getLatestTrackingEvent(props.order.id, (response) => {
            setLoading(false);
            window.location.reload();
        });
    };

    return (
        <Card>
            <CardHeader title="Delivery updates" action={
                <Tooltip title="Get latest tracking">
                    <IconButton onClick={(e)=>{getLatestTracking()}}>
                        <UpdateIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
            }/>

            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Delivery date</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        events.map((event, index) => (
                            <TableRow key={event.id}>
                                <TableCell>{event.type}</TableCell>
                                <TableCell>{Utils.formatOptionalDate(event.date, 'YYYY-MM-DD HH:mm')}</TableCell>
                                <TableCell>{event.courier_description}</TableCell>
                                <TableCell>{Utils.formatOptionalDate(event.delivery_date, 'YYYY-MM-DD HH:mm')}</TableCell>
                            </TableRow>
                        ))
                    }
                    {
                        events ? null : <TableRow>
                            <TableCell colSpan={4}>No tracking events yet</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>

            <LoadingScreen loading={loading}/>
        </Card>
    );
};

export default TrackingEvents;