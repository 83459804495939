import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import JsBarcode from "jsbarcode";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10
    },
    content: {
        height: '150px',
    },
    row: {
        flexDirection: 'row',
        flexGrow: 1
    },
    cell: {
        border: '1px',
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    section: {
        border: '1px',
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

// Create Document Component
const MyDocument = () => {
    let canvas = document.createElement('canvas');
    canvas.width = '100';
    canvas.height = '50';
    JsBarcode(canvas, 'PSH-123', {
        format: 'CODE39',
        displayValue: false,
        // width: 1,
        height: 40,
    });
    const barcode = canvas.toDataURL();
    console.log(barcode);

    return (

        <Document>
            <Page size="A6" orientation="landscape" style={styles.page}>

                <View style={styles.content}>
                    <View style={styles.row}>

                        <View style={styles.cell}>
                            <Text>PSH</Text>
                        </View>

                        <View style={styles.cell}>
                            <Text>PSH-123</Text>
                        </View>

                    </View>

                    <View style={styles.row}>

                        <View style={styles.cell}>
                            <Image src={barcode}/>
                        </View>

                    </View>
                </View>

            </Page>
        </Document>
    );
}

export default MyDocument;