import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton} from "@material-ui/core";
import EditableInteger from "../PurchaseOrders/PoDetails/PoItems/EditableInteger";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import {AddBox} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    editable: {
        maxWidth: '50px',
        borderWidth:'0px',
        border:'none',
    },
}));

const PackingListTable = props => {

    const classes = useStyles();

    const updateFromValue = (index, newVal) => {
        const newData = props.cartonRanges.slice();
        newData[index].from_carton = newVal;
        props.setCartonRanges(newData);
    };

    const updateToValue = (index, newVal) => {
        const newData = props.cartonRanges.slice();
        newData[index].to_carton = newVal;
        props.setCartonRanges(newData);
    };

    const updateSizeValue = (index, newVal, size) => {
        const newData = props.cartonRanges.slice();
        newData[index]['size_quantities'].forEach(sizeQuant =>{
            if(sizeQuant['size']['name'] === size){
                sizeQuant['quantity'] = newVal;
            }
        })
        props.setCartonRanges(newData);
    };

    const addRow = () => {
        let row = {from_carton:0,to_carton:0}
        row['size_quantities'] = []
        props.size.forEach(size =>{
            let sizeQuant = {size: {name:size}, quantity:0};
            row['size_quantities'].push(sizeQuant);
        })
        const newData = props.cartonRanges.slice();
        newData.push(row);
        props.setCartonRanges(newData);
    };

    const deleteRow = (index) => {
        let newData = props.cartonRanges.slice();
        newData = newData.filter((row, idx) => {
            return idx!==index;}
            )
        props.setCartonRanges(newData);
    };

    const getQty = (cartonRange, size) => {
        for(let i=0; i<cartonRange.size_quantities.length; i++) {
            if(cartonRange.size_quantities[i].size.name === size) {
                return cartonRange.size_quantities[i].quantity;
            }
        }
        return 0;
    };

    return (
        <div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: 75}}>From</TableCell>
                        <TableCell style={{width: 75}}>To</TableCell>
                        {
                            props.size.map((size) => (
                                <TableCell style={{width: 80}} key={size}>{size}</TableCell>
                            ))
                        }
                        <TableCell>
                            {props.isEditablePL?
                                <IconButton size="small" color="primary" onClick={(e) => addRow()}><AddBox /></IconButton>
                                : null}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {
                        props.cartonRanges.map((row, index) => (
                            <TableRow key={(row.from_carton + '-' + row.to_carton)}>
                                <TableCell>
                                    <EditableInteger classEditing={classes.editable}
                                                     value={row.from_carton}
                                                     onChange={(newVal) => updateFromValue(index, newVal)}
                                                     disabled={!props.isEditablePL}/>
                                    
                                </TableCell>

                                <TableCell>
                                    <EditableInteger classEditing={classes.editable}
                                                     value={row.to_carton}
                                                     onChange={(newVal) => updateToValue(index, newVal)}
                                                     disabled={!props.isEditablePL}/>
                                </TableCell>

                                {
                                    props.size.map((size, sizeIndex) => (
                                        <TableCell key={size}>

                                            <EditableInteger
                                                disabled={!props.isEditablePL}
                                                classEditing={classes.editable}
                                                value={getQty(row, size)}
                                                onChange={(newVal) => updateSizeValue(index, newVal, size)}
                                            />

                                        </TableCell>
                                    ))
                                    
                                }
                                <TableCell>
                                    {props.isEditablePL?
                                        <IconButton size="small" onClick={(e) => deleteRow(index)}><DeleteIcon /></IconButton>
                                        : null}
                                </TableCell>
                            </TableRow>
                        )
                        )
                    }
                </TableBody>

            </Table>
        </div>
    );
};

export default PackingListTable;