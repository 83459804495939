import Card from "@material-ui/core/Card/Card";
import {Grid, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import Pricing from "../Pricing";
import {PODetailsContext} from "./PoDetails/PoDetailsStore";

const PoStats = props => {

    const useStyles = makeStyles(theme => ({
        item: {
            padding: theme.spacing(3),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                '&:not(:last-of-type)': {
                    borderRight: `1px solid ${theme.palette.divider}`
                }
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-of-type)': {
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        label: {
            marginLeft: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const [data, setData] = useState({total_units: 0, total_value:0, units_shipped:0, shipped_value:0, currency: 'GBP'});

    const context = useContext(PODetailsContext);

    const server = new Server();

    useEffect( () => {
       reloadPoStats();
    }, [context.state.refreshCounter]);

    const reloadPoStats = () => {
        console.log('reload stats');
        server.fetchPoStats(props.poNumber, (response) => {
            if(response.status === 200) {
                setData(response.data);
            }
        });
    };

    const statsPairs = () => {
        return [
            ['Requested ' + data.total_units, Pricing.formatPrice(data.total_value, data.currency)],
            ['Shipped ' + data.units_shipped, Pricing.formatPrice(data.shipped_value, data.currency)],
        ];
    };

    return (
        <Card>
            <Grid
                alignItems="center"
                container
                justifyContent="space-between"
            >

                {statsPairs().map((nameValue, index) => (
                    <Grid
                        key={index}
                        className={classes.item}
                        item
                        xs
                    >
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="overline"
                        >
                            {nameValue[0]}
                        </Typography>
                        <div className={classes.valueContainer}>
                            <Typography variant="h3">{nameValue[1]}</Typography>
                        </div>
                    </Grid>
                ))}

            </Grid>
        </Card>
  );
};

export default PoStats;