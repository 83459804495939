import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import TableChartIcon from '@material-ui/icons/TableChart';
import StorageIcon from '@material-ui/icons/Storage';
import ExploreIcon from '@material-ui/icons/Explore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import SidebarNav from './SidebarNav';
import AuthService from "./AuthService";
import NavigationService from "./NavigationService";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 200,//was 240
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}));

const Sidebar = props => {
    const { open, variant, onClose, className, ...rest } = props;

    const classes = useStyles();

    let pages = [];

    if(AuthService.canViewProducts()) {
        pages.push({
            title: 'Products',
            href: NavigationService.PRODUCTS,
            icon: <ShoppingBasketIcon />
        });
    }

    if(AuthService.canViewDesignDocs()) {
        pages.push({
            title: 'Design docs',
            href: NavigationService.DESIGN_DOCS,
            icon: <ColorLensIcon />
        });
    }

    if(AuthService.canViewSizeRatios()) {
        pages.push({
            title: 'Size ratios',
            href: NavigationService.SIZE_RATIOS,
            icon: <ShoppingBasketIcon />
        });
    }

    if(AuthService.canViewTransitProducts()) {
        pages.push(
            {
                title: 'Transit products',
                href: NavigationService.TRANSIT_PRODUCTS,
                icon: <FlightTakeoffIcon />
            }
        );
    }

    if(AuthService.canViewConsolidatedPackages()) {
        pages.push({
            title: 'Consolidated POs',
            href: NavigationService.CONSOLIDATED_PACKAGES,
            icon: <AssignmentIcon />
        });
    }

    if(AuthService.canViewPurchaseOrders()) {
        pages.push({
            title: 'Purchase orders',
            href: '/PurchaseOrders',
            icon: <AssignmentIcon />
        });
    }

    if(AuthService.canViewShipments()) {
        pages.push(
            {
                title: 'POShipments',
                href: NavigationService.PO_SHIPMENTS,
                icon: <LocalShippingIcon />
            }
        );
    }

    let channelsNestedPages = [];
    if(AuthService.canViewChannelOrders()) {
        channelsNestedPages.push(
            {
                title: 'Orders',
                href: NavigationService.CHANNEL_ORDERS,
                icon: <TableChartIcon/>,
            }
        )
    }

    if(AuthService.canViewDeliveries()) {
        channelsNestedPages.push(
            {
                title: 'Exceptions',
                href: NavigationService.DELIVERIES,
                icon: <TableChartIcon/>,
            }
        )
    }

    if(AuthService.canViewChannelVariants()) {
        channelsNestedPages.push(
            {
                title: 'Variants',
                href: NavigationService.CHANNEL_VARIANTS,
                icon: <TableChartIcon/>,
            }
        )
    }

    if(AuthService.canViewChannelsTopSelling()) {
        channelsNestedPages.push(
            {
                title: 'Top selling',
                href: NavigationService.TOP_SELLING,
                icon: <TableChartIcon/>,
            }
        )
    }

    if(AuthService.canViewChannelsOverview()) {
        pages.push({
            title: 'Channels',
            href: NavigationService.CHANNELS_OVERVIEW,
            icon: <ShoppingCartIcon />,
            nestedPages: channelsNestedPages
        });
    }

    if(AuthService.canViewZalandoProducts()) {
        pages.push({
            title: 'Zalando',
            href: NavigationService.ZALANDO,
            icon: <ShoppingBasketIcon />,
            nestedPages: [
                {
                    title: 'Orders',
                    href: NavigationService.ZALANDO_ORDERS,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Products',
                    href: NavigationService.ZALANDO_PRODUCTS,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'New product',
                    href: NavigationService.NEW_ZALANDO_PRODUCT,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Bulk upload',
                    href: NavigationService.ZALANDO_BULK_UPLOAD,
                    icon: <TableChartIcon/>,
                },
            ]
        });
    }

    if(AuthService.canViewReports()) {
        pages.push({
            title: 'Reports',
            href: NavigationService.REPORTS,
            icon: <TableChartIcon />,
            nestedPages: [
                {
                    title: 'Stock',
                    href: NavigationService.STOCK_REPORT,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Delivery',
                    href: NavigationService.DELIVERY_REPORT,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Generic',
                    href: NavigationService.GENERIC_REPORT,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Production',
                    href: NavigationService.PRODUCTION_REPORT,
                    icon: <TableChartIcon/>,
                },
                {
                    title: 'Linesheet',
                    href: NavigationService.LINESHEET_REPORT,
                    icon: <TableChartIcon/>,
                },
            ]
        });
    }

    if(AuthService.canViewDebits()) {
        pages.push({
            title: 'Debits',
            href: '/debits',
            icon: <AssignmentReturnIcon />
        });
    }

    if(AuthService.canViewStock()) {
        pages.push({
            title: 'Warehouses',
            href: NavigationService.STOCK,
            icon: <StorageIcon />
        });
    }

    if(AuthService.canViewSuppliers()) {
        pages.push({
            title: 'Suppliers',
            href: '/suppliers',
            icon: <BusinessIcon />
        });
    }

    if(AuthService.canViewCustomers()) {
        pages.push({
            title: 'Customers',
            href: '/customers',
            icon: <PeopleIcon />
        });
    }

    if(AuthService.canViewPorts()) {
        pages.push({
            title: 'Ports',
            href: '/ports',
            icon: <DirectionsBoatIcon />
        });
    }

    if(AuthService.canViewDestinations()){
        pages.push(
            {
                title: 'Destinations',
                href: '/Destinations',
                icon: <ExploreIcon />
            }
        )
    }

    if(AuthService.canViewUsers()) {
        pages.push({
            title: 'Users',
            href: '/users',
            icon: <PeopleIcon />
        });
    }

    if(AuthService.isDeveloper()) {
        pages.push(
            {
                title: 'PDF builder',
                href: NavigationService.PDF_BUILDER,
                icon: <PictureAsPdfIcon />
            }
        );
    }


    //if i'm a supplier, show supplier pages only
    if(AuthService.isSupplierLoggedIn()) {
        pages = [
            {
                title: 'Design Docs',
                href: NavigationService.SUPPLIER_DESIGN_DOCS,
                icon: <ColorLensIcon />
            },
            {
                title: 'Consolidated POs',
                href: NavigationService.SUPPLIER_CONSOLIDATED_PACKAGES,
                icon: <AssignmentIcon />
            },
            {
                title: 'Purchase Orders',
                href: '/supplierPos',
                icon: <AssignmentIcon />
            },
            {
                title: 'PO Shipments',
                href: '/supplierPOShipments',
                icon: <LocalShippingIcon />
            }
        ];
    }


    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >

            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <SidebarNav
                    className={classes.nav}
                    pages={pages}
                />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
