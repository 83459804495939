import React, {useEffect, useState} from 'react';
import {Card, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";
import AuthService from "../AuthService";

const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = props => {
    const classes = useStyles();

    const selectedProductCodes = props.selectedProductCodes;
    const setSelectedProductCodes = props.setSelectedProductCodes;

    const [productCodes, setProductCodes] = useState([]);

    useEffect(() => {
        new Server().fetchProductCodesByDesignDocSupplier(AuthService.loggedInId(), (response) => {
            setProductCodes(response.data);
        });

    }, []);

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" direction={"row"}>

                    <Grid item>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            size="small"
                            options={productCodes}
                            getOptionLabel={s => s}
                            value={selectedProductCodes}
                            onChange={(e, v, r) => setSelectedProductCodes(v)}
                            autoHighlight
                            style={{width: 600}}
                            renderInput={params => <TextField {...params} label="Product codes" variant="outlined"/>}
                        />
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;