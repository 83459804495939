import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import ShipmentBarcodePdf from "./ShipmentBarcodePdf";
import {PDFDownloadLink} from '@react-pdf/renderer';


const BarcodeDialog = props => {
    const defaultShipmentNumber = props.shipmentNumber;

    const [shipmentNumber, setShipmentNumber] = useState('');

    useEffect(() => {
        setShipmentNumber(defaultShipmentNumber);
    }, [props.shipmentNumber]);


    const pdfFilename = () => {
        return shipmentNumber + '_barcode.pdf';
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Barcode generator</Typography></DialogTitle>
            <DialogContent>
                <TextField variant="outlined" size="small" type="text" label="Shipment number" value={shipmentNumber}
                           onChange={(e) => setShipmentNumber(e.target.value)}/>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose}>
                    Close
                </Button>
                <PDFDownloadLink
                    document={<ShipmentBarcodePdf shipmentNumber={shipmentNumber}/>}
                    fileName={pdfFilename()}
                >
                    {
                        ({blob, url, loading, error}) => (
                            <Button variant="text" color="primary">{loading ? 'Loading..' : 'Download'}</Button>
                        )
                    }
                </PDFDownloadLink>
            </DialogActions>
        </Dialog>
    )
};

export default BarcodeDialog;