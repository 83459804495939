import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";

import {makeStyles} from '@material-ui/styles';
import {
    Grid,
    Typography,
    Button,
    Card,
    CardHeader, Divider, CardContent,
} from "@material-ui/core";
import TelegramIcon from '@material-ui/icons/Telegram';
import Server from "../../Server";
import ZalandoData from "../ZalandoData";

import TableContainer from "@material-ui/core/TableContainer";
import ReactDataGrid from "react-data-grid";
import Info from "./Info";
import SizeGroups from "./SizeGroups";
import ModelImages from "./ProductImages/ModelImages";
import NavigationService from "../../NavigationService";
import Materials from "./Materials";
import LoadingScreen from "../../LoadingScreen";
import Utils from "../../Utils";
import LengthGroups from "./LengthGroups";

const NewZalandoProduct = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const history = useHistory();

    const [productCode, setProductCode] = useState('');

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [outlines, setOutlines] = useState(ZalandoData.outlineLabels());
    const [outline, setOutline] = useState(outlines[0]);

    const [bodyOptions, setBodyOptions] = useState([]);
    const [bodies, setBodies] = useState([]);

    const [sportOptions, setSportOptions] = useState([]);
    const [sports, setSports] = useState([]);
    const allSports = {name:'All sports', code: "all"};

    const [brands, setBrands] = useState(ZalandoData.brands());
    const [brand, setBrand] = useState(brands[0]);

    const [seasons, setSeasons] = useState(ZalandoData.seasons());
    const [season, setSeason] = useState(seasons[0]);

    const [colors, setColors] = useState(ZalandoData.colors());
    const [color, setColor] = useState(colors[0]);

    const [genders, setGenders] = React.useState(() => []);
    const [ages, setAges] = React.useState(() => []);

    const [certificates, setCertificates] = useState([]);
    const [certificate, setCertificate] = useState(null);

    const [materialProperties, setMaterialProperties] = useState(ZalandoData.materialProperties());
    const [materials, setMaterials] = useState(ZalandoData.materials());

    const [materialProperty, setMaterialProperty] = useState(ZalandoData.defaultMaterialProperty());
    const [material1, setMaterial1] = useState(materials[0]);
    const [material2, setMaterial2] = useState(materials[0]);
    const [material3, setMaterial3] = useState(materials[0]);
    const [materialPc1, setMaterialPc1] = useState(0);
    const [materialPc2, setMaterialPc2] = useState(0);
    const [materialPc3, setMaterialPc3] = useState(0);

    const [materialProperty2, setMaterialProperty2] = useState(null);
    const [material4, setMaterial4] = useState(materials[0]);
    const [material5, setMaterial5] = useState(materials[0]);
    const [material6, setMaterial6] = useState(materials[0]);
    const [materialPc4, setMaterialPc4] = useState(0);
    const [materialPc5, setMaterialPc5] = useState(0);
    const [materialPc6, setMaterialPc6] = useState(0);

    const [supplierColor, setSupplierColor] = useState('');

    const [sizeGroups, setSizeGroups] = React.useState(() => ZalandoData.sizeGroups());
    const [sizeGroup, setSizeGroup] = useState(sizeGroups[0]);

    const [lengthGroups, setLengthGroups] = React.useState(() => ZalandoData.lengthGroups());
    const [lengthGroup, setLengthGroup] = useState(lengthGroups[0]);

    const [items, setItems] = useState([]);
    const [selectedItemIndexes, setSelectedItemIndexes] = useState([]);

    const [imageFilesMap, setImageFilesMap] = useState({
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {document.title = 'New ZPP product';}, []);

    useEffect( () => {
        new Server().getAttributeData('outlines', (response) => {
            setOutlines(response.data);
            setOutline(response.data[0]);
        });

        new Server().getAttributeData('assortment_types', (response) => {
            setBodyOptions(response.data);
        });

        new Server().getAttributeData('sport_types', (response) => {
            response.data.unshift(allSports);
            setSportOptions(response.data);
        });

        new Server().getAttributeData('brands', (response) => {
            setBrands(response.data);
            setBrand(response.data[0]);
        });

        new Server().getAttributeData('seasons', (response) => {
            setSeasons(response.data);
            setSeason(response.data[0]);
        });

        new Server().getAttributeData('colors', (response) => {
            setColors(response.data);
            setColor(response.data[0]);
        });

        new Server().getAttributeData('certificates', (response) => {
           setCertificates(response.data);
        });

        new Server().getAttributeData('materials', (response) => {
            setMaterials(response.data);
            setMaterial1(response.data[0]);
            setMaterial2(response.data[0]);
            setMaterial3(response.data[0]);
        });

        new Server().getAttributeData('material_properties', (response) => {
            setMaterialProperties(response.data);
        });

        new Server().getAttributeData('sizegroups', (response) => {
            const allGroups = response.data;
            const sizeGroups = allGroups.filter(group =>
                group.size_type !== 'Length'
            );
            setSizeGroups(sizeGroups);
            setSizeGroup(sizeGroups[0]);
        });

        new Server().getAttributeData('lengthgroups', (response) => {
            const allGroups = response.data;

            var lengthGroups = allGroups.filter(group =>
                group.size_type === 'Length'
            );
            lengthGroups.unshift({
                'code': 'No length group',
                'product_type': '-',
                'gender': '-',
                'size_type': '-',
                'sizes': ['-']
            });
            setLengthGroups(lengthGroups);
            setLengthGroup(lengthGroups[0]);
        });

    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        setItems(items => {
            let rows = items.slice();
            for (let i = fromRow; i <= toRow; i++) {
                rows[i] = { ...rows[i], ...updated };
            }
            return  rows ;
        });
    };

    const addRow = () => {
        const newItems = items.slice();
        newItems.push({id: -Math.random(), sku: "", size:"", ean: "", length:""});
        setItems(newItems);
    };

    const deleteSelected = () => {
        const oldItems = items.slice();
        const newItems = [];
        for(var i=0; i<oldItems.length; i++) {
            if(!selectedItemIndexes.includes(i)) {
                newItems.push(oldItems[i]);
            }
        }
        setItems(newItems);

        //clear selection
        setSelectedItemIndexes([]);
    };

    const onRowsSelected = rows => {
        setSelectedItemIndexes(
            selectedItemIndexes.concat(
                rows.map(r => r.rowIdx)
            )
        );
    };

    const onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.rowIdx);
        setSelectedItemIndexes(
            selectedItemIndexes.filter(
                i => rowIndexes.indexOf(i) === -1
            )
        );
    };

    const onImageSelected = (file, imageIndex) => {
        setImageFilesMap((map) => {
            map[imageIndex] = file;
            return map;
        });

        console.log(JSON.stringify(imageFilesMap));
    };

    const allSportsSelected = () => {
      for(let i=0; i<sports.length; i++) {
          if(sports[i].code === allSports.code){
              return true;
          }
      }
      return false;
    };

    const getSportCodes = () => {
      if(allSportsSelected()) {
          return sportOptions.filter(sportOption => sportOption.code !== 'all').map(sportOption => sportOption.code);
      }
      return sports.map(sport=>sport.code);
    };

    const onSubmit = () => {
        if (!materialProperty) {
            alert('Material property must be set');
            return;
        }

        const productData = {
            product_code: productCode.trim(),
            name: name,
            description: description,
            outline: outline,
            assortment_types: bodies,
            sport_types: getSportCodes(),
            brand_code: brand.code,
            season_code: season.code,
            color_code: color.code,
            supplier_color: supplierColor,
            certificate: certificate?.code,
            ages: ages,
            genders: genders,
            size_group_code: sizeGroup.code,
            length_group_code: lengthGroup.code,

            material_property: materialProperty.name,
            material_code1: material1.code,
            material_code2: material2.code,
            material_code3: material3.code,
            material_pc1: materialPc1,
            material_pc2: materialPc2,
            material_pc3: materialPc3,

            material_property2: materialProperty2?.name,
            material_code4: material4.code,
            material_code5: material5.code,
            material_code6: material6.code,
            material_pc4: materialPc4,
            material_pc5: materialPc5,
            material_pc6: materialPc6,

            skus: items
        };
        console.log('Submitting..');
        console.log(productData);

        setLoading(true);
        new Server().submitZalandoProduct(productData, (response) => {
            setLoading(false);
            console.log(JSON.stringify(response.data));
            if(response.status === 200) {
                if(response.data.status === 'SUCCESS') {
                    alert('Submission successful.');
                    new NavigationService(history).reload();
                } else if (response.data.status === 'VALIDATION_ERRORS') {
                    alert('Data is not valid: \n' + Utils.formatList(response.data.errors, '\n'));
                } else {
                    alert('Zalando rejected submission with error: \n' + JSON.stringify(response.data.zalando_response));
                }
            } else {
                alert('An error occurred: \n' + JSON.stringify(response.data));
            }
        })
    };

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >

                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        New product
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        <TelegramIcon className={classes.buttonIcon} />
                        Submit
                    </Button>
                </Grid>
            </Grid>

            <div className={classes.poTable}>

                <Grid
                    container
                    spacing={2}
                >

                    {/*general product info*/}
                    {/*outlines/brands/seasons/colors/supplier color*/}
                    <Grid item>
                        <Info productCode={productCode} setProductCode={setProductCode}
                              name={name} setName={setName}
                              description={description} setDescription={setDescription}
                              outlines={outlines} outline={outline} setOutline={setOutline}
                              bodyOptions={bodyOptions} bodies={bodies} setBodies={setBodies}
                              sportOptions={sportOptions} sports={sports} setSports={setSports}
                              brands={brands} brand={brand} setBrand={setBrand}
                              seasons={seasons} season={season} setSeason={setSeason}
                              colors={colors} color={color} setColor={setColor}
                              certificates={certificates} certificate={certificate} setCertificate={setCertificate}
                              ages={ages} setAges={setAges}
                              supplierColor={supplierColor} setSupplierColor={setSupplierColor}
                              genders={genders} setGenders={setGenders}
                        />
                    </Grid>

                    {/*materials*/}
                    <Grid item>
                        <Materials
                            materialProperties={materialProperties}
                            materials={materials}

                            materialProperty={materialProperty} setMaterialProperty={setMaterialProperty}
                            material1={material1} setMaterial1={setMaterial1}
                            materialPc1={materialPc1} setMaterialPc1={setMaterialPc1}
                            material2={material2} setMaterial2={setMaterial2}
                            materialPc2={materialPc2} setMaterialPc2={setMaterialPc2}
                            material3={material3} setMaterial3={setMaterial3}
                            materialPc3={materialPc3} setMaterialPc3={setMaterialPc3}

                            materialProperty2={materialProperty2} setMaterialProperty2={setMaterialProperty2}
                            material4={material4} setMaterial4={setMaterial4}
                            materialPc4={materialPc4} setMaterialPc4={setMaterialPc4}
                            material5={material5} setMaterial5={setMaterial5}
                            materialPc5={materialPc5} setMaterialPc5={setMaterialPc5}
                            material6={material6} setMaterial6={setMaterial6}
                            materialPc6={materialPc6} setMaterialPc6={setMaterialPc6}
                        />
                    </Grid>

                    {/*sizegroup*/}
                    <Grid item>
                        <SizeGroups sizeGroups={sizeGroups} sizeGroup={sizeGroup} setSizeGroup={setSizeGroup}/>
                    </Grid>

                    {/*lengthgroup*/}
                    <Grid item>
                        <LengthGroups lengthGroups={lengthGroups} lengthGroup={lengthGroup} setLengthGroup={setLengthGroup}/>
                    </Grid>

                    <Grid item>
                        {/*sku, eans, sizes, lengths*/}
                        <Card>
                            <CardHeader title="SKUs"/>
                            <Divider/>
                            <CardContent>
                                <TableContainer>
                                    <Button onClick={addRow}>Add row</Button>
                                    <Button onClick={deleteSelected}>Delete</Button>
                                    <ReactDataGrid
                                        minWidth={700}
                                        columns={[
                                            {key: "sku", name: "SKU", editable: true, width: 200},
                                            {key: "size", name: "Size", editable: true, width: 70},
                                            {key: "length", name: "Length", editable: true, width: 70},
                                            {key: "ean", name: "EAN", editable: true},
                                        ]}
                                        rowGetter={i => items[i]}
                                        rowsCount={items.length}
                                        onGridRowsUpdated={onGridRowsUpdated}
                                        enableCellSelect={true}
                                        rowSelection={{
                                            showCheckbox: true,
                                            enableShiftSelect: true,
                                            onRowsSelected: onRowsSelected,
                                            onRowsDeselected: onRowsDeselected,
                                            selectBy: {
                                                indexes: selectedItemIndexes
                                            }
                                        }}
                                    />
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <ModelImages productCode={productCode} onImageSelected={onImageSelected}/>
                    </Grid>

                </Grid>

            </div>
            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default NewZalandoProduct;
