import {
    Card,
    IconButton,
    TableHead,
    Table,
    TableRow,
    TableContainer,
    TableCell,
    TableBody, Link
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import Utils from "../../Utils";


const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const DesignTabContent = props => {

    const product = props.product;

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (product?.code === 'ABC-123') return;

        reloadDocuments();
    }, [product, props.hidden]);

    const reloadDocuments = () => {
        new Server().fetchDesignDocuments(product.code, (response) => {
            setDocuments(response.data);
        });
    };

    const deleteDocument = (documentId) => {
        new Server().deleteDesignDocument(product.code, documentId, (response) => {
            reloadDocuments();
        });
    };

    return (
        <Card hidden={props.hidden}>

            <TableContainer>

                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">File name</TableCell>
                            <TableCell size="small">Type</TableCell>
                            <TableCell size="small">Added</TableCell>
                            <TableCell size="small">Last modified</TableCell>
                            <TableCell size="small">Suppliers</TableCell>
                            <TableCell size="small">
                                <IconButton size="small" color="primary"
                                            onClick={() => nav.openDesignDocUpload(product.code, true)}>
                                    <AddCircleIcon size="small"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            documents.map(
                                (document, index) => (
                                    <TableRow key={document.id}>
                                        <TableCell size="small">
                                            <Link href={document.url} rel="noopener noreferrer" target="_blank">
                                                <span>{document.filename}</span>
                                            </Link>
                                        </TableCell>

                                        <TableCell size="small">{document.doc_type}</TableCell>

                                        <TableCell size="small">
                                            {Utils.formatUserAndDate(document.created_by, document.created_date)}
                                        </TableCell>

                                        <TableCell size="small">
                                            {Utils.formatUserAndDate(document.last_modified_by, document.last_modified)}
                                        </TableCell>

                                        <TableCell size="small">
                                            {
                                                document.suppliers.map(
                                                    (supplier, index) => (
                                                        <div key={supplier.id}>
                                                            <span>{supplier.name}</span>
                                                            <br/>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </TableCell>

                                        <TableCell size="small">

                                            <IconButton size="small" color="default"
                                                        onClick={() => deleteDocument(document.id)}>
                                                <RemoveCircleIcon size="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>

            </TableContainer>

            <LoadingScreen loading={loading}/>
        </Card>
    );
};

export default DesignTabContent;