import React from 'react';
import {
     Grid
} from "@material-ui/core";
import PoProduct from "./PoProduct";

const PoProducts = props => {

    const po = props.po;
    const poProducts = props.poProducts;

    return (
        <Grid container direction="column" spacing={2}>
            {
                poProducts.map((poProduct) => (
                    <Grid item key={poProduct.product.code}>
                        <PoProduct po={po}
                                   poProduct={poProduct}
                        />
                    </Grid>
                ))
            }
        </Grid>
  );
};
export default PoProducts;