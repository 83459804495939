import React, {useEffect, useState} from "react";
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton, Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Pricing from "../../Pricing";
import Server from "../../Server";
import Utils from "../../Utils";
import TestData from "../../TestData";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";
import AuthService from "../../AuthService";
import EditInfoDialog from "./EditInfoDialog";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    redText: {
        color: theme.palette.red,
    },
    greenText: {
        color: theme.palette.green,
    }
}));

const Info = props => {

    const number = props.number;

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history)

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [pkg, setPkg] = useState(TestData.packageInfo());

    useEffect(()=>{
        new Server().fetchPackageInfo(number, response => {
            setPkg(response.data);
        });
    }, [])

    const EditButton = (props) => {
        const [open, setOpen] = useState(false);

        const onConfirm = () => {
            nav.reload();
        };

        const onClose = () => {
            setOpen(false);
        };

        return (
            <>
                <IconButton size="small" onClick={(e)=>{setOpen(true)}} disabled={AuthService.isSupplierLoggedIn()}>
                    <EditIcon fontSize="small"/>
                </IconButton>
                <EditInfoDialog open={open} onConfirm={onConfirm} onClose={onClose} number={number}/>
            </>
        )
    };

    const AckText = props => {
        if (pkg.acknowledged) {
            return (
                <span className={classes.greenText}>Supplier acknowledged on {Utils.formatDate(pkg.acknowledged_on, 'DD/MM/YYYY HH:mm')}</span>
            );
        } else {
            return (
                <span className={classes.redText}>Awaiting supplier acknowledgement</span>
            );
        }
    };

    const AckButton = props => {
        const ack = () => {
            if(AuthService.isAdminLoggedIn()) {
                alert("The supplier should acknowledge this package.");
                return;
            }
            new Server().acknowledgePackage(pkg.number, response => {
                if (response.status === 200) {
                    nav.reload();
                } else {
                    console.log(response.data);
                    enqueueSnackbar('Error (see console)', {variant: 'error'});
                }
            })
        };
        if (!pkg.acknowledged) {
            return (
                <Button hidden={pkg.acknowledged} size="small" color="primary" onClick={ack}>
                    acknowledge
                </Button>
            );
        } else {
            return null;
        }
    };

    return(
        <Card>
            <CardHeader title="Package details" action={<EditButton/>}/>

            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>Supplier: {pkg.supplier.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Season: {pkg.season}</TableCell>
                        <TableCell colSpan={2}>Origin: {pkg.coo.name} - {pkg.mode}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ordered: {pkg.total_units} units {Pricing.formatPrice(pkg.total_value, pkg.currency)}</TableCell>
                        <TableCell>Shipped: {pkg.total_shipped} units {Pricing.formatPrice(pkg.total_shipped_value, pkg.currency)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Created: {Utils.formatUserAndDate(pkg.created_by, pkg.created_on)}</TableCell>
                        <TableCell>Updated: {Utils.formatUserAndDate(pkg.updated_by, pkg.updated_on)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell><AckText/></TableCell>
                        <TableCell><AckButton/></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default Info;