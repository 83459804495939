import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardContent, CardHeader, Divider, Grid, TextField} from "@material-ui/core";
import GoogleStackedChart from "./GoogleStackedChart";
import {makeStyles} from "@material-ui/styles";
import Server from "../../../Server";
import {Autocomplete} from "@material-ui/lab";
import moment from "moment";
import Utils from "../../../Utils";
import VolumeOrdersDialog from "./VolumeOrdersDialog";

const useStyles = makeStyles(theme => ({
}));

const OrderVolumeMetrics = props => {

    const classes = useStyles();

    const selectedChannel = props.selectedChannel;
    const fromDate = props.fromDate;
    const toDate = props.toDate;

    const DAILY = "DAILY";
    const WEEKLY = "WEEKLY";
    const MONTHLY = "MONTHLY";

    const [timeScales, setTimeScales] = useState([DAILY, WEEKLY, MONTHLY]);
    const [timeScale, setTimeScale] = useState(DAILY);

    const [metrics, setMetrics] = useState([]);

    const [clickedDay, setClickedDay] = useState('');
    const [clickedChannel, setClickedChannel] = useState('');
    const [openVolumeOrdersDialog ,setOpenVolumeOrdersDialog] = useState(false);

    const onBarClick = (day, channelCode) => {
        setClickedDay(day);
        setClickedChannel(channelCode);
        setOpenVolumeOrdersDialog(true);
    };

    useEffect(() => {
        //Restrict time scales so that the chart does not display more than 30 bars
        var firstDay = moment(fromDate);
        var lastDay = moment(toDate);
        const dayCount = lastDay.diff(firstDay, 'days');
        const weekCount = lastDay.diff(firstDay, 'weeks');
        const monthCount = lastDay.diff(firstDay, 'months');

        let newTimeScales = [];
        //show DAILY when day count <= 31
        if(dayCount <= 31) {
            newTimeScales.push(DAILY);
        }
        if(weekCount <= 30) {
            newTimeScales.push(WEEKLY);
        }
        if(monthCount <= 30) {
            newTimeScales.push(MONTHLY);
        }
        setTimeScales(newTimeScales);
        if(newTimeScales.length){
            if(!Utils.isIn(timeScale, newTimeScales)) {
                setTimeScale(newTimeScales[newTimeScales.length-1]);
            }
        }
    }, [props.fromDate, props.toDate]);

    useEffect(() => {
        new Server().fetchChannelOrderVolumeMetrics(selectedChannel.code, timeScale, fromDate, toDate, (response) => {
            setMetrics(response.data);
        });
    }, [props.fromDate, props.toDate, props.selectedChannel, timeScale]);

    const onTimeScaleChange = (newTimeScale) => {
        setTimeScale(newTimeScale);
    };

    const TimeScaleSelector = () => (
        <Autocomplete
            options={timeScales}
            getOptionLabel={b => b}
            disableClearable
            value={timeScale}
            onChange={(e, v, r) => onTimeScaleChange(v)}
            autoHighlight
            style={{width: 200}}
            size="small"
            renderInput={params => <TextField {...params} label="" variant={"outlined"}/>}
        />
    );

    return (
        <Card>
            <CardHeader title="Order volume" action={<TimeScaleSelector/>}>

            </CardHeader>
            <Divider/>
            <CardContent>
                <GoogleStackedChart rawData={metrics} selectedChannel={props.selectedChannel} onBarClick={onBarClick}/>
                <VolumeOrdersDialog
                    open={openVolumeOrdersDialog}
                    onClose={() => setOpenVolumeOrdersDialog(false)}
                    timeScale={timeScale}
                    day={clickedDay}
                    channelCode={clickedChannel}
                />
            </CardContent>
        </Card>

    );
};

export default OrderVolumeMetrics;