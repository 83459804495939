import {Grid, Typography} from "@material-ui/core";
import React from "react";

const Header = props => {
    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h2"
                    gutterBottom
                    variant="overline"
                >
                    Browse POs
                </Typography>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Purchase Orders
                </Typography>
            </Grid>
            <Grid item>

            </Grid>
        </Grid>
    );
};

export default Header;