import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/styles";
import DeliveriesTable from "./DeliveriesTable";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    poDetails: {
        marginTop: theme.spacing(3)
    }
}));

const Deliveries = props => {
    const classes = useStyles();

    useEffect(() => {document.title = 'Deliveries';}, []);

    return (
        <div className={classes.root}>
            <DeliveriesTable/>
        </div>
    );
}

export default Deliveries;