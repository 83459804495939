import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Card, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";


const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = (props) => {
    const classes = useStyles();
    const {
        channels, selectedChannel, setSelectedChannel,
        fromDate, setFromDate,
        toDate, setToDate,
        resultCountOptions, selectedResultCount, setSelectedResultCount,
        onApplyFilters, onClearFilters,
    } = props;


    const onApplyFilterClick = () => {
        onApplyFilters();
    };

    const onClearFilterClick = () => {
        onClearFilters();
    };

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" direction={"row"}>

                    <Grid item>
                        {/*channel*/}
                        <Autocomplete
                            options={channels}
                            getOptionLabel={channel => channel.name}
                            value={selectedChannel}
                            onChange={(e, v, r) => setSelectedChannel(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Channel" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="From"
                            format="DD/MM/YYYY"
                            value={fromDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="To"
                            format="DD/MM/YYYY"
                            value={toDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        {/*result count*/}
                        <Autocomplete
                            options={resultCountOptions}
                            getOptionLabel={o => o.name}
                            value={selectedResultCount}
                            onChange={(e, v, r) => setSelectedResultCount(v)}
                            autoHighlight
                            // style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Result count" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Button color="primary" onClick={onApplyFilterClick}>
                            Apply
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={onClearFilterClick}>
                            Clear
                        </Button>
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;