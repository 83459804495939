import React, {useEffect, useRef, useState} from "react";
import Server from "../../Server";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingScreen from "../../LoadingScreen";
import EditIcon from "@material-ui/icons/Edit";
import Utils from "../../Utils";

const EditQAReportDialog = props => {
    const {reportId, open, onClose, onConfirm} = props;

    const isEditing = reportId !== undefined;

    const fileInputRef = useRef();

    const [reportNumber, setReportNumber] = useState('');
    const [productCodes, setProductCodes] = useState([]);
    const [selectedProductCodes, setSelectedProductCodes] = useState([]);

    const [packageNumbers, setPackageNumbers] = useState([]);
    const [selectedPackageNumbers, setSelectedPackageNumbers] = useState([]);

    const [poNumbers, setPoNumbers] = useState([]);
    const [selectedPoNumbers, setSelectedPoNumbers] = useState([]);

    const [expiryDate, setExpiryDate] = useState(null);
    const [fileName, setFileName] = useState('');
    const [newFile, setNewFile] = useState(null);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if(open){
            new Server().fetchPONumbers((response) => {
                setPoNumbers(response.data);
            });
            new Server().fetchPackageNumbers(response => {
                setPackageNumbers(response.data);
            });

            new Server().fetchProductCodes((response) => {
                setProductCodes(response.data);
            });

            if(isEditing) {
                new Server().fetchQAReport(reportId, (response) => {
                    const report = response.data;
                    setReportNumber(report.number);
                    setSelectedProductCodes(report.products.map(p => p.code));
                    setSelectedPoNumbers(report.pos.map(po => po.number));
                    setSelectedPackageNumbers(report.packages.map(pkg => pkg.number));
                    setExpiryDate(report.expiry_date);
                    setFileName(report.filename);
                });
            }

        }
    },[open])

    const confirm = () => {
        if(!reportNumber) {
            alert("Report number required.");
            return;
        }

        if(!expiryDate) {
            alert("Expiry date required.");
            return;
        }

        if(!selectedProductCodes || selectedProductCodes.length === 0){
            alert("Specify product codes this report relates to.");
            return;
        }

        if ((!selectedPoNumbers || selectedPoNumbers.length === 0) && (!selectedPackageNumbers || selectedPackageNumbers.length === 0)) {
            alert("Specify PO/package numbers this report relates to.");
            return;
        }

        if(!isEditing && !newFile) {
            alert("Select a report file.")
            return;
        }

        setLoading(true);

        if(isEditing) {
            new Server().editQAReport(reportId, reportNumber, selectedProductCodes, selectedPackageNumbers, selectedPoNumbers, newFile, Utils.formatDate(expiryDate, 'DD/MM/YYYY'), (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert('Error: \n' + JSON.stringify(response.data));
                    return;
                }
                resetFields();
                onConfirm();
            });
        } else {
            new Server().addQAReport(reportNumber, selectedProductCodes, selectedPackageNumbers, selectedPoNumbers, newFile, Utils.formatDate(expiryDate, 'DD/MM/YYYY'), (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert('Error: \n' + JSON.stringify(response.data));
                    return;
                }
                resetFields();
                onConfirm();
            });
        }
    };

    const resetFields = () => {
        setReportNumber('');
        setSelectedProductCodes([]);
        setSelectedPoNumbers([]);
        setSelectedPackageNumbers([]);
        setExpiryDate(null);
        setNewFile(null);
        setFileName('');
        fileInputRef.current.value = null;
    };

    const SelectFileButton = () => (
        <div>
            <input type="file"
                   ref={fileInputRef}
                   onChange={(e) => selectFile(e.target.files)}
                   hidden/>

            <Tooltip title="Select new file">
                <IconButton size={"small"} onClick={(e) => fileInputRef.current.click()}>
                    <EditIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
        </div>
    );

    const selectFile = (files) =>{
        if (files.length === 0) {
            return;
        }

        if (files.length > 1) {
            alert("Only 1 file can be selected.")
            return;
        }

        setNewFile(files[0]);
        setFileName(files[0].name);
        fileInputRef.current.value = null;
    };

    const fileDescription = () => {
        if(fileName) {
            return fileName;
        } else {
            if(newFile) {
                return newFile.name;
            } else {
                return 'Select a file';
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} disableBackdropClick={true}>
            <DialogTitle disableTypography><Typography variant="h4">{isEditing ? "Edit": "New"} QA report</Typography></DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={2}>

                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={6}>
                                <TextField label="Report number" variant="outlined"
                                           size="small"
                                           fullWidth
                                           value={reportNumber}
                                           onChange={(e) => setReportNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    size={"small"}
                                    fullWidth
                                    inputVariant="outlined"
                                    autoOk
                                    label="Expiry date"
                                    format="DD/MM/YYYY"
                                    value={expiryDate}
                                    InputAdornmentProps={{position: "start"}}
                                    onChange={date => setExpiryDate(date)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            size="small"
                            options={productCodes}
                            getOptionLabel={s => s}
                            value={selectedProductCodes}
                            onChange={(e, v, r) => setSelectedProductCodes(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Product codes" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            size="small"
                            options={packageNumbers}
                            getOptionLabel={s => s}
                            value={selectedPackageNumbers}
                            onChange={(e, v, r) => setSelectedPackageNumbers(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="Package numbers" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            size="small"
                            options={poNumbers}
                            getOptionLabel={s => s}
                            value={selectedPoNumbers}
                            onChange={(e, v, r) => setSelectedPoNumbers(v)}
                            autoHighlight
                            fullWidth
                            renderInput={params => <TextField {...params} label="PO numbers" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" justifyContent={"center"} alignItems={"center"}>

                            <Grid item>
                                <Typography>{fileDescription()}</Typography>
                            </Grid>

                            <Grid item>
                                <SelectFileButton/>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
};

export default EditQAReportDialog;