import React, {Component} from 'react'
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Routes from './Routes';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {SnackbarProvider} from 'notistack';

const browserHistory = createBrowserHistory();

export default class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={1000}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Router history={browserHistory}>
                            <Routes/>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>

            </ThemeProvider>
        );
    }
}
