import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Server from "../Server";

const OrderFinderDialog = props => {
    const [orderNumber, setOrderNumber] = useState("");
    const [order, setOrder] = useState(null);
    const [invalidOrderNumber, setInvalidOrderNumber] = useState(false);

    const findOrder = () => {
        const responseHandler = (response) => {
            if (response.status !== 200) {
                alert('An error occurred: \n' + response.status);
                return;
            }
            setOrder(response.data);
        };
        new Server().fetchOrder(orderNumber, responseHandler);
    };

    const onOrderNumberChange = (e) => {
        setOrderNumber(e.target.value);
        setInvalidOrderNumber(!containsNumbersOnly(e.target.value));
    };

    const containsNumbersOnly = (value) => {
        let regex = /\d+$/;
        return value === "" || value.match(regex);
    };

    return (
        <Dialog open={props.open} onClose={props.onCancel} fullWidth={false}
            maxWidth={'lg'}>
            <DialogTitle disableTypography><Typography variant="h4">Order finder</Typography></DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                >

                    <Grid item>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <TextField fullWidth label="Order number"
                                    value={orderNumber}
                                    onChange={onOrderNumberChange}
                                    error={invalidOrderNumber}
                                    helperText={invalidOrderNumber ? "Invalid entry" : ""}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    onClick={findOrder}
                                >Find
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        {order !== null ?
                            <div>
                                <Typography>Order number: {order.number}</Typography>
                                <Typography>Order date: {order.date}</Typography>
                                <Typography>Delivery date: {order.delivery_date}</Typography>
                                <Typography>First name: {order.first_name}</Typography>
                                <Typography>Last name: {order.last_name}</Typography>
                                <Typography>Address line 1: {order.address_line_1}</Typography>
                                <Typography>Address line 2: {order.address_line_2}</Typography>
                                <Typography>Address line 3: {order.address_line_3}</Typography>
                                <Typography>Zipcode: {order.zip_code}</Typography>
                                <Typography>City: {order.city}</Typography>
                                <Typography>Country code: {order.country_code}</Typography>
                                {order.order_items.map((orderItem) =>
                                    <Typography key={orderItem.id}>{orderItem.order_lines.length} * {orderItem.variant_code} {orderItem.description} {orderItem.order_lines[0].discounted_price} {order.currency}</Typography>
                                )}
                                <Typography>Total order amount: {order.amount} {order.currency}</Typography>
                            </div>
                            : null}

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>)
};

export default OrderFinderDialog;