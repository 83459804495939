import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {Button, Grid, Typography, Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Utils from "../../Utils";
import Server from "../../Server";
import PublishIcon from "@material-ui/icons/Publish";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import NavigationService from "../../NavigationService";
import ReceivePOShipmentDialog from "./ReceivePOShipmentDialog";
import AuthService from "../../AuthService";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CropFreeIcon from '@material-ui/icons/CropFree';
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingScreen from "../../LoadingScreen";
import ExcelPlUploadDialog from "./ExcelPlUpload/ExcelPlUploadDialog";
import BarcodeDialog from "./BarcodeDialog";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const POShipmentDetailsHeader = props => {

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const shipment = props.shipment;
    const shipmentProducts = props.shipmentProducts;

    const [openReceiveDialog, setOpenReceiveDialog] = useState(false);
    const [openExcelPlUploadDialog, setOpenExcelPlUploadDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const submitShipment = () => {

        setLoading(true);
        new Server().submitShipment(shipment.number, response => {
            setLoading(false);
            if(response.status !== 200) {
                alert(response.data);
                return;
            }
            alert("Shipment submitted.");
            window.location.reload();
        });
    };

    const receiveShipment = () => {
        setOpenReceiveDialog(true);
    };

    const onReceiveDialogClose = () => {
        setOpenReceiveDialog(false);
    };

    const onReceiveDialogConfirm = () => {
        setOpenReceiveDialog(false);
        nav.reload();
    }

    const deleteShipment = () => {
      if(window.confirm('This shipment will be deleted. This action cannot be undone. Proceed?')) {
          setLoading(true);
          new Server().deleteShipment(shipment.number, (response) => {
              setLoading(false);
              if(response.status === 200) {
                  nav.goBack();
              } else {
                  alert('Error occured: \n' + JSON.stringify(response.data, null, 2));
              }
          });
      }
    };

    const deletePackingLists = () => {
        if(window.confirm('All packing lists in this shipment will be deleted. This action cannot be undone. Proceed?')) {
            setLoading(true);
            new Server().deleteShipmentPackingLists(shipment.number, (response) => {
                setLoading(false);
                if(response.status === 200) {
                    nav.reload();
                } else {
                    alert('Error occured: \n' + JSON.stringify(response.data, null, 2));
                }
            });
        }
    };

    const ExportMenuButton = () => {
        const [anchorEl, setAnchorEl] = useState(null);

        const openMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const closeMenu = () => {
            setAnchorEl(null);
        };

        const onTotalsCsvClick = () => {
            closeMenu();

            setLoading(true);
            new Server().downloadTotalsCsv(shipment.number, (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert(response.data);
                }
            });
        };

        const onChecklistClick = () => {
            closeMenu();

            setLoading(true);
            new Server().downloadShipmentChecklist(shipment.number, (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert(response.data);
                }
            });
        };

        const onPLCsvClick = () => {
            closeMenu();

            setLoading(true);
            new Server().downloadShipmentPLCsv(shipment.number, (response) => {
                setLoading(false);
                if(response.status !== 200) {
                    alert(response.data);
                }
            });
        };

        return (
            <div>
                <Button variant="contained" onClick={openMenu}>
                    <CloudDownloadIcon className={classes.buttonIcon}/>
                    Export
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                >
                    <MenuItem onClick={onTotalsCsvClick}>Totals csv</MenuItem>
                    {(shipment.status !== 'DRAFT' && AuthService.isAdminLoggedIn()) ? <MenuItem onClick={onChecklistClick}>Checklist</MenuItem> : null}
                    {(shipment.status !== 'DRAFT' && AuthService.isAdminLoggedIn()) ? <MenuItem onClick={onPLCsvClick}>PL csv</MenuItem> : null}

                </Menu>
            </div>
            );
    };

    const UploadExcelPlButton = () => (
        <Button
            variant="contained"
            onClick={() => setOpenExcelPlUploadDialog(true)}
        >
            <CloudUploadIcon  className={classes.buttonIcon}/>
            Excel
        </Button>
    );

    const NumberBarcodeButton = () => {
        const [openBarcodeDialog, setOpenBarcodeDialog] = useState(false);

        return (
            <div>
                <Button
                    variant="contained"
                    onClick={() => setOpenBarcodeDialog(true)}
                >
                    <CropFreeIcon  className={classes.buttonIcon}/>
                    Barcode
                </Button>
                <BarcodeDialog open={openBarcodeDialog} onClose={() => setOpenBarcodeDialog(false)} shipmentNumber={shipment?.number}/>
            </div>
        )
    };

    const SubmitButton = () => (
        <Button
            color="primary"
            variant="contained"
            onClick={submitShipment}
        >
            <PublishIcon className={classes.buttonIcon}/>
            Submit
        </Button>
    );

    const ReceiveButton = () => ( AuthService.isAdminLoggedIn() ?
        <Button
            color="primary"
            variant="contained"
            onClick={receiveShipment}
        >
            <AssignmentReturnedIcon className={classes.buttonIcon}/>
            Receive
        </Button> : null
    );

    const DeleteButton = () => (
        <Button
            variant="contained"
            onClick={deleteShipment}
        >
            <DeleteIcon className={classes.buttonIcon} />
            Delete
        </Button>
    );

    const DeletePLSButton = () => (
        <Button
            variant="contained"
            onClick={deletePackingLists}
        >
            <DeleteIcon className={classes.buttonIcon} />
            Delete PLS
        </Button>
    );

    const showDeleteButton = () => {
        return (Utils.isIn(shipment.status, ['DRAFT', 'TRANSIT']) && AuthService.isAdminLoggedIn());
    };

    const showDeletePLSButton = () => {
        return (Utils.isIn(shipment.status, ['DRAFT', 'TRANSIT']));
    };

    return (
        <div>
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Shipment details
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        <Grid container direction="row" spacing={1}>

                            <Grid item>{shipment.number}</Grid>

                            <Grid item>{shipment.status}</Grid>
                        </Grid>
                    </Typography>
                </Grid>

                {/*buttons*/}
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ExportMenuButton/>
                        </Grid>
                        <Grid item>
                            <NumberBarcodeButton/>
                        </Grid>
                        <Grid item>
                            {(shipment.status !== 'RECEIVED') ? <UploadExcelPlButton/> : null}
                        </Grid>
                        <Grid item>
                            {showDeletePLSButton() ? <DeletePLSButton/> : null}
                        </Grid>
                        <Grid item>
                            {showDeleteButton() ? <DeleteButton/> : null}
                        </Grid>
                        <Grid item>
                            {(shipment.status === 'DRAFT') ? <SubmitButton/> : null}
                        </Grid>
                        <Grid item>
                            {(shipment.status === 'TRANSIT') ? <ReceiveButton/> : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <ReceivePOShipmentDialog
                open={openReceiveDialog}
                onClose={onReceiveDialogClose}
                onConfirm={onReceiveDialogConfirm}
                shipment={shipment}
                />

            <ExcelPlUploadDialog open={openExcelPlUploadDialog} onClose={() => setOpenExcelPlUploadDialog(false)} shipment={shipment}/>

            <LoadingScreen loading={loading}/>
        </div>
    );
};

export default POShipmentDetailsHeader;