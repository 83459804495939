import React, {useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Server from "../../Server";
import Utils from "../../Utils";
import LoadingScreen from "../../LoadingScreen";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import NavigationService from "../../NavigationService";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const CsvUploadDialog = props => {

    const classes = useStyles();
    const history = useHistory();
    const nav = new NavigationService(history);

    const po = props.po;

    const poCsvUploadRef = useRef();

    const [loading, setLoading] = useState(false);

    const POCsvUploadButton = () => (
        <Button variant="default" component="label" color="primary">
            <CloudUploadIcon  className={classes.buttonIcon} color="primary"/>
            upload
            <input
                type="file"
                ref={poCsvUploadRef}
                style={{ display: "none" }}
                hidden
                onChange={(e) => {handlePoCsvFileSelection(e.target.files)}}
            />
        </Button>
    );

    const handlePoCsvFileSelection = (files) => {
        if(!files) {
            alert('No file selected!');
            poCsvUploadRef.current.value = null;
            return;
        }
        setLoading(true);
        new Server().uploadPOCsv(po.number, files[0], (response) => {
            setLoading(false);
            if(response.status === 200) {
                nav.reload();
            } else if(response.status === 400) {
                alert(Utils.formatList(response.data, '\n'));
            } else {
                alert(response.data);
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">CSV Upload</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            - Expected headers: sku,quantity,price
                            <br/>
                            - Quantities and prices will be overwritten for skus already on the PO.
                            <br/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <POCsvUploadButton/>
                    </Grid>
                </Grid>
            </DialogContent>
            <LoadingScreen loading={loading}/>
        </Dialog>
    )
};

export default CsvUploadDialog;