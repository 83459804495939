import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import React, {useState} from "react";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/styles";
import SyncIcon from '@material-ui/icons/Sync';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import UploadTvgCodesDialog from "./UploadTvgCodesDialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import UploadVariantsDialog from "./UploadVariantsDialog";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ToggleChannelsDialog from "./ToggleChannelsDialog";
import JLPDialog from "./JLPDialog";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {
    const refreshData = props.refreshData;

    const classes = useStyles();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const JlpDialogButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        const onConfirm = () => {
            setOpenDialog(false);
            refreshData();
        }
        return (
            <>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                    <SettingsIcon className={classes.buttonIcon}/>
                    JLP
                </Button>
                <JLPDialog open={openDialog} onConfirm={onConfirm} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const exportVariantsCsv = () => {
        setLoading(true);
        new Server().downloadChannelVariantCsv(response => {
            setLoading(false);
            if (response.status === 200) {
                enqueueSnackbar('Variants exported to CSV', {variant: 'success'});
            } else {
                console.log(response.data);
                enqueueSnackbar('Error (see console)', {variant: 'error'});
            }
        });
    };

    const ExportButton = () => (
        <Button
            variant="contained"
            onClick={exportVariantsCsv}
        >
            <CloudDownloadIcon className={classes.buttonIcon}/>
            CSV
        </Button>
    );

    const UploadTvgIdsButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        const onConfirm = () => {
            refreshData();
            setOpenDialog(false);
        }
        return (
            <>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                    <CloudUploadIcon size={"small"} className={classes.buttonIcon}/>
                    TVG codes
                </Button>
                <UploadTvgCodesDialog open={openDialog} onConfirm={onConfirm} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const UploadVariantsButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        const onConfirm = () => {
            setOpenDialog(false);
            refreshData();
        }
        return (
            <>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                    <AddCircleIcon size={"small"} className={classes.buttonIcon}/>
                    New variants
                </Button>
                <UploadVariantsDialog open={openDialog} onConfirm={onConfirm} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    const ToggleChannelsButton = () => {
        const [openDialog, setOpenDialog] = useState(false);
        const onConfirm = () => {
            setOpenDialog(false);
            refreshData();
        }
        return (
            <>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                    <CheckCircleOutlineIcon size={"small"} className={classes.buttonIcon}/>
                    Toggle channels
                </Button>
                <ToggleChannelsDialog open={openDialog} onConfirm={onConfirm} onClose={() => setOpenDialog(false)}/>
            </>
        )
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Variants
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>

                    <Grid item>
                        <ToggleChannelsButton/>
                    </Grid>

                    <Grid item>
                        <UploadTvgIdsButton/>
                    </Grid>

                    <Grid item>
                        <JlpDialogButton/>
                    </Grid>

                    <Grid item>
                        <ExportButton/>
                    </Grid>

                    <Grid item>
                        <UploadVariantsButton/>
                    </Grid>

                </Grid>
            </Grid>

            <LoadingScreen loading={loading}/>
        </Grid>
    );
}

export default Header;