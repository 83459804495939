import {
    Card,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Typography, IconButton
} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";


const LinesheetReportProductsTable = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        tableContainer: {
            maxHeight: '100%',
        },
        productImage: {
            float: 'left',
            width:  50,
            height: 50,
            objectFit: 'cover',
            borderRadius: '25%',
        },

    }));

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>SKU</TableCell>
                            {
                                props.sizeGroupOption?.sizes.map((size) => (
                                    <TableCell key={size.name}>{size.name}</TableCell>
                                ))
                            }
                            <TableCell>Total</TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>Wholesale GBP</TableCell>
                            <TableCell>{null}</TableCell>
                        </TableRow>
                    </TableHead>

                        <TableBody>
                            {
                                props.linesheetSection && props.linesheetSection['rows'].map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {row['image_url'] ? <img className={classes.productImage} src={row['image_url']} alt="product"/> : <img className={classes.productImage} src={'/images/no-image.svg'} alt="product"/>}
                                    </TableCell>
                                    <TableCell>
                                    <div>
                                    <Typography>{row['sku']}</Typography>
                                    </div>
                                    </TableCell>
                                    {
                                        props.sizeGroupOption?.sizes.map((size) => (
                                            <TableCell key={size.name}>{row.sizes[size.name] ? row.sizes[size.name]: null}</TableCell>
                                        ))
                                    }
                                    <TableCell>{row['total']}</TableCell>
                                    <TableCell>{row['rrp']}</TableCell>
                                    <TableCell>{row['wholesale_gbp']}</TableCell>
                                    <TableCell>{row['eta']}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" onClick={() => props.deleteLinesheetRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                ))
                            }
                        </TableBody>

                </Table>
            </TableContainer>

        </Card>
    );
};

export default LinesheetReportProductsTable;