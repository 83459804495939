import React, {useEffect} from 'react'
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography, Button} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ZalandoProductsTable from "./ZalandoProductsTable";
import Server from "../Server";
import LoadingScreen from "../LoadingScreen";

const ZalandoProducts = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3)
        },
        poTable: {
            marginTop: theme.spacing(3)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {document.title = 'ZPP Products';}, []);

    const downloadReport = () => {
        setLoading(true);
        new Server().downloadZalandoStockReport((response) => {
            setLoading(false);
            if(response.status !== 200) {
                alert(response.data);
            }
        });
    };

    return (
        <div className={classes.root}>

            {/*header*/}
            <Grid
                alignItems="flex-end"
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Products
                    </Typography>
                </Grid>
                <Grid item>

                    <Button
                        color="default"
                        variant="contained"
                        onClick={(event) => {
                            downloadReport();
                        }}
                    >
                        <CloudDownloadIcon className={classes.buttonIcon} />
                        Stock report
                    </Button>

                </Grid>
            </Grid>


            {/*content start*/}
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >

                <Grid item>
                    <ZalandoProductsTable/>
                </Grid>

            </Grid>
            {/*content end*/}

            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default ZalandoProducts;
