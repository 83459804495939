import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import Header from './Header';
import SupplierShipmentsTable from "./SupplierShipmentsTable";
import NavigationService from "../NavigationService";
import AuthService from "../AuthService";
import Server from "../Server";
import StockPager from "../Stock/StockPager";
import LoadingScreen from "../LoadingScreen";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },
    conversationPlaceholder: {
        flexGrow: 1
    },
    poDetails: {
        marginTop: theme.spacing(3)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    tableContainer: {
        overflowY: 'scroll'
    },
    invoiceCell: { display:'flex', justifyContent: 'space-between'},
    errorIcon: {
        fill: theme.palette.red,
        marginLeft: '4px'
    },
    successIcon: {
        color: theme.palette.green,
        marginLeft: '4px'
    },

}));

const SupplierPOShipments = props => {

    const classes = useStyles();

    const history = useHistory();
    const nav = new NavigationService(history);

    const [loading, setLoading] = useState(false);
    const [shipments, setShipments] = useState([]);

    const [tabIndex, setTabIndex] = useState(1);

    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Shipments';}, []);

    useEffect(() => {
        fetchPoShipmentsByStatus(currentPageNumber, pageSize, tabIndex);
    }, []);

    const onTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        fetchPoShipmentsByStatus(1, pageSize, newTabIndex);
    };

    const getSelectedStatus = (statusIndex) => {
        switch(statusIndex) {
            case 0:
                return 'DRAFT';
            case 1:
                return 'TRANSIT';
            case 2:
                return 'RECEIVED';
        };
    };

    const fetchPoShipmentsByStatus = (pageNumber, pageSize, statusIndex) => {
        let status = getSelectedStatus(statusIndex);
        setLoading(true);
        new Server().fetchSupplierPoShipments(AuthService.loggedInId(), status, pageNumber, pageSize, (response) => {
            setLoading(false);
            setShipments(response.data);
            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
        });
    };

    const onPageSizeChange = (event) => {
        fetchPoShipmentsByStatus(currentPageNumber, event.target.value, tabIndex);
    };

    const onNextPageClick = (event) => {
        fetchPoShipmentsByStatus(currentPageNumber + 1, pageSize, tabIndex)
    };

    const onPreviousPageClick = (event) => {
        fetchPoShipmentsByStatus(currentPageNumber - 1, pageSize, tabIndex)
    };

    return (
        <div className={classes.root}>

            <Header tabIndex={tabIndex} onTabChange={onTabChange}/>

            <SupplierShipmentsTable shipments={shipments} className={classes.conversationPlaceholder}/>

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    )
};

export default SupplierPOShipments;