import React, {useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Server from "../Server";
import AuthService from "../AuthService";
import {useHistory} from "react-router-dom";
import NavigationService from "../NavigationService";

const NewPOShipmentDialog = props => {

    const history = useHistory();
    const nav = new NavigationService(history)

    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);

    const [currency, setCurrency] = useState('USD');
    const currencies = ['USD', 'GBP', 'EUR'];

    useEffect(()=>{
        new Server().fetchSuppliers(response => {
            setSuppliers(response.data);
            if(AuthService.isSupplierLoggedIn()) {
                presetSupplier(response.data);
            }
        })
    },[props.open]);

    const presetSupplier = (allSuppliers) => {
        const loggedin_supplier_id = parseInt(AuthService.loggedInId());
        for(let i=0; i<allSuppliers.length; i++) {
            if (allSuppliers[i].id === loggedin_supplier_id) {
                setSupplier(allSuppliers[i]);
                break;
            }
        }
    };

    const onConfirm = () => {
        if(!supplier) {
            alert("Select a supplier");
            return;
        }
        if(!currency) {
            alert("Select a currency");
            return;
        }

        new Server().newPoShipment(currency, supplier.id, (response) => {
            if (response.status !== 200) {
                alert(response.data);
                return;
            }
            AuthService.isAdminLoggedIn() ? nav.goToPoShipmentDetails(response.data.number) : nav.goToSupplierPOShipmentDetails(response.data.number)
        });
    };

    const onCancel = () => {
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} disableBackdropClick={true}>
            <DialogTitle disableTypography><Typography variant="h4">New shipment</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={suppliers}
                            getOptionLabel={s => s.name}
                            value={supplier}
                            onChange={(e, v, r) => setSupplier(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Supplier" variant="outlined"/>}
                            disabled={AuthService.isSupplierLoggedIn()}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={currencies}
                            getOptionLabel={t => t}
                            value={currency}
                            onChange={(e, v, r) => setCurrency(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPOShipmentDialog;