import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import React, {useState} from "react";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {
    const {
        channels, selectedChannel,
        fromDate,
        toDate,
        selectedResultCount,
    } = props;

    const classes = useStyles();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const exportExcel = () => {
        setLoading(true);
        new Server().downloadTopSellingReport('xlsx', channels, fromDate, toDate, selectedResultCount.value, response => {
            setLoading(false);
            if (response.status === 200) {
                enqueueSnackbar('Top selling excel report generated', {variant: 'success'});
            } else {
                console.log(response.data);
                enqueueSnackbar('Error (see console)', {variant: 'error'});
            }
        });
    };

    const ExcelButton = () => (
        <Button
            variant="contained"
            onClick={exportExcel}
        >
            <CloudDownloadIcon className={classes.buttonIcon}/>
            Excel
        </Button>
    );

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Top selling products
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1}>

                    <Grid item>
                        <ExcelButton/>
                    </Grid>

                </Grid>
            </Grid>


            <LoadingScreen loading={loading}/>
        </Grid>
    );
}

export default Header;