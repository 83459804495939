import React, {useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import Server from "./Server";
import NavigationService from "./NavigationService";
import {
    Grid,
    Button,
    TextField, Card
} from '@material-ui/core';
import AuthService from "./AuthService";


const useStyles = makeStyles(theme => ({
    root: {
        width: 350,
        padding: theme.spacing(2),
    },
}));

const SignIn = props => {

    const classes = useStyles();
    const history = useHistory();
    const navigation = new NavigationService(history);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = event => {
        // event.preventDefault();

        if (email === undefined || email.length === 0 || password === undefined || password.length === 0) {
            alert('Input your email and password');
            return
        }

        new Server().login(email, password, (response) => {

            if (response.status === 400) {
                alert(response.data);
                return
            }
            if (response.status === 200) {
                let id = response.data.auth_id;
                let name = response.data.auth_name;
                let is_supplier = response.data.is_supplier;
                let permissions = response.data.permissions;
                AuthService.storeAuthDetails(id, name, is_supplier, permissions);

                if (response.data.is_supplier) {
                    history.push('/SupplierConsolidatedPackages')
                } else {
                    navigation.goToPermittedPage();
                }
            }
        })
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            login();
        }
    };

    return (
        <Card className={classes.root}>
            <Grid container direction={'column'} spacing={1}>
                <Grid item>
                    <TextField
                        fullWidth
                        size="small"
                        label="Email address"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        value={email}
                        variant="filled"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        size="small"
                        label="Password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        value={password}
                        variant="filled"
                        onKeyDown={onKeyDown}
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={(e) => login(e)}
                    >
                        Login
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
};

export default withRouter(SignIn);
