import AuthService from "./AuthService";

class NavigationService {

    constructor(history) {
        this.history = history;
    }

    static LOGIN = "/auth/login";

    static CHANNELS_OVERVIEW = "/Channels";
    static CHANNEL_ORDERS = "/ChannelOrders";
    static CHANNEL_ORDER_DETAILS = "/ChannelOrders/:id";
    static DELIVERIES = "/deliveries";
    static CHANNEL_VARIANTS = "/ChannelVariants";
    static CHANNEL_VARIANT_DETAILS = "/ChannelVariants/:id";
    static TOP_SELLING = "/TopSelling";

    static TRANSIT_PRODUCTS = "/transitProducts";

    static CONSOLIDATED_PACKAGES = "/ConsolidatedPackages";
    static CONSOLIDATED_PACKAGE_DETAILS = "/ConsolidatedPackages/:id";

    static PURCHASE_ORDERS = "/PurchaseOrders";
    static PO_DETAILS = "/PoDetails/:poNumber";
    static PO_SHIPMENT_DETAILS = "/PoShipmentDetails/:shNumber";

    static PO_SHIPMENTS = "/POShipments";

    static SUPPLIERS = "/suppliers";
    static CUSTOMERS = "/customers";
    static PORTS = "/ports";
    static USERS = "/users";

    static PRODUCTS = "/Products";
    static EDIT_PRODUCT = "/EditProduct/:productCode";
    static PRODUCT_DETAILS = "/ProductDetails/:productCode";
    static DESIGN_DOC_UPLOAD = "/DesignDocUpload/:productCode";
    static DESIGN_DOCS = "/DesignDocs";

    static SIZE_RATIOS = "/SizeRatios";

    static STOCK = "/Stock";

    static REPORTS = "/Reports";
    static STOCK_REPORT = "/StockReport";
    static PRODUCTION_REPORT = "/ProductionReport";
    static LINESHEET_REPORT = "/LinesheetReport";
    static DELIVERY_REPORT = "/DeliveryReport";
    static GENERIC_REPORT = "/GenericReport";

    static ZALANDO = "/Zalando";
    static ZALANDO_PRODUCTS = "/Zalando/Products";
    static NEW_ZALANDO_PRODUCT = "/Zalando/NewProduct";
    static ZALANDO_ORDERS = "/Zalando/Orders";
    static ZALANDO_BULK_UPLOAD = "/Zalando/BulkUpload"

    static DEBITS = "/debits";
    static NEW_DEBIT = "/newdebit";
    static DEBIT_DETAILS = "/debitdetails/:debitNoteNumber";

    static SUPPLIER_CONSOLIDATED_PACKAGES = "/SupplierConsolidatedPackages";
    static SUPPLIER_PACKAGE_DETAILS = "/SupplierConsolidatedPackages/:id";

    static SUPPLIER_DESIGN_DOCS = "/SupplierDesignDocs";
    static SUPPLIER_POS = "/SupplierPos";
    static SUPPLIER_PO_DETAILS = "/SupplierPoDetails/:poNumber";
    static SUPPLIER_PO_SHIPMENTS = "/SupplierPOShipments"
    static SUPPLIER_PO_SHIPMENT_DETAILS = "/SupplierPOShipmentDetails/:shNumber";

    static DESTINATIONS = "/Destinations";

    static NOT_FOUND = "/not-found";
    static WELCOME = "/welcome";
    static PDF_BUILDER = "/pdfbuilder";

    goToPermittedPage() {
        if(AuthService.hasNoPermissions()) {
            alert('You have no permissions set on your account. Permissions can be added by an admin.');
            return;
        }

        this.history.push(NavigationService.getPermittedAdminPath());

        return;
    };

    goTo(route) {
        this.history.push(route);
    }

    goToConsolidatedPackages() {
        if(!AuthService.canViewConsolidatedPackages()) {
            alert('You do not have permission to view consolidated packages.');
            return;
        }
        this.openPage(false, NavigationService.CONSOLIDATED_PACKAGES);
    }

    goToConsolidatedPackage(number) {
        let path = '';
        if(AuthService.isSupplierLoggedIn()) {
            path = NavigationService.SUPPLIER_PACKAGE_DETAILS.replace(':id', number);
        } else{
            path = NavigationService.CONSOLIDATED_PACKAGE_DETAILS.replace(':id', number);
            if(!AuthService.canViewConsolidatedPackages()) {
                alert('You do not have permission to view consolidated packages.');
                return;
            }
        }
        this.openPage(false, path);
    }

    getChannelOrderDetailsPath(rcm_order_id) {
        return NavigationService.CHANNEL_ORDER_DETAILS.replace(':id', rcm_order_id);
    }

    goToChannelOrderDetails(rcm_order_id, newBrowserTab=false) {
        const path = this.getChannelOrderDetailsPath(rcm_order_id);
        if(!AuthService.canViewChannelOrders()) {
            alert('You do not have permission to view channel orders.');
            return;
        }
        this.openPage(newBrowserTab, path);
    }

    goToChannelVariantDetails(rcm_variant_id, newBrowserTab=false) {
        const path = NavigationService.CHANNEL_VARIANT_DETAILS.replace(':id', rcm_variant_id);
        if(!AuthService.canViewChannelVariants()) {
            alert('You do not have permission to view channel orders.');
            return;
        }
        this.openPage(newBrowserTab, path);
    }

    goToEditProduct(productCode) {
        //TODO add permission
        this.history.push(NavigationService.EDIT_PRODUCT.replace(':productCode', productCode));
    }

    goToProductDetails(productCode, newBrowserTab=false) {
        const path = NavigationService.PRODUCT_DETAILS.replace(':productCode', productCode);

        if(!AuthService.canViewProducts()) {
            alert('You do not have permission to view products.');
            return;
        }

        this.openPage(newBrowserTab, path);
    }

    openDesignDocUpload(productCode, newBrowserTab=false) {
        const path = NavigationService.DESIGN_DOC_UPLOAD.replace(':productCode', productCode);

        this.openPage(newBrowserTab, path);
    }

    goToProducts() {
        //TODO add permission
        this.history.push(NavigationService.PRODUCTS);
    }

    goToStock() {
        //TODO add permission
        this.history.push(NavigationService.STOCK);
    }

    goToRetailOrders() {
        //TODO add permission
        this.history.push(NavigationService.RETAIL_ORDERS);
    }

    goToPoShipmentDetails(shNumber, newBrowserTab=false) {
        const path = NavigationService.PO_SHIPMENT_DETAILS.replace(':shNumber', shNumber);
        this.openPage(newBrowserTab, path);
    }

    goToSupplierPOShipmentDetails(shNumber) {
        this.history.push(NavigationService.SUPPLIER_PO_SHIPMENT_DETAILS.replace(':shNumber', shNumber));
    }

    goToSupplierPoDetails(poNumber, newBrowserTab=false) {
        const path = NavigationService.SUPPLIER_PO_DETAILS.replace(':poNumber', poNumber);
        this.openPage(newBrowserTab, path);
    }

    gotoSupplierConsolidatedPackage(pkgNumber) {
        const path = NavigationService.SUPPLIER_PACKAGE_DETAILS.replace(':id', pkgNumber);
        this.openPage(false, path);
    }

    goToPoDetails(number, newBrowserTab=false) {
        const path = NavigationService.PO_DETAILS.replace(':poNumber', number);

        if(!AuthService.canViewPurchaseOrders()) {
            alert('You do not have permission to view purchase orders.');
            return;
        }

        this.openPage(newBrowserTab, path);
    }

    openPage(newTab, path) {
        if(newTab){
            window.open(path, "_blank");
        } else{
            this.history.push(path);
        }
    }

    goBack() {
        this.history.goBack();
    }

    reload() {
        window.location.reload();
    }

    static getPermittedAdminPath() {
        if(AuthService.hasNoPermissions()) {
            alert('You have no permissions set on your account. Permissions can be added by an admin.');
            return this.LOGIN;
        }
        return this.WELCOME;
    }

    static getRedirectPathFromRootUrl() {
        if (AuthService.isAdminLoggedIn()) {
            return this.getPermittedAdminPath();
        } else if (AuthService.isSupplierLoggedIn()) {
            return this.SUPPLIER_POS;
        }
        return this.LOGIN;
    }

}

export default NavigationService;