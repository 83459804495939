import React, {useEffect, useRef, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Typography,
    Button,
    DialogActions, Tooltip, IconButton,
} from '@material-ui/core';
import {Autocomplete} from "@material-ui/lab";
import Server from "../../Server";
import Utils from "../../Utils";
import EditIcon from "@material-ui/icons/Edit";
import HelpIcon from '@material-ui/icons/Help';
import {makeStyles} from "@material-ui/styles";
import FlexidHelperDialog from "./FlexidHelperDialog";

const EditShipmentInfoDialog = props =>{

    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const shipment = props.shipment;

    const input1ref = useRef();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [departurePort, setDeparturePort] = useState(null);
    const [flexid, setFlexid] = useState("");
    const [destination, setDestination] = useState(null);
    const [openFlexidHelper, setOpenFlexidHelper] = useState(false);

    useEffect(() => {
        setDeparturePort(shipment.departure_port);
        setFlexid(shipment.flex_id);
        setFileName(plFileName(shipment.packing_list_file));
        setDestination(shipment.destination);
    },[props.open])

    const isRenfoldShipment = () => {
        return destination?.name === 'RENFOLD UK' || destination?.name === 'RENFOLD DE';
    }

    const validate = () => {
        let errors = [];
        if (fileName === '' && file === null) {
            errors.push('Please upload a packing list file.');
        }
        if(!departurePort){
            errors.push("Departure port not set.");
        }
        if(!destination) {
            errors.push("Destination not set.")
        }

        if(!flexid && isRenfoldShipment()){
            errors.push("Flex ID must be set for shipments with destination RENFOLD UK and RENFOLD DE.");
        }

        return errors;
    };

    const confirm = () => {
        const errors = validate();
        if(errors.length > 0) {
            alert(Utils.formatList(errors, '\n'));
            return;
        }

        const newShipmentDetails = {
            file: file,
            departure_port_id: departurePort?.id,
            flex_id: flexid,
            destination_id: destination?.id,
        }
        new Server().updateShipmentInfo(shipment.number, newShipmentDetails, (response) => {
            if (response.status === 200) {
                props.setShipment(response.data);
            } else {
                alert('Error updating shipment info! \nError:\n' + JSON.stringify(response.data, null, 2));
            }
        });
        props.onClose();
    };

    const setPLFile = (files) =>{
        setFile(files[0]);
        setFileName(files[0].name);
        input1ref.current.value = null;
    };

    const UploadPLFileButton = () => (
        <div>
            <input type="file"
                   ref={input1ref}
                   onChange={(e) => setPLFile(e.target.files)}
                   hidden/>
            <EditIcon className={classes.buttonIcon}
                      onClick={(e) => input1ref.current.click()}/>
        </div>
    );

    const plFileName = (fileName) => {
        if (!fileName) {
            return 'No packing list file.';
        }
        return fileName.replace('/media/packing_lists/', '');
    };

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Edit Shipment details</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>

                    <Grid item>
                        <Autocomplete
                            options={props.ports}
                            getOptionLabel={port => port.name}
                            value={departurePort}
                            onChange={(e,v,r) => v && setDeparturePort(v)}
                            autoHighlight
                            size="small"
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="From port" variant="outlined"/>}
                            getOptionSelected={(option, value) => option.name === value.name}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={props.destinations}
                            getOptionLabel={destination=> destination.name}
                            value={destination}
                            onChange={(e,v,r) => v && setDestination(v)}
                            autoHighlight
                            size="small"
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Destination" variant="outlined"/>}
                            getOptionSelected={(option, value) => option.name === value.name}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" spacing={1} alignItems={"center"}>
                            <Grid item>
                                <TextField style={{width: 264}} variant="outlined" size="small" type="number" label="Flexport shipment ID" value={flexid} onChange={(e)=>setFlexid(e.target.value)}/>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Where can I find it?">
                                    <IconButton size="small" onClick={() => setOpenFlexidHelper(true)}>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item>
                        <Grid container direction={"row"}>
                            <Grid item>
                                <Typography variant="h5" component="h3" gutterBottom>
                                    {Utils.trim(plFileName(fileName))}
                                </Typography></Grid>
                            <Grid item><UploadPLFileButton/></Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        <FlexidHelperDialog open={openFlexidHelper} onClose={()=>setOpenFlexidHelper(false)}/>
        </Dialog>
    )
};

export default EditShipmentInfoDialog;