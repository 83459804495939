import React, {useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Dialog,
    DialogContent,
    Link,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import Utils from "../../../Utils";
import Server from "../../../Server";

const DesignDialog = props => {
    const {open, onClose, productCode} = props;

    useEffect(() => {
        if (productCode === 'ABC-123') return;

        if (open){
            reloadDocuments();
        }
    }, [productCode, open]);

    const reloadDocuments = () => {
        new Server().fetchDesignDocuments(productCode, (response) => {
            setDocuments(response.data);
        });
    };

    const [documents, setDocuments] = useState([]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle disableTypography><Typography variant="h4">{productCode + " design files"}</Typography></DialogTitle>

            <DialogContent>
                <TableContainer>

                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell size="small">File name</TableCell>
                                <TableCell size="small">Type</TableCell>
                                <TableCell size="small">Added on</TableCell>
                                <TableCell size="small">Last modified</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                documents.map(
                                    (document, index) => (
                                        <TableRow key={document.id}>
                                            <TableCell size="small">
                                                <Link href={document.url} rel="noopener noreferrer" target="_blank">
                                                    <span>{document.filename}</span>
                                                </Link>

                                            </TableCell>
                                            <TableCell size="small">{document.doc_type}</TableCell>
                                            <TableCell size="small">{Utils.formatDate(document.created_date, 'DD/MM/YYYY HH:mm')}</TableCell>
                                            <TableCell size="small">{Utils.formatDate(document.last_modified, 'DD/MM/YYYY HH:mm')}</TableCell>

                                        </TableRow>
                                    )
                                )
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
            </DialogContent>
        </Dialog>
    );

};

export default DesignDialog;