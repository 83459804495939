import {Button, Grid, TextField, Card, Checkbox} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {KeyboardDatePicker} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = props => {

    const channels = props.channels;
    const selectedChannel = props.selectedChannel;
    const setSelectedChannel = props.setSelectedChannel;
    const statuses = props.statuses;
    const selectedStatuses = props.selectedStatuses;
    const setSelectedStatuses = props.setSelectedStatuses;
    const fromDate = props.fromDate;
    const setFromDate = props.setFromDate;
    const toDate = props.toDate;
    const setToDate = props.setToDate;
    const onApplyFilterClick = props.onApplyFilterClick;
    const onClearFiltersClick = props.onClearFiltersClick;

    const classes = useStyles();

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const formatSelectedStatuses = (selectedStatuses) => {
        if (selectedStatuses.length === 1) {
            return selectedStatuses[0];
        }
        return selectedStatuses[0] + ' +' +(selectedStatuses.length-1)
    };

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" justifyContent="flex-start">

                    <Grid item>
                        {/*channel*/}
                        <Autocomplete
                            options={channels}
                            getOptionLabel={channel => channel.name}
                            value={selectedChannel}
                            onChange={(e, v, r) => setSelectedChannel(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Channel" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            size="small"
                            limitTags={1}
                            multiple
                            options={statuses}
                            disableCloseOnSelect
                            getOptionLabel={status => status}
                            value={selectedStatuses}
                            onChange={(e, v, r) => setSelectedStatuses(v)}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        size="small"
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Statuses"  />
                            )}
                            renderTags={
                                (selectedOptions, getTagProps) => {
                                    return (
                                        <div>{formatSelectedStatuses(selectedOptions)}</div>
                                    );
                                }
                            }
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="From"
                            format="DD/MM/YYYY"
                            value={fromDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setFromDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            size={"small"}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            label="To"
                            format="DD/MM/YYYY"
                            value={toDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date => setToDate(date)}
                        />
                    </Grid>

                    <Grid item>
                        <Button onClick={onApplyFilterClick}  color="primary">
                            Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onClearFiltersClick}>
                            Clear
                        </Button>
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;