import React, {useRef, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import Server from "../../Server";
import Utils from "../../Utils";
import ProgressLoadingScreen from "../../ProgressLoadingScreen";

const BulkImageUpload = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(4)
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const server = new Server();
    const imageUploadInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [maxLoadingProgress, setMaxLoadingProgress] = useState(100);
    const [uploadResults, setUploadResults] = useState("");

    const validate_image_files = (selected_files) => {
        if (selected_files.length === 0) return [];

        let errors = [];
        let valid_filenames = [];
        for(let i=0; i<selected_files.length; i++) {
            console.log(selected_files[i].name);
            const name = selected_files[i].name;

            //check jpg file
            if(Utils.parseFileExtension(name) !== 'jpg') {
                errors.push(name + " does not have a jpg extension. Expected .jpg")
                continue;
            }

            //check has underscore and dot
            const underscore_index = name.lastIndexOf("_");
            const dot_index = name.lastIndexOf(".");
            if(underscore_index === -1 || dot_index === -1) {
                errors.push(name + " is missing underscore and/or dot. Expected format: <product_code>_<image_number>.jpg")
                continue;
            }

            //check has valid image number
            if(!Utils.isValidInteger(name.substring(underscore_index + 1, dot_index))) {
                errors.push(name + " does not have a valid image number. Expected format: <product_code>_<image_number>.jpg")
                continue;
            }

            valid_filenames.push(name);
        }

        if(errors.length) {
            return errors;
        }

        //check images are in sequence 1 2 3 4 with no gaps or duplicates
        valid_filenames.sort();
        let imageNumbersMap = new Map();
        for(let i=0; i<valid_filenames.length; i++) {
            const name = valid_filenames[i];
            const productCode = getProductCode(name);
            const imageNumber = getImageNumber(name);
            // add image number to relevant product code in map
            if(!imageNumbersMap.has(productCode)) {
                imageNumbersMap.set(productCode, []);
            }
            imageNumbersMap.get(productCode).push(imageNumber);
        }

        for (const [productCode, image_numbers] of imageNumbersMap) {
            //if only 1 image, there should image number 1 only
            if (image_numbers.length === 1 && image_numbers[0] !== 1) {
                errors.push(productCode + " has 1 image. Image number should be 1.");
                continue;
            }

            //check image numbers are in sequence
            for(let i=0; i<image_numbers.length; i++) {
                if(image_numbers[i] !== i+1) {
                    errors.push("Invalid image number sequence for " + productCode + " " + image_numbers + " Expected 1 2 3 4 ...")
                    break;
                }
            }
        }
        return errors;
    };

    const handleImageFilesSelection = (selected_files) => {
        setUploadResults("");

        if(!selected_files) {
            alert('No files selected!');
            imageUploadInputRef.current.value = null;
            return;
        }

        const errors = validate_image_files(selected_files);
        if(errors.length) {
            alert(Utils.formatList(errors, '\n'));
            return
        }

        //upload each image file
        setLoading(true);
        setLoadingProgress(0);
        setMaxLoadingProgress(selected_files.length);
        let sentFileCount = 0;
        let sucessFilenames = [];
        let failedFilenames = [];
        for(let i=0; i<selected_files.length; i++) {
            const file = selected_files[i];
            const name = file.name;
            const extension = name.substring(name.lastIndexOf('.') + 1);
            const imageNumber = getImageNumber(name);
            const productCode = getProductCode(name);

            server.uploadZalandoProductImage(productCode, imageNumber, file, extension, (response) => {
                sentFileCount ++;
                if(response.status === 200) {
                    console.log('Successfully uploaded '+productCode + ' ' + imageNumber + ' ' + new Date() );
                    sucessFilenames.push(file.name);
                } else {
                    console.log('Failed to upload '+productCode + ' ' + imageNumber + ' ' + new Date() );
                    failedFilenames.push(file.name);
                }
                setLoadingProgress(sentFileCount);
                setLoading(sentFileCount !== selected_files.length);

                if(sentFileCount === selected_files.length) {
                    imageUploadInputRef.current.value = null;

                    if(sucessFilenames.length > 0) {
                        notifyImagesUploaded(sucessFilenames);
                    }

                    if(failedFilenames.length > 0) {
                        setUploadResults("Failed to upload " + failedFilenames.length + " images.");
                    } else {
                        setUploadResults("All images were uploaded successfully.");
                    }
                }
            });
        }
    };

    const notifyImagesUploaded = (sucessFilenames) => {
        server.sendImageUploadNotification(sucessFilenames, (response) => {
            if(response.status !== 200) {
                alert('Error emailing upload status \n' + JSON.stringify(response.data));
            }
        })
    };

    const getProductCode = (filename) => {
        return filename.substring(0, filename.lastIndexOf('_'));
    };

    const getImageNumber = (filename) => {
        const imageNumberString = filename.substring(filename.lastIndexOf("_") + 1, filename.lastIndexOf("."));
        return parseInt(imageNumberString);
    };

    return (
        <Card>
            <CardHeader title="Images"/>
            <Divider/>
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            Images need to be uploaded before submitting Zalando products in bulk.
                            <br/>
                            Images must be .jpg files
                            <br/>
                            File names must include an image number to determine their position. Image 1 will be the lead image.
                            <br/>
                            Image numbers for a product must be sequential (ie 1 2 3 4...)
                            <br/>
                            {"File name format: <product_code>_<image_number>.jpg"}
                            <br/>
                            {"Examples:"}
                            <br/>
                            {"HT-SWIM-040_1.jpg   HT-SWIM-040_2.jpg   HT-SWIM-040_3.jpg"}
                            <br/>
                            {"CMNOS207RPLN17_1.jpg   CMNOS207RPLN17_2.jpg   CMNOS207RPLN17_3.jpg   CMNOS207RPLN17_4.jpg"}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Button component="label" color="primary">
                            <CloudUploadIcon  className={classes.buttonIcon}/>
                            Upload images
                            <input
                                type="file"
                                ref={imageUploadInputRef}
                                style={{ display: "none" }}
                                hidden
                                multiple
                                onChange={(e) => {handleImageFilesSelection(e.target.files)}}
                            />
                        </Button>
                    </Grid>

                    <Grid item>
                        {uploadResults ?
                            <Typography variant="body1" component="p">
                                {uploadResults}
                            </Typography>
                        : null }
                    </Grid>
                </Grid>
            </CardContent>
            <ProgressLoadingScreen loading={loading} currentValue={loadingProgress} maxValue={maxLoadingProgress}/>
        </Card>
    );
};

export default BulkImageUpload;