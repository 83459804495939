import React, {useEffect} from 'react';
import MyDocument from "./MyDocument";
import {PDFViewer} from '@react-pdf/renderer';
import {makeStyles} from "@material-ui/styles";
import Server from "../Server";


const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    content: {
        flexGrow: 1
    },
}))

const PDFBuilder = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div>

            </div>

            <PDFViewer className={classes.content}>
                <MyDocument/>
            </PDFViewer>
        </div>
    );
};

export default PDFBuilder;