import React from 'react';
import {Chart} from "react-google-charts";
import {Grid, Typography} from "@material-ui/core";
import "./pie.css";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    underline: {
        textDecoration: "underline",
    }
}));

const GPercentagePie = props => {

    /*
    To display percentage as overlay https://stackoverflow.com/questions/41364245/add-label-in-the-middle-of-google-pie-chart-donut
     */

    const classes = useStyles();

    const status = props.status;
    const quantity = props.quantity;
    const total = props.total;
    const rest = total - quantity;
    const quantityDescription = props.quantityDescription;
    const onClick = props.onClick;

    const data = [
        ["status", "count"],
        [quantityDescription, quantity],
        ["rest", rest],
    ];

    const chartEvents = [
        {
            eventName: "select",
            callback({chartWrapper}) {
                console.log("Selected ", chartWrapper.getChart().getSelection());
                onClick(status);
            }
        }
    ];

    const options = {
        pieHole: 0.75,
        legend: 'none',
        pieSliceText: "none",
        reverseCategories: true, //counter clock-wise
        chartArea: {width: '75%', height: '75%'},
        slices: {
            1: {color: '#f27764'},
            0: {color: '#e7c1a2'},
        },
        tooltip: {
            trigger: 'none',
            textStyle: {
                fontSize: 12,
            }
        },
        backgroundColor: {
            fill: "transparent"
        }
    };

    const formatLegend = () => {
        return quantity + " " + quantityDescription;
    };

    const formatPercentage = () => {
        if (total === 0) {
            return "-";
        }
        const percentage = Math.round((quantity / total) * 100);
        return percentage + "%";
    };

    return (
        <Grid container direction="column" justifyContent={"center"} alignItems="center">
            <Grid item>
                <div style={{position: "relative"}}>
                    <div style={{width: "150px", height: "150px"}}>
                        <Chart
                            chartType="PieChart"
                            width="150px"
                            height="150px"
                            data={data}
                            options={options}
                            chartEvents={chartEvents}
                        />
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: "2px",
                            top: "2px",
                            width: "150px",
                            lineHeight: "150px",
                            textAlign: "center",
                            fontSize: '17px',
                            fontWeight: 400,
                            pointerEvents: "none"
                        }}
                    >
                        {formatPercentage()}
                    </div>
                </div>
            </Grid>

            <Grid item>
                <Typography className={classes.underline}
                            onClick={() => onClick(status)}
                            variant="body1">
                    {formatLegend()}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default GPercentagePie;