import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useState} from "react";
import Server from "../Server";

const NewUserDialog = props => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const confirm = () => {
      new Server().createUser(name, email, password, (response) => {
          if(response.status === 200) {
              alert('User successfully created.\nWARNING: New users have no permissions by default. Add permissions for this user by clicking \'Edit permissions\'.');
              props.onConfirm(response.data);
          } else {
              alert('Error creating new user: \n'+JSON.stringify(response.data, null, 2));
          }
      });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>New user</DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>

                        <TextField fullWidth label="Name" variant="outlined"
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                    </Grid>


                    <Grid item>
                        <TextField fullWidth label="Email" variant="outlined"
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <TextField fullWidth label="Password" variant="outlined"
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default NewUserDialog;