import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    Button,
    DialogActions
} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const FlexidHelperDialog = props =>{

    const useStyles = makeStyles(theme => ({
        buttonIcon: {
            marginRight: theme.spacing(1)
        },
        image: {

        }
    }));

    const classes = useStyles();

    return(
        <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
            <DialogTitle disableTypography><Typography variant="h4">Where to find the shipment flexport ID?</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <img className={classes.image} src="/images/flexid-helper.png" alt="Where to find shipment flexport id."/>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" component="p">
                            1 - Go to the relevant shipment on FlexPort.
                            <br/>
                            2 - Copy the numbers as highlighted text in the above image.
                            <br/>
                            The flexport id in this case is <bold>1603755</bold>.
                            <br/>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default FlexidHelperDialog;