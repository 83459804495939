import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Server from "../Server";

const ZalandoUpdatePriceDialog = props => {

    const [regularPrice, setRegularPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);

    const isValidPrice = (price, acceptZero) => {
        if (price === undefined || price === '') return false;
        const amount = parseInt(price);
        return !isNaN(amount) && (acceptZero ? amount >= 0 : amount > 0);
    };

    const getEansForProduct = (product) => {
        let eans = [];
        for (var i = 0; i < product.variants.length; i++) {
            eans.push(product.variants[i].ean);
        }
        return eans;
    };

    const formatSetPriceResponse = (results) => {
        let error_message = "The following updates were rejected by Zalando:\n";
        let successCount = 0;
        for(var i=0; i<results.length; i++) {
            const result = results[i];
            if(result.status === 'ACCEPTED'){
                successCount ++;
            } else {
                error_message += result?.ean + ' ' + result?.status + ' ' + result?.status_code + ' ' + result?.description  + ' ' + result?.sales_channel_id + '\n';
            }
        }
        if(results.length === successCount) {
            return "Prices successfully updated.\nNote: the new price may not appear immediately in the product list.";
        } else {
            return error_message;
        }
    };

    const pricesSuccessfullyUpdated = (results) => {
        let successCount = results.filter(result => result.status === 'ACCEPTED').length;
        return successCount === results.length;
    };

    const confirm = () => {
        console.log('new regular price ' + regularPrice + ' new discounted price ' + discountedPrice);
        if (!isValidPrice(regularPrice, false)) {
            alert('Invalid regular price!');
            return;
        }

        if (!isValidPrice(discountedPrice, true)) {
            alert('Invalid discounted price!');
            return;
        }

        const eans = getEansForProduct(props.productToUpdate);
        new Server().setPrice(eans, regularPrice, discountedPrice, (response) => {
            if (response.status === 200) {
                alert(formatSetPriceResponse(response.data));
                if (pricesSuccessfullyUpdated(response.data)) {
                    props.onConfirm();
                }
            } else {
                alert('Error: \n ' + JSON.stringify(response.data, null, 2));
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Update price</DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>
                        {props.productToUpdate.name}
                    </Grid>

                    <Grid item>

                        <TextField label="Regular price" variant="outlined" value={regularPrice} type={"number"}
                                   onChange={(e) => setRegularPrice(e.target.value)}/>
                    </Grid>


                    <Grid item>
                        <TextField label="Discounted price" variant="outlined" value={discountedPrice} type={"number"}
                                   onChange={(e) => setDiscountedPrice(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <Typography
                            component="h6"
                            gutterBottom
                            variant="caption"
                        >
                            Discounted price will not be set if it's 0.
                        </Typography>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ZalandoUpdatePriceDialog;

