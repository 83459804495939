import React, {useEffect, useState} from 'react';
import Server from "../../../Server";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";

const EditTrackingDialog = props => {

    const [couriers, setCouriers] = useState([]);
    const [courier, setCourier] = useState([]);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [shippingDescription, setShippingDescription] = useState('');

    useEffect(() => {

        setCourier(props.order.tracking.courier);
        setTrackingNumber(props.order.tracking.number);
        setShippingDescription(props.order.tracking.shipping_description);

        new Server().fetchCouriers((response) => {
            setCouriers(response.data);
        });

    }, [props.open]);


    const confirm = () => {
        if (!courier) {
            alert("No carrier selected");
            return;
        }
        new Server().updateOrderTracking(props.order.id, courier.code, trackingNumber, shippingDescription, (response) => {
            if(response.status !== 200) {
                alert('Error updating tracking \n' + JSON.stringify(response.data));
                return;
            }
            window.location.reload();
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle disableTypography><Typography variant="h4">Edit Tracking</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item>
                        <Autocomplete
                            size="small"
                            options={couriers}
                            getOptionLabel={c => c.name}
                            value={courier}
                            onChange={(e, v, r) => setCourier(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Carrier" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <TextField fullWidth label="Tracking number" variant="outlined"
                                   size="small"
                                   value={trackingNumber}
                                   onChange={(e) => setTrackingNumber(e.target.value)}
                        />
                    </Grid>

                    <Grid item>
                        <TextField fullWidth label="Shipping description" variant="outlined"
                                   size="small"
                                   value={shippingDescription}
                                   onChange={(e) => setShippingDescription(e.target.value)}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTrackingDialog;