import React from 'react';
import {Button, Card, Checkbox, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = props => {

    const {
        customers, selectedCustomer, setSelectedCustomer,
        departments, selectedDepartment, setSelectedDepartment,
        fits, selectedFit, setSelectedFit,
        categories, selectedCategory, setSelectedCategory,
        sizeRange, setSizeRange,
        onApplyFilters, onClearFilters
    } = props;

    const classes = useStyles();

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center">

                    {/*customer / department / fit / category*/}

                    <Grid item>
                        {/*customer*/}
                        <Autocomplete
                            options={customers}
                            getOptionLabel={d => d.name}
                            value={selectedCustomer}
                            onChange={(e, v, r) => setSelectedCustomer(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Customer" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*department*/}
                        <Autocomplete
                            options={departments}
                            getOptionLabel={d => d.code}
                            value={selectedDepartment}
                            onChange={(e, v, r) => setSelectedDepartment(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Department" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*fit*/}
                        <Autocomplete
                            options={fits}
                            getOptionLabel={d => d.code}
                            value={selectedFit}
                            onChange={(e, v, r) => setSelectedFit(v)}
                            autoHighlight
                            style={{width: 150}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Fit" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        {/*category*/}
                        <Autocomplete
                            options={categories}
                            getOptionLabel={d => d.name}
                            value={selectedCategory}
                            onChange={(e, v, r) => setSelectedCategory(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Category" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <TextField fullWidth label="Size range" variant="outlined"
                                   size="small"
                                   style={{ width: 200 }}
                                   value={sizeRange}
                                   onChange={(e) => setSizeRange(e.target.value)}
                        />
                    </Grid>

                    <Grid item>
                        <Button color="primary" onClick={onApplyFilters}>
                            Apply
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={onClearFilters}>
                            Clear
                        </Button>
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;