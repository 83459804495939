import {Button, Grid, TextField, Card} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import ProductData from "../../Products/ProductData";

const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = props => {

    const {
        selectedWarehouse, setSelectedWarehouse,
        code, setCode,
        departments, setDepartments, selectedDepartment, setSelectedDepartment,
        fits, setFits, selectedFit, setSelectedFit,
        categories, setCategories, selectedCategory, setSelectedCategory,
        selectedSeason, setSelectedSeason,
        selectedCollection, setSelectedCollection,
        selectedCoo, setSelectedCoo
    } = props;

    const warehouses = ['ALL', 'SYN', 'ZPP'];

    const onApplyFilterClick = props.onApplyFilterClick;
    const onClearFiltersClick = props.onClearFiltersClick;
    const onExportClick = props.onExportClick;

    const classes = useStyles();

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" direction={"row"}>

                    <Grid item>
                        {/*warehouse*/}
                        <Autocomplete
                            options={warehouses}
                            getOptionLabel={f => f}
                            value={selectedWarehouse}
                            onChange={(e, v, r) => setSelectedWarehouse(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Location" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Product"
                            placeholder={"code"}
                            size="small"
                            style={{width: 150}}
                            variant="outlined"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        {/*department*/}
                        <Autocomplete
                            options={departments}
                            getOptionLabel={f => f.code}
                            value={selectedDepartment}
                            onChange={(e, v, r) => setSelectedDepartment(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Department" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={fits}
                            getOptionLabel={f => f.code}
                            value={selectedFit}
                            onChange={(e, v, r) => setSelectedFit(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Fit" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={categories}
                            getOptionLabel={f => f.name}
                            value={selectedCategory}
                            onChange={(e, v, r) => setSelectedCategory(v)}
                            autoHighlight
                            style={{width: 200}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Category" variant="outlined"/>}
                        />
                    </Grid>

                    {/*season*/}
                    <Grid item>
                        <Autocomplete
                            options={ProductData.seasons}
                            getOptionLabel={season => season}
                            value={selectedSeason}
                            onChange={(e, v, r) => setSelectedSeason(v)}
                            autoHighlight
                            style={{width: 125}}
                            size="small"
                            renderInput={params => <TextField {...params} label="Season" variant="outlined"/>}
                        />
                    </Grid>

                    {/*collection*/}
                    <Grid item>
                        <TextField
                            label="Collection"
                            size="small"
                            style={{width: 125}}
                            variant="outlined"
                            value={selectedCollection}
                            onChange={(e) => setSelectedCollection(e.target.value)}
                        />
                    </Grid>

                    {/*coo*/}
                    <Grid item>
                        <TextField
                            label="COO"
                            size="small"
                            style={{width: 125}}
                            variant="outlined"
                            value={selectedCoo}
                            onChange={(e) => setSelectedCoo(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <Button onClick={onApplyFilterClick} color="primary">
                           Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onClearFiltersClick}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onExportClick}>
                            Export
                        </Button>
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;