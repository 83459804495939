import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import UploadDialog from "./UploadDialog";
import QtyDialog from "./QtyDialog";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LoadingScreen from "../LoadingScreen";
import Server from "../Server";

const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Header = props => {
    const {onRatiosUploaded} = props;

    const classes = useStyles();

    const [openQtyDialog, setOpenQtyDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);

    const [loading, setLoading] = useState(false);

    const onDownloadRatios = () => {
        setLoading(true);
        new Server().downloadSizeRatiosExcel((response) => {
            setLoading(false);
        });
    };

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                <Typography
                    component="h1"
                    variant="h3"
                >
                    Size ratios
                </Typography>
            </Grid>

            {/*buttons*/}
            <Grid item>
                <Grid container spacing={1} alignItems={"center"}>

                    <Grid item>
                        <Tooltip title="Download size ratios excel">
                            <Button
                                variant={"contained"}
                                onClick={onDownloadRatios}
                            >
                                <CloudDownloadIcon className={classes.buttonIcon}/>
                                Download
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <Tooltip title="Bulk upload size ratios">
                            <Button
                                variant={"contained"}
                                onClick={() => setOpenUploadDialog(true)}
                            >
                                <CloudUploadIcon  className={classes.buttonIcon}/>
                                Upload
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <Tooltip title="Generate product quantities">
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => setOpenQtyDialog(true)}
                            >
                                Calculate quantities
                            </Button>
                        </Tooltip>
                    </Grid>

                </Grid>
            </Grid>
            <UploadDialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)} onRatiosUploaded={onRatiosUploaded}/>
            <QtyDialog open={openQtyDialog} onClose={() => setOpenQtyDialog(false)}/>
            <LoadingScreen loading={loading}/>
        </Grid>
    );
};

export default Header;