import Utils from "./Utils";

class TestData {
    static CURRENCIES = ['GBP', 'EUR', 'USD'];
    static STATUSES = ['NEW', 'SHIPPED', 'COMPLETED'];
    static SUPPLIERS = ['ASHIMA', 'SIDDIQSONS', 'HATKAR', 'QWERTY', 'AZGARD9', 'GAP EGPYT'];
    static PORTS = ['NINGBO',
        'SHANGHAI',
        'QUINGDAO',
        'ISTANBUL',
        'CHITTAGONG',
        'DHAKA',
        'LAHORE',
        'KARACHI',
        'MUMBAI',
        'TUTICORIN',
        'ALEXANDRIA'];

    static COUNTRIES = ['CHINA','TURKEY','BANGLADESH','PAKISTAN','INDIA','EGYPT']
    static MODES = ['AIR','SEA','TRUCK']
    static SH_STATUSES = ['SUBMITTED', 'TRANSIT', 'ARRIVED', 'RECEIVED']

    static generateOrderVolumeMetrics(fromDate, toDate, ) {

    }

    static randomSupplier = () => {
        const name = this.SUPPLIERS[Utils.randomInt(this.SUPPLIERS.length)];
        const email = name + '@email.com';
        const password = 'password';
        return {
            name: name,
            username: email,
            password: password
        };
    }

    static randomPort = () => {
        return {
            name: this.PORTS[Utils.randomInt(this.PORTS.length)],
            country: this.COUNTRIES[Utils.randomInt(this.COUNTRIES.length)],
            dutyValue: Utils.randomInt(20)/100
        }
    }

    static makeCustomers(count) {
        let customers= [];
        for(var i=0; i<count; i++) {
            customers.push(this.randomCustomer())
        }
        return customers
    }

    static makePorts(count) {
        var ports = [];
        for(var i=0; i<count; i++) {
            ports.push(this.randomPort());
        }
        return ports;
    }

    static makeShipment() {
        return {
            number: 'SH-' + (100 + Utils.randomInt(1000)),
            departure_port: this.randomPort(),
            flex_id: 'flex_id ' + (100 + Utils.randomInt(1000)),
            agent_name: 'agent '+ (100 + Utils.randomInt(1000)),
            total_cartons: 1 + Utils.randomInt(9),
            received_date: new Date(),
            fulfilment_centre: 'fulfil ctr ' + (100 + Utils.randomInt(1000)),
            status: this.SH_STATUSES[Utils.randomInt(this.SH_STATUSES.length)],
            // pos: this.makePos(1 + Utils.randomInt(3)),
            skuFile: 'skuFile',
            packingListFile: 'packListFile',
            invoiceFile: 'invoiceFile',
            invoice_number: 'inv-' + (100 + Utils.randomInt(1000)),
            invoice_amount: 0,
            invoice_currency: 'USD',
            invoice_date: new Date(),
            invoice_due_date: new Date(),
            currency: 'USD',
            items: [],
        }
    }

    static newDate = (daysToAdd) => {
        Date.prototype.addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        return new Date().addDays(daysToAdd)
    };

    static newRo = () => {
        return {
            number: 'RO-' + (100 + Utils.randomInt(1000)),
            customer: this.randomCustomer(),
            order_date: this.newDate(-Utils.randomInt(10)),
            ship_date: this.newDate(Utils.randomInt(10)),
            reference: 'REF-' + (100 + Utils.randomInt(1000)),
            currency: this.CURRENCIES[Utils.randomInt(this.CURRENCIES.length)],
            incoterm: 'FAS',
            season: 'SS20',
            warehouse: null,
            status: 'NEW',
            items: []
        };
    }

    static emptySupplier = () => {
        return {
            name: '',
            username: '',
            password: ''
        };
    }

    static emptyPo = () => {
        return {
            number: '',
            supplier: this.emptySupplier(),
            issueDate: new Date(),
            exitDate: new Date(),
            reference: '',
            currency: 'GBP',
            totalUnits: 0,
            totalValue: 0,
            unitsShipped: 0,
            unitsOutstanding: 0,
            status: '',
            items: []
        };
    }

    static emptyPOShipment = () => {
        return {
            number: '',
            status: 'DRAFT',
            currency: 'USD',
            supplier: {name: ''},
            departure_port: {name: ''},
            flex_id: '',
            agent_name: '',
            date_received: null,
            destination_warehouse: null,
            packing_list_file: null,
            invoice: null,
            created_date: null,
            po: {number: ''}
        };
    }

    static emptyChannelOrder = () => {
        return {
            channel_order_id: 1,
            channel_order_number: '',
            channel_po_number:  '',
            currency:  'GBP',
            status:  '',
            date: '',
            expected_delivery_date:  '',
            dispatch_date: '',
            customer: {
                title: '',
                full_name:  '',
                first_name:  '',
                last_name:  '',
                phone:  '',
                email:  '',
            },
            shipping_address: {
                line1:  '',
                line2:  '',
                line3:  '',
                postcode:  '',
                country_code:  '',
            },
            channel: {
                name:  '',
                code:  '',
                order_prefix:  '',
                brightpearl_id: 1
            },
            tracking: {
                courier:  {
                    name: '',
                    code: '',
                },
                number:  '',
                url:  '',
                shipping_description:  '',
                last_checked: '',
            },
            total_value: '0',
            brightpearl_id: 1,
            lines: [],
        };
    }

    static randomPo = () => {
        return {
            number: 'PO-' + (100 + Utils.randomInt(1000)),
            supplier: this.randomSupplier(),
            issueDate: this.newDate(-Utils.randomInt(10)),
            exitDate: this.newDate(Utils.randomInt(10)),
            reference: 'REF-' + (100 + Utils.randomInt(1000)),
            currency: this.CURRENCIES[Utils.randomInt(this.CURRENCIES.length)],
            totalUnits: Utils.randomInt(20),
            totalValue: Utils.randomInt(100),
            unitsShipped: Utils.randomInt(20),
            unitsOutstanding: Utils.randomInt(10),

            status: this.STATUSES[Utils.randomInt(this.STATUSES.length)],

            items: this.newItems()
        }
    }

    static newItems = () => {
        const count = 5 + Utils.randomInt(10)
        let items = []
        for (let i = 0; i < count; i++)
            items.push(this.newItem())
        return items
    }

    static newItem = () => {
        const units = Utils.randomInt(20)
        const price = Utils.randomInt(40)
        const shipped = Utils.randomInt(units)
        const outstanding = units - shipped
        const unshippedValue = outstanding * price
        return {
            sku: this.randomSku(),
            customer: this.randomCustomer(),
            po: this.randomPoNumber(),
            size: this.randomSize(),
            ean: this.randomEan(),
            units: units,
            price: price,
            shipped: shipped,
            outstanding: outstanding,
            unshippedValue: unshippedValue
        }
    }
    static CUSTOMERS = ['CHELSEA', 'HOPPER', 'HOXTON', 'NOCOZO', 'LDN DNM', 'Sugar Dust', 'Northmore']
    static randomCustomer = () => {
        return {
            name: this.CUSTOMERS[Utils.randomInt(this.CUSTOMERS.length)],
        }
    }

    static randomPoNumber = () => {
        return 'PO-' + (100 + Utils.randomInt(800))
    }

    static SIZES = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']
    static randomSize = () => {
        return this.SIZES[Utils.randomInt(this.SIZES.length)]
    }

    static randomEan = () => {
        const randomInt1 = 100000 + Utils.randomInt(800000)
        const randomInt2 = 100000 + Utils.randomInt(800000)
        return '' + randomInt1 + '' + randomInt2
    }

    static randomSku = () => {
        const SKU_PREFIXES = ['URB', 'BCN', 'LGW', 'LHR', 'CTY']
        const prefix = SKU_PREFIXES[Utils.randomInt(this.CURRENCIES.length)]
        const randomInt1 = 10 + Utils.randomInt(80)
        const randomInt2 = 100 + Utils.randomInt(800)
        return prefix + '-' + randomInt1 + '-' + randomInt2;
    }

    static makeRo() {
        return this.newRo();
    }

    static makePo() {
        return this.emptyPo();
    }

    static makePos(count) {
        let pos = [];
        for (let i = 0; i < count; i++) {
            pos.push(this.emptyPo());
        }
        return pos;
    }

    static makeShipments(count) {
        let shipments = [];
        for(let i=0; i<count; i++) {
            shipments.push(this.makeShipment())
        }
        return shipments;
    }

    static randomStockItem() {
        const size = this.randomSize();
        return {
            variant: {
                sku: 'CPNY-' + (Utils.randomInt(1000)+1000)+'-'+size,
                ean: Utils.randomInt(1000000) + 5000000,
                size: size
            },
            available: Utils.randomInt(100),
            committed: Utils.randomInt(100),
            incoming: Utils.randomInt(100),
        }
    }

    static stockItems(count) {
        let items = [];
        for(let i=0; i<count; i++) {
            items.push(this.randomStockItem());
        }
        return items;
    }

    static packageSummary(){
        return {
            number: '',
            supplier_name: '',
            currency: '',
            season: '',
            pos: []
        };
    };

    static packageInfo(){
        return {
            number: '',
            supplier: {name: ''},
            currency: '',
            season: '',
            coo: {name: ''},
            mode: '',
            updated_on: new Date(),
            created_on: new Date(),
            created_by: {name: ''},
            updated_by: {name: ''},
            total_units: 0,
            total_value: 0,
            total_shipped: 0,
            total_shipped_value: 0,
        }
    }


    static LINESHEETPRODUCTS = {
        "Standard": [

            {'sku': 'CPNY-1',
                'description':'description',
                'sizes':{
                    'S': 10,
                    'M': 20,
                    'L': 30
                },
                'total': 1,
                'rrp': 2,
                'wholesale': 3,
                'eta': 'IN STOCK'},

            {'sku': 'CPNY-2',
                'description':'description2',
                'sizes':{
                    'S': 20,
                    'M': 40,
                    'L': 60
                },
                'total': 4,
                'rrp': 5,
                'wholesale': 6,
                'eta': '05-June'}

        ],

        "Numeric": [

            {'sku': 'CPNY-3',
                'description':'description3',
                'sizes':{
                    '18': 5,
                    '19': 7,
                    '20': 9
                },
                'total': 10,
                'rrp': 5,
                'wholesale': 4,
                'eta': '06-July'},

            {'sku': 'CPNY-4',
                'description':'description4',
                'sizes':{
                    '18': 11,
                    '19': 13,
                    '20': 15
                },
                'total': 9,
                'rrp': 8,
                'wholesale': 6,
                'eta': '06-April'}

        ]
    }

    static POSHIPMENTS = [
            {
                'shipment_number':  'PSH-111',
                'supplier': 'supplier1',
                'invoice_name': 'inv01',
                'departure_date': '20/08/2021',
                'eta': '22/08/2021',
                'status': 'RECEIVED',
                'cartons': 2,
                'total_units': 4,
                'total_value': 60.00,
                'currency': 'GBP',
                'entry_value': 24.00
            },

            {
                'shipment_number': 'PSH-112',
                'supplier': 'supplier1',
                'invoice_name': 'inv02',
                'departure_date': '27/08/2021',
                'eta': '05/09/2021',
                'status': 'TRANSIT',
                'cartons': 5,
                'total_units': 20,
                'total_value': 500.00,
                'currency': 'EUR',
                'entry_value': 200.00
            },

            {
                'shipment_number': 'PSH-113',
                'supplier': 'supplier2',
                'invoice_name': 'inv03',
                'departure_date': '27/08/2021',
                'eta': '31/08/2021',
                'status': 'DRAFT',
                'cartons': 4,
                'total_units': 4,
                'total_value': 40.00,
                'currency': 'USD',
                'entry_value': 16.00
            },

            {
                'shipment_number':  'PSH-114',
                'supplier': 'supplier3',
                'invoice_name': 'inv04',
                'departure_date': '27/08/2021',
                'eta': '28/08/2021',
                'status': 'RECEIVED',
                'cartons': 2,
                'total_units': 4,
                'total_value': 60.00,
                'currency': 'GBP',
                'entry_value': 24.00
            }
        ]
    }

export default TestData;