import React from 'react';
import {Card, CardHeader, Table, TableBody, TableCell, TableRow, Link} from "@material-ui/core";
import Pricing from "../../../Pricing";
import Utils from "../../../Utils";

const Info = props => {
    const order = props.order;

    const bpOrderUrl = Utils.getBpOrderUrl(order.brightpearl_id);
    const rcmOrderUrl = 'http://178.128.37.201/jl/ui/orders/' + order.id;

    return(
        <Card>
            <CardHeader title="Info"/>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Order number: {order.channel_order_number}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status: {order.status}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ordered: {Utils.formatDate(order.date, 'YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dispatched: {Utils.formatDate(order.dispatch_date, 'YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total value: {Pricing.formatPrice(order.total_value, order.currency)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>BrightPearl ID: <Link href={bpOrderUrl} rel="noopener noreferrer" target="_blank"><span>{order.brightpearl_id}</span></Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Internal ID: <Link href={rcmOrderUrl} rel="noopener noreferrer" target="_blank"><span>{order.id}</span></Link></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default Info;