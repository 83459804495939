import {Button, Card, CardActions, CardContent, CardHeader, Divider, Table, TableBody, TableRow, TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import moment from "moment";
import Pricing from "../Pricing";

const DebitNoteInfo = props => {

    const useStyles = makeStyles(theme => ({
        content: {
            padding: 0
        },
        actions: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    const debitNote = props.debitNote;



    const totalAmount = () => {
        let total = 0;
        for(var i=0; i<debitNote.items.length; i++) {
            total += parseFloat(debitNote.items[i].amount);
        }
        return total;
    };

    return (
        <Card>
            <CardHeader title="Debit note"/>
            <Divider/>
            <CardContent className={classes.content}>
                <Table>
                    <TableBody>

                        <TableRow>
                            <TableCell>Supplier</TableCell>
                            <TableCell>
                                {debitNote.supplier.name}
                            </TableCell>
                        </TableRow>

                        <TableRow selected>
                            <TableCell>Currency</TableCell>
                            <TableCell>{debitNote.currency}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>
                                {debitNote.number}
                            </TableCell>
                        </TableRow>

                        <TableRow selected>
                            <TableCell>Status</TableCell>
                            <TableCell>
                                {debitNote.status}
                            </TableCell>
                        </TableRow>

                        <TableRow >
                            <TableCell>Created</TableCell>
                            <TableCell>{moment(debitNote.created_date).format('YYYY-MM-DD')}</TableCell>
                        </TableRow>


                        <TableRow selected>
                            <TableCell>Amount</TableCell>
                            <TableCell>{Pricing.formatPrice(totalAmount(), debitNote.currency)}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </CardContent>
            <CardActions className={classes.actions}>
                <div>
                    <Button onClick={props.showEditDebitDialog}>
                        <EditIcon className={classes.buttonIcon}/>
                        Edit note
                    </Button>

                </div>
            </CardActions>

        </Card>
    );

};

export default DebitNoteInfo;