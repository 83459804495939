import {Grid, TextField, Card, Button} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const LinesheetReportFilters = props => {

    const useStyles = makeStyles(theme => ({
        root: {
            marginTop: theme.spacing(3),
        },
        rootCrd: {
            padding: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    const operators = ['>', '<', '>=', '<=', '='];

    const onChangeSizeGroupOptionSelection = (v) => {
        if (v === null)
            return;
        props.selectLinesheetSizeGroup(v, props.linesheet);
    };

    return (
        <Grid container direction={"column"} spacing={1} className={classes.root}>

            <Grid item>
                <Card className={classes.rootCrd}>
                    <Grid container spacing={2} alignItems="center">

                        <Grid item>
                            {/*brand*/}
                            <Autocomplete
                                options={props.brands}
                                getOptionLabel={brand => brand.name}
                                value={props.brandFilter}
                                onChange={(e, v, r) => props.setBrandFilter(v)}
                                autoHighlight
                                style={{width: 200}}
                                size="small"
                                renderInput={params => <TextField {...params} label="Brand" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            {/*category*/}
                            <Autocomplete
                                options={props.categories}
                                getOptionLabel={category => category.name}
                                value={props.categoryFilter}
                                onChange={(e, v, r) => props.setCategoryFilter(v)}
                                autoHighlight
                                size="small"
                                category={{width: 150}}
                                renderInput={params => <TextField {...params} label="Category" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            {/*body*/}
                            <Autocomplete
                                options={props.bodies}
                                getOptionLabel={body => body.name}
                                value={props.bodyFilter}
                                onChange={(e, v, r) => props.setBodyFilter(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Body" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            {/*gender*/}
                            <Autocomplete
                                options={props.genders}
                                getOptionLabel={gender => gender.name}
                                value={props.genderFilter}
                                onChange={(e, v, r) => props.setGenderFilter(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Gender" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            {/*age*/}
                            <Autocomplete
                                options={props.ages}
                                getOptionLabel={age => age.name}
                                value={props.ageFilter}
                                onChange={(e, v, r) => props.setAgeFilter(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Age" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            {/*style*/}
                            <Autocomplete
                                options={props.styles}
                                getOptionLabel={style => style.name}
                                value={props.styleFilter}
                                onChange={(e, v, r) => props.setStyleFilter(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Style" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={props.getLinesheetProducts}
                            >
                                Search
                            </Button>
                        </Grid>

                    </Grid>
                </Card>
            </Grid>

            <Grid item>
                <Card className={classes.rootCrd}>
                    <Grid container spacing={2} alignItems="center">

                        <Grid item>
                            <TextField fullWidth label="Factor" variant="outlined"
                                       size="small"
                                       type="number"
                                       value={props.factor} onChange={(e) => props.setFactor(e.target.value)}
                            />
                        </Grid>


                        <Grid item>
                            <h3>Quantity:</h3>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={operators}
                                value={props.operator}
                                onChange={(e, v, r) => props.setOperator(v)}
                                autoHighlight
                                size="small"
                                style={{width: 200}}
                                renderInput={params => <TextField {...params} label="Comparison operator"
                                                                  variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            <TextField fullWidth label="Quantity" variant="outlined"
                                       size="small"
                                       type="number"
                                       value={props.quantity} onChange={(e) => props.setQuantity(e.target.value)}
                            />
                        </Grid>

                        <Grid item>
                            {/*location*/}
                            <Autocomplete
                                options={props.locations}
                                getOptionLabel={location => location.name}
                                value={props.locationFilter}
                                onChange={(e, v, r) => props.setLocationFilter(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Location" variant="outlined"/>}
                            />
                        </Grid>

                        <Grid item>
                            <Autocomplete
                                options={['GBP', 'EUR', 'USD']}
                                value={props.currency}
                                onChange={(e, v, r) => props.setCurrency(v)}
                                autoHighlight
                                size="small"
                                style={{width: 150}}
                                renderInput={params => <TextField {...params} label="Currency" variant="outlined"/>}
                            />
                        </Grid>

                    </Grid>
                </Card>
            </Grid>

            <Grid item>
                <Card className={classes.rootCrd}>
                    <Grid container spacing={2} alignItems="center">

                        <Grid item>
                            <Autocomplete
                                options={props.sizeGroupOptions}
                                getOptionLabel={sizeGroup => sizeGroup.name}
                                value={props.sizeGroupOption}
                                onChange={(e, v, r) => onChangeSizeGroupOptionSelection(v)}
                                autoHighlight
                                size="small"
                                style={{width: 200}}
                                renderInput={params => <TextField {...params} label="Sizes" variant="outlined"/>}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LinesheetReportFilters;