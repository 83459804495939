import {makeStyles} from "@material-ui/styles";
import Card from "@material-ui/core/Card/Card";
import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Server from "../Server";
import ZalandoUpdatePriceDialog from "./ZalandoUpdatePriceDialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Utils from "../Utils";
import Pricing from "../Pricing";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MissingCostDialog from "./MissingCostDialog";

const ZalandoProductsTable = props => {

    const useStyles = makeStyles(theme => ({
        root: {},
        image:{
            width: 100
        },
        priceCell: {
            textDecoration: 'underline'
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        valueText: {
            fontWeight: "bold",
        },
        valueLabel: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(1)
        },
        infoIcon: {
            marginLeft: theme.spacing(1),
        }
    }));
    const classes = useStyles();

    const statuses = [
        "LIVE",
        "PART_LIVE",
        "IN_REVIEW",
        "REJECTED",
        "BLOCKED",
        "OTHER"
    ];

    // const [products, setProducts] = useState(TestZalandoProducts.products());//uncomment to load local products
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    const [stockValue, setStockValue] = useState(0);
    const [noCostProductCodes, setNoCostProductCodes] = useState([]);

    //update price dialog
    const [productToUpdate, setProductToUpdate] = useState({});
    const [openUpdatePriceDialog, setOpenUpdatePriceDialog] = useState(false);

    const [openMissingCostDialog, setOpenMissingCostDialog] = useState(false);

    useEffect( () => {
        new Server().fetchZalandoProducts((response) => {
            if(response.status !== 200) {
                alert('Unknown error occurred. Reload the page and contact admin if issue persists.');
                return;
            }
            const allProducts = response.data.products;
            setProducts(allProducts);
            setFilteredProducts(allProducts.filter((p) => p.status === statuses[0]));
            console.log('Product count: ' + allProducts.length);
            console.log(response.data);

            setStockValue(response.data.stock_value);
            setNoCostProductCodes(response.data.no_cost_products);
        });
    }, []);

    const showUpdatePriceDialog = (product) => {
        setProductToUpdate(product);
        setOpenUpdatePriceDialog(true);
    };

    const onCloseUpdatePriceDialog = () => {
        setProductToUpdate({});
        setOpenUpdatePriceDialog(false);
    };

    const onConfirmUpdatePriceDialog = () => {
        setProductToUpdate({});
        setOpenUpdatePriceDialog(false);
    };

    const Discountedprice = (props) => {
      return (
          <span>
              <s>{props.price}</s> <br/>
              {props.discounted}
          </span>
      )
    };

    const getImageUrl = (product) => {
        const url = product.image_url;
        if (url) {
            return url;
        } else {
            return '/images/no-image.svg';
        }
    };

    const getTr = (product) => {
        const variantCount = product.variants.length;
        const imageUrl = getImageUrl(product);
        const productStatus = product.status;

        let price = 'NA';
        let discountedPrice = '';
        let hasDiscountedPrice = false;
        if(product.variants.length > 0) {
            price = product.variants[0].regular_price;
            discountedPrice = product.variants[0].discounted_price;
            hasDiscountedPrice = discountedPrice !== 'NA'
        }

        var rows = [
            <TableRow key={product.code}>
                <TableCell rowSpan={variantCount + 1}>
                    <img src={imageUrl} className={classes.image} alt="product"/>
                </TableCell>
                <TableCell rowSpan={variantCount + 1}>
                    {product.name}<br/>
                    {product.zpp_product_type}<br/>
                    {product.season}<br/>
                    {product.code}<br/>
                    {product.zpp_product_code}
                    </TableCell>
                <TableCell rowSpan={variantCount + 1}>{productStatus}</TableCell>
                <TableCell className={classes.priceCell} rowSpan={variantCount + 1} onClick={() => showUpdatePriceDialog(product)}>
                    {hasDiscountedPrice ? <Discountedprice price={price} discounted={discountedPrice}/> : price }
                    </TableCell>
            </TableRow>
        ];
        for (let i = 0; i < variantCount; i++) {
            const variant = product.variants[i];
            rows.push(
                <TableRow key={variant.ean}>
                    <TableCell>{variant.size}</TableCell>
                    <TableCell>{variant.ean}</TableCell>
                    <TableCell>{variant.status}</TableCell>
                    <TableCell>{variant.qty}</TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const onTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        setFilteredProducts(getProductsByStatus(statuses[newTabIndex]));
    };

    const getProductsByStatus = (status) => {
        if(status === "OTHER") {
            return products.filter((p) => !Utils.isIn(p.status, statuses));
        }
        return products.filter((p) => p.status === status);
    };

    const getProductCount = (status) => {
        return getProductsByStatus(status).length;
    };

    return (
        <React.Fragment>
            <Grid container direction="column" spacing={1}>
                <Grid item>

                    <Grid container direction="row" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <Paper>
                                <Tabs
                                    value={tabIndex}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={onTabChange}
                                >
                                    {
                                        statuses.map((status) => (
                                                <Tab key={status} label={status + " (" + getProductCount(status) + ")"}/>
                                            )
                                        )
                                    }
                                </Tabs>
                            </Paper>
                        </Grid>

                        <Grid item>
                            <Paper style={{height: '100%', minHeight: "48px"}}>
                                <div className={classes.valueContainer}>
                                    <Typography variant="h5" className={classes.valueText}>{Pricing.formatPrice(stockValue, 'GBP')}</Typography>
                                    <Typography variant="h5" className={classes.valueLabel}>total value</Typography>
                                    <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} onClick={(e) => setOpenMissingCostDialog(true)}/>
                                    <MissingCostDialog open={openMissingCostDialog} noCostProductCodes={noCostProductCodes} onClose={() => setOpenMissingCostDialog(false)}/>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item>
                    <Card>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Price €</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell>Ean</TableCell>
                                            <TableCell>Ean status</TableCell>
                                            <TableCell>Stock</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredProducts.map((product) => {

                                            return getTr(product)

                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <ZalandoUpdatePriceDialog open={openUpdatePriceDialog}
                                                      onClose={onCloseUpdatePriceDialog}
                                                      onConfirm={onConfirmUpdatePriceDialog}
                                                      productToUpdate={productToUpdate}/>

                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>

    );
}

export default ZalandoProductsTable;