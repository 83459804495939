import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const MissingCostDialog = props => {
    const formatProductCodes = () => {
        let text = "";
        props.noCostProductCodes.forEach((code) => text += code + " ");
        return text;
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onCLose}
            >
                <DialogTitle>{"Products with no cost:"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {formatProductCodes()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MissingCostDialog;