import React, {useState} from 'react';
import {Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import EditPackingListDialog from "../../PackingLists/EditPackingListDialog";
import Utils from "../../Utils";

const useStyles = makeStyles((theme) => ({
    productHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ShipmentProductTable = props => {
    const classes = useStyles();

    const shipmentProduct = props.shipmentProduct;
    const shipment = props.shipment;

    const[openDialog, setOpenDialog] = useState(false);
    const[packingList, setPackingList] = useState(null);

    const getSizeQuantityTotal = () => {
        let totalQuants = 0;
        for (let size in shipmentProduct.totals) {
            totalQuants += shipmentProduct.totals[size];
        }
        return totalQuants;
    };

    const getPackingListTotalUnits = (quantities) => {
        let packingListTotal = 0;
        for (let size in quantities) {
            packingListTotal += quantities[size];
        }
        return packingListTotal;
    };

    const getPackingListTotalCartons = (cartonRanges) => {
        let totalPLCartons = 0;
        cartonRanges.forEach(cartonRange =>{
            totalPLCartons += cartonRange.to_carton-cartonRange.from_carton+1
        })
        return totalPLCartons
    };

    const getProductCartonsTotal = (summaries) => {
        let totalCartons = 0;
        summaries.forEach(summary =>{
                totalCartons += getPackingListTotalCartons(summary.carton_ranges);
        });
        return totalCartons;
    };

    const openPackingListDialog = (packingList) => {
        setPackingList(packingList);
        setOpenDialog(true);
    };

    const onDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={shipmentProduct.sizes.length + 5}>
                                <div className={classes.productHeader}>
                                    <Typography>{shipmentProduct.product.code}</Typography></div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Packing lists</TableCell>
                            <TableCell>PO</TableCell>
                            <TableCell>Customer</TableCell>
                            {
                                shipmentProduct.sizes.map((size) => (
                                    <TableCell key={size}>{size}</TableCell>
                                ))
                            }
                            <TableCell>Total Units</TableCell>
                            <TableCell>Total Cartons</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            shipmentProduct.packing_list_summaries.map((summary, index) => (
                                <TableRow key={index}>

                                    <TableCell onClick={(e) => openPackingListDialog(summary)}>  {summary.pl_number} </TableCell>
                                    <TableCell>  {summary.po_number} </TableCell>
                                    <TableCell>  {Utils.trim(summary.customer_name, 20)} </TableCell>
                                    {
                                        shipmentProduct.sizes.map((size) => (
                                            <TableCell key={size}>{summary.qtys[size]}</TableCell>
                                        ))
                                    }
                                    <TableCell>{getPackingListTotalUnits(summary.qtys)}</TableCell>
                                    <TableCell>{getPackingListTotalCartons(summary.carton_ranges)}</TableCell>

                                </TableRow>
                            ))
                        }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total</TableCell>
                            {
                                shipmentProduct.sizes.map((size) => (
                                    <TableCell key={size}>{shipmentProduct.totals[size]}</TableCell>
                                ))
                            }
                            <TableCell> {getSizeQuantityTotal()} </TableCell>
                            <TableCell>{getProductCartonsTotal(shipmentProduct.packing_list_summaries)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <EditPackingListDialog
                open={openDialog}
                onClose={onDialogClose}
                shipment={shipment}
                plNumber={packingList?.pl_number}
            />
        </div>
    );
};

export default ShipmentProductTable;