import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Grid, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Server from "../Server";

const EditPermissionsDialog = props => {

    const [permissionsMap, setPermissionsMap] = useState({
        'Consolidated packages': false,
        'Purchase orders': false,
        'Shipments': false,
        'Channels overview': false,
        'Channel orders': false,
        'Channel variants': false,
        'Channel deliveries': false,
        'Channels top selling': false,
        'Suppliers': false,
        'Customers': false,
        'Ports': false,
        'Users': false,
        'Zalando products': false,
        'Debits': false,
        'Products': false,
        'Transit products': false,
        'Design documents': false,
        'Size ratios': false,
        'Retail orders': false,
        'Warehouses': false,
        'Reports': false,
        'Destinations': false,
    });

    const hasPermission = (resource, action, permissions) => {
        if(permissions === undefined) {
            return false;
        }
        return permissions.filter(permission => permission.resource === resource && permission.action === action).length >= 1;
    };


    const getResourceName = (label) => {
        // convert "Purchase orders" into "PURCHASE_ORDERS"
        return label.trim().toUpperCase().replaceAll(' ', '_');
    };

    useEffect( () => {
        if(props.user && props.open) {
            new Server().fetchUser(props.user.id, (response) => {
                let newPermissionsMap = JSON.parse(JSON.stringify(permissionsMap));
                const labels = Object.keys(newPermissionsMap);
                for(let i=0; i<labels.length; i++) {
                    newPermissionsMap[labels[i]] = hasPermission(getResourceName(labels[i]), 'VIEW', response.data.permissions);
                }
                setPermissionsMap(newPermissionsMap);
            });
        }

    }, [props.user, props.open])

    const confirm = () => {
        let newPermissions = [];
        const labels = Object.keys(permissionsMap);
        for(let i=0; i<labels.length; i++) {
            if(permissionsMap[labels[i]] === true) {
                newPermissions.push({
                    resource: getResourceName(labels[i]),
                    action: 'VIEW'
                });
            }
        }
        new Server().updateUserPermissions(props.user.id, newPermissions, (response) => {
            if(response.status === 200) {
                props.onConfirm();
            } else {
                alert('Error updating user permissions: \n' + JSON.stringify(response.data, null, 2));
            }
        });
    };

    const LabeledCheckbox = (props) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.checked}
                        onChange={props.onChange}
                        color="primary"
                    />
                }
                label={props.label}
            />
        );
    };

    const updatePermission = (label, newValue) => {
        let newPermissionsMap = JSON.parse(JSON.stringify(permissionsMap));
        newPermissionsMap[label] = newValue;
        setPermissionsMap(newPermissionsMap);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle disableTypography><Typography variant="h4">Update permissions for {props.user?.name}</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={1}
                >
                    {
                        Object.keys(permissionsMap).map((label, index) => (
                                <Grid item key={label}>
                                    <LabeledCheckbox checked={permissionsMap[label]}
                                                     onChange={(event) => {updatePermission(label, event.target.checked)}}
                                                     label={label}/>
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )

};
export default EditPermissionsDialog;