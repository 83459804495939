import {Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Server from "../../Server";
import LoadingScreen from "../../LoadingScreen";

const ReceivePOShipmentDialog = props => {

    const shipment = props.shipment;

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.open) {
            console.log('poshipment dialog useeffect');
            new Server().fetchWarehouses(response => {
                setWarehouses(response.data);
            });
        }
    },[props.open])

    const receive = () => {
        setLoading(true);
        new Server().receivePOShipment(shipment.number, warehouse.id, response => {
            setLoading(false);
            if(response.status === 200) {
                props.onConfirm();
            } else {
                alert(response.data);
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle disableTypography><Typography variant="h4">Select destination warehouse</Typography></DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={3}>

                    <Grid item>
                        <Autocomplete
                            options={warehouses}
                            getOptionLabel={w => w.name}
                            value={warehouse}
                            onChange={(e,v,r) => setWarehouse(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Warehouse" variant="outlined"/>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="default">
                    Cancel
                </Button>
                <Button onClick={() => {receive()}} color="primary">
                    Receive
                </Button>
            </DialogActions>
            <LoadingScreen loading={loading}/>
        </Dialog>
    );
};

export default ReceivePOShipmentDialog;