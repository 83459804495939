import React, {useState} from 'react';
import {Card, Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    rootCrd: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Filters = props => {
    const classes = useStyles();

    const onSearchTextChanged = props.onSearchTextChanged;

    const [searchQuery, setSearchQuery] = useState("");

    const onSearchQueryChange = (e) => {
        const newQuery = e.target.value;
        setSearchQuery(e.target.value);
        if(newQuery === "") {
            onSearchTextChanged("");
        }
    };

    const onSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearchTextChanged(e.target.value);
        }
    };

    return (
        <div>
            <Card className={classes.rootCrd}>
                <Grid container spacing={1} alignItems="center" direction={"row"}>

                    <Grid item>
                        <TextField fullWidth label="Filter" variant="outlined"
                                   size="small"
                                   style={{ width: 300 }}
                                   value={searchQuery}
                                   onChange={onSearchQueryChange}
                                   onKeyDown={onSearchKeyDown}
                        />
                    </Grid>


                </Grid>
            </Card>
        </div>
    );
};

export default Filters;