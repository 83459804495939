import React, {createContext, useReducer} from 'react';

const initialState = {refreshCounter: 0};
const PODetailsContext = createContext(initialState);
const { Provider } = PODetailsContext;

const PoDetailsProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'RELOAD_PO_STATS':
                const newState = {
                    refreshCounter: state.refreshCounter + 1,
                }
                return newState;
            default:
                throw new Error();
        }
    }, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {PODetailsContext, PoDetailsProvider}