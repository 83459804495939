import React from 'react';
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import InfoIcon from '@material-ui/icons/Info';
import TooltipLabel from "../../Common/TooltipLabel";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},
    productImage: {
        float: 'left',
        width:  50,
        height: 75,
        objectFit: 'cover',
        borderRadius: '5%',
    },
}));

const TopSellingTable = props => {
    const classes = useStyles();

    const products = props.products;

    return (
        <Card className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">Rank</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">% total sales</TableCell>
                            <TableCell align="center">
                                <TooltipLabel labelText={"Stock cover"} tipText={"Based on current channel stock levels and past 7 day sales"}/>
                            </TableCell>
                            <TableCell align="center">
                                <TooltipLabel labelText={"Channel stock"} tipText={"Current channel stock level"}/>
                            </TableCell>
                            <TableCell align="center">Collection</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            products.map((product, index) => (
                                <TableRow key={product.product_code}>
                                    <TableCell>{product.product_code}</TableCell>
                                    <TableCell><img className={classes.productImage} src={product.image_url} alt={product.product_code}/></TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell align="center">{product.rank}</TableCell>
                                    <TableCell align="center">{product.sold_units_in_period}</TableCell>
                                    <TableCell align="center">{product.perc_of_total_sold}</TableCell>
                                    <TableCell align="center">{product.stock_cover}</TableCell>
                                    <TableCell align="center">{product.channel_stock}</TableCell>
                                    <TableCell align="center">{product.collection}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
};

export default TopSellingTable;