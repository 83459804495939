import React from 'react';
import {Grid, Tooltip} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const TooltipLabel = props => {
    const labelText = props.labelText;
    const tipText = props.tipText;

    const classes = useStyles();

    return (
        <div>
        <Tooltip title={tipText}>

            <Grid container direction="row" spacing={1} alignItems={"center"} justifyContent={"center"} >
                <Grid item>
                    {labelText}
                </Grid>
                <Grid item>
                    <InfoIcon fontSize="small" className={classes.icon}/>
                </Grid>
            </Grid>

        </Tooltip>
        </div>
    );
};

export default TooltipLabel;