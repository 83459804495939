import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import ChannelOrdersHeader from "./ChannelOrdersHeader";
import ChannelOrdersTable from "./ChannelOrdersTable";
import LoadingScreen from "../../LoadingScreen";
import StockPager from "../../Stock/StockPager";
import Filters from "./Filters";
import Server from "../../Server";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },

    conversationPlaceholder: {
        flexGrow: 1
    },
}));

const ChannelOrders = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);

    const [statuses, setStatuses] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const thirtyDaysAgo = moment().subtract(30,'d')
    const [fromDate, setFromDate] = useState(thirtyDaysAgo);
    const [toDate, setToDate] = useState(new Date());

    const [channelOrders, setChannelOrders] = useState([]);

    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    useEffect(() => {document.title = 'Channel orders';}, []);

    useEffect(() => {
        new Server().fetchChannels((response) => {
           setChannels(response.data);
        });

        new Server().fetchOrderStatuses((response) => {
            setStatuses(response.data.statuses);
        });

        fetchChannelOrdersByStatus(currentPageNumber, pageSize, null, [], fromDate, toDate);
    }, []);


    const fetchChannelOrdersByStatus = (pageNumber, pageSize, channel, statuses, fromDate, toDate) => {
        setLoading(true);
        new Server().fetchChannelOrders(channel, statuses, fromDate, toDate, pageNumber, pageSize, (response) => {
            setLoading(false);
            setChannelOrders(response.data);
            const headers = response.headers;
            setPageSize(parseInt(headers['page-size']));
            setPageCount(parseInt(headers['page-count']));
            setCurrentPageNumber(parseInt(headers['page-number']));
            setHasNextPage(headers['has-next'] === 'True')
            setHasPreviousPage(headers['has-previous'] === 'True')
            setSelectedChannel(channel);
            setSelectedStatuses(statuses);
            setFromDate(fromDate);
            setToDate(toDate);
        });
    };

    const onPageSizeChange = (event) => {
        fetchChannelOrdersByStatus(currentPageNumber, event.target.value, selectedChannel, selectedStatuses, fromDate, toDate);
    };

    const onNextPageClick = (event) => {
        fetchChannelOrdersByStatus(currentPageNumber + 1, pageSize, selectedChannel, selectedStatuses, fromDate, toDate)
    };

    const onPreviousPageClick = (event) => {
        fetchChannelOrdersByStatus(currentPageNumber - 1, pageSize, selectedChannel, selectedStatuses, fromDate, toDate)
    };

    const onApplyFilterClick = () => {
        fetchChannelOrdersByStatus(1, pageSize, selectedChannel, selectedStatuses, fromDate, toDate);
    };

    const onClearFiltersClick = () => {
        fetchChannelOrdersByStatus(1, pageSize, null, [], thirtyDaysAgo, new Date());
    };

    return (
        <div className={classes.root}>

            <ChannelOrdersHeader/>

            <Filters
                channels={channels} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
                statuses={statuses} selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses}
                fromDate={fromDate} setFromDate={setFromDate}
                toDate={toDate} setToDate={setToDate}
                onApplyFilterClick={onApplyFilterClick} onClearFiltersClick={onClearFiltersClick}
            />

            <ChannelOrdersTable orders={channelOrders} className={classes.conversationPlaceholder}/>

            <StockPager pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        currentPageNumber={currentPageNumber}
                        pageCount={pageCount}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        onNextPageClick={onNextPageClick}
                        onPreviousPageClick={onPreviousPageClick}
            />
            <LoadingScreen loading={loading}/>
        </div>
    );
}

export default ChannelOrders;