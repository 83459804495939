import Card from "@material-ui/core/Card/Card";
import React, {useEffect, useState} from "react";
import {CardContent, CardHeader, Divider, IconButton, TableHead} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddVariantDialog from "./AddVariantDialog";
import Server from "../../Server";
import EditVariantButton from "./EditVariantButton";

const useStyles = makeStyles(theme => ({
    table: {
        width: 600
    },
}));

const ProductEans = props => {

    const productCode = props.productCode;

    const classes = useStyles();

    const [variants, setVariants] = useState([]);

    useEffect( () => {
        reloadVariants();
    }, [productCode]);

    const reloadVariants = () => {
        new Server().fetchProductVariants(productCode, response => {
           setVariants(response.data);
        });
    };

    const deleteVariant = (variantId) => {
        new Server().deleteProductVariant(productCode, variantId, (response) => {
           if(response.status === 200) {
               reloadVariants()
           } else {
               alert(response.data);
           }
        });
    };

    const onVariantEdited = () => {
        reloadVariants();
    };

    const AddVariantButton = props => {
        const productCode = props.productCode;
        const onConfirm = props.onConfirm;

        const [open, setOpen] = useState(false);

        return (
            <>
                <IconButton size="small" color="primary" onClick={()=>setOpen(true)}>
                    <AddCircleIcon size="small"/>
                </IconButton>
                <AddVariantDialog productCode={productCode} open={open} onClose={() => setOpen(false)} onConfirm={onConfirm}/>
            </>
        );
    };

    return (
        <Card>
            <CardHeader title="Variants"/>
            <Divider/>
            <CardContent>
                {/*<TableContainer>*/}

                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell size="small">SKU</TableCell>
                                <TableCell size="small">EAN</TableCell>
                                <TableCell size="small">Size</TableCell>
                                <TableCell size="small">
                                    <AddVariantButton productCode={productCode} onConfirm={onVariantEdited}/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                variants.map((variant, index) => (
                                    <TableRow key={variant.id}>
                                        <TableCell size="small">{variant.sku}</TableCell>
                                        <TableCell>{variant.ean}</TableCell>
                                        <TableCell size="small">{variant.size.name}</TableCell>
                                        <TableCell size="small">
                                            <EditVariantButton productCode={productCode} variantId={variant.id} onConfirm={onVariantEdited}/>

                                            <IconButton size="small" color="default" onClick={() => deleteVariant(variant.id)}>
                                                <RemoveCircleIcon size="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                {/*</TableContainer>*/}
            </CardContent>
        </Card>
    );
};

export default ProductEans;