import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid} from "@material-ui/core";
import Header from "./Header";
import ImageSetter from "./ImageSetter";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

const GenericReport = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header/>

            {/*content start*/}
            <Grid
                className={classes.content}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item>
                    <ImageSetter/>
                </Grid>

            </Grid>
        </div>
    );
}

export default GenericReport;