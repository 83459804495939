import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Autocomplete} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import Server from "../Server";

const NewStockDialog = props => {

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [variants, setVariants] = useState([]);
    const [variant, setVariant] = useState(null);
    const [available, setAvailable] = useState(0);
    const [committed, setCommitted] = useState(0);

    const server = new Server();

    useEffect(() => {
        server.fetchWarehouseCodes((response) => {
            setWarehouses(response.data);
        });

        server.fetchVariants((response) => {
            setVariants(response.data);
        })
    }, [props.open]);

    const confirm = () => {
        if(!warehouse || !variant) {
            alert('Warehouse and variant need to be set.');
            return;
        }

        const data = {
                variant_id: variant.id,
                available: available,
                committed: committed,
        };
        server.newStockItem(warehouse, data, (response) => {
            if(response.status === 200) {
                props.onConfirm();
            } else {
                alert('Error: \n' + JSON.stringify(response.data));
            }
        });

    }

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle disableTypography><Typography variant="h4">New stock item</Typography></DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                >

                    <Grid item>
                        <Autocomplete
                            options={warehouses}
                            getOptionLabel={w => w}
                            value={warehouse}
                            onChange={(e, v, r) => setWarehouse(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Warehouse" variant="outlined"/>}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={variants}
                            getOptionLabel={s => s.sku}
                            value={variant}
                            onChange={(e, v, r) => setVariant(v)}
                            autoHighlight
                            style={{width: 300}}
                            renderInput={params => <TextField {...params} label="Variant" variant="outlined"/>}
                        />
                    </Grid>


                    <Grid item>
                        <TextField fullWidth label="Available" variant="outlined"
                                   value={available}
                                   onChange={(e) => setAvailable(e.target.value)}/>
                    </Grid>

                    <Grid item>
                        <TextField fullWidth  label="Committed" variant="outlined"
                                   value={committed}
                                   onChange={(e) => setCommitted(e.target.value)}/>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default NewStockDialog;