import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {
    Button,
    Card,
    Checkbox, CircularProgress, IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Toolbar, Tooltip, Typography
} from "@material-ui/core";
import Utils from "../Utils";
import {lighten} from "@material-ui/core/styles";
import clsx from "clsx";
import ShareDialog from "./ShareDialog";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import UnshareDialog from "./UnshareDialog";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        maxHeight: '100%',
    },
    NumberCell : { cursor: 'pointer'},

    paper: {
        width: '100%',
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cell: {
        paddingTop: 6,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 6,
    }

}));

const DDTable = props => {

    const selected = props.selectedDesignDocs;
    const setSelected = props.setSelectedDesignDocs;
    const docs = props.designDocs;
    const setShouldRefreshData = props.setShouldRefreshData;

    const classes = useStyles();

    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openUnshareDialog, setOpenUnshareDialog] = useState(false);

    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        deleteButton: {
            color: theme.palette.red,
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }));

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();
        const { numSelected, share, unshare } = props;

        return numSelected === 0 ? null : (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
                variant={"dense"}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        Design documents
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Button className={classes.deleteButton} color="default" variant="text" onClick={unshare}>Revoke access</Button>
                ) : null}
                {numSelected > 0 ? (
                    <Button color="primary" variant="text" onClick={share}>Share</Button>
                ) : null}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = docs.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onRowCheckBoxClick = (event, docId) => {
        const selectedIndex = selected.indexOf(docId);
        let newSelected = JSON.parse(JSON.stringify(selected));

        if (selectedIndex === -1) {//if not selected, add
            newSelected.push(docId);
        } else {//if already selected, remove
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const rowCount = docs.length;
    const numSelected = selected.length;

    const share = () => {
        setOpenShareDialog(true);
    };

    const onConfirmShareDialog = props => {
        setOpenShareDialog(false);
        setShouldRefreshData(true);
    };

    const unshare = () => {
        setOpenUnshareDialog(true);
    };


    const onConfirmUnshareDialog = props => {
        setOpenUnshareDialog(false);
        setShouldRefreshData(true);
    };

    return (
        <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} share={share} unshare={unshare}/>

            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    size="small"
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell size="small">Product</TableCell>
                            <TableCell size="small">File</TableCell>
                            <TableCell size="small">Type</TableCell>
                            <TableCell size="small">Suppliers</TableCell>
                            <TableCell size="small">Added</TableCell>
                            <TableCell size="small">Last modified</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            docs.map((doc, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={doc.id}
                                    selected={isSelected(doc.id)}
                                >
                                    <TableCell padding="checkbox" onClick={(event) => onRowCheckBoxClick(event, doc.id)}>
                                        <Checkbox
                                            size="small"
                                            checked={isSelected(doc.id)}
                                        />
                                    </TableCell>
                                    <TableCell size="small">{doc.product.code}</TableCell>
                                    <TableCell size="small">
                                        <Link href={doc.url} rel="noopener noreferrer" target="_blank">
                                            <span>{doc.filename}</span>
                                        </Link>
                                    </TableCell>
                                    <TableCell size="small">{doc.doc_type}</TableCell>
                                    <TableCell size="small">
                                        {
                                            doc.suppliers.map(
                                                (supplier, index) => (
                                                    <div key={supplier.id}>
                                                        <span>{supplier.name}</span>
                                                        <br/>
                                                    </div>
                                                )
                                            )
                                        }
                                    </TableCell>

                                    <TableCell size="small">{Utils.formatUserAndDate(doc.created_by, doc.created_date)}</TableCell>
                                    <TableCell size="small">{Utils.formatUserAndDate(doc.last_modified_by, doc.last_modified)}</TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            <ShareDialog open={openShareDialog} onConfirm={onConfirmShareDialog} onClose={() => setOpenShareDialog(false)} selectedDocumentIds={selected}/>
            <UnshareDialog open={openUnshareDialog} onConfirm={onConfirmUnshareDialog} onClose={() => setOpenUnshareDialog(false)} selectedDocumentIds={selected}/>
        </Paper>
    )

};

export default DDTable;