import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import Server from "../../Server";
import NavigationService from "../../NavigationService";
import AuthService from "../../AuthService";

const PoShipments = (props) => {

    const history = useHistory();
    const nav = new NavigationService(history)

    const [shipments, setShipments] = useState([]);
    
    useEffect(()=>{
        new Server().getPoShipments(props.poNumber, (response) => {
            setShipments(response.data)
        });
    },[props.poNumber]);

    return(
        <Card>
            <CardHeader title="Shipments"/>
            <Table size="small">
                <TableBody>
                    {shipments.map((shipment) => (
                            <TableRow key={shipment.id}
                                      onClick={(e) => AuthService.isAdminLoggedIn() ? nav.goToPoShipmentDetails(shipment.number) : nav.goToSupplierPOShipmentDetails(shipment.number)}>
                                <TableCell>{shipment.number}</TableCell>
                                <TableCell>{shipment.status}</TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </Card>
    )
};

export default PoShipments;